import React, { useEffect } from 'react'
import { Routes, Route, NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Details from "./Details";
import Results from "./Results";
import Instructions from "./Instructions";
import ResultsUpload from './ResultsUpload';
import ResultsShow from './ResultsShow';

const style = {
    li: "py-2 lg:py-4 px-2 lg:px-10 uppercase font-bold border-2 border-b-none w-full",
    active: "bg-yellow-c1 border-4 py-2 lg:py-4 px-2 lg:px-10 uppercase font-bold border-b-none w-full text-white",
};
export const EventBody = () => {
    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
        window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
        setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
            element.scrollIntoView();
            }
        }, 0);
        }
    }, [pathname, hash, key]);
    const r_event = useSelector((state) => state.event);
    
    return (
        <>
            <ul className="flex flex-row w-full overflow-x-auto" id="event-tabs">
                <li className="flex my-auto sm:w-full md:w-2/6">
                    <NavLink
                        end
                        to={`/event-detail/${r_event.event.event_uuid}/#event-tabs`}
                        className={({ isActive }) =>
                            isActive ? style.active : style.li
                        }
                    >
                    Description
                    </NavLink>
                </li>
                {r_event.event_engagement && r_event.event_engagement.length > 0 ? (
                    <li className="flex my-auto sm:w-full md:w-2/6">
                        <NavLink
                            end
                            to="instructions#event-tabs"
                            className={({ isActive }) =>
                            isActive ? style.active : style.li
                            }
                        >
                            Instructions
                        </NavLink>
                    </li>
                ) : (
                    <li className="flex my-auto sm:w-full md:w-2/6">
                        <div className={`${style.li}`}>
                            <div className="flex flex-row">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 my-auto pr-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                            </svg>
                            <span>Instructions</span>
                            </div>
                        </div>
                    </li>
                )}

                {r_event.event_engagement && r_event.event_engagement.length > 0 ? (
                    <li className="flex my-auto sm:w-full md:w-2/6">
                        <NavLink
                            to="upload-proof#event-tabs"
                            className={({ isActive }) =>
                            isActive ? style.active : style.li
                            }
                        >
                            <span className="line-clamp-1">Submit your proofs</span>
                        </NavLink>
                    </li>
                ) : (
                    <li className="flex my-auto sm:w-full md:w-2/6">
                        <div className={`${style.li}`}>
                            <div className="flex flex-row w-full">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 my-auto pr-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                            </svg>
                            <span className="line-clamp-1">Submit your proofs</span>
                            </div>
                        </div>
                    </li>
                )}

                
                {r_event.event.closed_event == 1? (
                    <li className="flex my-auto sm:w-full md:w-2/6">
                        <NavLink
                            to="results#event-tabs"
                            className={({ isActive }) =>
                            isActive ? style.active : style.li
                            }
                        >
                            <span className="line-clamp-1">Results</span>
                        </NavLink>
                    </li>
                ) : (
                    <li className="flex my-auto sm:w-full md:w-2/6">
                        <div className={`${style.li}`}>
                            <div className="flex flex-row w-full">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 my-auto pr-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                            </svg>
                            <span className="line-clamp-1">Results</span>
                            </div>
                        </div>
                    </li>
                )}

            </ul>
            <Routes>
                <Route index path="" element={<Details />} />
                <Route path="instructions" element={<Instructions />} />
                <Route
                    path="upload-proof"
                    element={<ResultsUpload />}
                />
                <Route
                    path="results"
                    element={<ResultsShow />}
                />
            </Routes>

        </>
    )
}

import CustomModal from "../../components/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import AdminInput from "../../components/admin/admin.input";
import AdminButton from "../../components/admin/admin.button";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { deleteWdMethods } from "../../store/actions/_withdrawalMethods.actions";

export default function WdMethodsDelete({ status, close }) {
  const dispatch = useDispatch();
  const r_wdmethod = useSelector((state) => state.wdmethod);
  const [data, setData] = useState(undefined);

  useEffect(() => {
    setData(r_wdmethod.wdmethods[r_wdmethod.modal.key]);
    return () => {
      setData(undefined);
    };
  }, [r_wdmethod.modal.key, r_wdmethod.wdmethods]);

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(deleteWdMethods(r_wdmethod.modal.key, data.id));
  };

  return (
    data && (
      <CustomModal
        title="Delete Withdrawal Method"
        size="w-1/3"
        status={status}
        closedButton={close}
      >
        <form onSubmit={handleSubmit}>
          <AdminInput
            className=""
            onChange={handleChange}
            type="text"
            id="name"
            placeholder="Method Name"
            errors={r_wdmethod.error}
            // label="Role Name"
            value={data.name}
            disabled={true}
            // pattern=""
          />
          <div className="w-1/2 mx-auto">
            <AdminButton
              status={r_wdmethod.btnLoading}
              text="Delete"
              type="submit"
              icon={faTrash}
            />
          </div>
        </form>
      </CustomModal>
    )
  );
}

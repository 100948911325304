import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import HeaderSection from "../../../../components/HeaderSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";

export default function HowToWin() {
  const [data, setData] = useState(undefined);
  const r_main = useSelector((state) => state.main.how_to_win);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    setData(r_main[0]);
  }, [r_main]);

  const handleClick = (item, key) => {
    setData(item);
    setCurrent(key);
  };

  return (
    data && (
      <section className="w-full h-auto p-10 bg-no-repeat bg-cover bg-[url('../img/backgrounds/white-background.png')] flex flex-col justify-center items-center">
        <HeaderSection
          titleStyle="black"
          sub={t("howtoplay.how_to_win.sub")}
          title1={t("howtoplay.how_to_win.title_1")}
          title2={t("howtoplay.how_to_win.title_2")}
        />
        <div className="w-11/12 lg:p-10 mt-10">
          <div className="bg-white lg:bg-none p-2">
            <div className="grid grid-cols-1 lg:grid-cols-3">
              <div className="flex flex-nowrap overflow-x-auto w-full lg:flex-col">
                {r_main.map((item, key) => (
                  <BulletButtonHowToPlay
                    key={key}
                    text={`${t(item.btn_text)}`}
                    className="w-[300px] text-xl shrink-0 lg:w-2/3 mb-5 lg:mb-10 aldo"
                    active={current === key ? true : false}
                    onClick={() => handleClick(item, key)}
                    icon={item.icon}
                  />
                ))}
              </div>
              <div className="ring-4 ring-gray-300 p-5 col-span-2">
                <img
                  className="w-1/3 float-left mr-4 mb-4"
                  src={data.cover}
                  alt=""
                />
                <div className="">
                  <div className="font-bold text-xl uppercase mb-5 aldo">
                    {t(data.title_1)}{" "}
                    <span className="text-yellow-c1">
                      {t(data.title_2)}
                    </span>
                  </div>
                  <p className="text-xs md:text-sm lg:text-lg segoe">
                    {t(data.description_1)}
                  </p>
                  <p className="text-xs md:text-sm lg:text-lg segoe pt-3">
                    {t(data.description_2)}
                  </p>
                  <p className="text-xs md:text-sm lg:text-lg segoe pt-3">
                    {t(data.description_3)}
                  </p>
                  {data.blockquote && (
                    <blockquote className="text-xs md:text-sm lg:text-lg w-10/12 mx-auto mt-10 italic">
                      <FontAwesomeIcon
                        icon={faQuoteLeft}
                        className="text-xl mr-2"
                      />
                      {t(data.blockquote)}
                      <FontAwesomeIcon
                        icon={faQuoteRight}
                        className="text-xl ml-2"
                      />
                      <span className="float-right font-bold">
                        Ken Johnson
                      </span>
                    </blockquote>
                  )}
                  {data.steps.length > 0 && (
                    <div className="w-11/12 mx-auto clear-both text-xs md:text-sm">
                      <h2 className="font-bold underline uppercase mb-5">
                        {t("home.howto.steps")}
                      </h2>
                      <ol className="list-decimal">
                        {data.steps.map((step, step_key) => (
                          <li key={step_key}>{t(step)}</li>
                        ))}
                      </ol>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 lg:mt-0 p-2"></div>
        </div>
      </section>
    )
  );
}

function BulletButtonHowToPlay(props) {
  const { text, className, onClick, icon, active } = props;

  let style1 = active
    ? "bg-black text-white uppercase mx-auto w-[95%] lg:w-[96%] p-5 font-bold flex items-center justify-center"
    : "bg-yellow-c1 text-black uppercase mx-auto w-[95%] lg:w-[96%] p-5 font-bold flex items-center justify-center";
  let style2 = active
    ? "bg-black text-black uppercase text-base mx-auto w-[3%] lg:w-[2%]"
    : "bg-yellow-c1 text-black uppercase text-base mx-auto w-[3%] lg:w-[2%]";

  return (
    <div
      className={`flex justify-center mx-auto ${className} active:scale-90`}
    >
      <button
        className={style1}
        style={{ transform: "skew(-20deg)" }}
        onClick={onClick}
      >
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            className="mr-2 text-lg lg:text-3xl lg:font-bold"
          />
        )}
        {text}
      </button>
      <div className={style2} style={{ transform: "skew(-20deg)" }}></div>
    </div>
  );
}

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import AddAccount from "./add-account";
import Loading from '../../../../components/Loading'
import { getAccounts, removeAccount } from "../../../../store/actions/_withdraws.actions";
import { accountsModal } from "../../../../store/reducers/_withdraws.reducers";
import "./style.scss";

export default function WithdrawMethods() {
  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.withdraw);

  useEffect(() => {
    if (!accounts.accounts.loaded) {
      dispatch(getAccounts());
    }
  }, [accounts.accounts.loaded, dispatch]);

  const handleRemoveAccount = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(removeAccount(id));
    }
  }

  return (
    <div>
      <AddAccount />
      {accounts.accounts.loading ? <Loading /> : 
      <>
      <div className="w-full flex justify-end">
        <button
          onClick={() => dispatch(accountsModal(true))}
          className="w-auto ml-2 transform-skew-20 bg-[#001D3D] font-bold uppercase aldo p-3 text-[#FFC300]"
        >
          <FontAwesomeIcon icon={faPlus} /> Add Account
        </button>
      </div>
      {accounts.accounts.accounts.length > 0 ? (
        <table className="table mt-10 border-table">
          <thead>
            <tr>
              <th>Method</th>
              <th>Alias</th>
              <th>Email</th>
              <th>Username</th>
              {/* <th>Number</th>
              <th>Routing</th> */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {accounts.accounts.accounts.map((item, key) => (
              <tr key={key}>
                <td>{item.withdrawal_method.name}</td>
                <td>{item.alias}</td>
                <td>{item.email}</td>
                <td>{item.username}</td>
                {/* <td></td>
                <td></td> */}
                <td>
                  <span className="bg-[#FFC300] p-[5px] rounded-[3px]" onClick={ () => handleRemoveAccount(item.id) }>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="text-[#061322] cursor-pointer"
                      
                    />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>No Accounts</div>
      )}
      </>}
    </div>
  );
}

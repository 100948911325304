import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import BulletButton from "../../../components/BulletButton";
import { joinEventModal } from "../../../store/reducers/_events.reducers";
import { modal } from "../../../store/_main.reducers";
import parse from 'html-react-parser';
import { EventHeader } from "./EventHeader";
import { EventSpecification } from "./EventSpecification";

export default function Details() {
  const r_event = useSelector((state) => state.event);
  const r_auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleModal = () => {
    dispatch(modal(r_auth.logged));
    dispatch(joinEventModal(true));
  };

  // console.log(r_event.event);

  return (
    <>

      <div className="my-3">
        <p>{r_event.event.brief_description && parse(r_event.event.brief_description)}</p>
      </div>




{/* 
      <div className="border-4 border-gray-300 p-4 lg:p-10 w-full lg:w-3/4 bg-white">
        <div className="w-full flex justify-between lg:flex-row flex-col">
          <div id="title" className="w-3/4">
            <div className="text-yellow-600 font-bold text-sm">
              {r_event.event.start_date_us_format}{" "}
              <span className="text-black">to</span>{" "}
              {r_event.event.end_date_us_format}
            </div>
            <h2 className="text-bold font-bold text-lg uppercase">
              {r_event.event.title}
            </h2>
          </div>
          <div className="w-full lg:w-1/4 my-5 flex flex-col items-center justify-center">
            {r_event.event.blocked && (
              <CountDown date={r_event.event.full_start_time} />
            )}
            {r_auth.logged !== undefined &&
              r_event.event_engagement.length === 0 && (
                <BulletButton
                  text={r_auth.logged ? "Join Event" : "Login and Join"}
                  onClick={handleModal}
                  className="w-full mt-2"
                  icon=""
                />
              )}
          </div>
        </div>
        <p className="m-2 lg:m-5">
          {r_event.event.brief_description &&
            parse(r_event.event.brief_description)}
        </p>
        <h2 id="specific-games" className="font-bold text-lg uppercase">
          Specifications
        </h2>
        <div className="mt-3">
          <div className="flex border-b border-gray-200 py-3">
            <div className="font-bold mr-3">Created by:</div>
            <div>
              {r_event.event.user_owner?.id === 32 || r_event.event.user_owner?.id === 11 ? (
                <>ITHL Sponsored <FontAwesomeIcon icon={faCircleCheck} className="text-blue-600" /></>
              ) : (
                <Link
                  className="text-blue-400 hover:underline"
                  to={`/profile/${
                    r_event.event.user_owner?.username ||
                    r_event.event.user_owner?.uuid
                  }/about`}
                >
                  {r_event.event.user_owner?.username ||
                    r_event.event.user_owner?.name}
                </Link>
              )}
            </div>
          </div>
          <div className="flex border-b border-gray-200 py-3">
            <div className="font-bold mr-3">Points for completing event:</div>
            <div>{r_event.event.max_points_seasson}</div>
          </div>
          <div className="flex border-b border-gray-200 py-3">
            <div className="font-bold mr-3">Platform:</div>
            <div>{r_event.event.platform}</div>
          </div>
          <div className="flex border-b border-gray-200 py-3">
            <div className="font-bold mr-3">Type Of Event:</div>
            <div>{r_event.event.type_of_event}</div>
          </div>
          <div className="flex border-b border-gray-200 py-3">
            <div className="font-bold mr-3">Requirements:</div>
            <div>{r_event.event.requirements}</div>
          </div>
        </div>

        <div
          id="details"
          className="mt-7 grid grid-cols-1 lg:grid-cols-2 gap-x-3"
        >
          <div>
            <div className="border-t-4 border-yellow-400 border-l-4 border-l-gray-300 border-r-4 border-r-gray-300 border-b-4 border-b-gray-300 p-5 mt-5 mx-auto w-11/12">
              <h2 className="uppercase font-bold text-lg">Awards</h2>
              <div className="mt-5">
                <div className="flex mb-5  items-center">
                  <div className="font-bold mr-3 w-1/2 lg:w-2/5">
                    Big Award:
                  </div>
                  <div className="transform-skew-20 bg-yellow-400 p-3 font-bold w-1/2 lg:w-2/5 text-center">
                    ${r_event.event.first_place_reward}
                  </div>
                </div>

                <div className="flex mb-5  items-center">
                  <div className="font-bold mr-3 w-1/2 lg:w-2/5">
                    1st Place:
                  </div>
                  <div className="transform-skew-20 bg-yellow-400 p-3 font-bold w-1/2 lg:w-2/5 text-center">
                    {r_event.event.first_place_extra_points} Points
                  </div>
                </div>

                {r_event.event.second_place_reward && (
                  <div className="flex mb-5 items-center">
                    <div className="font-bold mr-3 w-1/2 lg:w-2/5">
                      2nd Place:
                    </div>
                    <div className="transform-skew-20 bg-yellow-400 p-3 font-bold w-1/2 lg:w-2/5 text-center">
                      {r_event.event.second_place_reward} Points
                    </div>
                  </div>
                )}
                {r_event.event.third_place_reward && (
                  <div className="flex items-center">
                    <div className="font-bold mr-3 w-1/2 lg:w-2/5">
                      3th Place:
                    </div>
                    <div className="transform-skew-20 bg-yellow-400 p-3 font-bold w-1/2 lg:w-2/5 text-center">
                      {r_event.event.third_place_reward} points
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full mt-5 lg:mt-0">
            {r_event.event.blocked ? (
              <img className="w-full" src={r_event.event.cover} alt="" />
            ) : (
              <img src={r_event.event.screenshot} alt="" />
            )}
          </div>
        </div>
      </div> */}
    </>
  );
}


import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18next, { t } from "i18next";
import { initReactI18next } from "react-i18next";
// Redux
import store from "./store/store";
import { Provider } from "react-redux";
import { library } from "@fortawesome/fontawesome-svg-core";
// CSS
import "./assets/css/tailwind.css";
import "animate.css";
import "./assets/css/custom.css";
import english from './utils/languages/en.json'
import spanish from './utils/languages/es.json'
import {
   faUsers,
   faGears,
   faCalendarDays,
   faUser,
   faHeadset,
   faGamepad,
   faNewspaper,
   faWallet,
   faCoins,
   faCashRegister
} from "@fortawesome/free-solid-svg-icons";
library.add(
   faUsers,
   faGears,
   faCalendarDays,
   faUser,
   faHeadset,
   faGamepad,
   faNewspaper,
   faWallet,
   faCoins,
   faCashRegister
);


const root = ReactDOM.createRoot(document.getElementById("root"));


i18next.use(initReactI18next).init({
   resources: {
      en: english,
      es: spanish,
   },
   lng: localStorage.getItem('lng') || 'en',
   fallbackLng: "en",
   interpolation: { escapeValue: false },
});

root.render(
   <React.StrictMode>
      <Provider store={store}>
         <App />
      </Provider>
   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

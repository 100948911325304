import { useSelector } from 'react-redux';

export default function ProfileBanner() {
	const { banner } = useSelector( state => state.pageProfile.user)

	return (
		<div className="w-full h-48 bg-green-200 flex items-center justify-center">
			<img src={banner} className="w-full h-full object-cover object-center" alt="" />
		</div>
	)
}
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  faChevronLeft,
  faSpinner,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ResetPasswordAction } from "../../store/actions/_auth.actions";
import { joinEventModal } from "../../store/reducers/_events.reducers";
import { errorLogin } from "../../store/reducers/_auth.reducers";
import { t } from "i18next";

export default function ResetPassword() {
  const dispatch = useDispatch();
  let { token } = useParams();
  const [data, setData] = useState({
    email: "",
    token: "`",
    password: "",
    password_confirmation: "",
  });
  const r_auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(joinEventModal(false));
  }, [dispatch]);

  useEffect(() => {
    setData({
      ...data,
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, localStorage.getItem("email")]);

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(ResetPasswordAction(data));
  };

  return (
    <div className="w-full h-screen bg-cover bg-no-repeat bg-center bg-[url('../img/banners/bg-login-blur.png')] flex justify-center pt-10 md:p-0 md:items-center">
      <div className="w-4/5 h-3/5 border-4 border-yellow-500 bg-blue-c1 flex">
        <div className="border-r-1 border-yellow-500 h-full w-full p-5 flex flex-col justify-between">
          <Link to="/" className="text-yellow-c1 text-sm md:text-base">
            <FontAwesomeIcon icon={faChevronLeft} /> {t("login.go_home")}
          </Link>
          <div className="w-full">
            <div className="flex flex-col justify-center items-center mb-5 md:mb-10">
              <span className="font-bold text-white text-sm md:text-base">
                {/* {t("login.be_best")} */}
                Reset your password
              </span>
              <span className="text-gray-600 text-xs md:text-sm">
                {/* {t("login.enter_details")} */}
                Enter your email addres
              </span>
            </div>
            <form
              action=""
              className="flex flex-col w-full md:w-3/5 mx-auto mt-5"
              onSubmit={handleSubmit}
            >
              {r_auth.error_login.status && (
                <div className="flex justify-between items-center bg-red-100 text-red-500 border border-red-500 mb-4 p-2">
                  {r_auth.error_login.message}
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="cursor-pointer"
                    onClick={() =>
                      dispatch(errorLogin({ status: false, message: "" }))
                    }
                  />
                </div>
              )}
              <input
                defaultValue={data.email}
                name="email"
                type="email"
                id="input_email"
                placeholder={t("login.email")}
                className="bg-blue-c2 p-2 focus:outline-none border border-yellow-600 mb-2 text-white"
                onChange={handleChange}
              />
              <input
                defaultValue={data.password}
                name="password"
                type="password"
                id="input_password"
                placeholder="Password"
                className="bg-blue-c2 p-2 focus:outline-none border border-yellow-600 mb-2 text-white"
                onChange={handleChange}
              />
              <input
                defaultValue={data.password_confirmation}
                name="password_confirmation"
                type="password"
                id="input_password_confirmation"
                placeholder="Password confirmation"
                className="bg-blue-c2 p-2 focus:outline-none border border-yellow-600 mb-2 text-white"
                onChange={handleChange}
              />
              <button
                className={`w-full p-2 mt-3 text-sm md:text-base active:scale-90 bg-yellow-500 ${
                  r_auth.button_loading ? "opacity-80 cursor-not-allowed" : ""
                }`}
                type="submit"
                disabled={r_auth.button_loading}
              >
                {/* {t("login.login")} */}
                {r_auth.button_loading ? (
                  <>
                    <FontAwesomeIcon icon={faSpinner} spin /> Loading
                  </>
                ) : (
                  "Reset Password"
                )}
              </button>
            </form>
          </div>
          <div className="flex justify-start items-end">
            <img
              className="w-14"
              src={require("../../assets/img/logo-treasure/brand-logo.png")}
              alt=""
            />
            <div className="text-white w-full text-center text-xs md:text-sm">
              {/* {t("login.account")}{" "} */}
              <Link to="/auth/login" className="text-yellow-c1">
                {/* {t("login.signup")} */}
                Go Login
              </Link>
            </div>
          </div>
        </div>
        <div className="hidden border-l-2 border-yellow-500 h-full w-full p-10 md:flex flex-col justify-center items-center">
          <img
            className="w-3/5 xl:w-1/3 max-auto"
            src={require("../../assets/img/images/fortnite-game-card.png")}
            alt=""
          />
          <span className="font-bold uppercase text-white mt-5">
            {t("login.upcoming")}{" "}
            <span className="text-yellow-c1">{t("login.events")}</span>
          </span>
          <span className="mt-4 text-gray-500 text-xs">
            {t("login.content")}
          </span>
        </div>
      </div>
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../../components/Modal";
import { AskPayment, EventDetail, PaymentSuccess } from "./components";
import { modal } from "../../../../store/_main.reducers";
import { joinEventModal } from "../../../../store/reducers/_events.reducers";
import "./styles.scss";
import { setEventToPay } from "../../../../store/reducers/reducer.payments";

export const JoinEvent = (props) => {
  const { eventData } = props;

  const dispatch = useDispatch();
  const r_events = useSelector((state) => state.event);
  const r_payment = useSelector((state) => state.payment);

  useEffect(() => {
    dispatch(setEventToPay(r_events.event));
  }, [r_events.event]);

  const handleCancel = () => {
    dispatch(modal(false));
    dispatch(joinEventModal(false));
    window.location.reload();
  };
  return (
    <Modal size="w-full lg:w-[900px] relative">
      <FontAwesomeIcon
        icon={faRemove}
        onClick={handleCancel}
        className="
          absolute
          right-[40px]
          top-[35px]
          text-[25px]
          cursor-pointer
        "
      />

      <div className="max-h-[500px] overflow-y-auto overflow-x-hidden w-full px-4 scrollbar custom-scroll relative top-[20px]">
        {!r_payment.eventToPay.isPaid && (
          <>
            <h2 className="uppercase font-bold font-['AldotheApache'] text-[34px]">
              Are you Ready{" "}
              <span className="text-yellow-c1">to join the challenge?</span>
            </h2>

            {Object.entries(r_events.payment_mode?.response).length === 0 && (
              <EventDetail eventData={eventData} />
            )}

            <AskPayment eventData={eventData} />
          </>
        )}

        {r_payment.eventToPay.isPaid && <PaymentSuccess />}
      </div>
    </Modal>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUsePaymentMethod,
  getPaymentMethods,
  payToJoinEvent,
} from "../../../../../store/actions/_subscriptions.actions";
import { loadStripe } from "@stripe/stripe-js";
import Loading from "../../../../../components/Loading";
import { CreatePaymentMethod } from "./CreatePaymentMethod";
import { useState } from "react";
import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { PaymentMethods } from "../../../../../components/Payment/PaymentMethods";
import { toast } from "react-toastify";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

export function AskPayment({ eventData }) {
  const dispatch = useDispatch();
  const r_payment = useSelector((state) => state.payment);
  const r_events = useSelector((state) => state.event);
  const r_subscription = useSelector((state) => state.subscription);
  const [isVisiblePaymentMethod, setIsVisiblePaymentMethod] = useState(false);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(null);
  const [paymentData, setPaymentData] = useState(null);

  useEffect(() => {
    if (r_events.payment_mode.active) {
      dispatch(createOrUsePaymentMethod());
    }
  }, [r_events.payment_mode.active, dispatch]);

  useEffect(() => {
    dispatch(getPaymentMethods());
  }, []);

  useEffect(() => {
    if (paymentData) {
      if (paymentData.id) {
        const data = {
          paymentMethodId: paymentData.id,
          event_uuid: eventData,
        };
        dispatch(payToJoinEvent(data));
      }
    }
  }, [paymentData]);

  const payUsingThis = () => {
    if (!paymentMethodSelected) {
      toast.error("Select payment method", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      return;
    }

    const data = {
      paymentMethodId: paymentMethodSelected.id,
      event_uuid: eventData,
    };
    dispatch(payToJoinEvent(data));
  };

  return (
    <>
      {r_subscription.loading && <Loading />}

      {!r_payment.eventToPay.isPaid && (
        <>
          {!r_subscription.loading && r_events.payment_mode.active && (
            <>
              {isVisiblePaymentMethod && (
                <>
                  <span
                    className="segoe cursor-pointer mt-1"
                    onClick={() => {
                      setIsVisiblePaymentMethod(false);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      className="text-[11px] relative top-[-1px]"
                    />{" "}
                    Go back
                  </span>
                </>
              )}
              <h3 className="segoe text-[#181818] font-bold text-[24px] mt-[10px] mb-[25px]">
                Add Payment Method
              </h3>
              {r_events.payment_mode.response.type === "pay_event" &&
                !isVisiblePaymentMethod && (
                  <>
                    {!isVisiblePaymentMethod && (
                      <PaymentMethods
                        paymentMethodSelected={paymentMethodSelected}
                        setPaymentMethodSelected={setPaymentMethodSelected}
                      />
                    )}
                  </>
                )}

              {isVisiblePaymentMethod && (
                <CreatePaymentMethod setPaymentData={setPaymentData} />
              )}

              {!isVisiblePaymentMethod && (
                <>
                  <div className="transform-skew-20 bg-yellow-c1 p-4 w-max my-5 bg-[#001D3D] text-[#FFC300]">
                    <button
                      className="font-['AldotheApache'] text-[18px]"
                      onClick={() => {
                        setIsVisiblePaymentMethod(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add new method
                    </button>
                  </div>

                  <div className="event-pric my-5">
                    <ul>
                      <li className="flex relative py-2 border-t border-[#E5E7EB] mb-5">
                        <div className="w-1/2 text-[#6B7280] segoe">
                          Subtotal
                        </div>
                        <div className="w-1/2 text-right text-[#6B7280] segoe">
                          $1.00
                        </div>
                      </li>
                      {/* <li className="flex py-2 border-b border-[#E5E7EB]">
                            <div className="w-1/2 text-[#6B7280] segoe">Taxes</div>
                            <div className="w-1/2 text-right text-[#6B7280] segoe">$0.00</div>
                        </li> */}
                      <li className="flex mt-3">
                        <div className="w-1/2 segoe">Total</div>
                        <div className="w-1/2 text-right segoe">$1.00</div>
                      </li>
                    </ul>
                  </div>
                </>
              )}

              {r_subscription.methods.length > 0 && !isVisiblePaymentMethod && (
                <>
                  <div className="w-full flex justify-end">
                    <div className="transform-section w-max">
                      <button
                        type="submit"
                        className="font-['AldotheApache'] text-[24px]"
                        onClick={payUsingThis}
                      >
                        Pay event
                      </button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

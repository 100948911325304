import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faLock } from "@fortawesome/free-solid-svg-icons";
import { modal } from "../../store/reducers/_role.reducers";

export default function RoleList() {
  const dispatch = useDispatch();
  const r_role = useSelector((state) => state.role);

  return (
    <>
      <div className="w-11/12 mx-auto mt-5">
        <ul className="top-buttons">
          <li className="button-black" onClick={() => dispatch(modal({ create: true }))}>
            Add Role
          </li>
        </ul>
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Updated at</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {r_role.roles.length > 0 &&
              r_role.roles.map((item, key) => (
                <tr key={key}>
                  <td>{item.name}</td>
                  <td>{item.updated_at}</td>
                  <td>
                    <ul className="flex space-x-1">
                      <li className="options" onClick={() => dispatch(modal({ update: true, key }))}>
                        <FontAwesomeIcon
                          className="text-green-600"
                          icon={faEdit}
                        />
                      </li>
                      <li className="options" onClick={() => dispatch(modal({ delete: true, key }))}>
                        <FontAwesomeIcon
                          className="text-red-500"
                          icon={faTrash}
                        />
                      </li>
                      <li className="options" onClick={() => dispatch(modal({ permissions: true, key }))}>
                        <FontAwesomeIcon
                          className="text-blue-500"
                          icon={faLock}
                        />
                      </li>
                    </ul>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWdMethods } from "../../store/actions/_withdrawalMethods.actions";
import { wdmethods } from "../../store/reducers/_withdrawalMethods.reducers";
import List from "./wdmethods.list";
import Create from "./wdmethods.create";
import Update from "./wdmethods.update";
import Delete from "./wdmethods.delete";
import { modal } from "../../store/reducers/_withdrawalMethods.reducers";
import Loading from "../../components/Loading";

export default function WdMethods() {
  const dispatch = useDispatch();
  const r_wdmethod = useSelector((state) => state.wdmethod);

  useEffect(() => {
    dispatch(getWdMethods());
    return () => {
      dispatch(wdmethods({}));
    };
  }, [dispatch]);

  return (
    <>
      {r_wdmethod.loading ? (
        <Loading />
      ) : (
        <>
          <ul className="top-buttons w-11/12 mx-auto mt-5">
            <li
              className="button-black"
              onClick={() => dispatch(modal({ create: true }))}
            >
              Add Method
            </li>
          </ul>
          {r_wdmethod.wdmethods.length > 0 ? (
            <Routes>
              {["", "/"].map((path, key) => (
                <Route path={path} key={key} element={<List />} />
              ))}
            </Routes>
          ) : (
            <div className="w-10/12 mx-auto bg-blue-50 text-blue-400 border border-blue-300 p-3 mt-10">
              No records
            </div>
          )}
          <>
            <Create
              status={r_wdmethod.modal.create}
              close={() => dispatch(modal({ create: false }))}
            />
            <Update
              status={r_wdmethod.modal.update}
              close={() => dispatch(modal({ update: false }))}
            />
            <Delete
              status={r_wdmethod.modal.delete}
              close={() => dispatch(modal({ delete: false }))}
            />
          </>
        </>
      )}
    </>
  );
}

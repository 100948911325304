/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHistoryEventsAction } from "../../../../store/actions/_pageProfile.actions";
import { BasicEventCard } from "../../../../components/basicEventCard/index";
// import Loading from '../../../../../../components/Loading';
// import { CustomNotFoundData } from './CustomNotFoundData';

export default function Name() {
  const dispatch = useDispatch();
  const r_pp = useSelector((state) => state.pageProfile);

  useEffect(() => {
    dispatch(getHistoryEventsAction(r_pp.user.uuid));
  }, []);

  return (
    <div id="event-explorer" className="scrollbar custom-scroll">
      <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-4 m-auto">
        {r_pp.historyEvents.map((event, key) => {
          return <BasicEventCard className="" data={event} key={key} />;
        })}
      </div>

      {/* {isLoading && <Loading color="white" />}

      {listEvents.length < 1 && !isLoading && (
        <CustomNotFoundData
          categoryEvent={categoryEvent}
          eventFilter={eventFilter}
        />
      )} */}
    </div>
  );
}

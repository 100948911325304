import { t } from "i18next";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useState } from "react";

export default function Header() {

  const location = useLocation(); 
  const [title, setTitle] = useState("");

  useEffect(() => {
    const sectionsPath = location.pathname.split("/");
    if(sectionsPath.length > 0){
      if(sectionsPath[sectionsPath.length - 1] == 'terms-conditions'){
        setTitle("Terms and conditions");
      }
    }
  }, [location.pathname])
  

  return (
    <section className="w-full min-h-[500px] bg-cover bg-no-repeat bg-[url('../img/banners/banner-01.png')] bg-center flex justify-center items-center">
      <div className="text-white uppercase font-bold flex flex-col">
        <h1 className="text-3xl lg:text-6xl aldo">
          <span className="text-yellow-c1 uppercase">{title}</span>
        </h1>
      </div>
    </section>
  );
}

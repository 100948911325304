/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import { getUsers } from "../../store/actions/_users.actions";
import { resetAll } from "../../store/reducers/_users.reducers";

export default function UserList() {
   const dispatch = useDispatch();
   const r_users = useSelector((state) => state.user);

   useEffect(() => {
      dispatch(resetAll());
      if (!r_users.loaded) {
         dispatch(getUsers());
      }
   }, [dispatch]);

   return (
      <>
         {r_users.loading && <Loading />}
         {r_users.loaded && (
            <div className="w-11/12 mx-auto pt-10">
               <div className="top-buttons">
                  <Link to="create">Add Kickstarter user</Link>
                  <Link to="create-user">Add user</Link>
               </div>
               <table className="table">
                  <thead>
                     <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Subscription</th>
                        <th>...</th>
                     </tr>
                  </thead>
                  <tbody>
                     {
                        r_users.users && <>
                           {
                              r_users.users.length > 0 && <>
                                 {r_users.users.map((user, key) => (
                                    <tr key={key}>
                                       <td>{user.name}</td>
                                       <td>{user.email}</td>
                                       <td>{user.role.name}</td>
                                       <td>{user.psubscription.name}</td>
                                       <td></td>
                                    </tr>
                                 ))}
                              </>
                              
                           }
                        </>
                     }
                     
                  </tbody>
               </table>
            </div>
         )}
      </>
   );
}

import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faWarning,
  faTimes,
  faRemove,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../components/Modal";
import BulletButton from "../../../components/BulletButton";
import { uploadProof } from "../../../store/actions/_events.actions";
import { resetErrors } from "../../../store/reducers/_events.reducers";
import { modal } from "../../../store/_main.reducers";
import { proofDescription } from "../../../utils/constanst";
import Loading from "../../../components/Loading";
import checkPayment from "../../../assets/img/icons/payment-check-satatus.png";
import warnIcon from "../../../assets/img/icons/warn.svg";
import successIcon from "../../../assets/img/icons/payment-check-satatus.png";

export default function ResultsUpload() {
  let { event_id } = useParams();
  const [data, setData] = useState({
    event_uuid: event_id,
    firts_proof: "",
    second_proof: "",
    comment: "",
    output_1: false,
    output_2: false,
  });
  const dispatch = useDispatch();
  const r_event = useSelector((state) => state.event);

  const handleFile = (event) => {
    let id = event.target.id;
    let element = document.getElementById(id);
    var output = document.getElementById(element.getAttribute("data-show"));
    setData({
      ...data,
      [output.id]: true,
    });
    output.src = URL.createObjectURL(element.files[0]);
    output.onload = function () {
      URL.revokeObjectURL(output.src); // free memory
    };
  };

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData();
    let firts_proof = document.getElementById("picture_1");
    let second_proof = document.getElementById("picture_2");
    formData.append("firts_proof", firts_proof.files[0] || data.firts_proof);
    formData.append("second_proof", second_proof.files[0] || data.second_proof);
    formData.append("event_uuid", data.event_uuid);
    formData.append("comment", data.comment);
    dispatch(uploadProof(formData));
  };

  const handleCancel = () => {
    window.location.reload();
  };

  console.log(r_event);

  return (
    <form className="p-4 lg:p-10 w-full bg-white" action="" id="upload-results">
      <h2 className="font-bold uppercase lg:text-2xl my-7">
        upload your <span className="text-yellow-c1">results</span>
      </h2>

      {r_event.event_engagement.length > 0 && (
        <>
          {!r_event.event_engagement[0].firts_proof &&
            !r_event.event_engagement[0].second_proof && (
              <>
                <div className="w-full flex bg-[#DDDDDD73] rounded-[4px] px-2 py-3 mb-3">
                  <div className="w-1/12">
                    <img src={warnIcon} className="p-1" />
                  </div>

                  <div className="w-11/12">
                    <p className="text-justify">
                      The Events results will be published 48 hours after the
                      end of the event. Remember to upload your information and
                      preferred withdrawal methods in your profile to receive
                      the prize, you can always keep up to date with the latest
                      events and contact us through our Instagram @ithl.offcial
                      @ithl.latam.
                    </p>
                    <p className="mt-5 text-center">
                      The first one to upload the proofs will be the winner!
                    </p>
                  </div>
                </div>
              </>
            )}

          {r_event.event_engagement[0].firts_proof &&
            r_event.event_engagement[0].second_proof && (
              <>
                <div className="w-full flex bg-[#DDDDDD73] rounded-[4px] px-2 py-3 mb-3">
                  <div className="w-1/12">
                    <img src={successIcon} className="p-1" />
                  </div>

                  <div className="w-11/12">
                    <p className="text-justify">
                      This event is now closed. Your proofs will be evaluated in
                      the next 48 hours and the winners will be announced in the
                      results tab. Stay tuned for when the tab is unlocked, you
                      will also receive an email with the results.
                      <br />
                      <br />
                      Remember to upload your information and preferred
                      withdrawal methods in your profile to receive the prize,
                      you can always keep up to date with the latest events and
                      contact us through our Instagram @ithl.offcial
                      @ithl.latam.
                    </p>
                  </div>
                </div>
              </>
            )}
        </>
      )}

      {r_event.errors.status && (
        <div className="bg-red-100 border border-red-300 p-5 text-red-600 flex justify-between items-start mb-10">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faWarning} className="text-4xl" />
            <ul className="ml-10">
              {r_event.errors.messages &&
                Object.entries(r_event.errors.messages).map((err, key) => (
                  <li key={key}>{err[1]}</li>
                ))}
            </ul>
          </div>
          <FontAwesomeIcon
            icon={faTimes}
            className="text-2xl cursor-pointer"
            onClick={() => dispatch(resetErrors())}
          />
        </div>
      )}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
        <div className="w-full">
          <h3 className="font-bold mb-5">
            Picture{" "}
            <span className="bg-yellow-400 rounded-full p-1 px-2 text-yellow-800">
              1
            </span>
          </h3>
          <div className={proofDescription}>
            {/* {r_event.event.description_proof_1} */}
            <span className="text-[12px]">
              {/* Take a screenshot of the gym where Druddigon is located and then upload that image here! */}
              {r_event.event.description_proof_1}
            </span>
          </div>

          {r_event.event_engagement.length > 0 && (
            <>
              {!r_event.event_engagement[0].firts_proof &&
                !r_event.event_engagement[0].second_proof && (
                  <>
                    <img
                      id="output_1"
                      alt="Proof number 1"
                      className={`w-full ${data.output_1 ? "block" : "hidden"}`}
                    />
                    <label htmlFor="picture_1" className="w-1/2">
                      <span className="flex flex-col items-center justify-center w-full h-56 border-4 border-dashed border-gray-300 text-gray-400 text-xl">
                        <FontAwesomeIcon icon={faUpload} />
                        {data.output_1
                          ? "Change/Update your file/image here"
                          : "Upload your file/image here"}
                      </span>
                      <input
                        type="file"
                        id="picture_1"
                        data-show="output_1"
                        className="hidden"
                        onChange={handleFile}
                      />
                    </label>
                  </>
                )}

              {r_event.event_engagement[0].firts_proof &&
                r_event.event_engagement[0].second_proof && (
                  <>
                    <img
                      src={r_event.event_engagement[0].firts_proof}
                      className="p-1"
                    />
                  </>
                )}
            </>
          )}
        </div>
        <div className="w-full">
          <h3 className="font-bold mb-5">
            Picure{" "}
            <span className="bg-yellow-400 rounded-full p-1 px-2 text-yellow-800">
              2
            </span>
          </h3>
          <div className={proofDescription}>
            {/* {r_event.event.description_proof_2} */}
            <span className="text-[12px]">
              {/* If you're in that location, you must have rotated the Pokestop
              disk, right? Submit a screenshot of the Pokestop! */}
              {r_event.event.description_proof_2}
            </span>
          </div>

          {r_event.event_engagement.length > 0 && (
            <>
              {!r_event.event_engagement[0].firts_proof &&
                !r_event.event_engagement[0].second_proof && (
                  <>
                    <img
                      id="output_2"
                      alt="Proof number 2"
                      className={`w-full ${data.output_2 ? "block" : "hidden"}`}
                    />
                    <label htmlFor="picture_2" className="w-1/2">
                      <span className="flex flex-col items-center justify-center w-full h-56 border-4 border-dashed border-gray-300 text-gray-400 text-xl">
                        <FontAwesomeIcon icon={faUpload} />
                        {data.output_2
                          ? "Change/Update your file/image here"
                          : "Upload your file/image here"}
                      </span>
                      <input
                        type="file"
                        id="picture_2"
                        data-show="output_2"
                        className="hidden"
                        onChange={handleFile}
                      />
                    </label>
                  </>
                )}

              {r_event.event_engagement[0].firts_proof &&
                r_event.event_engagement[0].second_proof && (
                  <>
                    <img
                      src={r_event.event_engagement[0].second_proof}
                      className="p-1"
                    />
                  </>
                )}
            </>
          )}
        </div>

        {r_event.event_engagement.length > 0 && (
          <>
            {!r_event.event_engagement[0].firts_proof &&
              !r_event.event_engagement[0].second_proof && (
                <>
                  <div className="lg:col-span-2">
                    <textarea
                      onChange={handleChange}
                      name="comment"
                      className="w-full border-4 border-gray-300 h-36 resize-none focus:outline-none focus:border-gray-400 p-2"
                    ></textarea>
                  </div>
                </>
              )}
          </>
        )}
      </div>

      {r_event.event_engagement.length > 0 && (
        <>
          {!r_event.event_engagement[0].firts_proof &&
            !r_event.event_engagement[0].second_proof && (
              <>
                <div className="text-center w-full mt-5">
                  {/*<button disabled={r_event.btnLoading} className="font-bold uppercase py-3 px-10 bg-gradient-to-b from-yellow-400 to-yellow-500 active:scale-95 rounded">
                        {r_event.btnLoading ? <><FontAwesomeIcon icon={faSpinner} spin /> Loading</> : 'Save'}
                      </button>*/}
                  <button
                    disabled={r_event.event.blocked}
                    type="button"
                    onClick={() => dispatch(modal(true))}
                    className={`font-bold uppercase py-3 px-10 bg-gradient-to-b from-yellow-400 to-yellow-500  rounded ${
                      r_event.event.blocked
                        ? "cursor-not-allowed opacity-60"
                        : "active:scale-95"
                    }`}
                  >
                    Save
                  </button>
                  {r_event.event.blocked && (
                    <div className="bg-blue-100 border border-blue-300 text-blue-900 p-3 mt-4">
                      Event blocked until {r_event.event.start_date_us_format}
                    </div>
                  )}
                </div>
              </>
            )}
        </>
      )}

      <Modal size="w-full lg:w-1/2">
        {r_event.event.proof_uploaded == false && (
          <>
            <div className="bg-yellow-50 rounded-full w-28 h-28 flex items-center border border-yellow-300 mx-auto">
              <FontAwesomeIcon
                icon={faWarning}
                className="text-yellow-500 w-full text-center text-6xl"
              />
            </div>
            <h2 className="font-bold text-xl uppercase text-center my-4">
              Do you want to save your results{" "}
              <span className="text-yellow-500">your results?</span>
            </h2>
            <p className="my-5">
              It's important that you know that results can only be saved once!
              You will not be able to make any changes once you click "Yes".
            </p>
            <div className="mt-5 w-full lg:w-2/3 mb-2 flex flex-col lg:flex-row mx-auto">
              {r_event.btnLoading ? (
                <div className="flex justify-center w-full">
                  <Loading />
                </div>
              ) : (
                <>
                  <BulletButton
                    text="CANCEL"
                    className="w-full lg:w-1/2 mb-3 lg:mb-0 mx-2"
                    onClick={() => dispatch(modal(false))}
                    color="bg-gradient-to-b from-gray-200 to-gray-400"
                    padding="py-2 px-3"
                    iconSize="text-2xl"
                  />
                  <BulletButton
                    text="Yes, submit"
                    className="w-full lg:w-1/2 mx-2"
                    onClick={handleSubmit}
                    color="bg-gradient-to-b from-yellow-300 to-yellow-500"
                    padding="py-2 px-3"
                    iconSize="text-2xl"
                  />
                </>
              )}
            </div>
          </>
        )}

        {r_event.event.proof_uploaded == true && (
          <>
            <FontAwesomeIcon
              icon={faRemove}
              onClick={handleCancel}
              className="
                  absolute
                  right-[40px]
                  top-[55px]
                  text-[25px]
                  cursor-pointer
                "
            />
            <div className="flex flex-col text-center">
              <img
                src={checkPayment}
                alt="payment status"
                className="w-[171px] h-[171px] mx-auto my-5"
              />
              <h2 className="uppercase font-bold font-['AldotheApache'] text-[34px] text-[#404B52]">
                Your proofs were successfully uploaded!
              </h2>
              {/* <p className='segoe text-[16px] text-[#707070]'>
                    Now that you are part of the event, go to the "submit your proofs" tab in the event description and upload your results to see if you are next. 
                  </p> */}
              {/* <div className='transform-section w-max mx-auto my-5'>
                    <button                         
                        type="submit"
                        className="font-['AldotheApache'] text-[24px] uppercase "
                        onClick={handleReload}
                    >
                    go to play
                    </button>
                  </div> */}
            </div>
          </>
        )}
      </Modal>
    </form>
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function Loading(props) {
	const { color } = props;

	return (
		<div className="flex justify-center my-10">
			<FontAwesomeIcon icon={faSpinner} spin className={`text-5xl text-${color || 'black'}`} />
		</div>
	)
}
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../components/CustomModal";
// import { getCardsAction } from "../../../store/actions/action.payments";
import Loading from "../../../components/Loading";
// import { loadStripe } from "@stripe/stripe-js";
import {
  faChevronLeft,
  faPlus,
  faRemove,
} from "@fortawesome/free-solid-svg-icons";

// import {
//   CardElement,
//   // Elements,
//   useStripe,
//   useElements,
// } from "@stripe/react-stripe-js";
// import { cardElementOptions } from "../../../utils/constanst";
import { addEvent } from "../../../store/actions/_events.actions";
// import { getIntentPayment, getPaymentMethods, payToJoinEvent } from "../../../store/actions/_subscriptions.actions";
import { PaymentMethods } from "../../../components/Payment/PaymentMethods";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CreatePaymentMethod } from "../../pages/events/JoinEvent/components/CreatePaymentMethod";
import { toast } from "react-toastify";
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

export default function EventPayment({ status, closeModal, data, formData }) {
  const dispatch = useDispatch();
  const r_payment = useSelector((state) => state.payment);
  const r_subscription = useSelector((state) => state.subscription);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(null);
  const [isVisiblePaymentMethod, setIsVisiblePaymentMethod] = useState(false);
  const [paymentData, setPaymentData] = useState(null);

  useEffect(() => {
    if (paymentData) {
      if (paymentData.id) {
        formData.append("paymentMethodId", paymentData.id);
        dispatch(addEvent(formData));
      }
    }
  }, [paymentData]);

  /* useEffect(()=>{
    console.log(data);
  }) */

  const payUsingThis = () => {
    if (!paymentMethodSelected) {
      toast.error("Select payment method", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      return;
    }

    formData.append("paymentMethodId", paymentMethodSelected.id);
    dispatch(addEvent(formData));
  };

  return (
    <CustomModal
      title=""
      size="w-full lg:w-[900px] relative"
      status={status}
      closedButton={closeModal}
    >
      <FontAwesomeIcon
        icon={faRemove}
        onClick={closeModal}
        className="
          absolute
          right-[30px]
          top-[20px]
          text-[25px]
          cursor-pointer
        "
      />

      <div className="max-h-[400px] overflow-y-auto overflow-x-hidden w-full px-4 scrollbar custom-scroll">
        {r_payment.loading ? (
          <Loading />
        ) : (
          <>
            <h2 className="uppercase font-bold font-['AldotheApache'] text-[34px]">
              Are you Ready{" "}
              <span className="text-yellow-c1">to join the challenge?</span>
            </h2>
            {isVisiblePaymentMethod && (
              <>
                <span
                  className="segoe cursor-pointer"
                  onClick={() => {
                    setIsVisiblePaymentMethod(false);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="text-[11px] relative top-[-1px]"
                  />{" "}
                  Go back
                </span>
              </>
            )}

            <p className="my-2 segoe font-bold font-[#181818] italic text-[24px]">
              Select your payment process
            </p>

            {!isVisiblePaymentMethod && (
              <>
                <PaymentMethods
                  paymentMethodSelected={paymentMethodSelected}
                  setPaymentMethodSelected={setPaymentMethodSelected}
                />

                <div className="transform-skew-20 bg-yellow-c1 p-4 w-max my-5 bg-[#001D3D] text-[#FFC300]">
                  <button
                    className="font-['AldotheApache'] text-[18px]"
                    onClick={() => {
                      setIsVisiblePaymentMethod(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add new method
                  </button>
                </div>

                <div className="event-pric my-5">
                  <ul>
                    <li className="flex relative py-2 border-t border-[#E5E7EB] mb-5">
                      <div className="w-1/2 text-[#6B7280] segoe">
                        Reward amount
                      </div>
                      <div className="w-1/2 text-right text-[#6B7280] segoe">
                        ${data.first_place_reward}
                      </div>
                    </li>
                    <li className="flex py-2 border-b border-[#E5E7EB]">
                      <div className="w-1/2 text-[#6B7280] segoe">
                        Creation fee
                      </div>
                      <div className="w-1/2 text-right text-[#6B7280] segoe">
                        $
                        {parseFloat(data.total_to_pay) -
                          parseFloat(data.first_place_reward)}
                      </div>
                    </li>
                    <li className="flex mt-3">
                      <div className="w-1/2 segoe">Total</div>
                      <div className="w-1/2 text-right segoe">
                        ${parseFloat(data.total_to_pay)}
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            )}

            {isVisiblePaymentMethod && (
              <CreatePaymentMethod setPaymentData={setPaymentData} />
            )}

            {r_subscription.methods.length > 0 && !isVisiblePaymentMethod && (
              <>
                <div className="w-full flex justify-end">
                  <div className="transform-section w-max">
                    <button
                      type="submit"
                      className="font-['AldotheApache'] text-[24px]"
                      onClick={payUsingThis}
                    >
                      Pay event
                    </button>
                  </div>
                </div>
              </>
            )}

            {/* {
              isVisiblePaymentMethod && <Elements stripe={stripePromise}>
                <AddPayment formData={formData} />
              </Elements>
            } */}
          </>
        )}
      </div>
    </CustomModal>
  );
}

// function AddPayment({formData}) {
//   const stripe = useStripe();
//   const elements = useElements();
//   const dispatch = useDispatch();
// 	const r_events = useSelector(state => state.event);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (elements == null) {
//       return;
//     }

//     const { error, paymentMethod } = await stripe.createPaymentMethod({
//       type: "card",
//       card: elements.getElement(CardElement),
//     });
//     if (error) {
//       console.log(error);
//     } else {
// 			formData.append('paymentMethodId', paymentMethod.id)
// 			dispatch(addEvent(formData));
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit} className="flex items-center flex-col">
// 			{r_events.errors.status && <div className="bg-red-100 text-red-500 border border-red-300 p-2 my-3">There are some errors in your form event creation, please fix and continue with payment</div>}
//       <CardElement options={cardElementOptions} />
//       <div className="w-1/2 mx-auto flex items-center justify-center">
//         <button
//           type="submit"
//           disabled={!stripe || !elements}
//           className="w-1/2 mx-auto bg-blue-500 text-white font-bold border-b-4 border-blue-700 p-2 mt-5 text-sm md:text-base active:scale-95"
//         >
//           Pay and Create
//         </button>
//       </div>
//     </form>
//   );
// }

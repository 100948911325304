import api from "../../utils/api";
import {
  ppLoading,
  ppBtnLoading,
  historyEvents,
  ppError,
  ppModal,
  setUser,
  setFollows,
  setEvents,
  setFollowStatus,
  addOneToFollows,
  takeOneToFollows,
  followsList,
  removeFromFollowerList,
  setRemoveUsersFollowLoading,
  setRefreshFollows,
} from "../reducers/_pageProfile.reducers";
import { moreResultItemIsLoading } from "../reducers/_search.reducers";
import { getUser } from "./_profile.actions";

export const getDataAction = (user) => async (dispatch) => {
  dispatch(ppLoading(true));
  try {
    const response = await api.get(`/profile/${user}`);
    dispatch(setUser(response.data[0]));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(ppLoading(false));
  }
};
export const getDataAdminAction = (user) => async (dispatch) => {
  dispatch(ppLoading(true));
  try {
    const response = await api.get(`/profile/admin/${user}`);
    dispatch(setUser(response.data[0]));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(ppLoading(false));
  }
};

export const followAction = (user) => async (dispatch) => {
  dispatch(moreResultItemIsLoading(user));
  dispatch(ppBtnLoading(true));
  try {
    const response = await api.get(`/profile/${user}/follow`);
    dispatch(setFollowStatus(response.data.status));
    dispatch(addOneToFollows());
    dispatch(getUser());
    dispatch(moreResultItemIsLoading(null));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(ppBtnLoading(false));
  }
};

export const unFollowAction = (user) => async (dispatch) => {
  dispatch(moreResultItemIsLoading(user));
  dispatch(ppBtnLoading(true));
  try {
    const response = await api.get(`/profile/${user}/unfollow`);
    dispatch(setFollowStatus(response.data.status));
    dispatch(takeOneToFollows());
    dispatch(getUser());
    dispatch(moreResultItemIsLoading(null));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(ppBtnLoading(false));
  }
};

export const removeFollow = (following_user_id, follower_user_id) => async (dispatch) => {
  dispatch(setRemoveUsersFollowLoading(follower_user_id));
  try {
    const response = await api.get(`/profile/remove/${following_user_id}/${follower_user_id}`);
    dispatch(setRefreshFollows(true));
  } catch (error) {
    console.error(error);
  } finally {
  }
};

export const codeLinkPartnerAction = () => async (dispatch) => {
  dispatch(ppLoading(true));
  try {
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(ppLoading(false));
  }
};

export const getHistoryEventsAction = (user_uuid) => async (dispatch) => {
  // dispatch(ppLoading(true));
  try {
    const response = await api.get(`/profile/${user_uuid}/history-events`);
    dispatch(historyEvents(response.data));
  } catch (error) {
    console.error(error);
  }
};

export const getFollowsAction = (user_uuid) => async (dispatch) => {
  dispatch(ppLoading(true));
  try {
    const response = await api.get(`/profile/${user_uuid}/followers`);
    dispatch(
      setFollows({
        followersCount: response.data.followersCount,
        followingCount: response.data.followingCount,
        followers: response.data.followers,
        following: response.data.following,
      })
    );
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(ppLoading(false));
  }
};

export const getEventsAction = (user_uuid) => async (dispatch) => {
  dispatch(ppLoading(true));
  try {
    const response = await api.get(`/profile/${user_uuid}/events`);
    dispatch(
      setEvents({
        published: response.data.published,
        winned: response.data.winned,
      })
    );
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(ppLoading(false));
  }
};

export const getFollowStatusAction = (user_uuid) => async (dispatch) => {
  dispatch(ppLoading(true));
  try {
    const response = await api.get(`/profile/${user_uuid}/follow/status`);
    dispatch(setFollowStatus(response.data.status));
  } catch (error) {
    dispatch(setFollowStatus("login"));
  } finally {
    dispatch(ppLoading(false));
  }
};

export const getFollowersList = () => async (dispatch) => {
  dispatch(ppLoading(true));
  try {
    const response = await api.get("/profile/followers/list/all");
    // console.log(response.data.following)
    dispatch(
      followsList({
        followers: response.data.following_me,
        following: response.data.following,
      })
    );
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(ppLoading(false));
  }
};

export const followFromList = (user) => async (dispatch) => {
  try {
    // const response = await api.get(`/profile/${user}/follow`);
    console.log(user);
  } catch (error) {
    console.error(error);
  }
};

export const unFollowFromList = (user, indexElement) => async (dispatch) => {
  try {
    const response = await api.get(`/profile/${user}/unfollow`);
    if (response.status === 200) {
      dispatch(removeFromFollowerList(indexElement));
    }
  } catch (error) {
    console.error(error);
  }
};

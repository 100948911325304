import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AdminAlert(props) {
  const { message, status, style, close } = props;
  return (
    status && (
      <div
        // className={` bg-${style}-200 border border-${style}-300 p-3 mb-5 text-${style}-700 flex justify-between items-start`}
        className={
          style === "green"
            ? "bg-green-200 border border-green-300 p-3 mb-5 text-green-700 flex justify-between items-start"
            : "bg-red-200 border border-red-300 p-3 mb-5 text-red-700 flex justify-between items-start"
        }
      >
        {message}
        <FontAwesomeIcon
          icon={faTimes}
          className="cursor-pointer hover:scale-105 active:scale-95"
          onClick={close}
        />
      </div>
    )
  );
}

import { createSlice } from "@reduxjs/toolkit";

export const profileSlice = createSlice({
	name: 'profile',
	initialState: {
		user: [],
		btnLoading: false,
		loaded: false,
		loading: false,
		followeds:[],
		error: {
			status: false,
			message: '',
			messages: []
		},
		alert: {
			status: false,
			message: '',
			style: 'green'
		}
	},
	reducers: {
		user: (state, action) => {
			state.user = action.payload;
			state.loaded = true;
		},
		setFolloweds: (state, action) => {
			state.followeds = action.payload;
		},
		setAssets: (state, action) => {
			state.btnLoading = action.payload.btnLoading;
			state.loaded = action.payload.loaded;
			state.loading = action.payload.loading;
		},
		errors: (state, action) => {
			state.error.status = action.payload.status;
			state.error.message = action.payload.messagge;
			state.error.messages = action.payload.messages;
		},
		alert: (state, action) => {
			state.alert.status = action.payload.status;
			state.alert.message = action.payload.message;
			state.alert.style = action.payload.style;
		},
		avatar: (state, action) => {
			state.user.avatar = action.payload
		},
		banner: (state, action) => {
			state.user.banner = action.payload;
		}
	}
})

export const {user, setAssets, errors, alert, avatar, banner, setFolloweds} = profileSlice.actions;
export default profileSlice.reducer;
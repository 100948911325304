import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BulletLink(props) {
   const { text, className, to, color, padding, icon, iconSize } = props;
   return (
      <div className={`flex justify-center mx-auto ${className}  active:scale-90`}>
         <Link
            className={`${color ? color : "bg-yellow-c1"
               }  text-blue-c1 uppercase mx-auto w-[90%] ${padding ? padding : "p-5"
               } font-bold text-center`}
            style={{ transform: "skew(-20deg)" }}
            to={to}
         >
            {icon && (
               <FontAwesomeIcon
                  icon={icon}
                  className={`text-black mr-2 ${iconSize ? iconSize : "text-xl lg:text-5xl"
                     } lg:font-bold`}
               />
            )}
            {text}
         </Link>
         <div
            className={`${color ? color : "bg-yellow-c1"
               } text-black uppercase text-base mx-auto w-[3%]`}
            style={{ transform: "skew(-20deg)" }}
         ></div>
      </div>
   );
}

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { t } from "i18next";

export default function Section3() {
   const r_auth = useSelector((state) => state.auth);
   return (
      <div className="text-white flex flex-col justify-center">
         <div className="border-b-yellow-c4 mt-5 lg:mt-0 w-1/2 mb-2 lg:mb-10 text-xl uppercase font-bold">
			{t("footer.needhelp")}
         </div>
         <ul>
            <Menu to="/HowToPlay" text="FAQ" />
            {!r_auth.logged && (
               <Menu to="/auth/register" text={t("footer.register")} />
            )}
            <Menu to="/terms-conditions" text={t("footer.terms")} />
            {/*<Menu to="" text="Privacy Policy" />*/}
            {!r_auth.logged && <Menu to="/auth/login" text={t("footer.login")} />}
         </ul>
      </div>
   );
}

function Menu({ to, text }) {
   return (
      <li>
         <Link className="block mb-1 lg:mb-6" to={to}>
            {text}
         </Link>
      </li>
   );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faGamepad,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink, Routes, Route } from "react-router-dom";
import EventList from "../../../events/events.list";
import EventsJoined from './profile-events-joined'
import CreateEvent from "../../../events/events.create";
import UpdateEvent from '../../../events/events.update';
import DeleteEvent from '../../../events/events.delete';
import Judge from '../../../events/events.judge';

const style = {
  active:
    "bg-yellow-400 border border-yellow-500 text-blue-900 p-2 font-bold hover:opacity-80",
  inactive: "bg-gray-200 border border-gray-300 p-2 hover:opacity-80",
  created: "rounded-l",
  joined: "rounded-r",
  create:"p-2 bg-yellow-400 text-blue-900 font-bold transform-skew-20 hover:opacity-80 mx-[5px]",
  info:"p-2 bg-[#3B82F6] text-white font-bold transform-skew-20 hover:opacity-80 mx-[5px]",
};

export default function Events() {
  // return <EventList />
  return (
    <div className="ml-10">
      <h1 className="font-bold text-xl my-2">My Events</h1>
      <div className="w-full mt-5 flex justify-between items-center">
        <div>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? `${style.active} ${style.created}`
                : `${style.inactive} ${style.created}`
            }
            to={`created`}
          >
            <FontAwesomeIcon icon={faCalendarDays} /> Created
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? `${style.active} ${style.joined}`
                : `${style.inactive} ${style.joined}`
            }
            to={`joined`}
          >
            <FontAwesomeIcon icon={faGamepad} /> Joined
          </NavLink>
        </div>

        <Link className={style.create} to={`create`}>
          <FontAwesomeIcon icon={faPlus} /> Create Event
        </Link>
      </div>
      <Routes>
        {["", "created/*"].map((path, key) => (
          <Route path={path} key={key} element={<EventList />} />
        ))}
        <Route path="joined/*" element={<EventsJoined />} />
        <Route path="create" element={<CreateEvent />} />
        <Route path="update/:event_id" element={<UpdateEvent />} />
        <Route path="delete/:event_id" element={<DeleteEvent />} />
        <Route path="judge/:event_uuid" element={<Judge />} />
      </Routes>
    </div>
  );
}

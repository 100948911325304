import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
//import { recentEventsAction } from "../../events/_events.actions";
//import BulletLink from "../../../components/BulletLink";
import { getNewsAction } from "../../../store/actions/_news.actions";
import HeaderSection from "../../../components/HeaderSection";
// import image from "../../../assets/img/images/new1.png";
//import Loading from "../../../components/Loading";
import 'react-alice-carousel/lib/alice-carousel.css';
import { NewsCard } from "./NewsCard";
import { t } from "i18next";
import { GeneralCarousel } from "../../../components/GeneralCarousel";

const style = {
   li: "text-gray-800 uppercase font-bold px-3 py-2 border-b-4 hover:text-yellow-500 cursor-pointer hover:border-b-4 hover:border-yellow-600 transition-all duration-300",
   liActive:
      "uppercase font-bold px-3 py-2 text-yellow-500 cursor-pointer border-b-4 border-yellow-600",
};

export default function UpdatesAndNews() {
   const dispatch = useDispatch();
   const r_news = useSelector((state) => state.news);
   const [news, setNews] = useState([]);

   const [data, setData] = useState({
      style: "all",
      news: [],
   });

   useEffect(() => {
      if (!r_news.news_home_loaded) {
         dispatch(getNewsAction(10));
      }
   }, [dispatch, r_news.news_home_loaded]);

   useEffect(() => {
      if (r_news.news_home_loaded) {
         setNews([]);
         setData({
            style: "all",
            news: r_news.news_home,
         });
         setItemsToCarousel(data.news);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [r_news.news_home_loaded, r_news.news_home]);

   const filterNews = (event) => {
      let id = event.target.id;
      let element = document.getElementById(id);
      let dataValue = element.getAttribute("data-value");
      if (id === "all") {
         setData({
            style: event.target.id,
            news: r_news.news_home,
         });
         setItemsToCarousel(r_news.news_home);
      } else {
         let filter_news = r_news.news_home.filter(
            // eslint-disable-next-line eqeqeq
            (item) => item.category == dataValue
         );
         
         setData({
            style: event.target.id,
            news: filter_news,
         });

         setItemsToCarousel(filter_news);
      }
   };

   const setItemsToCarousel = (items = []) => {
      setNews([]);
      items.map((item, key) => {
         setNews(preState => [...preState, (<NewsCard item={item} data-value={key+1} />) ]);
      });
   }

   useEffect( () => {
      setTimeout( () => {
         document.getElementById('all').click();
      },5000)
   },[])
   

   return (
      <section className="py-[4em] w-full h-auto p-3 xl:p-10 bg-no-repeat bg-cover bg-[url('../img/backgrounds/main-bg.png')] justify-center items-center">
         <div className="text-center">
            <HeaderSection
               titleStyle="black"
               sub={t("home.news.sub")}
               title1={t("home.news.title1")}
               title2={t("home.news.title2")}
            />
         </div>
         <div className="w-11/12 mx-auto mt-10">
            <ul className="w-full flex  overflow-x-auto">
               <li
                  id="all"
                  data-value="All"
                  className={data.style === "all" ? style.liActive : style.li}
                  onClick={filterNews}
               >
                  {t("home.news.all")}
               </li>
               <li
                  id="games"
                  data-value="games"
                  className={data.style === "games" ? style.liActive : style.li}
                  onClick={filterNews}
               >
                  {t("home.news.games")}
               </li>
               <li
                  id="movies"
                  data-value="movies"
                  className={
                     data.style === "movies" ? style.liActive : style.li
                  }
                  onClick={filterNews}
               >
                  {t("home.news.movies")}
               </li>
               <li
                  id="shows"
                  data-value="shows"
                  className={data.style === "shows" ? style.liActive : style.li}
                  onClick={filterNews}
               >
                  {t("home.news.shows")}
               </li>
               <li id="" data-value="" className="grow border-b-4"></li>
            </ul>
            <div className="w-full my-[20px]">
               <GeneralCarousel items={news} />
            </div>
         </div>
      </section>
   );
}

import { useEffect, useState } from "react";
import { eventExplorerAction, eventTypesAction } from "../../store/actions/_events.actions";
import { useDispatch, useSelector } from "react-redux";
import { ContainerCardExploreEvents, FilterEvent, ContainerTabs } from "./components";

import { t } from "i18next";
import './styles.scss';
import HeaderSection from "../HeaderSection";
import { Link } from "react-router-dom";

export function EventExplorer({ category = "", event_type="", oder="", isPage = false }) {

  const dispatch = useDispatch();
  const eventState = useSelector((state) => state.event);
  const [isLoading, setIsloading] = useState(false);
  const [categoryEvent, setCategoryEvent] = useState('all');
  const [eventFilter, setEventFilter] = useState({
    category: event_type,
    search: "",
    order_by: oder
  });

  useEffect(() => {
    if(category){
      setCategoryEvent(category)
    }
  }, [])
  

  const [eventsExplorer, setEventExplorer] = useState({
    all: {
      data: [],
      notFoundMessage: null
    },
    live_events: {
      subtitle: "",
      data: [],
      notFoundMessage: null
    },
    our_events: {
      data: [],
      notFoundMessage: null
    },
    user_events: {
      subtitle: "",
      data: []
    },
    community_events: {
      data: [],
      notFoundMessage: null
    },
  });


  useEffect(() => {
    dispatch(eventTypesAction());
  }, [dispatch]);

  useEffect(() => {
    if (categoryEvent != "all") {
      setEventFilter({
        category: "",
        search: "",
        order_by: ""
      });
    }
  }, [categoryEvent]);

  useEffect(() => {
    dispatch(eventExplorerAction(categoryEvent, eventFilter.category, eventFilter.search, eventFilter.order_by));
  }, [categoryEvent, eventFilter]);

  useEffect(() => {
    if (eventState.explorer.data[categoryEvent]) {
      const data = { ...eventState.explorer.data[categoryEvent] };
      data.data = eventState.explorer.data[categoryEvent];
      setEventExplorer(preState => ({ ...preState, [categoryEvent]: data }));
    }


    setIsloading(eventState.explorer.isLoading);
  }, [eventState]);

  return (
    <section className="w-full h-auto p-5 xl:p-10 bg-no-repeat bg-cover bg-[url('../img/backgrounds/dark-background.png')] flex flex-col">


      <div className="grid grid-cols-2 mb-8">
        <HeaderSection
          titleStyle="white"
          sub2={t("event_explorer." + categoryEvent + ".subtitle")}
          title1={t("event_explorer." + categoryEvent + ".title")}
          title2="" />
        <div className="flex flex-row-reverse my-auto">
          <Link to="/settings/users/profile/my-events/create" className="flex flex-row bg-[#003566] py-2 px-2 rounded-3xl">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 text-[#ffc300] pr-1 my-auto">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
            <span className="text-xs md:text-base text-[#ffc300] aldo my-auto">Create Event</span>
          </Link>
        </div>
      </div>

      <ContainerTabs handleTab={{ categoryEvent, setCategoryEvent }} />
      <br />

      <FilterEvent filter={{ eventFilter, setEventFilter, categoryEvent }} isPage={isPage} />
      <br />
      <ContainerCardExploreEvents eventData={{ eventsExplorer, isLoading, categoryEvent, eventFilter }} />
    </section>
  );
}


import { useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AdminTextArea from "../../../components/admin/admin.textarea";
import { styles } from "../../../utils/constanst";

export default function EventDetail({
  handleChange,
  ckeditorValue,
  data,
  ckeditorValueBriefDescription,
}) {
  const r_events = useSelector((state) => state.event);
  return (
    <fieldset className={`grid-cols-1 ${styles.fieldset}`}>
      <legend className={styles.legend}>Event Details</legend>
      <div className={`${styles.boxInfo} w-full`}>
        Brief description is for a short text description
      </div>
      {/* <AdminTextArea
        label="brief description (*)"
        className=""
        onChange={handleChange}
        height={24}
        id="brief_description"
        // placeholder="Brief Description"
        errors={r_events.errors}
        value={data.brief_description}
      /> */}

      <div className="no-tailwindcss-base">
        <CKEditor
          id="ckeditor-2"
          editor={ClassicEditor}
          config={{
            toolbar: {
              items: [
                "heading",
                "|",
                "bold",
                "italic",
                "link",
                "bulletedList",
                "|",
                "outdent",
                "indent",
                "|",
                "blockQuote",
                "undo",
                "redo",
              ],
            },
          }}
          data={data.brief_description}
          // onReady={(editor) => {
          //   // You can store the "editor" and use when it is needed.
          //   console.log("Editor is ready to use!", editor);
          // }}
          onChange={(event, editor) => {
            ckeditorValueBriefDescription(editor.getData());
          }}
          // onBlur={(event, editor) => {
          //   console.log("Blur.", editor);
          // }}
          // onFocus={(event, editor) => {
          //   console.log("Focus.", editor);
          // }}
        />
        <p className="text-[#707070] text-[14px]">
          Describe your event briefly. Do not give away to many details but enough to make it interesting.          
        </p>
      </div>

      {/* <AdminTextArea
        label="details"
        className="w-full col-span-4"
        onChange={handleChange}
        height={64}
        id="details"
        // placeholder="Details"
        errors={r_events.errors}
      /> */}
      <div className="no-tailwindcss-base">
        <CKEditor
          id="ckeditor"
          editor={ClassicEditor}
          config={{
            toolbar: {
              items: [
                "heading",
                "|",
                "bold",
                "italic",
                "link",
                "bulletedList",
                "numberedList",
                "|",
                "outdent",
                "indent",
                "|",
                "blockQuote",
                "insertTable",
                "mediaEmbed",
                "undo",
                "redo",
              ],
            },
            table: {
              contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
            },
          }}
          data={data.details}
          // onReady={(editor) => {
          //   // You can store the "editor" and use when it is needed.
          //   console.log("Editor is ready to use!", editor);
          // }}
          onChange={(event, editor) => {
            ckeditorValue(editor.getData());
          }}
          // onBlur={(event, editor) => {
          //   console.log("Blur.", editor);
          // }}
          // onFocus={(event, editor) => {
          //   console.log("Focus.", editor);
          // }}
        />
        <p className="text-[#707070] text-[14px]">
        These instructions will be released when the event is unblocked. Be specific, add as many details on what we are looking for, what is the treasure, and any other specifics that help the player find the right treasure.  
        </p>
      </div>
    </fieldset>
  );
}

export const styles = {
  fieldset: "grid gap-5 border-t border-blue-300 rounded p-5 mb-5",
  legend:
    "bg-white py-2 px-10 border border-blue-200 rounded bg-blue-100 text-blue-900 font-bold",
  boxInfo: "bg-blue-100 border border-blue-300 text-blue-400 p-3",
  boxDelete: "w-full bg-red-100 border border-red-300 text-red-400 p-3",
  checkbox: "w-5 h-5 mr-5 cursor-pointer",
  checkboxLabel: "font-bold text-gray-500 capitalize cursor-pointer",
  checkboxGroup: "flex justify items-center mt-5",
};

export const styleAccount = {
  fieldset: "border-t border-blue-300 rounded p-5 mb-5 w-full",
  legend:
    "bg-white py-2 px-2 border border-blue-200 rounded bg-blue-100 text-blue-900 font-bold",
}

export const optionsSelect = [
  {
    name: "Game",
    value: "game",
  },
  {
    name: "Movie",
    value: "movie",
  },
  {
    name: "TV Show",
    value: "show",
  },
];

export const proofDescription = "bg-blue-200 border border-blue-300 text-blue-900 p-2 mb-1";

export const cardElementOptions = {
  style: {
    base: {
      color: "#32325d",
      fontSize: "20px",
    },
    invalid: {
      color: "#fa755a",
      fontSize: "20px",
    },
  },
};


export const cardFlatAppearance = {
  theme: 'night',
  variables: {
    fontFamily: ' "Gill Sans", sans-serif',
    fontLineHeight: '1.5',
    borderRadius: '10px',
    colorBackground: '#F6F8FA',
    colorPrimaryText: '#262626'
  },
  rules: {
    '.Block': {
      backgroundColor: 'var(--colorBackground)',
      boxShadow: 'none',
      padding: '12px'
    },
    '.Input': {
      padding: '12px'
    },
    '.Input:disabled, .Input--invalid:disabled': {
      color: 'lightgray'
    },
    '.Tab': {
      padding: '10px 12px 8px 12px',
      border: 'none'
    },
    '.Tab:hover': {
      border: 'none',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
    },
    '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
      border: 'none',
      backgroundColor: '#fff',
      boxShadow: '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
    },
    '.Label': {
      fontWeight: '500'
    }
  }
};
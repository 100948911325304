import { t } from "i18next";
export default function ContactForm() {

	const handleSubmit = (event) => {
		event.preventDefault()
	}

	return (
		<form action="" className="flex flex-col items-center w-full lg:w-1/2 p-2 my-4 lg:p-10" onSubmit={handleSubmit}>
			<div className="border border-gray-400 border-t-4 border-t-yellow-500">
				<textarea name="" className="w-full border-b border-gray-400 p-3 resize-none focus:outline-none" rows="4" placeholder={t("contact.message")}></textarea>
				<input type="text" className="border-b border-gray-400 p-3 w-full focus:outline-none" placeholder={t("contact.name")} />
				<input type="text" className="border-b border-gray-400 p-3 w-full focus:outline-none" placeholder={t("contact.email")} />
				<button type="submit" className="bg-yellow-500 w-full uppercase font-bold p-2 mt-5 border-yellow-700 border active:scale-90 text-sm lg:text-base">{t("contact.button")}</button>
			</div>
		</form>
	)
}
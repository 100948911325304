import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GeneralCarousel } from "../../GeneralCarousel";

export const UpcomingBanners = () => {
  const banners = [
    <UpcComingBanner
      item={{
        title: "play now and win",
        background: require("../../../assets/img/banners/game-banner-upcoming.png"),
        sm_background: require("../../../assets/img/banners/sm-game-banner-upcoming.png"),
        description:
          "At this moment there are not upcoming events, but stay tuned for new challenges",
        button: {
          text: "games",
          to: "/explore-events/all/recents/1",
        },
      }}
      data-value={1}
    />,
    <UpcComingBanner
      item={{
        title: "More events coming soon",
        background: require("../../../assets/img/banners/movie-banner-upcoming.png"),
        sm_background: require("../../../assets/img/banners/sm-movie-banner-upcoming.png"),
        description:
          "At this moment there are not upcoming events, but stay tuned for new challenges",
        button: {
          text: "movies",
          to: "/explore-events/all/recents/2",
        },
      }}
      data-value={2}
    />,
    <UpcComingBanner
      item={{
        title: "Have fun and win with ITHL",
        background: require("../../../assets/img/banners/event-banner-upcoming.png"),
        sm_background: require("../../../assets/img/banners/sm-event-banner-upcoming.png"),
        description:
          "At this moment there are not upcoming events, but stay tuned for new challenges",
        button: {
          text: "events",
          to: "/explore-events/all/recents/3",
        },
      }}
      data-value={3}
    />,
  ];

  return (
    <div className="m-auto max-w-[1200px] w-full">
      <GeneralCarousel items={banners} slise={1} />
    </div>
  );
};

const UpcComingBanner = ({ item }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const imageUrl = windowWidth >= 650 ? item.background : item.sm_background;

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <>
      {item && (
        <div
          style={{ backgroundImage: `url(${imageUrl}` }}
          className={
            "rounded-[30px] m-auto w-full min-h-[400px] bg-cover bg-no-repeat bg-center flex flex-col md:justify-center"
          }
        >
          <div className="flex justify-end">
            <div className="p-5 w-full md:w-[45%]">
              <h2 className="text-white text-2xl md:text-5xl mb-3 md:w-3/4 aldo">
                {item.title}
              </h2>
              <p className="segoe text-white text-sm md:text-base md:w-[60%] font-[350]">
                {item.description}
              </p>
              <Link to={item.button.to}>
                <button
                  className="mt-5 uppercase rounded-[8px] bg-[#FFC300] px-[30px] py-[8px] text-[1em] font-bold"
                >
                  {item.button.text}
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

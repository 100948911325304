import api from "../../utils/api";
import {
  loading,
  btnLoading,
  error,
  success,
  roles,
  modal,
  store,
  update,
	destroy
} from "../reducers/_role.reducers";

export const getRoles = () => async (dispatch) => {
  dispatch(loading(true));
  try {
    const response = await api.get(`/roles`);
    dispatch(roles(response.data));
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(loading(false));
  }
};

export const storeRoles = (data) => async (dispatch) => {
  dispatch(btnLoading(true));
  try {
    const response = await api.post(`/roles`, data);
    dispatch(store(response.data));
    dispatch(modal({ create: false }));
  } catch (err) {
    dispatch(
      error({
        status: true,
        message: err.response.data.message,
        messages: err.response.data.errors,
      })
    );
  } finally {
    dispatch(btnLoading(false));
  }
};

export const updateRoles = (data, role_id, key) => async (dispatch) => {
  dispatch(btnLoading(true));
  try {
    const response = await api.put(`/roles/${role_id}`, data);
    dispatch(update({ key, data: response.data }));
    dispatch(modal({ update: false }));
  } catch (err) {
    dispatch(
      error({
        status: true,
        message: err.response.data.message,
        messages: err.response.data.errors,
      })
    );
  } finally {
    dispatch(btnLoading(false));
  }
};

export const deleteRoles = (key, role_id) => async (dispatch) => {
	dispatch(btnLoading(true));
  try {
    const response = await api.delete(`/roles/${role_id}`);
    dispatch(destroy({ key, data: response.data }));
		dispatch(modal({ delete: false }));
  } catch (err) {
    console.error(err);
  } finally {
		dispatch(btnLoading(false));
  }
};
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/Loading";
import { getNews } from "../../store/actions/_news.actions";
import { backToList } from "../../store/reducers/_news.reducers";

export default function List() {
   const dispatch = useDispatch();
   const r_news = useSelector((state) => state.news);

   useEffect(() => {
      if (!r_news.news_loaded) {
         dispatch(getNews());
      }
      dispatch(backToList(false));
   }, [dispatch, r_news.news_loaded]);

   return (
      <div className="w-10/12 mx-auto mt-10">
         <div className="top-buttons">
            <Link to="create">Add new</Link>
         </div>
         {r_news.loading && <Loading />}
         {!r_news.loading && (
            <>
               {r_news.news.data && r_news.news.data.length > 0 ? (
                  <table className="table">
                     <thead>
                        <tr>
                           <th>Title</th>
                           <th>Category</th>
                           <th>Created At</th>
                           <th>Options</th>
                        </tr>
                     </thead>
                     <tbody>
                        {r_news.news.data.map((item, key) => (
                           <tr key={key}>
                              <td>{item.title}</td>
                              <td>{item.category}</td>
                              <td>{item.created_at}</td>
                              <td>
                                 <Link
                                    className="mx-1"
                                    to={`update/${item.id}`}
                                 >
                                    <FontAwesomeIcon
                                       className="text-yellow-700 text-xl active:scale-95"
                                       icon={faEdit}
                                    />
                                 </Link>
                                 <Link
                                    className="mx-1"
                                    to={`delete/${item.id}`}
                                 >
                                    <FontAwesomeIcon
                                       className="text-red-500 text-xl active:scale-95"
                                       icon={faTrash}
                                    />
                                 </Link>
                              </td>
                           </tr>
                        ))}
                     </tbody>
                  </table>
               ) : (
                  <div className="w-full bg-blue-100 text-blue-800 border border-blue-600 p-5">
                     No records
                  </div>
               )}
            </>
         )}
      </div>
   );
}

export default function HeaderSection(props) {
   const {sub, sub2, title1, title2, titleStyle} = props;

   return (
      <div>
         <div className={`segoe header-sub1-section text-${titleStyle} text-center font-bold uppercase text-xs lg:text-lg mb-2`}>
            {sub}
         </div>
         <div className={`header-title-section uppercase text-${titleStyle} text-2xl lg:text-6xl font-bold aldo`}>
            {title1} <span className="text-yellow-c1">{title2}</span>
         </div>
         <p className={`header-sub2-section segoe text-${titleStyle} font-bold text-md lg:text-lg mb-2`}>
            {sub2}
         </p>
      </div>
   );
}

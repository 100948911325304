export default function AdminInput(props) {
   const { className, onChange, type, id, placeholder, errors, label, value, disabled, pattern, dataShow, classNameInput } = props;
	const defaultValue = value || ""

   return (
      <div className={`relative ${className}`}>
			<label className="segoe text-gray-500 capitalize" htmlFor={id}>{label}</label>
         <input
				defaultValue={defaultValue}
            type={type}
            name={id}
            id={id}
            disabled={disabled}
            onChange={onChange}
            placeholder={placeholder}
            pattern={pattern}
            data-show={dataShow}
            className={`${classNameInput} p-3 w-full focus:outline-none border ${
               errors?.status &&
               Object.entries(errors.messages).length > 0 &&
               errors.messages[id]
                  ? "border-red-600"
                  : "border-gray-300"
            } text-black ${disabled ? 'bg-gray-200' : '' }`}
         />
         {errors?.status &&
            Object.entries(errors.messages).length > 0 &&
            errors.messages[id] && (
               <span className="text-red-600">{errors.messages[id][0]}</span>
            )}
      </div>
   );
}

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCaretDown,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { logoutAction } from "../../store/actions/_auth.actions";
//import { useTranslation } from 'react-i18next';
import NoUserImage from "../../assets/img/avatars/no_user.jpeg";

export default function Element(props) {
  const { url, text, expand, onClick, submenu, id } = props;
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();
  const r_profile = useSelector((state) => state.profile);
  //  const { t, i18n } = useTranslation();
  // const openMenu = (id) => {
  //   let element = document.getElementById(`element_${id}`);
  //   let clases = document.getElementsByClassName('elements');
  //   console.log(clases);
  //   element.classList.remove("hidden");
  // }

  return expand ? (
    <div className="flex">
      {menu && (
        <div
          className="hidden lg:block fixed top-0 left-0 w-full h-screen bg-transparent z-10"
          onClick={() => setMenu(!menu)}
        ></div>
      )}
      <li className="text-white pl-10 py-4 lg:py-10 block uppercase transition-all duration-200 relative" onClick={() => setMenu(!menu)}>
        {id === 1 ? (
          <div className="flex items-center relative">
            <div
              className="absolute w-[24px] h-[24px] top-0 left-0 lg:top-[-20px] lg:left-0 lg:w-[64px] lg:h-[64px] rounded-full bg-center bg-cover cursor-pointer"
              style={{ backgroundImage: `url(${r_profile.user.avatar || NoUserImage})`, }}
            ></div>
            <div className="ml-[32px] lg:ml-[70px]">
              <FontAwesomeIcon icon={faCaretDown} className="text-lg text-[#ffc300] my-auto cursor-pointer" />
            </div>
          </div>
        ) : (
          <>
            {text} <FontAwesomeIcon icon={faCaretDown} className="text-lg text-[#ffc300] my-auto cursor-pointer" />
          </>
        )}
        {menu && (
          <ul className="absolute w-[250%] lg:w-[250%] bg-[#061322] lg:top-24 left-[-120%] lg:left-[-120%] flex flex-col p-2 lg:p-5 z-20 aldo" id={`element_${id}`}>
            {submenu.map((item, key) => (
              <li key={key} className="border-b-2 border-[#ffc300] mt-4">
                <Link
                  to={item.url}
                  onClick={() => onClick()}>
                  <FontAwesomeIcon icon={item.icon} className="mr-1 text-[#ffc300]" /> {item.name}
                </Link>
              </li>
            ))}
            {id === 1 && (
              <>
                <li onClick={() => dispatch(logoutAction())} className="mt-5">
                  <a href="/">
                    <FontAwesomeIcon icon={faSignOutAlt} className="mr-2 text-[#ffc300]" />
                    Logout
                  </a>
                </li>
              </>
            )}
          </ul>
        )}
      </li>
      {/* Notification Button */}
      <li className="flex">
        <FontAwesomeIcon icon={faBell} className="text-lg text-[#ffc300] my-auto mx-5 cursor-pointer" />
      </li>
    </div>
  ) : (
    <li className="flex mt-5 md:mt-0 ml-5 md:ml-0">
      <div className="my-auto mr-4">
        <NavLink
          to={url}
          onClick={onClick}
          className={({ isActive }) => isActive
            ? "uppercase font-bold px-3 transition-all duration-200 text-[#ffc300] border-b-2 border-[#ffc300] cursor-pointer"
            : "uppercase font-bold px-3 transition-all duration-200 text-white cursor-pointer"
          }
        >
          {text}
        </NavLink>
      </div>
    </li>
  );
}

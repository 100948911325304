import InputMask from 'react-input-mask';
export default function BasicInput(props) {
   const { className, onChange, type, id, placeholder = "", name="", errors, mask="" } = props;

   return (
      <div className={className}>
         { name && <label className="text-[#FFFFFF] mb-[6px] text-[14px] block font-[200] opacity-50">{name}</label> }
         {
            !mask ? <>
               
               <input
                  type={type}
                  name={id}
                  onChange={onChange}
                  placeholder={placeholder}
                  className={`${className} bg-blue-c2 p-2 w-full focus:outline-none border ${
                     errors?.status &&
                     Object.entries(errors.messages).length > 0 &&
                     errors.messages[id]
                        ? "border-red-600"
                        : "border-yellow-600"
                  } text-white`}
               />
            </> : <>
               <InputMask mask={mask}
                  name={id}
                  onChange={onChange}
                  placeholder={placeholder}
                  className={`${className} bg-blue-c2 p-2 w-full focus:outline-none border ${
                     errors?.status &&
                     Object.entries(errors.messages).length > 0 &&
                     errors.messages[id]
                        ? "border-red-600"
                        : "border-yellow-600"
                  } text-white`}
                  />
            </>
         }
         
         {errors?.status &&
            Object.entries(errors.messages).length > 0 &&
            errors.messages[id] && (
               <span className="text-red-300">{errors.messages[id][0]}</span>
            )}
      </div>
   );
}

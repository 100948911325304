import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { faChevronLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loginAction, verifyEmailAction } from "../../store/actions/_auth.actions";
import { joinEventModal } from "../../store/reducers/_events.reducers";
import { errorLogin, registered, setErrorVerifyEmail, setIsEmailVerified } from "../../store/reducers/_auth.reducers";
import { ToastContainer } from "react-toastify";
import { toast } from 'react-toastify';
import { useSearchParams } from "react-router-dom";
import { t } from "i18next";

export default function AuthLogin() {
   const dispatch = useDispatch();
   const [data, setData] = useState({
      email: "",
      password: "",
   });
   const r_auth = useSelector((state) => state.auth);

   useEffect(()=>{
      if(r_auth.registered == true){
         toast.success('You need to verify your account to access, just confirm the message that has been sent to your email to start the hunt! ', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
         });

         dispatch(registered(false));
      }
   })

   useEffect(() => {
      dispatch(joinEventModal(false));
   }, [dispatch]);

   const handleChange = (event) => {
      setData({
         ...data,
         [event.target.name]: event.target.value,
      });
   };

   const handleSubmit = (event) => {
      event.preventDefault();
      dispatch(verifyEmailAction({email: data.email}));
   };

   useEffect(() => {
     if(r_auth.isEmailVerified){
      dispatch(setIsEmailVerified(false));
      dispatch(loginAction(data));
     }
   }, [r_auth.isEmailVerified])
   
   useEffect(() => {
      if(r_auth.error_verify_email.status == 'failed'){
         toast.warn(r_auth.error_verify_email.message, {
           position: "top-right",
           autoClose: 5000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
           theme: "colored",
         });
         dispatch(setErrorVerifyEmail({status:"", message:""}));
      }
    }, [r_auth.error_verify_email])
   
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if(searchParams){
      if(searchParams.get('v')){
        try {
          const {verified} = JSON.parse(window.atob(searchParams.get('v')));
          if(verified){
            toast.success('Your account was verified', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }

        } catch(e) {
        }

      }
    }
  }, [])

   return (
      <>      
         <ToastContainer />
         <div className="w-full min-h-screen bg-cover bg-no-repeat bg-center bg-[url('../img/banners/bg-login-blur.png')] flex justify-center pt-10 md:p-0 md:items-center">
            <div className="w-4/5 h-3/5 border-4 border-yellow-500 bg-blue-c1 flex">
               <div className="border-r-1 border-yellow-500 h-full w-full p-5 flex flex-col justify-between">
                  <Link to="/" className="text-yellow-c1 text-sm md:text-base">
                     <FontAwesomeIcon icon={faChevronLeft} /> {t("login.go_home")}
                  </Link>
                  <div className="w-full">
                     <div className="flex flex-col justify-center items-center mb-5 md:mb-10">
                        <span className="font-bold text-white text-sm md:text-base">
                           {t("login.be_best")}
                        </span>
                        <span className="text-gray-600 text-xs md:text-sm">
                           {t("login.enter_details")}
                        </span>
                     </div>
                     <form
                        action=""
                        className="flex flex-col w-full md:w-3/5 mx-auto mt-5"
                        onSubmit={handleSubmit}
                     >
                        {r_auth.error_login.status && (
                           <div className="flex justify-between items-center bg-red-100 text-red-500 border border-red-500 mb-4 p-2">
                              {r_auth.error_login.message}
                              <FontAwesomeIcon
                                 icon={faTimes}
                                 className="cursor-pointer"
                                 onClick={() =>
                                    dispatch(
                                       errorLogin({ status: false, message: "" })
                                    )
                                 }
                              />
                           </div>
                        )}
                        <input
                           defaultValue={data.email}
                           name="email"
                           type="text"
                           placeholder={t("login.email")}
                           className="bg-blue-c2 p-2 focus:outline-none border border-yellow-600 mb-2 text-white"
                           onChange={handleChange}
                        />
                        <input
                           defaultValue={data.password}
                           name="password"
                           type="password"
                           placeholder={t("login.password")}
                           className="bg-blue-c2 p-2 focus:outline-none border border-yellow-600 mt-2 text-white"
                           onChange={handleChange}
                        />
                        <Link
                           to="/auth/recovery-password"
                           className="self-end text-gray-500 mt-1 text-sm md:text-base"
                        >
                           {t("login.forgot")}
                        </Link>
                        <button
                           className="w-full bg-yellow-500 p-2 mt-3 text-sm md:text-base active:scale-90"
                           type="submit"
                        >
                           {t("login.login")}
                        </button>
                     </form>
                  </div>
                  <div className="flex justify-start items-end">
                     <img
                        className="w-14"
                        src={require("../../assets/img/logo-treasure/brand-logo.png")}
                        alt=""
                     />
                     <div className="text-white w-full text-center text-xs md:text-sm">
                        {t("login.account")}{" "}
                        <Link to="/auth/register" className="text-yellow-c1">
                           {t("login.signup")}
                        </Link>
                     </div>
                  </div>
               </div>
               <div className="hidden border-l-2 border-yellow-500 h-full w-full p-10 md:flex flex-col justify-center items-center">
                  <img
                     className="w-3/5 xl:w-1/3 max-auto"
                     src={require("../../assets/img/images/fortnite-game-card.png")}
                     alt=""
                  />
                  <span className="font-bold uppercase text-white mt-5">
                     {t("login.upcoming")}{" "}
                     <span className="text-yellow-c1">{t("login.events")}</span>
                  </span>
                  <span className="mt-4 text-gray-500 text-xs">
                     {t("login.content")}
                  </span>
               </div>
            </div>
         </div>
      </>
   );
}

import { createSlice } from '@reduxjs/toolkit';

export const moduleSlice = createSlice({
	name: 'module',
	initialState: {
		loading: false,
		btnLoading: false,
		modules: []
	},
	reducers: {
		loading: (state, action) => {
			state.loading = action.payload
		},
		btnLoading: (state, action) => {
			state.btnLoading = action.payload
		},
		modules: (state, action) => {
			state.modules = action.payload
		}
	}
})

export const { loading, btnLoading, modules } = moduleSlice.actions;
export default moduleSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";

export const wdmethodsSlice = createSlice({
	name: 'wdmethod',
	initialState: {
		loading: false,
		btnLoading: false,
		error: {
			status: false,
			message: '',
			messages: []
		},
		success: {
			status: false,
			message: ''
		},
		wdmethods: [],
		modal: {
			create: false,
			update: false,
			delete: false,
			permissions: false,
			key: undefined
		}
	},
	reducers: {
		loading: (state, action) => {
			state.loading = action.payload
		},
		btnLoading: (state, action) => {
			state.btnLoading = action.payload
		},
		error: (state, action) => {
			state.error.status = action.payload.status;
			state.error.message = action.payload.message;
			state.error.messages = action.payload.messages
		},
		success: (state, action) => {
			state.success.status = action.payload.status;
			state.success.message = action.payload.message;
		},
		wdmethods: (state, action) => {
			state.wdmethods = action.payload
		},
		modal: (state, action) => {
			state.modal.create = action.payload.create || false;
			state.modal.update = action.payload.update || false;
			state.modal.delete = action.payload.delete || false;
			state.modal.permissions = action.payload.permissions || false;
			state.modal.key = (action.payload.key === 0) ? 0 : action.payload.key || undefined;
			state.error.status = false
		},
		store: (state, action) => {
			state.wdmethods.push(action.payload);
		},
		update: (state, action) => {
			state.wdmethods[action.payload.key] = action.payload.data
		},
		destroy: (state, action) => {
			state.wdmethods.splice(action.payload.key,1)
		}
	}
})

export const { loading, btnLoading, error, success, wdmethods, modal, store, update, destroy } = wdmethodsSlice.actions;
export default wdmethodsSlice.reducer;
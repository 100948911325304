import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";

export const Filters = (props) => {

  const {
    types,
    handleChangeSearch,
    searchFilter,
    handleSubmit,
    className = "flex justify-center my-[50px]"
  } = props;
  
  const handleChangeType = (type) => {
    handleChangeSearch({
      target:{
        name: "type",
        value: type
      }
    });
  }

  return (
    <div id="filter-search" className={className}>
      <form className="w-full max-w-[800px]" onSubmit={handleSubmit}>
        <div className="flex items-center border-b border-black py-2">
          <FontAwesomeIcon
              className="text-back text-xl active:scale-95"
              icon={faSearch}
          />
          <input 
            name="find"
            onChange={handleChangeSearch}
            autoComplete="off"
            className="
              font-bold 
              segoe
              appearance-none
              bg-transparent
              border-none
              w-full
              text-gray-700
              mr-3
              py-1
              px-2
              leading-tight
              focus:outline-none" 
            value={searchFilter.find}
            type="text"
            placeholder="Search"
            aria-label="Full name" />
        </div>
        {/* <div className="filters my-4">
          <ul className="flex">
            {
              types.map((type, index)=>{
                return type == searchFilter.type ?
                  (
                    <li 
                      onClick={()=>{handleChangeType(type)}}
                      key={index} 
                      className="
                        mr-3
                        text-[#001D3D]
                        bg-[#FFC300]
                        rounded-[20px]
                        text-center
                        font-bold
                        segoe
                        px-[20px]
                        py-[4px]
                        cursor-pointer">
                      {t(`search.filter.${type}`)}
                    </li>
                  ) : (
                    <li 
                      onClick={()=>{handleChangeType(type)}}
                      key={index} 
                      className="
                        mr-3
                        text-white
                        bg-[#003566]
                        rounded-[20px]
                        text-center
                        font-bold
                        segoe
                        px-[20px]
                        py-[4px]
                        cursor-pointer">
                      {t(`search.filter.${type}`)}
                    </li>
                  )
                
              })
            }
          </ul>
        </div> */}

      </form>
    </div>
  )
}

/**
 * JavaScript comment
 * @Author: Publio Quintero
 * @Date: 2022-10-16 11:42:42
 * @Desc: at the reducers, the variable starts with a pp, that means page-profile
 */
import { current } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export const pageProfileSlice = createSlice({
  name: "pageProfile",
  initialState: {
    loading: false,
    loaded: false,
    profile: [],
    followers: 0,
    error: {
      status: false,
      message: "",
      errors: [],
    },
    btnLoading: false,
    modal: false,
    user: {},
    follows: {
      followers: [],
      following: [],
      followersCount: 0,
      followingCount: 0,
      status: "follow",
    },
    removeUsersFollowLoading:[],
    refreshFollows:false,

    events: {
      published: 0,
      winned: 0,
    },
    historyEvents: [],
    followsList: {
      followers: [],
      following: [],
    },
  },
  reducers: {
    setRefreshFollows: (state, {payload}) => {
      state.refreshFollows = payload;
    },
    ppLoading: (state, action) => {
      state.loading = action.payload;
    },
    setRemoveUsersFollowLoading: (state, {payload}) => {
      state.removeUsersFollowLoading = [...state.removeUsersFollowLoading, payload];
    },
    ppBtnLoading: (state, action) => {
      state.btnLoading = action.payload;
    },
    ppError: (state, action) => {
      state.error.status = action.payload.status;
      state.error.message = action.payload.message;
      state.error.errors = action.payload.errors;
    },
    ppModal: (state, action) => {
      state.modal = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
      state.loaded = true;
    },
    setFollows: (state, action) => {
      state.follows.followersCount = action.payload.followersCount;
      state.follows.followingCount = action.payload.followingCount;
      state.follows.followers = action.payload.followers;
      state.follows.following = action.payload.following;
    },
    setEvents: (state, action) => {
      state.events.published = action.payload.published;
      state.events.winned = action.payload.winned;
    },
    setFollowStatus: (state, action) => {
      state.follows.status = action.payload;
    },
    addOneToFollows: (state, action) => {
      state.follows.followersCount = state.follows.followersCount + 1;
    },
    takeOneToFollows: (state, action) => {
      state.follows.followersCount = state.follows.followersCount - 1;
    },
    historyEvents: (state, action) => {
      state.historyEvents = action.payload;
    },
    followsList: (state, action) => {
      state.followsList.followers = action.payload.followers;
      state.followsList.following = action.payload.following;
    },
    removeFromFollowerList: (state, action) => {
      state.followsList.following.splice(action.payload, 1);
    },
  },
});

export const {
  setRefreshFollows,
  setRemoveUsersFollowLoading,
  ppLoading,
  ppBtnLoading,
  ppError,
  ppModal,
  setUser,
  setFollows,
  setEvents,
  setFollowStatus,
  addOneToFollows,
  takeOneToFollows,
  historyEvents,
  followsList,
  removeFromFollowerList,
} = pageProfileSlice.actions;
export default pageProfileSlice.reducer;

import React from 'react'
import { Link } from 'react-router-dom'
import errorImg from '../../../assets/img/icons/error-page.png';

export const Page404 = () => {
  return (
    <div className='md:flex w-10/12 m-auto mt-[100px]'>

        <div className='md:w-3/6 sm:w-full sm:mb-5'>
            <h1 className="uppercase font-bold font-['AldotheApache'] text-[125px] text-[#000]">404</h1>
            <h2 className="uppercase font-bold font-['AldotheApache'] leading-none">
                <span className='text-[60px] text-yellow-c1'>opss..</span> <br />
                <span className='text-[50px] text-[#000]'>page not found</span>
            </h2>
            <p className='segoe'>
                I’m sorry, we're trying to figure out what's wrong. But don't <br /> worry, you can go backwards!
            </p>
            <div className='mt-8'>
                <Link to='/' className="px-8 py-2 bg-yellow-c1 rounded-[8px] font-['AldotheApache']">
                    Go back to the home
                </Link>
            </div>
        </div>
        <div className='md:w-3/6 sm:w-full sm:mt-5'>
            <br />
            <img src={errorImg} alt="Error page" className='w-full max-w-[600px]' />
        </div>

    </div>
  )
}

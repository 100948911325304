import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BulletButton(props) {
   const { text, className, onClick, icon, color, padding, iconSize, disabled } = props;

   return (
      <div
         className={`flex justify-center mx-auto ${className}  ${!disabled ? 'active:scale-90' : ''}`}
      >
         <button
				disabled={disabled}
            className={`${
               color ? color : "bg-yellow-c1"
            } text-blue-c1 uppercase mx-auto w-[95%] lg:w-[93%] ${
               padding ? padding : "p-5"
            } font-bold flex items-center justify-center ${disabled ? 'cursor-not-allowed' : ''}`}
            style={{ transform: "skew(-20deg)" }}
            onClick={onClick}
         >
            {icon && (
               <FontAwesomeIcon
                  icon={icon}
                  className={`text-black mr-2 ${iconSize ? iconSize : 'text-xl lg:text-5xl'} lg:font-bold`}
               />
            )}
            {text}
         </button>
         <div
            className={`${
               color ? color : "bg-yellow-c1"
            } text-black uppercase text-base mx-auto w-[3%] lg:w-[3%]`}
            style={{ transform: "skew(-20deg)" }}
         ></div>
      </div>
   );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getCreatorUsers,
  getScores,
} from "../../../../../store/actions/_users.actions";
import {
  faCaretDown,
  faCaretUp,
  faEquals,
  // faSearch,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BulletLink from "../../../../../components/BulletLink";
import Loading from "../../../../../components/Loading";
// import HeaderSection from "../../../../../components/HeaderSection";
import { getBigPrizeUpcomming } from "../../../../../store/actions/_events.actions";
import { TransformLetter } from "../../../../../components/TransformLetter";
import { useCountdown } from "../../../../../hooks/Countdown";
import { t } from "i18next";
import "./styles.scss";
import "react-dropdown/style.css";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  creatorsTop,
  setIsDisableShowMoreScore,
} from "../../../../../store/reducers/_users.reducers";
import { UserPopUpCard } from "../../../../../components/userPopUpCard";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function BigPrize() {
  const dispatch = useDispatch();
  const [bigPrizeUpcomming, setBigPrizeUpcomming] = useState(null);
  const r_users = useSelector((state) => state.user);
  const r_events = useSelector((state) => state.event);
  const [actualPageScore, setActualPageScore] = useState(1);
  const [order, setOrder] = useState("desc");

  useEffect(() => {
    dispatch(getCreatorUsers(actualPageScore, r_users.creatorsTop, "desc"));
    return () => {
      dispatch(creatorsTop([]));
    };
  }, []);

  useEffect(() => {
    // dispatch(getScores());
    dispatch(getBigPrizeUpcomming());
  }, [dispatch]);

  useEffect(() => {
    setBigPrizeUpcomming(r_events.upcoming_big_prize_event);
  }, [r_events.upcoming_big_prize_event]);

 /*  const handleShowMore = () => {
    const newPage = actualPageScore + 1;
    dispatch(getScores(newPage, r_users.scores));
    setActualPageScore(newPage);
  }; */
  const handleShowMoreCreators = () => {
    const newPage = actualPageScore + 1;
    dispatch(getCreatorUsers(newPage, r_users.creatorsTop, order));
    setActualPageScore(newPage);
  };

  return (
    <section className="w-full h-auto p-5 lg:p-10 bg-no-repeat bg-cover flex flex-col justify-center items-center bg-[url('../img/backgrounds/dark-background.png')] ">
      {bigPrizeUpcomming &&
        (bigPrizeUpcomming.isLoading ? (
          <Loading color="white" />
        ) : (
          bigPrizeUpcomming.data.map((item, key) => {
            return (
              <div
                key={key}
                className="bg-sm-big-prize md:bg-lg-big-prize h-[600px] bg-cover w-full my-10"
              >
                <div
                  className="
                                 uppercase 
                                 text-[2em]
                                 font-['UACadet'] 
                                 tracking-[.25em] 
                                 transform-skew-20 
                                 bg-blue-c2 
                                 text-yellow-c1 w-2/3 
                                 lg:w-3/12 p-5 
                                 font-bold mt-[-30px] 
                                 mx-auto 
                                 text-center 
                                 shadow-sm"
                >
                  <FontAwesomeIcon icon={faTrophy} className="mr-2" />
                  {t("home.big_price.title")}
                </div>
                <div className="mb-8 tracking-[.08em] text-4xl md:text-5xl uppercase font-bold text-center mt-10 text-white aldo hidden md:block">
                  {t("home.big_price.subtitle_1")}{" "}
                  <span className="text-yellow-c1">
                    {t("home.big_price.subtitle_2")}
                  </span>
                </div>

                <div className="text-center font-['UACadet'] text-[1.6em]">
                  <TransformLetter
                    letterToTransform={item.first_place_reward}
                    finalAppendLetter="$"
                    className="px-[1.2rem] py-[.7rem] mx-[8px] rounded"
                  />
                </div>

                <BulletLink
                  text={t("home.big_price.play_now")}
                  className="w-2/3 lg:w-2/12 mx-auto mt-10 text-lg md:text-2xl tracking-[.15em] aldo"
                  to={"/event-detail/" + item.event_uuid}
                />

                <div className="big-prize-counter my-[2.5em]">
                  <CountDown date={item.full_start_time} />
                </div>
              </div>
            );
          })
        ))}

      {/* WINNERS */}

      {/* <HeaderSection
        sub={t("home.big_price.results.title")}
        title1={t("home.big_price.results.subtitle_1")}
        title2={t("home.big_price.results.subtitle_2")}
        titleStyle="white"
      />

      <div className="w-11/12 mx-auto mt-10">
        <div className="font-bold w-full grid grid-cols-2 lg:grid-cols-5 text-white uppercase text-xs mb-2 lg:w-11/12 mx-auto">
          <div className="pl-10">Position</div>
          <div>Player</div>
          <div className="hidden lg:block">Name</div>
          <div className="hidden lg:block">Points</div>
          <div className="hidden lg:block">Rank</div>
        </div>

        <div className="winners-container overflow-auto scroll custom-scroll">

          {r_users.loading_scores && <Loading color="white" />}
          {!r_users.loading_scores && r_users.scores &&
            r_users.scores.length > 0 &&
            r_users.scores.map((player, key) => (
              <div
                className="relative w-11/12 mx-auto text-white grid grid-cols-2 lg:grid-cols-5 items-center hover:bg-opacity-20 mb-2 h-16 bg-blue-c2"
                key={key}
              >
                <div className="transform-skew-20 bg-blue-c2 w-8 absolute top-0 left-[-12px] h-16"></div>
                <div className="transform-skew-20 bg-blue-c2 w-8 absolute top-0 right-[-12px] h-16"></div>
                <div className="pl-10 flex items-centers ">
                  {key + 1}
                  <Caret
                    place={player.current_position}
                    previus={player.previous_position}
                  />
                </div>
                <div className="font-bold aldo text-lg tracking-widest ">
                  <Link to={`/profile/${player.user.username || player.user.uuid}/about`}>{player.user.username || player.user.name}</Link>
                </div>
                <div className="text-yellow-c1 hidden lg:block segoe hover:underline">
                <Link to={`/profile/${player.user.username || player.user.uuid}/about`}>{player.user.name}</Link>
                </div>
                <div className="font-bold hidden lg:block font-['UACadet']">
                  {player.total_points}
                </div>
                <div className="font-bold  hidden lg:block font-['UACadet']">
                  {player.current_position}
                </div>
              </div>
            ))}

            <div className="w-full text-center">
              {
                !r_users.isDisableShowMoreScore && <button className="mt-[20px] segoe text-[#061322] font-bold text-[14px] text-center bg-yellow-c1 px-[30px] py-[5px] rounded-[4px]" onClick={handleShowMore}>SHOW MORE</button>
              }
            </div>
            
        </div>

      </div> */}

      {/* CREATORS LIST */}

      <div className="text-center my-2">
        <div
          className={`header-title-section uppercase text-white text-2xl lg:text-6xl font-bold aldo`}
        >
          {t("home.big_price.creators.title_part_1")}
          <span className="text-yellow-c1">
            {t("home.big_price.creators.title_part_2")}
          </span>{" "}
          <br />
          {t("home.big_price.creators.title_part_3")}
        </div>
      </div>

      <div className="w-full filter-form relative mr-[12em]">
        <div className="relative"></div>

        <div className="flex justify-end">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button
                className="
                                    text-white
                                    bg-[#003566] 
                                    inline-flex 
                                    w-full 
                                    justify-center 
                                    p-3
                                    md:px-4
                                    md:py-2 
                                    text-sm 
                                    font-medium
                                    shadow-sm
                                    focus:outline-none 
                                    focus:ring-2 
                                    focus:ring-indigo-500 
                                    focus:ring-offset-2 
                                    focus:ring-offset-gray-100
                                    rounded-[6px]
                                    md:rounded-none"
              >
                <img
                  className="w-[20px] md:mr-[10px] relative "
                  src={require("../../../../../assets/img/icons/order-by.png")}
                  alt="Order by"
                />
                <span className="hidden md:block">
                  {t("event_explorer.filter.order_by")}
                </span>
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className="
                                    bg-[#001D3D] 
                                    absolute 
                                    right-0 
                                    z-10 
                                    mt-2 
                                    w-[275px]
                                    origin-top-right
                                    divide-y
                                    divide-gray-100
                                    shadow-lg
                                    ring-1
                                    ring-black
                                    ring-opacity-5
                                    focus:outline-none
                                    px-[10px]
                                    py-[3px]
                                "
              >
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(creatorsTop([]));
                          setActualPageScore(1);
                          setOrder("desc");
                          dispatch(getCreatorUsers(1, [], "desc"));
                          dispatch(setIsDisableShowMoreScore(false));
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-white segoe font-bold capitalize",
                          "block px-4 py-2 text-sm capitalize"
                        )}
                      >
                        {t("home.big_price.filter.desc")}
                      </span>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setActualPageScore(1);
                          dispatch(creatorsTop([]));
                          setOrder("asc");
                          dispatch(setIsDisableShowMoreScore(false));
                          dispatch(getCreatorUsers(1, [], "asc"));
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-white segoe font-bold capitalize",
                          "block px-4 py-2 text-sm capitalize"
                        )}
                      >
                        {t("home.big_price.filter.asc")}
                      </span>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>

      <div className="w-11/12 mx-auto mt-10">
        <div className="font-bold w-full grid grid-cols-2 lg:grid-cols-5 text-white uppercase text-xs mb-2 lg:w-11/12 mx-auto">
          <div className="pl-10 uppercase">Position</div>
          <div className="uppercase">User</div>
          <div className="hidden lg:block uppercase">Name</div>
          <div className="hidden lg:block uppercase">Created events</div>
          <div className="hidden lg:block uppercase">User Joined</div>
        </div>

        <div className="winners-container overflow-auto scroll custom-scroll">
          {r_users.loading_creatorsTop && <Loading color="white" />}
          {!r_users.loading_creatorsTop &&
            r_users.creatorsTop &&
            r_users.creatorsTop.length > 0 &&
            r_users.creatorsTop.map((player, key) => (
              <div
                className="relative w-11/12 mx-auto text-white grid grid-cols-2 lg:grid-cols-5 items-center hover:bg-opacity-20 mb-2 h-16 bg-blue-c2"
                key={key}
              >
                <div className="transform-skew-20 bg-blue-c2 w-8 absolute top-0 left-[-12px] h-16"></div>
                <div className="transform-skew-20 bg-blue-c2 w-8 absolute top-0 right-[-12px] h-16"></div>
                <div className="pl-10 flex items-centers ">
                  {key + 1}
                  {/* <Caret
                    place={player.current_position}
                    previus={player.previous_position}
                  /> */}
                </div>
                <div>
                  <UserPopUpCard
                    user={player}
                    className="font-bold aldo text-lg tracking-widest"
                  />
                  {/* <Link to={`/profile/${player.username || player.uuid}/about`}>{player.username || player.name}</Link> */}
                </div>
                <div className="text-yellow-c1 hidden lg:block segoe hover:underline">
                  <Link to={`/profile/${player.username || player.uuid}/about`}>
                    {player.name}
                  </Link>
                </div>
                <div className="font-bold hidden lg:block font-['UACadet'] text-[20px]">
                  {player.events_created_by_user_count}
                </div>
                <div className="font-bold  hidden lg:block font-['UACadet'] text-[20px]">
                  {player.joined_users_count}
                </div>
              </div>
            ))}

          <div className="w-full text-center">
            {!r_users.isDisableShowMoreScore && (
              <button
                className="mt-[20px] segoe text-[#061322] font-bold text-[14px] text-center bg-yellow-c1 px-[30px] py-[5px] rounded-[4px]"
                onClick={handleShowMoreCreators}
              >
                SHOW MORE
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
//
function Caret({ place, previus }) {
  if (place === previus) {
    return <FontAwesomeIcon icon={faEquals} className="text-3xl ml-2" />;
  }
  if (place > previus) {
    return (
      <FontAwesomeIcon
        icon={faCaretDown}
        className="text-3xl text-red-700 ml-2"
      />
    );
  }
  if (place < previus) {
    return (
      <FontAwesomeIcon
        icon={faCaretUp}
        className="text-3xl text-green-700 ml-2"
      />
    );
  }
}

const CountDown = (props) => {
  const { date } = props;
  const [days, hours, minutes, seconds] = useCountdown(date);
  return (
    <div className="flex justify-center">
      <div className="mx-2 md:mx-4">
        <p className="text-yellow-c1 text-2xl md:text-5xl font-bold text-center">
          {days}
        </p>
        <p className="text-white text-xl md:text-4xl font-bold uppercase text-center segoe">
          {t("home.big_price.days")}
        </p>
      </div>

      <span className="text-white text-center text-2xl md:text-5xl font-bold segoe">
        :
      </span>

      <div className="mx-2 md:mx-4">
        <p className="text-yellow-c1 text-2xl md:text-5xl font-bold text-center segoe">
          {hours}
        </p>
        <p className="text-white text-xl md:text-4xl font-bold uppercase text-center segoe">
          {t("home.big_price.hours")}
        </p>
      </div>

      <span className="font-bold text-white text-center text-2xl md:text-5xl segoe">
        :
      </span>

      <div className="mx-2 md:mx-4">
        <p className="text-yellow-c1 text-2xl md:text-5xl font-bold text-center segoe">
          {minutes}
        </p>
        <p className="text-white text-xl md:text-4xl font-bold uppercase text-center segoe">
          Min
        </p>
      </div>

      <span className="font-bold text-white text-center text-2xl md:text-5xl segoe">
        :
      </span>

      <div className="mx-2 md:mx-4">
        <p className="text-yellow-c1 text-2xl md:text-5xl font-bold text-center segoe">
          {seconds}
        </p>
        <p className="text-white text-xl md:text-4xl font-bold uppercase text-center segoe">
          Sec
        </p>
      </div>
    </div>
  );
};

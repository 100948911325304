import { configureStore } from "@reduxjs/toolkit";
import mainReducers from "./_main.reducers";
import authReducers from './reducers/_auth.reducers'
import userReducers from './reducers/_users.reducers'
import profileReducers from './reducers/_profile.reducers'
import subscriptionReducers from './reducers/_subscriptions.reducers'
import eventsReducers from "./reducers/_events.reducers";
import newsReducers from './reducers/_news.reducers';
import paymentReducers from './reducers/reducer.payments'
import pageProfileReducers from "./reducers/_pageProfile.reducers";
import roleReducers from './reducers/_role.reducers'
import searchReducers from './reducers/_search.reducers'
import moduleReducers from './reducers/_module.reducers'
import withdrawReducers from './reducers/_withdraws.reducers'
import withdrawalMethodReducers from './reducers/_withdrawalMethods.reducers'
import requestWithdrawal from './reducers/_request.withdrawal.reducers'
import paymentRequest from "./reducers/_paymentRequest.reducers";

export default configureStore({
	reducer: {
		main: mainReducers,
		auth: authReducers,
		user: userReducers,
		profile: profileReducers,
		subscription: subscriptionReducers,
		event: eventsReducers,
		news: newsReducers,
		payment: paymentReducers,
		pageProfile: pageProfileReducers,
		role: roleReducers,
		search: searchReducers,
		module: moduleReducers,
		withdraw: withdrawReducers,
		wdmethod: withdrawalMethodReducers,
		requestwd: requestWithdrawal,
		payreq: paymentRequest
	}
})
import { Routes, Route } from "react-router-dom";
import Login from "./Auth.login";
import Register from "./Auth.register";
import Recovery from "./Auth.recover-password";
import ResetPassword from './Auth.reset-password'

export default function Auth() {
  return (
    <Routes>
      <Route path="" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="recovery-password" element={<Recovery />} />
      <Route path="reset-password/:token" element={<ResetPassword />} />
    </Routes>
  );
}

// import { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
// import momenttz from "moment-timezone";
import moment from "moment";

export default function Timezone({ date, formatStr = 'llll', convertType = "toLocal"}) {
  // const profile = useSelector((state) => state.profile);
  // const [value, setValue] = useState();

  switch (convertType) {
    case "toLocal":

      if(date){          
        let ndate = date.replace(" ","T");
        return moment.utc(ndate).local().format(formatStr);
      }
      break;
    case "toUTC":
    default:
      return moment(date).utc().format(formatStr);
  }
}

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BasicButton( props ) {
	const { status } = props
   return (
      <button
			disabled={status}
         className="w-full bg-yellow-500 p-2 mt-5 text-sm md:text-base"
         type="submit"
      >
         {status ? <><FontAwesomeIcon icon={faSpinner} spin /> Loading</> : 'Register'}
      </button>
   );
}

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import CustomModal from "../../components/CustomModal";
import { modules } from "../../store/reducers/_module.reducers";
import { getModules, updatePermission } from "../../store/actions/_module.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function RolePermission({ status, close }) {
  const dispatch = useDispatch();
  const r_role = useSelector((state) => state.role);
  const r_module = useSelector((state) => state.module);
  const [data, setData] = useState(undefined);

  useEffect(() => {
    setData(r_role.roles[r_role.modal.key]);
    return () => {
      setData(undefined);
    };
  }, [r_role.modal.key, r_role.roles]);

  useEffect(() => {
    if (data !== undefined) {
      dispatch(getModules(data.id));
    }
    return () => {
      dispatch(modules([]));
    };
  }, [dispatch, data]);

  // const handleChange = (event) => {
  //   setData({
  //     ...data,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   dispatch(deleteRoles(r_role.modal.key, data.id));
  // };

  // console.log(r_module.loading, data, r_module.modules.length);

  return (
    data && (
      <CustomModal
        title={`Update Permissions to ${data.name}`}
        size="w-1/2"
        status={status}
        closedButton={close}
      >
        {r_module.loading ? (
          <Loading />
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th>id</th>
                <th>Module</th>
                <th>Parent</th>
                <th>Path</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {r_module.modules.map((item, key) => (
                <tr key={key}>
                  <td>{item.id}</td>
                  <td>
                    <FontAwesomeIcon icon={item.icon} /> {item.name}
                  </td>
                  <td>{item.parent_id}</td>
                  <td>{item.url}</td>
                  <td>
                    {item.permissions.length > 0
                      ? item.permissions[0].status
                        ? <ButtonPermission module={item.id} role={data.id} checked={true} />
                        : <ButtonPermission module={item.id} role={data.id} checked={false} />
                      : <ButtonPermission module={item.id} role={data.id} checked={false} /> }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </CustomModal>
    )
  );
}

const ButtonPermission = ( { module, role, checked } ) => {
	const dispatch = useDispatch();

	const handleChange = (event) => {
		let checked = event.target.checked;
		dispatch(updatePermission(module, role, checked));
	}

	return (
		<input className="w-5 h-5 bg-blue-100" name="algo" type="checkbox" defaultChecked={checked} onChange={handleChange} />
	)
}
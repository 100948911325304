import { faCircleCheck, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSearchData } from "../../store/actions/_search.actions";
import { useClickEment } from "../../hooks/useClickEment";
import { Link, useNavigate } from "react-router-dom";

export const SearchUserEvents = ({
  searchType = "all",
  color = "white",
  placeholder = "Search...",
  className,
  handleEvent
}) => {
  const searchState = useSelector((state) => state.search);
  const clickElement = useClickEment();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("")
  const navigate = useNavigate()
  const handleChangeSearch = ({ target }) => {
    setSearchValue(target.value);
    dispatch(
      getSearchData({
        find: target.value,
        type: "all",
      })
    );
  };

  const changePage = (page) => {
    console.log(handleEvent);
    if(handleEvent){
      handleEvent();
    }

    navigate(page);
  }

  return (
    <>
      <div className={className+" relative w-full"} id="search-navbar">
        <div
          className={`flex items-center border-b border-${color} py-2 w-full`}
        >
          <FontAwesomeIcon
            className={`text-${color} text-xl active:scale-95`}
            icon={faSearch}
          />
          <input
            name="search-navbar-input"
            autoComplete="off"
            className={`
                    text-${color}
                    font-bold 
                    segoe
                    appearance-none
                    bg-transparent
                    border-none
                    w-full
                    text-${color}-700
                    mr-3
                    py-1
                    px-2
                    leading-tight
                    focus:outline-none
                `}
            onChange={handleChangeSearch}
            type="text"
            placeholder={placeholder}
            aria-label="Full name"
          />
        </div>
        {clickElement && (
          <>
            {(clickElement.name == "search-navbar-input" ||
              clickElement.classList.contains("search-list")) && (
              <div className="bg-[#061322] absolute w-full text-white z-50">
                <ul>
                  {searchState.principalResult &&
                  
                    (searchType == "all" || searchType == "users") && (
                      <li className="px-2 py-1 border-b border-white segoe text-[14px] font-bold search-list">
                        <span
                          to={`/profile/${searchState.principalResult?.username || searchState.principalResult?.uuid}/about`}
                          className="search-list"
                        >
                          <FontAwesomeIcon
                            className="text-white text-sm mr-3"
                            icon={faSearch}
                          />
                          {searchState.principalResult.username}

                          {searchState.principalResult.role_id == 3 && (
                            <FontAwesomeIcon
                              className="ml-4 text-[#0066FF] text-back text-[14px] relative left-[-5px]"
                              icon={faCircleCheck}
                            />
                          )}
                        </span>
                      </li>
                    )}

                  {searchState.moreResults.length > 0 &&
                    (searchType == "all" || searchType == "users") &&
                    searchState.moreResults.map((result, index) => {
                      return (
                        <li
                          key={index}
                          className="search-list px-2 py-1 border-b border-white segoe text-[14px] font-bold"
                        >
                          <span                       
                            onClick={()=>{changePage(`/profile/${result.username || result.uuid}/about`)}}

                            className="search-list"
                          >
                            <FontAwesomeIcon
                              className="text-white text-sm mr-3"
                              icon={faSearch}
                            />
                            {result.username}

                            {result.role_id == 3 && (
                              <FontAwesomeIcon
                                className="ml-4 text-[#0066FF] text-back text-[14px] relative left-[-5px]"
                                icon={faCircleCheck}
                              />
                            )}
                          </span>
                        </li>
                      );
                    })}

                  {searchState.events.length > 0 &&
                    (searchType == "all" || searchType == "events") &&
                    searchState.events.map((result, index) => {
                      return (
                        <li
                          key={index}
                          className="search-list px-2 py-1 border-b border-white segoe text-[14px] font-bold"
                        >
                          <span
                           onClick={()=>{changePage("/event-detail/" + result.event_uuid)}}
                            className="search-list"
                          >
                            <FontAwesomeIcon
                              className="text-white text-sm mr-3"
                              icon={faSearch}
                            />
                            {result.title}
                          </span>
                        </li>
                      );
                    })}

                  {searchType == "all" &&
                    (searchState.principalResult ||
                      searchState.moreResults.length > 0 ||
                      searchState.events.length > 0) && (
                      <li className="search-list px-2 py-3 mt-3 segoe text-[14px] font-bold text-center">
                        <span
                          onClick={()=>{changePage("/search?find=" + searchValue)}}
                          className="search-list bg-[#001D3D] py-1 px-5 m-auto"
                        >
                          Show detailed search
                        </span>
                      </li>
                    )}
                  {searchType == "events" && searchState.events.length > 0 && (
                    <li className="search-list px-2 py-3 mt-3 segoe text-[14px] font-bold text-center">
                      <span
                        onClick={()=>{changePage("/search?find=" + searchValue)}}
                        className="search-list bg-[#001D3D] py-1 px-5 m-auto"
                      >
                        Show detailed search
                      </span>
                    </li>
                  )}

                  {searchType == "users" &&
                    (searchState.principalResult ||
                      searchState.moreResults.length > 0) && (
                      <li className="search-list px-2 py-3 mt-3 segoe text-[14px] font-bold text-center">
                        <span
                          onClick={()=>{changePage("/search?find=" + searchValue)}}
                          className="search-list bg-[#001D3D] py-1 px-5 m-auto"
                        >
                          Show detailed search
                        </span>
                      </li>
                    )}
                </ul>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

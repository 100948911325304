/* eslint-disable eqeqeq */
import { useEffect, useState, useRef } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EventInfo from "./update/event.update.info";
import EventTime from "./update/event.update.time";
import EventCapture from "./update/event.update.captures";
import EventAward from "./update/event.update.awards";
import EventDetail from "./update/event.update.details";
import AdminButton from "../../components/admin/admin.button";
import AdminCancel from "../../components/admin/admin.cancel";
import AdminAlert from "../../components/admin/admin.alert";
import Loading from "../../components/Loading";
import {
  updateEvent,
  eventTypesAction,
  getEvents,
  myRole,
} from "../../store/actions/_events.actions";
import { resetAlert } from "../../store/reducers/_events.reducers";
import EventPayment from "./update/event.update.payment";
import Timezone from "../../hooks/timezone";
import { ToastContainer, toast } from "react-toastify";

export default function EventUpdate() {
  const dispatch = useDispatch();
  const r_events = useSelector((state) => state.event);
  const eventRegister = useRef(null);
  let { event_id } = useParams();

  const [data, setData] = useState();
  const [dataForm, setDataForm] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (!r_events.loaded) {
      dispatch(getEvents());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [r_events.loaded]);

  useEffect(() => {
    setData(null);
    if (
      r_events.loaded &&
      r_events.events.draft &&
      r_events.events.draft.length > 0
    ) {
      let data = null;
      data = r_events.events.draft.filter(
        // eslint-disable-next-line eqeqeq
        (item) => item.event_uuid == event_id
      );

      if (data.length < 1) {
        data = r_events.events.live.filter(
          // eslint-disable-next-line eqeqeq
          (item) => item.event_uuid == event_id
        );
      }

      if (data.length > 0) {
        let live_data = data[0];
        if (live_data.closed_event == 0) {
          const initTime = Timezone({
            date: live_data.full_start_time,
            formatStr: "YYYY-MM-DD HH:mm",
          });

          const newDate = new Date(initTime);
          if (newDate < new Date()) {
            data = [];
          }
        }
      }

      if (data.length > 0) {
        setData(data[0]);
      } else {
        setData(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [r_events.loaded, r_events.events.length]);

  useEffect(() => {
    dispatch(myRole());
    dispatch(eventTypesAction());
  }, [dispatch]);

  // useEffect( () => {
  //   var payment = parseFloat(data.first_place_reward) + parseFloat(data.first_place_reward) * (10 / 100);
  //   setData({
  //     ...data,
  //     total_to_pay: payment
  //   })
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[data.first_place_reward]);

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckbox = (event) => {
    let value = event.target.checked ? 1 : 0;
    let error = false;

    if (data.is_free_event && event.target.name == "big_prize") {
      if (value) {
        error = true;
        toast.error("You cannot activate both options", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }
    }

    if (data.big_prize && event.target.name == "is_free_event") {
      if (value) {
        error = true;
        toast.error("You cannot activate both options", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }
    }
    if (error) {
      return;
    }

    setData({
      ...data,
      [event.target.name]: value,
    });
    // console.log(data);
  };

  // const handleCheckbox = (event) => {
  //   let value = event.target.checked ? 1 : 0;
  //   setData({
  //     ...data,
  //     [event.target.name]: value,
  //   });
  // };

  const ckeditorValue = (value) => {
    setData({
      ...data,
      details: value,
    });
  };

  const ckeditorValueBriefDescription = (value) => {
    setData({
      ...data,
      brief_description: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData();
    let imagefile = document.querySelector("#screenshot");
    let imagefile_cover = document.querySelector("#cover");
    formData.append("title", data.title || "");
    formData.append("big_prize", data.big_prize || 0);
    formData.append("cover", imagefile_cover.files[0] || "");
    formData.append("screenshot", imagefile.files[0] || "");
    formData.append("description_proof_1", data.description_proof_1 || "");
    formData.append("description_proof_2", data.description_proof_2 || "");
    formData.append("platform", data.platform || "");
    formData.append("start_date", data.start_date || "");
    formData.append("start_time", data.start_time || "");
    formData.append("end_date", data.end_date || "");
    formData.append("end_time", data.end_time || "");
    formData.append("first_place_reward", data.first_place_reward || 0);
    formData.append(
      "first_place_extra_points",
      data.first_place_extra_points || 0
    );
    formData.append("second_place_reward", data.second_place_reward || 0);
    formData.append("third_place_reward", data.third_place_reward || 0);
    formData.append("max_points_seasson", data.max_points_seasson || 0);
    formData.append("type_of_event", data.type_of_event);
    formData.append("event_type_id", data.event_type_id);
    formData.append("requirements", data.requirements || "");
    formData.append("brief_description", data.brief_description || "");
    formData.append("details", data.details || "");
    formData.append("is_free_event", data.is_free_event);
    formData.append("type_prize", data.type_prize);
    formData.append("total_to_pay", data.total_to_pay);
    formData.append("image_original", data.screenshot);
    formData.append("cover_original", data.cover);

    const utcStartDate = Timezone({
      date: data.start_date + " " + data.start_time + ":00",
      formatStr: "YYYY-MM-DD",
      convertType: "toUTC",
    });

    const utcStartTime = Timezone({
      date: data.start_date + " " + data.start_time + ":00",
      formatStr: "HH:mm",
      convertType: "toUTC",
    });

    const utcEndDate = Timezone({
      date: data.end_date + " " + data.end_time + ":00",
      formatStr: "YYYY-MM-DD",
      convertType: "toUTC",
    });

    const utcEndTime = Timezone({
      date: data.end_date + " " + data.end_time + ":00",
      formatStr: "HH:mm",
      convertType: "toUTC",
    });

    formData.append("utc_start_date", utcStartDate || "");
    formData.append("utc_start_time", utcStartTime || "");
    formData.append("utc_end_date", utcEndDate || "");
    formData.append("utc_end_time", utcEndTime || "");

    // if (r_events.know_role === 3 && data.total_to_pay > 0) {
    //   setDataForm(formData);
    //   setOpenModal(true);
    // } else {
    dispatch(updateEvent(formData, event_id));
    // }
  };

  // useEffect( () => {
  //   if (dataForm !== null) {
  //     dispatch(addEvent(dataForm));
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[dataForm])

  return (
    <>
      {r_events.alert.status && (
        <Navigate to="/settings/users/profile/my-events/created/draft" />
      )}
      {r_events.loading && <Loading />}
      {!r_events.loading && data && (
        <>
          <ToastContainer />
          <form
            action=""
            id="register-user"
            ref={eventRegister}
            onSubmit={handleSubmit}
            className="w-full mt-5 mb-10 pb-32 form-event shadow-2xl"
          >
            <AdminAlert
              message={r_events.alert.message}
              status={r_events.alert.status}
              style={r_events.alert.style}
              close={() => dispatch(resetAlert())}
            />

            <EventInfo
              data={data}
              handleChange={handleChange}
              handleCheckbox={handleCheckbox}
            />
            <EventTime handleChange={handleChange} data={data} />
            <EventCapture handleChange={handleChange} data={data} />
            <EventAward handleChange={handleChange} data={data} />
            <EventDetail
              handleChange={handleChange}
              ckeditorValue={ckeditorValue}
              data={data}
              ckeditorValueBriefDescription={ckeditorValueBriefDescription}
            />

            <div className="flex justify-between w-1/2 float-right">
              <AdminCancel
                text="Back"
                to="/settings/users/profile/my-events/created/draft"
              />
              {/* {r_events.know_role === 3 ? (
                data.is_free_event ? (
                  <AdminButton status={r_events.btnLoading} text="Submit" />
                ) : (
                  data.type_prize === "money" &&
                  data.first_place_reward > 0 && (
                    <AdminButton
                      type="submit"
                      status={r_events.btnLoading}
                      text={`Your total is 
                        $${data.total_to_pay}`}
                    />
                  )
                )
              ) : (
                <AdminButton status={r_events.btnLoading} text="Submit" />
              )} */}
              <AdminButton status={r_events.btnLoading} text="Submit" />
            </div>
          </form>
          <EventPayment
            closeModal={() => setOpenModal(false)}
            status={openModal}
            data={data}
            formData={dataForm}
          />
        </>
      )}
    </>
  );
}

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import defaultImage from '../../../../assets/img/avatars/no_user.jpeg'

export default function ProfileHeader() {
  const r_pp = useSelector((state) => state.pageProfile);

  return (
    <div className="mt-5 flex">
      <div className="w-32 h-32">
        <img
          src={r_pp.user.avatar || defaultImage}
          className="rounded-full w-full h-full"
          alt=""
        />
      </div>
      <div className="ml-10 mt-5">
        <h1 className="font-bold text-lg">
          {r_pp.user.username || `${r_pp.user.name} ${r_pp.user.lastname}`}
        </h1>
        <div className="text-sm text-gray-400 flex space-x-5">
          <Link className="text-yellow-c1" to={"/followers/"+r_pp.user.username}>{r_pp.follows.followersCount} Followers</Link>
          <span>{r_pp.follows.followingCount} Following</span>
        </div>
      </div>
    </div>
  );
}

import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import Footer from '../../../components/footer/Footer'
import Loading from '../../../components/Loading'
import { getSearchData } from '../../../store/actions/_search.actions'
import { EventsFound, Filters, UsersFound } from './components'
import no_data_search from '../../../assets/img/banners/no_data_search.svg';
import { setIsShowInNavBar } from '../../../store/reducers/_search.reducers'
import { pastEventsAction } from '../../../store/actions/_events.actions'
import { Fragment } from "react";
import { t } from "i18next";
import { Menu, Transition } from "@headlessui/react";



function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}



export const PastEventsPage = () => {
  
  const categoryEvent = "";
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const _eventState = useSelector((state) => state.event);
  const [searchFilter, setSearchFilter] = useState({
    find: "",
    order_by: ""
  });


  const handleChangeSearch = ({target}) => {
    setSearchFilter({
      ...searchFilter,
      [target.name]: target.value,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(pastEventsAction("", "", searchFilter.find, searchFilter.order_by));
  }

  useEffect(() => {
    dispatch(pastEventsAction("", "", searchFilter.find, searchFilter.order_by));
  }, [searchFilter.order_by])
  

  return (
    <div className="w-full bg-cover bg-no-repeat bg-[url('../img/backgrounds/main-bg.png')] bg-center flex flex-col justify-center">

        <div className='w-fill mx-[50px] mt-[20px]'>
          
          <div className='px-[40px]'>
            <h2 className="uppercase font-bold font-['AldotheApache'] text-[4em] m-2 text-[#001D3D]">
              Past events
            </h2>
            
            <div className="grid grid-cols-2 gap-4 filter-form">
              
              <Filters 
                searchFilter={searchFilter} 
                types={_eventState.types} 
                handleChangeSearch={handleChangeSearch}
                handleSubmit={handleSubmit}
                className=""
              />

              <div className="flex justify-end">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button
                      className="
                                          text-white
                                          bg-[#001D3D] 
                                          inline-flex 
                                          w-full 
                                          justify-center 
                                          p-3
                                          md:px-4
                                          md:py-2 
                                          text-sm 
                                          font-medium
                                          shadow-sm
                                          focus:outline-none 
                                          focus:ring-2 
                                          focus:ring-indigo-500 
                                          focus:ring-offset-2 
                                          focus:ring-offset-gray-100
                                          rounded-[50%]
                                          md:rounded-none"
                    >
                      <img
                        className="w-[20px] md:mr-[10px] relative md:pt-3"
                        src={require("../../../assets/img/icons/order-by.png")}
                        alt="Order by"
                      />
                      <span className="hidden md:block">{t("event_explorer.filter.order_by")}</span>
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className="
                                          bg-[#001D3D] 
                                          absolute 
                                          right-0 
                                          z-10 
                                          mt-2 
                                          w-[275px]
                                          origin-top-right
                                          divide-y
                                          divide-gray-100
                                          shadow-lg
                                          ring-1
                                          ring-black
                                          ring-opacity-5
                                          focus:outline-none
                                          px-[10px]
                                          py-[3px]
                                      "
                    >
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                setSearchFilter({
                                  ...searchFilter,
                                  order_by: "recents",
                                });
                              }}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-white segoe font-bold capitalize",
                                "block px-4 py-2 text-sm capitalize"
                              )}
                            >
                              {t("event_explorer.filter.decendent_date")}
                            </span>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                setSearchFilter({
                                  ...searchFilter,
                                  order_by: "older",
                                });
                              }}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-white segoe font-bold capitalize",
                                "block px-4 py-2 text-sm capitalize"
                              )}
                            >
                              {t("event_explorer.filter.ascending_date")}
                            </span>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          {
            _eventState.pastEvents.loading && <Loading color="black" />
          }

          
          {
            !_eventState.pastEvents.loading && <>
              <EventsFound sectionName="" events={_eventState.pastEvents.list} />
            </>
          }

          {
              _eventState.pastEvents.loading == false && (
              _eventState.pastEvents.list < 1
            ) && (
              <div className='m-auto text-center w-full bg-[#1D2C3C] py-[80px] mb-[100px]'>
                <div className="header-title-section uppercase text-white text-2xl lg:text-6xl font-bold aldo">
                  There are no events <span className="text-yellow-c1">in past events</span>
                </div>
                {/* <p className="header-sub2-section segoe text-white font-bold text-md lg:text-lg my-5">
                  Soon discover more events for this category or participate in our ITHL events, don't wait any longer to win!
                </p> */}
                <img src={no_data_search} className="max-w-[300px] w-full m-auto" />
              </div>
            )
          }
        
        </div>

    </div>
  )
}

/* eslint-disable eqeqeq */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { joinEvent } from "../../../../../store/actions/_events.actions";
import paymentCheck from "../../../../../assets/img/icons/payment-check.png";
import Loading from "../../../../../components/Loading";
import parse from "html-react-parser";
import defaultImage from "../../../../../assets/img/avatars/no_user.jpeg";
import { Link } from "react-router-dom";

export const EventDetail = ({ eventData }) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
//   const r_subscription = useSelector((state) => state.subscription);
  const r_events = useSelector((state) => state.event);

  const handleCheckbox = (e) => {
    let accept = e.target.checked;
    setActive(accept);
  };

  const handleJoinEvent = () => {
    dispatch(joinEvent(eventData.event_uuid));
  };

  return (
    <>
      {Object.entries(r_events.payment_mode?.response).length === 0 && (
        <>
          <div className="flex mt-10 animate__animated animate__fadeIn">
            <div>
              <img
                className="w-full rounded-[6px] max-w-[450px]"
                src={eventData.cover}
                alt=""
              />
            </div>

            <div className="px-4 w-full">
              <div className="event-header flex">
                <div className="event-title w-8/12">
                  <h3 className="segoe font-bold capitalice text-[24px]">
                    {eventData.title}
                  </h3>

                  <div className="flex items-center mt-4 mb-5">
                    <div
                      style={{
                        backgroundImage: `url(${
                          eventData.user_owner?.avatar || defaultImage
                        })`,
                      }}
                      className={
                        "w-full h-screen bg-cover bg-no-repeat bg-center w-[50px] h-[50px] min-w-[50px] min-h-[50px] bg-red-100 rounded-[100%]"
                      }
                    ></div>

                    {/* <img
                            src={eventData.user_owner?.avatar || defaultImage}
                            className="rounded-[50%] max-w-[40px]"
                            alt=""
                            /> */}

                    <span className="pl-[10px] font-[#001D3D] segoe font-[600] text-[12px]">
                      {eventData.user_owner.username
                        ? eventData.user_owner.username
                        : eventData.user_owner.name}
                    </span>
                  </div>
                </div>

                <div className="w-4/12">
                  <div className="text-center transform-skew-20 bg-yellow-c1 p-4 font-bold uppercase font-['AldotheApache'] text-[24px]">
                    Award ${eventData.first_place_reward}
                  </div>
                </div>
              </div>

              {!eventData.is_free_event && (
                <>
                  <div className="mt-3">
                    <h3
                      className="
                                            segoe
                                            font-['#181818']
                                            text-[24px]
                                            font-[600]
                                            mb-3
                                        "
                    >
                      Order Sumary
                    </h3>

                    <ul>
                      <li className="flex items-center mb-2">
                        <img alt="image_1" src={paymentCheck} className="mr-2" />
                        Duration of the event: Effective from 06/12 to 12/12
                      </li>
                      <li className="flex items-center mb-2">
                        <img alt="image_2" src={paymentCheck} className="mr-2" />
                        Instructions to play (available the day the event
                        starts)
                      </li>
                      <li className="flex items-center mb-2">
                        <img alt="image_3" src={paymentCheck} className="mr-2" />
                        Active section to upload your proofs as .pdf, .png files
                      </li>
                    </ul>
                  </div>

                  <div className="event-pric my-5">
                    <ul>
                      <li className="flex relative py-2 border-t border-[#E5E7EB] mb-5">
                        <div className="w-1/2 text-[#6B7280] segoe">
                          Subtotal
                        </div>
                        <div className="w-1/2 text-right text-[#6B7280] segoe">
                          $1.00
                        </div>
                      </li>
                      <li className="flex py-2 border-b border-[#E5E7EB]">
                        <div className="w-1/2 text-[#6B7280] segoe">Taxes</div>
                        <div className="w-1/2 text-right text-[#6B7280] segoe">
                          $0.02
                        </div>
                      </li>
                      <li className="flex mt-3">
                        <div className="w-1/2 segoe">Total</div>
                        <div className="w-1/2 text-right segoe">$1.02</div>
                      </li>
                    </ul>
                  </div>
                </>
              )}

              {eventData.is_free_event == 1 && (
                <>
                  <div className="mt-3">
                    <p className="segoe my-3">
                      {parse(eventData.brief_description)}
                    </p>

                    <span
                      className="
                                                px-[20px] 
                                                py-[5px] 
                                                font-bold 
                                                text-[20px] 
                                                segoe 
                                                uppercase 
                                                bg-[#7ED360]"
                    >
                      FREE ENTRY
                    </span>
                  </div>

                  {r_events.loading && <Loading />}
                </>
              )}
            </div>
          </div>
        </>
      )}

      {!r_events.payment_mode.active && (
        <div className="mt-5 w-full flex">
          <div className="mt-10 flex items-center w-3/6">
            <input
              type="checkbox"
              name="accept_conditions"
              onChange={handleCheckbox}
              id="accept_conditions"
              className="w-5 h-5 mr-3 mt-1"
              disabled={r_events.payment_mode.active}
            />
            <label htmlFor="accept_conditions" className="segoe text-[14px]">
              I have read{" "}
              <Link
                to={"/terms-conditions"}
                className="text-[#001D3D] font-bold"
              >
                Terms & Conditions
              </Link>{" "}
              to participate in the event
            </label>
          </div>

          <div className="w-3/6 flex flex-end justify-end">
            <div className="transform-section w-max">
              <button
                onClick={handleJoinEvent}
                disabled={!active}
                className="font-['AldotheApache'] text-[24px]"
              >
                {eventData.is_free_event ? "JOIN NOW" : "Proceed to payment"}
              </button>
            </div>

            {/* 
                <div className="w-3/6">
                    <BulletButton
                    text="Proceed to payment"
                    className="font-['AldotheApache'] text-[24px] "
                    onClick={handleJoinEvent}
                    icon={faCheck}
                    color="bg-yellow-c1"
                    padding="py-5 px-1"
                    iconSize="text-[20px]"
                    disabled={!active}
                    />         
                </div> */}
          </div>
        </div>
      )}
    </>
  );
};

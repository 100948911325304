import HowToPlay from "./HowToPlay";
//import RecentEvents from "../home/RecentEvents";
import FAQ from "./FAQ";
import MainAbout from "./MainAbout";
import OurMembers from "./OurMembers";

export default function About() {
	return (
		<>
			<MainAbout />
			<HowToPlay />
			<OurMembers />
			{/*<RecentEvents />*/}
			<FAQ />
		</>
	)
}
import api from '../../utils/api'
import { loading, btnLoading, modules } from '../reducers/_module.reducers.js'

export const getModules = (role_id) => async (dispatch) => {
	dispatch(loading(true));
	try {
		const response = await api.get(`/modules/${role_id}`);
		// console.log(response);
		dispatch(modules(response.data));
	} catch (err) {
		console.error("Error getting modules: ", err);
	} finally {
		dispatch(loading(false));
	}
}

export const updatePermission = (module_id, role_id, status) => async (dispatch) => {
	const data = {module_id, role_id, status};
	try {
		await api.post(`/permissions`, data);
	} catch (err) {
		console.error("Error getting modules: ", err);
	}
}
import React from 'react'
import { UserPopUpCard } from '../../../../components/userPopUpCard'
import './tableEventUsers.css'


export const TableEventUsers = ({users, winners, className}) => {
  return (
    <div className={className}>
        <table className='w-full table table-rounded'>
            <thead className='bg-[#001D3D] text-[#FFFFFF] segoe'>
                <tr>
                    <th>Position</th>
                    <th>Name</th>
                    <th>Username</th>
                </tr>
            </thead>
            <tbody>
                {
                    (users.length < 1) && !winners.first && !winners.second && !winners.third && <tr>
                        <td colSpan={3}> Usuarios no encontrados </td>
                    </tr>
                }
                {
                    winners.first && <>
                        <tr>
                            <td className='font-["AldotheApache"] text-[20px]' >1</td>
                            <td>{winners.first.user.name}</td>
                            <td><UserPopUpCard user={winners.first.user} /></td>
                        </tr>
                    </>
                }
                {
                    winners.second && <>
                        <tr>
                            <td className='font-["AldotheApache"] text-[20px]' >2</td>
                            <td>{winners.second.user.name}</td>
                            <td><UserPopUpCard user={winners.second.user} /></td>
                        </tr>
                    </>
                }
                {
                    winners.third && <>
                        <tr>
                            <td className='font-["AldotheApache"] text-[20px]' >3</td>
                            <td>{winners.third.user.name}</td>
                            <td><UserPopUpCard user={winners.third.user} /></td>
                        </tr>
                    </>
                }
                {
                    users.map((user, key) => {
                        return <>
                            <tr key={key}>
                                <td className='font-["AldotheApache"] text-[20px]' >{key+4}</td>
                                <td>{user.user.name}</td>
                                <td><UserPopUpCard user={user.user} /></td>
                            </tr>
                        </>
                    })
                }
            </tbody>
        </table>
    </div>
  )
}

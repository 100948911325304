import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading";
import { getInvoices } from "../../../store/actions/_profile.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { PaymentMethods } from "../../../components/Payment/PaymentMethods";
import "./styles.scss";
import { getPaymentMethods } from "../../../store/actions/_subscriptions.actions";
import { CreateCustomPaymentMethod } from "../../../components/CreateCustomPaymentMethod/CreateCustomPaymentMethod";
import { faPlus, faRemove } from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../../../components/CustomModal";
import { t } from "i18next";

export default function ProfilePayments() {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const r_payments = useSelector((state) => state.subscription);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(null);

  useEffect(() => {
    dispatch(getInvoices());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPaymentMethods());
  }, []);

  useEffect(() => {
    // dispatch(getPaymentMethods());
    // setOpenModal(false);
  }, [paymentData]);

  return (
    <div id="paymentPage">
      <h1 className="font-bold p-2 text-[24px] tracking-wider mb-1 block segoe">
        Payments
      </h1>

      <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
        <div className="w-full mx-auto overflow-x-auto rounded-[10px] border border-gray-300 segoe px-4">
          <h3 className="font-bold p-2 italic text-[16px] tracking-wider my-5 block segoe">
            Payment Methods
          </h3>

          {r_payments.loading && <Loading />}
          {!r_payments.loading && (
            <>
              <PaymentMethods
                paymentMethodSelected={paymentMethodSelected}
                setPaymentMethodSelected={setPaymentMethodSelected}
              />

              {r_payments.methods.payment_method == false && (
                <>
                  <h4 className="font-[700] segoe mb-2 text-[18px]">
                    {t("payments.no_methods.title")}
                  </h4>
                  <p className="segoe text-14px">
                    {t("payments.no_methods.subtitle")}
                  </p>
                </>
              )}

              {/* BOTON PARA AGREGAR NUEVO METODO DE */}
              {/* <div className="col-span-4 flex justify-start my-4">
                  <button 
                    onClick={()=>{setOpenModal(true)}}
                    className="
                    w-[200px]
                    ml-2
                    transform-skew-20
                    bg-[#FFC300]
                    font-bold
                    uppercase
                    aldo
                    p-2
                    text-[#001D3D]
                    text-[14px]
                  ">
                  <FontAwesomeIcon icon={faPlus} /> Add new method
                  </button>
                </div> */}
            </>
          )}
        </div>
        <div className="w-full mx-auto overflow-x-auto rounded-[10px] border border-gray-300 segoe px-4">
          <h3 className="font-bold p-2 italic text-[16px] tracking-wider my-5 block segoe">
            Billing History
          </h3>

          {r_payments.loading && <Loading />}
          {!r_payments.loading && (
            <>
              <table className="table">
                <thead>
                  <tr className="bg-white border-b border-gray-300">
                    <th>Date</th>
                    <th>Bill Number</th>
                    <th>Status</th>
                    <th>Description</th>
                    <th></th>
                    {/*<th>Period start</th>
                      <th>Period end</th>*/}
                  </tr>
                </thead>
                <tbody>
                  {r_payments.invoices && (
                    <>
                      {r_payments.invoices.map((item, key) => (
                        <tr key={key} className="py-2">
                          <td className="segoe text-[14px]">
                            <DateFormat date={item.created} />
                          </td>
                          <td className="segoe text-[14px]">{item.number}</td>
                          <td className="segoe text-[14px]">{item.status}</td>
                          <td className="segoe text-[14px]">
                            {item.lines.data[0].description}
                          </td>
                          <td className="segoe text-[14px]">
                            <a
                              href={item.invoice_pdf}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FontAwesomeIcon
                                icon={faDownload}
                                className="text-[#003566]"
                              />
                            </a>
                          </td>
                          {/*<td><DateFormat date={item.period_start} /></td>
                              <td><DateFormat date={item.period_end} /></td>*/}
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>

      <CustomModal
        title=""
        size="w-full max-w-[600px]"
        status={openModal}
        closedButton={() => {
          setOpenModal(false);
        }}
      >
        <FontAwesomeIcon
          icon={faRemove}
          onClick={() => {
            setOpenModal(false);
          }}
          className="
            absolute
            right-[30px]
            top-[20px]
            text-[25px]
            cursor-pointer
            "
        />

        <CreateCustomPaymentMethod setPaymentData={setPaymentData} />
      </CustomModal>
    </div>
  );
}

const DateFormat = ({ date }) => {
  let unix_time = new Date(date * 1000).toLocaleDateString("en-US");
  return unix_time;
};

import { Routes, Route } from "react-router-dom";
import List from "./roles.list";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRoles } from "../../store/actions/_role.actions";
import { roles } from "../../store/reducers/_role.reducers";
import Create from "./roles.create";
import Update from "./roles.update";
import Delete from "./roles.delete";
import Permissions from "./roles.permissions";
import { modal } from "../../store/reducers/_role.reducers";
import Loading from "../../components/Loading";

export default function Roles() {
  const dispatch = useDispatch();
  const r_role = useSelector((state) => state.role);

  useEffect(() => {
    dispatch(getRoles());
    return () => {
      dispatch(roles({}));
    };
  }, [dispatch]);

  return (
    <>
      {r_role.loading ? (
        <Loading />
      ) : (
        <>
          {r_role.roles.length > 0 ? (
            <>
              <Routes>
                {["", "/"].map((path, key) => (
                  <Route path={path} key={key} element={<List />} />
                ))}
              </Routes>
              <Create
                status={r_role.modal.create}
                close={() => dispatch(modal({ create: false }))}
              />
              <Update
                status={r_role.modal.update}
                close={() => dispatch(modal({ update: false }))}
              />
              <Delete
                status={r_role.modal.delete}
                close={() => dispatch(modal({ delete: false }))}
              />
              <Permissions
                status={r_role.modal.permissions}
                close={() => dispatch(modal({ permissions: false }))}
              />
            </>
          ) : (
            <div className="w-10/12 mx-auto bg-blue-50 text-blue-400 border border-blue-300 p-3 mt-10">
              No records
            </div>
          )}
        </>
      )}
    </>
  );
}

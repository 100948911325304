import { Routes, Route } from "react-router-dom";
import List from "./users.list";
import Create from "./users.create";
import CreateUser from "./users.create.user";
import Profile from "./profile/users.profile";

export default function Users() {
   return (
      <Routes>
         <Route path="" element={<List />} />
         <Route path="create" element={<Create />} />
			<Route path="create-user" element={<CreateUser />} />
         <Route path="profile/*" element={<Profile />} />
      </Routes>
   );
}

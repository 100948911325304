import BulletLink from "../../../../components/BulletLink";
import { t } from "i18next";

export function MainBanner() {
  return (
    <section className="w-full h-[350px] pb-[200px] bg-cover bg-center bg-no-repeat bg-main-banner z-0 relative">
      <div className="flex justify-center text-center mx-auto pt-5">
        <h2 className="aldo text-4xl md:text-5xl uppercase text-yellow-c1 mt-2">
          {t("home.main_banner_1")} {t("home.main_banner_2")} {t("home.main_banner_3")}
        </h2>
      </div>
      
      <div className="flex flex-col-reverse h-full top-[90px] relative">
        <div className="justify-center items-center w-2/3 mx-auto md:w-1/3">
          <BulletLink
            to="explore-events"
            text={t("home.view_events")}
            className="mt-5 w-10/12 text-sm md:w-1/2 md:text-xl z-0 aldo"
          />
        </div>
      </div>
    </section>
  );
}

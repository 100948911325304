import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

export default function ProfileSearch() {
	return (
		<div className="border-b border-black mt-10 w-10/12 mx-auto">
			<FontAwesomeIcon icon={faSearch} className="mr-2" />
			<input type="text" placeholder="SEARCH EVENTS" className="focus:outline-none border-b" />
		</div>
	)
}
import { BasicEventCard } from "../../../../../components/basicEventCard";
import { UserFollowList, UserList } from "./components";

export const UsersFound = ({data}) => {

  

  return (
    <div id="search-users" className='grid grid-cols-12 gap-4'>

        {
          data.principalResult && (
            <>
              <div className='col-span-12 bg-white shadow-lg p-3'>
                <UserFollowList users={[data.principalResult]} />
                {
                  data.principalResult.events_created_by_user_active.length > 0 && (
                    <div className="mx-[50px] my-8">
                      <h2 className=" segoe text-[18px] m-2 text-[#061322]">
                        Live events from {data.principalResult.username}
                      </h2>
                      <div className='grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 gap-4 m-auto'>
                        {
                          data.principalResult.events_created_by_user_active.map((event, key) => {
                            return (
                              <BasicEventCard
                              className=""
                              data={event} 
                              key={key} 
                              categoryEvent='all'
                              />
                            )
                          })
                        }
                      </div>
                    </div>                
                  )
                }
              </div>
            </>
          )
        }
      

      {
        data.moreResults.length > 0 && (
          <div className='col-span-12 bg-white shadow-lg p-3'>
            <h2 className="uppercase font-bold font-['AldotheApache'] text-[24px] m-2 text-[#001D3D]">
              More Results
            </h2>
            <UserFollowList users={data.moreResults} />
          </div>
        )
      }
    
    </div>
  )
}

import api from "../../utils/api";
import {
  loading,
  balance,
  accounts,
  accountsLoaded,
  accountsLoading,
  accountsModal,
  btnLoading,
  errors,
  created
} from "../reducers/_withdraws.reducers";

export const getBalance = () => async (dispatch) => {
  dispatch(loading(true));
  try {
    const response = await api.get("/get-balance");
    dispatch(balance(response.data[0]));
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(loading());
  }
};

export const getAccounts = () => async (dispatch) => {
  dispatch(accountsLoading(true));
  try {
    const response = await api.get("/user-withdrawal");
    dispatch(accounts(response.data));
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(accountsLoading(false));
  }
};

export const addAccount = (data) => async (dispatch) => {
  dispatch(btnLoading(true));
  try {
    await api.post("/user-withdrawal", data);
    dispatch(accountsLoaded(false));
    dispatch(accountsModal(false));
	dispatch(created(true))
  } catch (err) {
    // console.log(err);
    dispatch(
      errors({
        status: true,
        messages: err.response.data.errors,
        message: err.response.data.message,
      })
    );
  } finally {
    dispatch(btnLoading(false));
  }
};

export const removeAccount = (id) => async (dispatch) => {
  dispatch(accountsLoading(true));
  try {
    await api.delete(`/user-withdrawal/${id}`);
    dispatch(accountsLoaded(false));
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(accountsLoading());
  }
};

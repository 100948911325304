import { createSlice } from "@reduxjs/toolkit";

const subscriptionSlice = createSlice({
   name: "subscription",
   initialState: {
      subscriptions: [],
      loading: false,
      payment_method: "waiting",
      subscription: {},
      intent: {},
      modal_update_subscription: false,
      methods: [],
		error_update_subscription: {
			status: false,
			message: ""
		},
		invoices: []
   },
   reducers: {
      subscriptions: (state, action) => {
         state.subscriptions = action.payload;
         state.loading = false;
      },
      loading: (state, action) => {
         state.loading = action.payload;
      },
      createPayment: (state, action) => {
         state.payment_method = action.payload;
      },
      subscription: (state, action) => {
         state.subscription = action.payload;
      },
      intent: (state, action) => {
         state.intent = action.payload;
      },
      updateSubscription: (state, action) => {
         state.modal_update_subscription = action.payload;
      },
      methods: (state, action) => {
         state.methods = action.payload;
      },
      errorUpdateSubscription: (state, action) => {
			state.error_update_subscription.status = action.payload.status;
			state.error_update_subscription.message = action.payload.message;
		},
		invoices: (state, action) => {
			state.invoices = action.payload
		}
   },
});

export const {
   subscriptions,
   loading,
   createPayment,
   subscription,
   intent,
   updateSubscription,
   methods,
   errorUpdateSubscription,
	invoices
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HeaderSection from "../../../../components/HeaderSection";
import BulletLink from "../../../../components/BulletLink";
import { t } from "i18next";

export default function AvailableGames() {
  const [data, setData] = useState(undefined);
  const r_main = useSelector((state) => state.main.available_games);

  useEffect(() => {
    setData(r_main[0]);
  }, [r_main]);

  return (
    data && (
      <section className="w-full h-auto p-5 lg:p-10 bg-no-repeat bg-cover bg-[url('../img/backgrounds/dark-background.png')] flex flex-col justify-center items-center">
        <HeaderSection
          titleStyle="white"
          sub={t("howtoplay.participate_through.sub")}
          title1={t("howtoplay.participate_through.title_1")}
          title2={t("howtoplay.participate_through.title_2")}
        />
        <div className="text-yellow-c1 text-left w-full lg:w-10/12 mx-auto my-6 lg:my-14 text-base lg:text-lg segoe">
          {t("howtoplay.participate_through.description.title")}
        </div>
        <div className="text-white text-left w-full lg:w-10/12 text-base lg:text-lg mb-10 segoe">
          {t("howtoplay.participate_through.description.paragraph_1")}
        </div>
        {data && (
          <div className="lg:grid lg:grid-cols-4 w-full lg:w-11/12 mx-auto animate__animated animate__fadeIn">
            <div className="col-span-3 bg-white p-4 flex flex-col lg:flex-row relative">
              <div className="grid grid-cols-1 lg:grid-cols-4 w-full">
                <img
                  className="w-full object-cover object-center"
                  src={data.screenshot}
                  alt=""
                />
                <div className="segoe col-span-2">
                  <div className="text-black font-bold uppercase px-10 pt-5 mb-2">
                    {t(data.type)}
                  </div>
                  <div className="relative bg-yellow-c1 w-1/2 h-12">
                    <div className="w-[200px] ml-2 absolute top-0 left-0 transform-skew-20 bg-yellow-c1 font-bold uppercase aldo p-3 flex">
                      {t(data.title)}
                    </div>
                  </div>
                  <ul className="ml-5 my-5 text-xs lg:text-base">
                    {/* 
                    <li className="text-gray-400 font-bold mb-2 capitalize">
                      <span className="text-black font-bold mr-2">
                        {t("howtoplay.participate_through.category")}
                      </span>
                      {t(data.category)}
                    </li>
                     */}
                    <li className="text-gray-400 font-bold mb-2">
                      <span className="text-black font-bold mr-2">
                        {t("howtoplay.participate_through.platform")}:
                      </span>
                      {t(data.platform)}
                    </li>
                    <li className="text-gray-400 font-bold mb-2 capitalize">
                      <span className="text-black font-bold mr-2">
                        {t("howtoplay.participate_through.channel")}:
                      </span>
                      {t(data.channel)}
                    </li>
                  </ul>
                  <p className="text-justify m-2 text-xs lg:text-base">
                    {t(data.brief_description)}
                  </p>
                  <BulletLink
                    className="mt-5 text-xs lg:text-base lg:mt-10 lg:w-1/2 aldo"
                    text={t("howtoplay.participate_through.event_btn")}
                    to={`/game-events/${data.type_of_event}`}
                  />
                </div>
                <img src={data.icon} alt="" className="absolute top-[10px] right-[10px] sepia" />
              </div>
            </div>
            <div className="flex flex-row lg:flex-col justify-between p-2 animate__animated animate__fadeIn border">
              {r_main.map((item, key) => (
                <div className="lg:h-[33%] relative" key={key} onClick={() => setData(item)}>
                  <img
                    className="w-full lg:w-full object-cover border z-0 border-yellow-600 h-full my-1 cursor-pointer hover:scale-105 active:scale-95"
                    src={item.banner}
                    alt=""
                  />
                  <div className="bg-yellow-600 rounded-lg absolute z-10 top-[10px] right-[10px] text-white py-1 px-2 capitalize">{item.type_of_event}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </section>
    )
  );
}

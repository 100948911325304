import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesRight,
  faTrophy,
  faEdit,
  faTrash,
  faPaperPlane,
  faEye,
  faCircleCheck,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import {
  changeStatusEvent,
  publishEvent,
} from "../../../store/actions/_events.actions";
import { useEffect } from "react";
import { useState } from "react";
import Timezone from "../../../hooks/timezone";

export default function EventList({ data, type }) {
  return data && data.length > 0 ? (
    <div className="w-full">
      {data.map((item, key) => (
        <div
          key={key}
          className="w-full border-b flex px-3 py-5 hover:bg-gray-100"
        >
          <div className="flex flex-col w-3/12">
            <span className="text-sm text-gray-600">
              {moment(item.start_date).format("MMM Do")} -{" "}
              {moment(item.end_date).format("MMM Do")}
            </span>
            <span>{item.title}</span>
          </div>
          <div className="flex flex-col w-3/12">
            <span className="text-sm text-gray-600">Category</span>
            <span>{item.event_type.name}</span>
          </div>
          <div className="flex flex-col w-3/12">
            <span className="text-sm text-gray-600">User creator</span>
            <span>{item.user_owner?.username}</span>
          </div>
          <div className="flex flex-col w-2/12">
            <span className="text-sm text-gray-600">Users</span>
            <span>{item.user_events_count}</span>
          </div>
          <div className="flex flex-col w-2/12">
            <span className="text-sm text-gray-600">Reward</span>
            <span>{item.first_place_reward || "FREE"}</span>
          </div>
          <div className="w-2/12 flex items-center">
            <Options type={type} uuid={item.event_uuid} item={item} />
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className="w-3/4 mx-auto bg-blue-100 my-5 p-5 border border-blue-300">
      No records
    </div>
  );
}

const Options = ({ type, uuid, item }) => {
  const [initialTime, setInitialTime] = useState(null);

  useEffect(() => {
    if (item) {
      const initTime = Timezone({
        date: item.full_start_time,
        formatStr: "YYYY-MM-DD HH:mm",
      });

      setInitialTime(new Date(initTime));
    }
  }, [item]);

  const dispatch = useDispatch();

  const handlePublishEvent = (uuid) => {
    dispatch(publishEvent(uuid));
  };

  const changeStatusToApprove = (uuid, type) => {
    dispatch(changeStatusEvent(uuid, type));
  };

  if (type === "approve") {
    return (
      <>
        {!item.closed_event ? (
          <>
            <div className="group flex relative">
              <Link to={`/settings/users/profile/my-events/judge/${uuid}`}>
                <FontAwesomeIcon
                  icon={faTrophy}
                  className="bg-blue-900 text-yellow-500 p-2 rounded-lg active:scale-95"
                />
              </Link>
              <span
                className="group-hover:opacity-100 z-10 transition-opacity bg-gray-800 px-2 py-1 text-center text-sm text-gray-100 rounded-md absolute left-1/2 
            -translate-x-1/2 translate-y-full opacity-0 mt-[-12px] mx-auto"
              >
                Define Winners
              </span>
            </div>
            <div className="group flex relative">
              <span
                className="ml-1 cursor-pointer"
                onClick={() => {
                  changeStatusToApprove(uuid, "approve");
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="bg-[#85F1AA] text-white p-2 rounded-lg active:scale-95"
                />
              </span>
              <span
                className="group-hover:opacity-100 z-10 transition-opacity text-center bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
            -translate-x-1/2 translate-y-full opacity-0 mt-[-12px] mx-auto"
              >
                Confirm winners
              </span>
            </div>

            <div className="group flex relative">
              <span
                className="ml-1 cursor-pointer"
                onClick={() => {
                  changeStatusToApprove(uuid, "reject");
                }}
              >
                <FontAwesomeIcon
                  icon={faXmarkCircle}
                  className="bg-red-500 text-white p-2 rounded-lg active:scale-95"
                />
              </span>
              <span
                className="group-hover:opacity-100 z-10 transition-opacity text-center bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
            -translate-x-1/2 translate-y-full opacity-0 mt-[-12px] mx-auto"
              >
                Rejected winners
              </span>
            </div>

            <div className="group flex relative">
              <Link
                to={`/event-detail/${uuid}`}
                className="relative mx-1 top-[-2px] flex items-center justify-center bg-[#FFC300]  text-white rounded-lg p-1 w-8 h-8"
              >
                <FontAwesomeIcon icon={faAnglesRight} className="text-xl" />
              </Link>
              <span
                className="group-hover:opacity-100 z-10 transition-opacity text-center bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
            -translate-x-1/2 translate-y-full opacity-0 mt-[5px] mx-auto"
              >
                Preview
              </span>
            </div>

            <FreeEventBadge item={item} />
          </>
        ) : (
          <>
            <span className="cursor-pointer opacity-60">
              <FontAwesomeIcon
                icon={faTrophy}
                className="bg-blue-900 text-yellow-500 p-2 rounded-lg active:scale-95"
              />
            </span>
            <Link
              to={`/event-detail/${uuid}`}
              className="relative top-[-2px] flex mx-1 items-center justify-center bg-[#FFC300]  text-white rounded-lg p-1 w-8 h-8"
            >
              <FontAwesomeIcon icon={faAnglesRight} className="text-xl" />
            </Link>
            <FreeEventBadge item={item} />
          </>
        )}
      </>
    );
  }

  if (type === "live") {
    return (
      <>
        {
          <div className="inline-grid grid-cols-3 gap-1">
            {!item.closed_event ? (
              <>
                {initialTime > new Date() ? (
                  <>
                    <div className="group flex relative">
                      <Link
                        to={`/settings/users/profile/my-events/update/${uuid}`}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="bg-yellow-400 p-2 rounded-lg active:scale-95"
                        />
                      </Link>
                      <span
                        className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
                    -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto"
                      >
                        Edit
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="group flex relative">
                      <span>
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="bg-gray-400 p-2 rounded-lg active:scale-95 text-white"
                        />
                      </span>
                      <span
                        className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
                    -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto"
                      >
                        Edit
                      </span>
                    </div>
                  </>
                )}

                <div className="group flex relative">
                  <Link to={`/settings/users/profile/my-events/judge/${uuid}`}>
                    <FontAwesomeIcon
                      icon={faTrophy}
                      className="bg-blue-900 text-yellow-500 p-2 rounded-lg active:scale-95"
                    />
                  </Link>
                  <span
                    className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
                  -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto"
                  >
                    Winners
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="group flex relative">
                  <span className="cursor-pointer opacity-60">
                    <FontAwesomeIcon
                      icon={faTrophy}
                      className="bg-blue-900 text-yellow-500 p-2 rounded-lg active:scale-95"
                    />
                  </span>
                  <span
                    className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
                -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto"
                  >
                    Winners
                  </span>
                </div>
              </>
            )}

            <div className="group flex relative">
              <Link
                to={`/event-detail/${uuid}`}
                className="relative mx-1 flex items-center justify-center bg-[#FFC300]  text-white rounded-lg p-1 w-8 h-8"
              >
                <FontAwesomeIcon icon={faAnglesRight} className="text-xl" />
              </Link>
              <span
                className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
            -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto"
              >
                Preview
              </span>
            </div>

            <FreeEventBadge item={item} />
          </div>
        }
      </>
    );
  }
  if (type === "draft") {
    return (
      <div className="inline-grid grid-cols-3 gap-1">
        <div className="group flex relative">
          <Link to={`/settings/users/profile/my-events/update/${uuid}`}>
            <FontAwesomeIcon
              icon={faEdit}
              className="bg-yellow-400 p-2 rounded-lg active:scale-95"
            />
          </Link>
          <span
            className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
          -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto"
          >
            Edit
          </span>
        </div>

        <div className="group flex relative">
          <Link to={`/settings/users/profile/my-events/delete/${uuid}`}>
            <FontAwesomeIcon
              icon={faTrash}
              className="bg-yellow-400 p-2 rounded-lg active:scale-95"
            />
          </Link>
          <span
            className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
          -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto"
          >
            Delete
          </span>
        </div>

        <div className="group flex relative">
          <Link to={`/event-detail/${uuid}`}>
            <FontAwesomeIcon
              icon={faEye}
              className="bg-yellow-400 p-2 rounded-lg active:scale-95"
            />
          </Link>
          <span
            className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
          -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto"
          >
            Preview
          </span>
        </div>

        <div className="group flex relative">
          <button
            onClick={() => {
              handlePublishEvent(uuid);
            }}
          >
            <FontAwesomeIcon
              icon={faPaperPlane}
              className="bg-blue-900 text-yellow-500 p-2 rounded-lg active:scale-95"
            />
          </button>
          <span
            className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
          -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto"
          >
            Publish
          </span>
        </div>

        <FreeEventBadge item={item} />
      </div>
    );
  }
  if (type === "past") {
    return (
      <div className="inline-grid grid-cols-3 gap-1">
        {!item.closed_event ? (
          <>
            <div className="group flex relative">
              <Link to={`/settings/users/profile/my-events/judge/${uuid}`}>
                <FontAwesomeIcon
                  icon={faTrophy}
                  className="bg-blue-900 text-yellow-500 p-2 rounded-lg active:scale-95"
                />
              </Link>
              <span
                className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
              -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto"
              >
                Winners
              </span>
            </div>

            <div className="group flex relative">
              <Link
                to={`/event-detail/${uuid}`}
                className="relative mx-1 flex items-center justify-center bg-[#FFC300]  text-white rounded-lg p-1 w-8 h-8"
              >
                <FontAwesomeIcon icon={faAnglesRight} className="text-xl" />
              </Link>
              <span
                className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
              -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto"
              >
                Preview
              </span>
            </div>
            <FreeEventBadge item={item} />
          </>
        ) : (
          <>
            <div className="group flex relative">
              <span className="cursor-pointer opacity-60">
                <FontAwesomeIcon
                  icon={faTrophy}
                  className="bg-blue-900 text-yellow-500 p-2 rounded-lg active:scale-95"
                />
              </span>
              <span
                className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
              -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto"
              >
                Winners
              </span>
            </div>
            <div className="group flex relative">
              <Link
                to={`/event-detail/${uuid}`}
                className="relative mx-1 flex items-center justify-center bg-[#FFC300]  text-white rounded-lg p-1 w-8 h-8"
              >
                <FontAwesomeIcon icon={faAnglesRight} className="text-xl" />
              </Link>
              <span
                className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
              -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto"
              >
                Preview
              </span>
            </div>
            <FreeEventBadge item={item} />
          </>
        )}
        {moment(item.end_date).format("YYYY-MM-DD") >
          moment().format("YYYY-MM-DD") && (
          <>
            <div className="group flex relative">
              <Link to={`/event-detail/${uuid}`}>
                <FontAwesomeIcon icon={faAnglesRight} className="text-xl" />
              </Link>
              <span
                className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
            -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto"
              >
                Preview
              </span>
            </div>
          </>
        )}
      </div>
    );
  }
  return (
    <>
      <div className="group flex relative">
        <Link to={`/event-detail/${uuid}`}>
          <FontAwesomeIcon icon={faAnglesRight} className="text-xl" />
        </Link>
        <span
          className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
      -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto"
        >
          Preview
        </span>
      </div>
    </>
  );
};

const FreeEventBadge = ({ item, className = "" }) => {
  return item.is_free_event ? (
    <>
      <div className={className}>
        <span
          className={
            "flex items-center bg-[#7ED360] rounded-lg p-1 w-8 h-8 custom-badge"
          }
        >
          <span className="text-xs -rotate-45 font-bold text-black">Free</span>
        </span>
      </div>
    </>
  ) : (
    <></>
  );
};

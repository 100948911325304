import { createSlice } from "@reduxjs/toolkit";

export const newsSlice = createSlice({
  name: "news",
  initialState: {
    error: {
      status: false,
      message: "",
      messages: [],
    },
    loading: false,
    news: [],
    btnLoading: false,
    redirect: false,
    news_loaded: false,
    news_home: [],
    news_home_loaded: false,
    new: {},
	 news_popular: []
  },
  reducers: {
    news: (state, action) => {
      state.news = action.payload;
      state.news_loaded = true;
    },
    loadingOn: (state) => {
      state.loading = true;
    },
    loadingOff: (state) => {
      state.loading = false;
    },
    errorOn: (state, action) => {
      state.error.status = true;
      state.error.message = action.payload.message || "";
      state.error.messages = action.payload.errors.errors || [];
    },
    errorOff: (state) => {
      state.error.status = false;
      state.error.message = "";
      state.error.messages = [];
    },
    btnLoadingOn: (state) => {
      state.btnLoading = true;
    },
    btnLoadingOff: (state) => {
      state.btnLoading = false;
    },
    backToList: (state, action) => {
      state.redirect = action.payload;
    },
    refreshNews: (state) => {
      state.news_loaded = false;
    },
    newsHome: (state, action) => {
      state.news_home = action.payload;
      state.news_home_loaded = true;
    },
    showNew: (state, action) => {
      state.new = action.payload;
    },
    newToZero: (state) => {
      state.new = {};
    },
	 newsPopular: (state, action) => {
		state.news_popular = action.payload
	 }
  },
});

export const {
  news,
  loadingOn,
  loadingOff,
  errorOn,
  errorOff,
  btnLoadingOn,
  btnLoadingOff,
  backToList,
  refreshNews,
  newsHome,
  showNew,
  newToZero,
  newsPopular
} = newsSlice.actions;
export default newsSlice.reducer;

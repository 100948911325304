import { BrowserRouter, Routes, Route } from "react-router-dom";
import Auth from "./app/auth/Auth";
import Home from "./app/pages/home/Home";
import Dashboard from "./app/pages/Dashboard";
import RequireAuth from "./components/RequireAuth";
import Authenticated from "./components/Authenticated";
import Container from "./components/Container";
import About from "./app/pages/about/About";
import Contact from "./app/pages/contact/Contact";
import GameEvents from "./app/pages/GameEvents";
import Settings from "./app/pages/Settings";
import Kickstarter from "./app/pages/Kickstarter";
import Events from "./app/pages/events/Events";
import MyEvents from "./app/pages/events/MyEvents";
import ReadNews from "./app/pages/news/ReadNews";
import Terms from "./app/pages/terms/Terms";
import HowToPlay from "./app/pages/howtoplay/HowToPlay";
import PageProfile from "./app/pages/profile/profile.index";
import ScrollToTop from "./hooks/ScrollToTop";
import { SearchPage } from "./app/pages/search";
import { PastEventsPage } from "./app/pages/pastEvents";
import { Page404 } from "./app/pages/page404";
import { HowToPlayInfo } from "./app/pages/howToPlayInfo/HowToPlayInfo";
import ProfileFollowers from "./app/pages/profile/ProfileFollowers";
import api from "./utils/api";
import { useEffect, useState } from "react";
import moment from "moment";

function App() {
  return (
    <BrowserRouter>
      {/* <Container> */}
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<Container />}>
          <Route path="*" element={<Page404 />} />
        </Route>
        <Route path="/" element={<Container />}>
          <Route path="" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="search" element={<SearchPage />} />
          <Route path="past-events" element={<PastEventsPage />} />
          <Route path="news/:new_id" element={<ReadNews />} />
          <Route path="contact" element={<Contact />} />
          <Route path="game-events/">
            <Route path="" element={<GameEvents />} />
            <Route path=":type_of_event" element={<GameEvents />} />
          </Route>
          <Route path="explore-events/">
            <Route path="" element={<GameEvents />} />
            <Route path=":filter_category" element={<GameEvents />} />
            <Route path=":filter_category/">
              <Route path="" element={<GameEvents />} />
              <Route path=":order_event/">
                <Route path="" element={<GameEvents />} />
                <Route path=":event_type" element={<GameEvents />} />
              </Route>
            </Route>
          </Route>
          
          <Route path="followers/:user/*" element={<ProfileFollowers />} />
          <Route path="kickstarter" element={<Kickstarter />} />
          <Route path="terms-conditions" element={<Terms />} />
          <Route path="event-detail/:event_id/*" element={<Events />} />
          <Route path="howtoplay" element={<HowToPlay />} />
          <Route path="howtoplay-info" element={<HowToPlayInfo />} />
          <Route path="profile/:user/*" element={<PageProfile />} />
          <Route
            path="dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="settings/*"
            element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            }
          />
          <Route
            path="events/my-events"
            element={
              <RequireAuth>
                <MyEvents />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="auth">
          <Route
            path="*"
            element={
              <Authenticated>
                <Auth />
              </Authenticated>
            }
          />
        </Route>
        <Route path="test" element={<Test />}></Route>
      </Routes>
      {/* </Container> */}
    </BrowserRouter>
  );
}

function Test() {
  const [serverDate, setServerDate] = useState(undefined);
  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get("/test");
      setServerDate(response.data);
    };
    fetchData().catch(console.error);
  }, []);
  return (
    <div className="flex flex-col">
      <span>Server: {serverDate}</span>
      <span>Local: {moment().format()}</span>
    </div>
  );
}

export default App;

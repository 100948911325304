import HeaderSection from "../../../components/HeaderSection";
// import GameLogos from "../home/GameLogos";
import ContactMap from "./Contact.map";
import ContactForm from "./Contact.form";
import ContactElements from "./Contact.elements";
import { t } from "i18next";

export default function Contact() {
  return (
    <>
      <section className="w-full min-h-[500px] bg-cover bg-no-repeat bg-[url('../img/banners/banner-single-challenge.png')] bg-center flex justify-center items-center">
        <div className="text-white text-3xl lg:text-6xl uppercase font-bold flex flex-col">
          <div className="aldo">
            {t("contact.title1")} <span className="text-yellow-c1">{t("contact.title2")}</span>
          </div>
        </div>
      </section>
      {/* 
      <section className="flex justify-center pt-5">
        <HeaderSection titleStyle="dart" sub="" title1="Contact" title2="Us" />
      </section>
       */}
      <section className="w-full min-h-[500px] p-2 lg:p-10 bg-cover bg-no-repeat bg-[url('../img/backgrounds/main-bg.png')] bg-center flex flex-col justify-center items-center">
        <div className="w-full flex flex-col md:flex-row">
          <ContactMap />
          <ContactForm />
        </div>
        <ContactElements />
      </section>
      {/* <GameLogos /> */}
    </>
  );
}

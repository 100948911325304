import { t } from "i18next";

export default function MainAbout() {
  return (
    <section className="w-full min-h-[500px] bg-cover bg-no-repeat bg-[url('../img/banners/banner-about.png')] bg-center flex justify-center items-center">
      <div className="text-white text-3xl lg:text-6xl uppercase font-bold flex flex-col">
        <div className="aldo">
          {t("about.main.title_1")} <span className="text-yellow-c1">{t("about.main.title_2")}</span>
        </div>
      </div>
    </section>
  );
}

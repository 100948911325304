import React, { useEffect, useState } from 'react'
import noUser from '../../../../assets/img/avatars/no_user.jpeg';
import { UserPopUpCard } from '../../../../components/userPopUpCard';

export const WinnerCard = ({className, mbottom, user}) => {

  const [state, setState] = useState(null);
  useEffect(() => {
    switch (user.status) {
      case 'first':
        setState("Winner");
        break;
      case 'second':
        setState("2ND PLACE");
        break;
      case 'third':
        setState("3RD PLACE");
        break;
    
      default:
        setState("");
        break;
    }
  }, [user])
  

  return (
    <>
      {
        user && <>
          <div className={'flex flex-col text-center m-auto px-[20px] py-[20px] rounded-[10px] '+className}>
            <div className={`
              min-h-[92px] 
              min-w-[92px] 
              h-[92px] 
              w-[92px]
              overflow-hidden
              rounded-[50%]
              m-auto
              text-center
              bg-cover
              bg-no-repeat
              bg-center`}
              style={{backgroundImage: `url(${user.user.avatar || noUser})`}}
              >
            </div>
            <h2 className='text-[#fff] font-bold segoe text-[19px] mt-1'>{user.user?.name}</h2>
            <UserPopUpCard user={user.user} className={'text-[#fff] segoe text-[16px] '+mbottom} />
            {/* <span className={'text-[#fff] segoe text-[16px] '+mbottom}>@{user.user?.username}</span> */}
            <p className='text-[#FFC300] text-[32px] font-["AldotheApache"] uppercase'>{state}</p>
          </div>
        </>
      }
    </>
  )
}

/**
 * lang comment
 * @Author: Publio Quintero
 * @Date: 2022-10-16 11:43:53
 * @Desc: pageProfile es la vista donde mostramos la información referente al perfil del usuario, y donde la hacemos pública para que otros usuarios la puedan visitar.
 * Su estructura de archivos inicia en src/app/pages/profile
 * La ruta de inicio está en App.js
 * Los componentes que conforman este archivo están en src/app/pages/profile/components
 * El action  al que hace referencia es src/store/actions/_pageProfile.actions.js
 * El reducer al que hace referencia es src/store/reducers/_pageProfile.reducers.js
 * El reducer es inicializado en src/store/store.js
 * El modelo al que está relacionado en el backend es PageProfile
 * El controlador al que está relacionado en el backend es PageProfileController
 */

import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileNavigation from "./components/navigation";
import ProfileSearch from "./components/search";
import ProfileBanner from "./components/banner";
import ProfileHeader from "./components/header";
import ProfileFollowButton from "./components/followButton";
import ProfileSocialMedia from "./components/socialMedia";
import {
  getDataAction,
  getFollowsAction,
  getEventsAction,
  getFollowStatusAction
} from "../../../store/actions/_pageProfile.actions";
import Loading from "../../../components/Loading";

export default function Profile() {
  const dispatch = useDispatch();
  let { user } = useParams();
  const r_pp = useSelector((state) => state.pageProfile);

  useEffect(() => {
    // if (!r_pp.loaded) {
      dispatch(getDataAction(user));
    // }
  }, []);

  useEffect(() => {
    if (r_pp.user.uuid) {
      dispatch(getFollowsAction(r_pp.user.uuid));
      dispatch(getEventsAction(r_pp.user.uuid));
      dispatch(getFollowStatusAction(r_pp.user.uuid))
    }
  }, [r_pp.user.uuid, dispatch]);

  return (
    <>
      {r_pp.loading && <Loading />}
      {!r_pp.loading && r_pp.user && (
        <div>
          <ProfileBanner />
          <div className="w-11/12 mx-auto flex">
            <div className="basis-9/12">
              <ProfileHeader />
              <ProfileNavigation />
            </div>
            <div className="basis-4/12 flex flex-col">
              <ProfileFollowButton />
              <ProfileSearch />
              <ProfileSocialMedia />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import api from "../../utils/api";
import {
   news,
   loadingOn,
   loadingOff,
   errorOn,
   errorOff,
   btnLoadingOn,
   btnLoadingOff,
   backToList,
   refreshNews,
   newsHome,
   showNew,
   newToZero,
   newsPopular,
} from "../reducers/_news.reducers";

export const getNews = (limit) => async (dispatch) => {
   dispatch(loadingOn());
   try {
      const response = await api.get("/news");
      dispatch(news(response.data));
   } catch (error) {
      dispatch(
         errorOn({
            message: error.responde.data.message,
         })
      );
   } finally {
      dispatch(loadingOff());
   }
};

export const getNewsPopular = (limit) => async (dispatch) => {
   dispatch(loadingOn());
   try {
      const response = await api.get(`/get-news/${limit || ""}`);
      dispatch(newsPopular(response.data));
   } catch (error) {
      dispatch(
         errorOn({
            message: error.responde.data.message,
         })
      );
   } finally {
      dispatch(loadingOff());
   }
};

export const getNewById = (new_id) => async (dispatch) => {
   dispatch(loadingOn());
   dispatch(newToZero());
   try {
      const response = await api.get(`/news/${new_id}`);
      dispatch(showNew(response.data));
   } catch (error) {
      console.log(error);
   } finally {
      dispatch(loadingOff());
   }
};

export const storeNews = (data) => async (dispatch) => {
   dispatch(errorOff());
   dispatch(btnLoadingOn());
   try {
      await api.post("/news", data);
      dispatch(refreshNews());
      dispatch(backToList(true));
   } catch (error) {
      dispatch(
         errorOn({
            errors: error.response.data,
         })
      );
   } finally {
      dispatch(btnLoadingOff());
   }
};

export const updateNews = (data, new_id) => async (dispatch) => {
   dispatch(errorOff());
   dispatch(btnLoadingOn());
   try {
      const response = await api.post(`/news/${new_id}/update/`, data);
      dispatch(news(response.data));
   } catch (error) {
      dispatch(
         errorOn({
            errors: error.responde.data,
         })
      );
   } finally {
      dispatch(btnLoadingOff());
   }
};

export const deleteNews = (new_id) => async (dispatch) => {
   dispatch(errorOff());
   dispatch(btnLoadingOn());
   try {
      const response = await api.delete("/");
      dispatch(news(response.data));
   } catch (error) {
      dispatch(
         errorOn({
            errors: error.responde.data,
         })
      );
   } finally {
      dispatch(btnLoadingOff());
   }
};

export const getNewsAction = (limit) => async (dispatch) => {
   dispatch(loadingOn());
   try {
      const response = await api.get(`/get-news/${limit || ""}`);
      dispatch(newsHome(response.data));
   } catch (error) {
      dispatch(
         errorOn({
            errors: error.responde.data,
         })
      );
   } finally {
      dispatch(loadingOff());
   }
};

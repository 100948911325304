import { createSlice } from "@reduxjs/toolkit";

const withdrawsSlice = createSlice({
  name: "withdraw",
  initialState: {
    loading: false,
    balance: {},
    accounts: {
      modal: false,
      loaded: false,
      accounts: [],
      loading: false,
      btnLoading: false,
      errors: {
        status: false,
        messages: [],
        message: "",
      },
	  created: false
    },
  },
  reducers: {
    loading: (state, action) => {
      state.loading = action.payload?.status ? action.payload.status : false;
    },
    balance: (state, action) => {
      state.balance = action.payload;
    },
    accounts: (state, action) => {
      state.accounts.accounts = action.payload ? action.payload : [];
      state.accounts.loaded = true;
    },
    accountsLoaded: (state, action) => {
      state.accounts.loaded = action.payload ? action.payload : false;
    },
    accountsLoading: (state, action) => {
      state.accounts.loading = action.payload ? action.payload : false;
    },
    accountsModal: (state, action) => {
      state.accounts.modal = action.payload ? action.payload : false;
    },
    btnLoading: (state, action) => {
      state.accounts.btnLoading = action.payload ? action.payload : false;
    },
    errors: (state, action) => {
      state.accounts.errors.status = action.payload
        ? action.payload.status
        : false;
      state.accounts.errors.messages = action.payload
        ? action.payload.messages
        : [];
      state.accounts.errors.message = action.payload
        ? action.payload.message
        : "";
    },
	created: (state, action) => {
		state.accounts.created = (action.payload) ? action.payload : false;
	}
  },
});

export const {
  loading,
  balance,
  accounts,
  accountsLoaded,
  accountsLoading,
  accountsModal,
  btnLoading,
  errors,
  created
} = withdrawsSlice.actions;
export default withdrawsSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
	name: 'auth',
	initialState: {
		logged: undefined,
		access_token: '',
		expires_in: '',
		refresh_token: '',
		registered: null,
		token_type: '',
		items_menu: [],
		menu_loaded: false,
		isEmailVerified: false,
		error_verify_email: {
			status: "",
			message: ""
		},
		errors: {
			status: false,
			messages: [],
			message: ''
		},
		button_loading: false,
		error_login: {
			status: false,
			message: ''
		},
		success: {
			status: false,
			message: "Check your email!"
		}
	},
	reducers: {
		setErrorVerifyEmail: (state, {payload}) => {
			state.error_verify_email.status = payload.status;
			state.error_verify_email.message = payload.message;
		},
		setIsEmailVerified: (state, {payload})=>{
			state.isEmailVerified = payload;
		},
		registered: (state, {payload}) => {
			state.registered = payload;
		},
		login: (state, payload) => {
			state.logged = true;
			state.access_token = payload.access_token;
			state.expires_in = payload.expires_in;
			state.refresh_token = payload.refresh_token;
			state.token_type = payload.token_type;
		},
		logout: (state) => {
			state.logged = false;
			state.access_token = '';
			state.expires_in = '';
			state.refresh_token = '';
			state.token_type = '';
		},
		items_menu: (state, action) => {
			state.items_menu = action.payload;
			state.menu_loaded = true
		},
		errors: (state, action) => {
			state.errors.status = true;
			state.errors.messages = action.payload.errors;
			state.errors.message = action.payload.message;

		},
		button_loading: (state, action) => {
			state.button_loading = action.payload
		},
		verify: (state, action) => {
			state.logged = action.payload
		},
		errorLogin: (state, action) => {
			state.error_login.status = action.payload.status;
			state.error_login.message = action.payload.message
		},
		success: (state, action) => {
			state.success.status = action.payload.status;
			state.success.message = action.payload.message;
		}
	}
})

export const { setErrorVerifyEmail, setIsEmailVerified, login, logout, items_menu,errors, button_loading, verify, errorLogin, success, registered } = authSlice.actions;
export default authSlice.reducer
import React from "react";
import { useState } from "react";

export const EventTab = ({ props, active = false }) => {
  const { tab, setCategoryEvent } = props;
  const [isTabOver, setIsTabOver] = useState(false);
  return (
    <div className={"flex flex-row "}>
      {active ? (
        <>
          <button
            onClick={() => {
              setCategoryEvent(tab.slug);
            }}
            className="
              flex
              flex-row
              bg-[#001D3D]

              
              border-2 
              border-yellow-400
              text-yellow-400 
              px-2 
              -skew-x-12 
              w-full 
              justify-between
              "
          >
            <div className="py-2">
              <h3 className="text-[10px] md:text-lg font-bold uppercase md:pl-2">
                {tab.name}
              </h3>
            </div>
            <div className="flex h-full">
              <div className="w-5 m-1 mr-1 md-mr-5 md:pt-2">
                <img src={tab.imgActive} alt={tab.imgActive} />
              </div>
              <div className="bg-blue-c2 w-1 md:w-2 h-full"></div>
            </div>
            {/* {
              tab.notify && (
                <div className="">
                  <span>{tab.textNotify}</span>
                </div>
              )
            } */}
          </button>
        </>
      ) : (
        <>
          <button
            onClick={() => {
              setCategoryEvent(tab.slug);
            }}
            onMouseOver={() => {
              setIsTabOver(true);
            }}
            onMouseOut={() => {
              setIsTabOver(false);
            }}
            className="
            flex
            flex-row
            bg-yellow-c1 
            hover:bg-[#001D3D] 
            active:bg-[#001D3D] 
            border-2
            border-[#ffc300]
            hover:border-2 
            hover:border-yellow-400 
            active:border-2 
            active:border-yellow-400 
            text-blue-c1 
            hover:text-yellow-400 
            active:text-yellow-400 
            px-2 
            -skew-x-12 
            w-full 
            justify-between
            "
          >
            <div className="py-2">
              <h3 className="text-[10px] md:text-lg font-bold uppercase md:pl-2">
                {tab.name}
              </h3>
            </div>
            <div className="flex h-full">
              <div className=" w-5 m-1 mr-1 md-mr-5 md:pt-2">
                {isTabOver ? (
                  <img src={tab.imgActive} alt={tab.imgActive} />
                ) : (
                  <img src={tab.img} alt={tab.img} />
                )}
              </div>
              <div className="bg-blue-c2 w-1 md:w-2 h-full"></div>
            </div>
            {/* {
            tab.notify && (
              <div className="">
                <span>{tab.textNotify}</span>
              </div>
            )
          } */}
          </button>
        </>
      )}
    </div>
  );
};

import React from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeaderSection from "../../../../components/HeaderSection";
import { t } from "i18next";

export default function FAQ() {
  return (
    <section className="w-full h-auto p-5 lg:p-10 bg-no-repeat bg-cover bg-[url('../img/backgrounds/dark-background.png')] flex flex-col justify-center items-center">
      <HeaderSection
        titleStyle="white"
        sub={t("about.faq.sub")}
        title1={t("about.faq.title1")}
        title2={t("about.faq.title2")}
      />
      <div className="w-full lg:w-9/12 mt-10">
        <Question
          questionWhite={t("about.faq.question1.white")}
          questionYellow={t("about.faq.question1.yellow")}
          answer={t("about.faq.question1.answer")}
        />
        <Question
          questionWhite={t("about.faq.question2.white")}
          questionYellow={t("about.faq.question2.yellow")}
          answer={t("about.faq.question2.answer")}
        />
        <Question
          questionWhite={t("about.faq.question3.white")}
          questionYellow={t("about.faq.question3.yellow")}
          answer={t("about.faq.question3.answer")}
        />
        <Question
          questionWhite={t("about.faq.question4.white")}
          questionYellow={t("about.faq.question4.yellow")}
          answer={t("about.faq.question4.answer")}
        />
        <Question
          questionWhite={t("about.faq.question5.white")}
          questionYellow={t("about.faq.question5.yellow")}
          answer={t("about.faq.question5.answer")}
        />
        <Question
          questionWhite={t("about.faq.question6.white")}
          questionYellow={t("about.faq.question6.yellow")}
          answer={t("about.faq.question6.answer")}
        />
        <Question
          questionWhite={t("about.faq.question7.white")}
          questionYellow={t("about.faq.question7.yellow")}
          answer={t("about.faq.question7.answer")}
        />
        <Question
          questionWhite={t("about.faq.question8.white")}
          questionYellow={t("about.faq.question8.yellow")}
          answer={t("about.faq.question8.answer")}
        />
      </div>
    </section>
  );
}

function Question({ questionWhite, questionYellow, answer }) {
  return (
    <details className="flex justify-between items-center bg-blue-c2 border-t w-full border-t-yellow-c4 p-5 mb-3">
      <summary className="list-none flex flex-row justify-between cursor-pointer">
        <div className="uppercase text-xl aldo py-1">
          <span className="text-white">{questionWhite}</span>{" "}
          <span className="text-yellow-c1">{questionYellow}</span>
        </div>
        <div className="bg-yellow-c1 p-2">
          <FontAwesomeIcon icon={faChevronDown} className="text-xl" />
        </div>
      </summary>
      <div className="bg-blue-c1 my-2 p-5 rounded-sm ">
        <p className="text-white lg:text-sm text-xs pr-3">{answer}</p>
      </div>
    </details>
  );
}
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
   faEnvelope,
   faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {
   faFacebook,
   faTwitter,
   faYoutube,
   faInstagram,
   faTwitch
} from "@fortawesome/fontawesome-free-brands";

export default function ContactElements() {
   return (
      <div className="grid grid-cols-1 lg:grid-cols-3 w-11/12 mx-auto mt-10 gap-5">
         <Element
            icon={faLocationDot}
            text="500 W 500 S Lindon, Utah 84042"
            to="https://www.google.com/maps/place/500+S+500+W,+Lindon,+UT+84042/@40.3272216,-111.7267371,17z/data=!4m5!3m4!1s0x874d84eff10e9c2f:0x1808d428b4eb2777!8m2!3d40.32789!4d-111.7262685" />
         {/*<Element icon={faPhone} text="+1 (786) 623-8895" />*/}
         <Element
            icon={faEnvelope}
            text="Ken@internationaltreasurehuntersleague.com"
            to="mailto:Ken@internationaltreasurehuntersleague.com"
         />
         <Element
            icon={faInstagram}
            text="@ITHL.Official"
            to="https://www.instagram.com/ithl.official"
         />
         <Element
            icon={faTwitch}
            text="ithlgaming"
            to="https://www.twitch.tv/ithlgaming"
         />
         <Element
            icon={faFacebook}
            text="International Treasure Hunters League"
            to="https://www.facebook.com/people/International-Treasure-Hunters-League/100082160763706/"
         />
         <Element
            icon={faTwitter}
            text="ITHL_official"
            to="https://twitter.com/ITHL_official"
         />
         {/* 
         <Element
            icon={faYoutube}
            text="International Treasure Hunters League ITHL"
            to="https://www.youtube.com/channel/UClTbnPC6mkbpjO_1OYicrBQ"
         />
          */}
      </div>
   );
}

function Element({ icon, text, to }) {
   return (
      <div className="border border-gray-400 border-t-4 border-t-yellow-500 mb-2 p-3 w-full cursor-pointer">
         <div className="text-center text-yellow-600 text-5xl">
            <a href={to} target="_blank" rel="noreferrer">
               <FontAwesomeIcon icon={icon} />
            </a>
         </div>
         <div className="text-center text-sm lg:text-base my-2">
            <a href={to} target="_blank" rel="noreferrer">
               {text}
            </a>
         </div>
      </div>
   );
}

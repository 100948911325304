import api from "../../utils/api";
import { setEventStatusPay } from "../reducers/reducer.payments";
import {
   subscriptions,
   loading,
   createPayment,
   subscription,
   intent,
   updateSubscription,
   methods,
   errorUpdateSubscription,
} from "../reducers/_subscriptions.reducers";

export const getSubscriptions = () => (dispatch) => {
   dispatch(loading(true));
   api.get("/subscriptions")
      .then((response) => dispatch(subscriptions(response.data)))
      .catch((error) => console.log(error))
      .then(() => dispatch(loading(false)));
};

export const createOrUsePaymentMethod = () => async (dispatch) => {
   dispatch(loading(true));
   try {
      const response = await api.get("/subscription/method");
      dispatch(createPayment(response.data.paymentMethod));
   } catch (error) {
      console.log("/subscription/method", error);
   } finally {
      dispatch(loading(false));
   }
};


export const deletePaymentMethod = (payment_id) => async (dispatch) => {
   dispatch(loading(true));
   try {
      const response = await api.delete("/subscription/payment-method/"+payment_id);

      dispatch(createPayment(response.data.paymentMethod));
   } catch (error) {

      console.log("/subscription/payment-method/"+payment_id, error);

   } finally {
      dispatch(loading(false));
   }
};


export const getSubscription = (subscription_id) => async (dispatch) => {
   dispatch(loading(true));
   try {
      const response = await api.get(`/subscriptions/${subscription_id}`);
      dispatch(subscription(response.data[0]));
   } catch (error) {
      console.log(`/subscriptions/${subscription_id}`, error.response.data);
   } finally {
      dispatch(loading(false));
   }
};

export const getIntentPayment = () => async (dispatch) => {
   dispatch(loading(true));
   try {
      const response = await api.get(`/subscription/get-intent`);
      dispatch(loading(false));
      dispatch(intent(response.data));
   } catch (error) {
      dispatch(loading(false));
   }
};

export const getPaymentMethods = () => async (dispatch) => {
   dispatch(loading(true));
   try {
      const response = await api.get(`/subscription/get-intent`);
      
      dispatch(loading(false));
      dispatch(methods(response.data));
   } catch (error) {
      dispatch(loading(false));
   }
};

export const createUserAndSubscription = (data) => async (dispatch) => {
   dispatch(loading(true));
   try {
      const response = await api.post(`/subscription/create`, data);
      if (response.status === 200) {
         dispatch(updateSubscription(true));
      }
   } catch (error) {
      console.log(`/subscription/create`, error);
   } finally {
      dispatch(loading(false));
   }
};

export const updateUserSubscription = (data) => async (dispatch) => {
   dispatch(loading(true));
   try {
      const response = await api.post(`/subscription/update`, data);
      if (response.status === 200) {
         dispatch(updateSubscription(true));
      }
   } catch (error) {
      dispatch(
         errorUpdateSubscription({
            status: true,
            message:
               "Looks like there is an error updating your account. You are allowed to change your subscription once a month",
         })
      );
   } finally {
      dispatch(loading(false));
   }
};


export const payToJoinEvent = (data) => async dispatch => {
   dispatch(loading(true));
   try {
      const response = await api.post(`/subscription/single-charge`, data);
      if (response.status === 200) {

         
         dispatch(setEventStatusPay(true));
      }
   } catch (error) {
      console.error("Single charge",error.response.data)
   } finally {
      dispatch(loading(false))
   }
}
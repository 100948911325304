import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../../../../components/CustomModal";
import AdminInput from "../../../../components/admin/admin.input";
import AdminButton from "../../../../components/admin/admin.button";
import { getWdMethods } from "../../../../store/actions/_withdrawalMethods.actions";
import { addAccount } from "../../../../store/actions/_withdraws.actions";
import {
  accountsModal,
  errors,
  created,
} from "../../../../store/reducers/_withdraws.reducers";
import paypalImg from "../../../../assets/img/icons/paypal.png"

export default function AddAccount() {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const accounts = useSelector((state) => state.withdraw);
  const methods = useSelector((state) => state.wdmethod.wdmethods);
  const [imgMethod, setImgMethod] = useState("");

  useEffect(() => {
    dispatch(getWdMethods());
  }, [dispatch]);

  const handleChange = (event) => {
    if(event.target.name == "method"){
      if(event.target.value){
        let id = event.target.value;
        let method = methods.find(item => item.id == id);
        if(method){
          setImgMethod(method.icon);
        }
      }else{
        setImgMethod("");
      }
    }

    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };



  const closeModal = () => {
    dispatch(errors());
    dispatch(accountsModal());
    setData({});
  };

  return (
    <CustomModal
      title="Add new account"
      size="w-1/2"
      status={accounts.accounts.modal}
      closedButton={closeModal}
      className="rounded-[15px]"
    >
      {methods.length > 0 && (
        <div className="w-4/5 mx-auto">
          <label
            htmlFor="method"
            className="text-gray-500 capitalize"
          >
            Select Method 
          </label>
          
          <div className="flex border border-gray-300 text-black rounded-md">
            {
              imgMethod ? <>
                <img className="w-[20px] h-[20px] relative top-[9px] left-2" src={require(`../../../../assets/img/icons/${imgMethod}`)}/>
              </> : <>
                <div className="w-[20px] h-[20px]"></div>
              </>
            }
            <select
              name="method"
              id="method"
              className="p-[10px] w-full focus:outline-none bg-white"
              onChange={handleChange}
            >
              <option></option>
              {methods.map((item, key) => (
                <option value={item.id} key={key}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          {/* 1 = Paypal */}
          {data?.method === "1" && <Paypal resetData={() => setData({})} />}
        </div>
      )}
    </CustomModal>
  );
}

const Paypal = ({ resetData }) => {
  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.withdraw.accounts);
  const profile = useSelector((state) => state.profile);
  const [data, setData] = useState({
    withdrawal_method_id: 1,
  });

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(addAccount(data));
  };

  useEffect(() => {
    if (accounts.created) {
      resetData();
      setTimeout(() => {
        dispatch(created(false));
      }, 500);
    }
  }, [accounts.created]);

  // console.log("Accounts", accounts);

  return (
    <div className="mt-5">
      {accounts.errors.status && (
        <div className="p-2 mb-2 bg-red-100 border border-red-300 text-red-400 text-sm flex justify-between">
          {accounts.errors.message}
          <FontAwesomeIcon
            icon={faTimes}
            className="font-bold hover:scale-105 cursor-pointer active:scale-95"
            onClick={() => dispatch(errors())}
          />
        </div>
      )}
      <form action="" onSubmit={handleSubmit}>
        <AdminInput
          classNameInput="rounded-md py-2"
          className="mt-3"
          label="Email"
          onChange={handleChange}
          type="text"
          id="email"
          errors={accounts.errors}
          value={profile.user.email}
        />
        <AdminInput
          label="Username"
          onChange={handleChange}
          classNameInput="rounded-md py-2"
          className="mt-3 rounded-md"
          type="text"
          id="username"
          errors={accounts.errors}
        />
        <AdminInput
          classNameInput="rounded-md py-2"
          className="mt-3"
          label="Name account"
          onChange={handleChange}
          type="text"
          id="alias"
          errors={accounts.errors}
        />
        <div className="w-9/12 mx-auto">
          <AdminButton
            status={accounts.btnLoading}
            text="Create Account"
            type="submit"
            icon={faCheck}
          />
        </div>
      </form>
    </div>
  );
};

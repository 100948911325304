export default function GameLogos() {
  return (
    <section className="lg:flex justify-between items-center hidden lg:w-11/12 mx-auto pt-20 pb-10">
      <img
        className={style.icons}
        src={require("../../../../assets/img/icons/pc.png")}
        alt=""
      />
      <img
        className={style.icons}
        src={require("../../../../assets/img/icons/headphones.png")}
        alt=""
      />
      <img
        className={style.icons}
        src={require("../../../../assets/img/icons/control.png")}
        alt=""
      />
      <img
        className={style.icons}
        src={require("../../../../assets/img/icons/camera.png")}
        alt=""
      />
      <img
        className={style.icons}
        src={require("../../../../assets/img/icons/dice.png")}
        alt=""
      />
      <img
        className={style.icons}
        src={require("../../../../assets/img/icons/play.png")}
        alt=""
      />
    </section>
  );
}

const style = {
  icons: `hover:sepia mx-2`,
};

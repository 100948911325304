import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import NewRequest from "./new-request";
import Loading from "../../../../components/Loading";
import { getRequest } from "../../../../store/actions/request.withdrawal.actions";
import { modal } from "../../../../store/reducers/_request.withdrawal.reducers";
import "./style.scss";

export default function BalanceHistory() {
  const dispatch = useDispatch();
  const requestwd = useSelector((state) => state.requestwd);

  useEffect(() => {
    if (!requestwd.loaded) {
      dispatch(getRequest());
    }
  }, [requestwd.loaded, dispatch]);

  /* const handleRemoveAccount = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(removeAccount(id));
    }
  }; */

  return (
    <div>
      <NewRequest />
      {requestwd.loading ? (
        <Loading />
      ) : (
        <>
          <div className="w-full flex justify-end">
            <button
              onClick={() => dispatch(modal(true))}
              className="w-auto ml-2 transform-skew-20 bg-[#001D3D] font-bold uppercase aldo p-3 text-[#FFC300]"
            >
              <FontAwesomeIcon icon={faPlus} /> Cash Out
            </button>
          </div>
          {requestwd.requests.length > 0 ? (
            <table className="table mt-10 border-table">
              <thead>
                <tr>
                  <th>To</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Request Data</th>
                </tr>
              </thead>
              <tbody>
                {requestwd.requests.map((item, key) => (
                  <tr key={key}>
                    <td>{item.name} {">"} {item.alias} {">"} {item.email || item.username}</td>
                    <td>${item.amount}</td>
                    <td><Statues status={item.status} batch={item.payout_batch_id} /></td>
                    <td>{moment(item.request_date).fromNow()}</td>
                    {/* <td>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="text-red-500 hover:scale-105 active:scale-95 cursor-pointer"
                        // onClick={() => handleRemoveAccount(item.id)}
                      />
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>No Requests</div>
          )}
        </>
      )}
    </div>
  );
}


const Statues = ( { status, batch } ) => {
  if (status === 'pending')
      return <div className="text-red-400 font-bold">Pending...</div>
  
  if (status === 'in_progress')
      return <div className="text-blue-600 font-bold">In progress ({batch})</div>
}
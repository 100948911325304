import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminInput from "../../components/admin/admin.input";
// import AdminTextArea from "../../components/admin/admin.textarea";
import AdminButton from "../../components/admin/admin.button";
import AdminCancel from "../../components/admin/admin.cancel";
import AdminSelect from "../../components/admin/admin.select";
import { storeNews } from "../../store/actions/_news.actions";
import { Navigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function UsersCreate() {
  const dispatch = useDispatch();
  const r_news = useSelector((state) => state.news);
  const r_main = useSelector((state) => state.main);
  const [data, setData] = useState({
    title: "",
    cover: "",
    details: "",
    category: "",
  });

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData();
    let imagefile_cover = document.querySelector("#cover");
    formData.append("title", data.title);
    formData.append("cover", imagefile_cover.files[0] || data.cover);
    formData.append("category", data.category);
    formData.append("details", data.details);
    dispatch(storeNews(formData));
  };

  const ckeditorValue = (value) => {
    setData({
      ...data,
      details: value,
    });
  };

  return (
    <>
      {r_news.redirect && <Navigate to="/settings/news" />}
      <form
        action=""
        id="register-new"
        onSubmit={handleSubmit}
        className="w-1/2 mt-20 form shadow-2xl"
      >
        <fieldset>
          <legend>Add New / Information</legend>
          <div className="">
            <AdminInput
              label="title (*)"
              className="w-full mb-5"
              onChange={handleChange}
              type="text"
              id="title"
              placeholder="Title"
              errors={r_news.error}
            />
            <AdminInput
              label="Cover (*)"
              className="w-full mb-5"
              onChange={handleChange}
              type="file"
              id="cover"
              placeholder="cover"
              errors={r_news.error}
            />
            <AdminSelect
              label="Category"
              className="w-full mb-5"
              onChange={handleChange}
              options={r_main.optionsSelect}
              id="category"
              placeholder="Select category"
              errors={r_news.errors}
              value={data.category}
            />

            {/* <AdminTextArea
                     label="details"
                     className="w-full col-span-4"
                     onChange={handleChange}
                     id="details"
                     placeholder="Details"
                     errors={r_news.error}
                  /> */}
            <div className="no-tailwindcss-base">
              <CKEditor
                id="ckeditor"
                editor={ClassicEditor}
                config={{
                  toolbar: {
                    items: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "link",
                      "bulletedList",
                      "numberedList",
                      "|",
                      "outdent",
                      "indent",
                      "|",
                      "blockQuote",
                      "insertTable",
                      "mediaEmbed",
                      "undo",
                      "redo",
                    ],
                  },
                  table: {
                    contentToolbar: [
                      "tableColumn",
                      "tableRow",
                      "mergeTableCells",
                    ],
                  },
                }}
                data=""
                // onReady={(editor) => {
                //   // You can store the "editor" and use when it is needed.
                //   console.log("Editor is ready to use!", editor);
                // }}
                onChange={(event, editor) => {
                  ckeditorValue(editor.getData());
                }}
                // onBlur={(event, editor) => {
                //   console.log("Blur.", editor);
                // }}
                // onFocus={(event, editor) => {
                //   console.log("Focus.", editor);
                // }}
              />
            </div>
          </div>
          <div className="mt-5 flex justify-between w-1/2 float-right">
            <AdminCancel text="Back" to="/settings/news" />
            <AdminButton status={r_news.btnLoading} text="Submit" />
          </div>
        </fieldset>
      </form>
    </>
  );
}

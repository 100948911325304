/* eslint-disable react-hooks/exhaustive-deps */
// import { faEdit, faTrophy, faTrash } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import Loading from "../../components/Loading";
import { getEvents } from "../../store/actions/_events.actions";
import { resetAll, alert } from "../../store/reducers/_events.reducers";
import Live from "./list/event-list-live";
import Draf from ".//list/event-list-draft";
import Past from ".//list/event-list-past";
import { BasicSearch } from "../../components/basicSearch/BasicSearch";
import { useState } from "react";


const style = {
  active: 'text-yellow-500 border-b-4 border-yellow-400 block w-full text-center p-3 font-bold',
  inactive: 'text-gray-500 border-b-4 border-gray-400 block w-full text-center p-3'
}

export default function EvenList() {
  const dispatch = useDispatch();
  const r_events = useSelector((state) => state.event);
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();

  useEffect(() => {
    dispatch(resetAll());
    // if (!r_events.loaded) {
      dispatch(getEvents());
    // }
  }, [dispatch]);


  useEffect(() => {
    dispatch(
      alert({
        status: false,
        message: "",
        style: "",
      })
    );
  }, [dispatch]);

  
  useEffect(() => {
    if(searchValue){      
      setSearchValue("");
      dispatch(getEvents());
    }
  }, [location]);

  const handleChangeSearch = (e) => {
    console.log(e.target.value);
    const cleanInput = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');
    if(cleanInput){
      
      setSearchValue(cleanInput);
      dispatch(getEvents(cleanInput));
    }else{
      
      setSearchValue('');
      dispatch(getEvents(''));
    }
  }

  return (
    <>
      <div className="w-full mx-auto pt-[32px]">
        <div className="flex">
          <div className="w-3/6">              
            <h1 className="font-bold text-xl mb-5">All the events</h1>
          </div>

          
          <div className="w-3/6 flex justify-end">
            <BasicSearch
              handleChangeSearch={handleChangeSearch}
              className=""
              searchValue={searchValue}
            />
          </div>
        </div>
        <div className="w-full flex justify-between">
          <NavLink
            className={({ isActive }) =>
              isActive ? style.active : style.inactive
            }
            to="live"
          >
            Live Now
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? style.active : style.inactive
            }
            to="draft"
          >
            Pending / Draft
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? style.active : style.inactive
            }
            to="past"
          >
            Past Events
          </NavLink>
        </div>
          {r_events.loading && <Loading />}
          {r_events.loaded && !r_events.loading && (
                  <Routes>
                    { ['','/','live'].map( (item, key) => <Route key={key} path={item} element={<Live />} /> )}
                    <Route path="past" element={<Past />} />
                    <Route path="draft" element={<Draf />} />
                  </Routes>
            )}
      </div>
    </>
  );
}
import BulletButton from '../../components/BulletButton';

export default function Kickstarter() {
   return (
      <section className="w-full h-screen bg-cover bg-no-repeat bg-[url('../img/banners/banner-home.png')] bg-center flex justify-center items-center">
         <div className="text-white text-3xl lg:text-6xl uppercase font-bold flex flex-col w-1/2 items-center">
            <div className="aldo">
               <span className="text-yellow-c1">Kick</span>starter
            </div>
            <div className='text-base my-5 rounded-full animate__animated animate__pulse animate__infinite bg-blue-100 p-2 text-blue-900 border boder-blue-900'>Starting Soon...</div>
            <BulletButton
               text="Create a Free account"
               className="mt-5 w-10/12 text-sm lg:w-1/2 lg:text-xl aldo"
               onClick={() => false}
            />
         </div>
      </section>
   );
}

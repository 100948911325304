/* eslint-disable react-hooks/exhaustive-deps */
import { dispatch, useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faHistory } from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink, Routes, Route, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import WithdrawMethods from "./withdraw-methods";
import BalanceHistory from "./balance";
import { getBalance } from "../../../../store/actions/_withdraws.actions";
import Loading from "../../../../components/Loading";

const style = {
  active:
    "bg-yellow-400 border border-yellow-500 text-black px-5 py-3 font-bold hover:opacity-80 text-[25x] rounded-[8px]",
  inactive: "bg-gray-200 border border-gray-300 px-5 py-3 hover:opacity-80 text-[#858585]",
  created: "rounded-5",
  joined: "",
  create:
    "px-5 py-3 bg-yellow-400 text-black font-bold transform-skew-20 hover:opacity-80",

  tab: "py-3 px-5 text-[#858585] rounded-[8px] font-bold",
  activeTab: "py-3 px-5 text-[#000] bg-[#FFC300] shadow-xl rounded-[8px] font-bold"
};

const Balance = ({ balance }) => {
  return balance ? balance.total : 0.0;
};

const WonEvents = ({ balance }) => {
  return balance ? balance.total_won_events : 0;
};

export default function Withdraws() {
  const dispatch = useDispatch();
  const r_withdraws = useSelector((state) => state.withdraw);
  const location = useLocation();
  const [isAccountActive, setIsAccountActive] = useState(false);
  const [balance, setBalance] = useState();

  useEffect(() => {
    dispatch(getBalance());
  }, []);

  useEffect(() => {
    let path = location.pathname;
    let pathSections = path.split("/");
    setIsAccountActive(false);
    if(pathSections.length > 0){
      let section = pathSections[pathSections.length-1];
      if(section == "accounts" || section == "withdraws"){
        setIsAccountActive(true);
      }
    }
  }, [location.pathname])

 
  return (
    <div className="w-11/12 mx-auto">
      {r_withdraws.loading ? (
        <Loading />
      ) : (
        <>
          <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4 mt-5">
            <div>
              <h3 className="segoe text-[34px] font-[700]">Status</h3>
              <p className="segoe mt-3 mb-5">
                Payments will be made within 14 days of winning the event.
              </p>

              <div className="">
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-7 text-center border border-[#DFDFDF] rounded-[8px] py-[30px]">
                    <h2 className="segoe text-[30px]">
                      USD$ <Balance balance={r_withdraws.balance} />
                    </h2>
                    <p className="text-[24px] font-[200]">Available money</p>
                  </div>
                  <div className="col-span-5 bg-[#FFD23333] text-center border border-[#DFDFDF] rounded-[8px] py-[30px]">
                    <h2 className="text-[30px]">
                      <WonEvents balance={r_withdraws.balance} />
                    </h2>
                    <p className="text-[24px] font-[200]">Won Events</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                className="
						bg-[#061322]
						rounded-[8px] 
						p-5
					"
              >
                <span
                  className="
							text-[#FFC300] 
							font-['AldotheApache'] 
							text-[30px] 
							uppercase"
                >
                  Want to keep WINNING? <br />
                  Bring out the hunter in you
                </span>

                <div className="w-full flex justify-end mt-[70px]">
                  <Link
                    to="/game-events"
                    className="
								w-[200px]
								ml-2
								transform-skew-20
								bg-yellow-c1
								font-bold
								uppercase
								aldo
								p-3
								text-center
							"
                  >
                    GO TO EVENTS
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-between items-center my-10">
            <div className="w-max flex justify-between items-center bg-[#e5e7eb] rounded-[8px]">
              <NavLink
                className={({ isActive }) =>
                  isActive || isAccountActive
                    ? `${style.activeTab}`
                    : `${style.tab}`
                }
                to={`accounts`}
              >
                <FontAwesomeIcon icon={faMoneyBill} /> Accounts
              </NavLink>
              
              <NavLink
                className={({ isActive }) =>
                  isActive
                  ? `${style.activeTab}`
                  : `${style.tab}`
                }
                to={`balance`}
              >
                <FontAwesomeIcon icon={faHistory} /> History
              </NavLink>
            </div>
          </div>

          <div>
            <Routes>
              {["/", "accounts"].map((path, key) => (
                <Route key={key} path={path} element={<WithdrawMethods />} />
              ))}
              <Route path="balance" element={<BalanceHistory />} />
            </Routes>
          </div>
        </>
      )}
    </div>
  );
}

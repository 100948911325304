import Menu from "./menu/Menu";
import Footer from './footer/Footer'
import { Outlet } from 'react-router-dom'

export default function Container( { children } ) {
   return (
      <div className="w-full">
         <Menu />
         <div className="min- h-auto min-h-[700px] mb-20 w-full"><Outlet /></div>
			<Footer />
      </div>
   );
}

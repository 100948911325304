import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faSignInAlt,
  faHeartCrack,
	faSpinner,
  faGear
} from "@fortawesome/free-solid-svg-icons";
// import { faGears } from "@fortawesome/free-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  followAction,
  unFollowAction,
} from "../../../../store/actions/_pageProfile.actions";
import { useEffect } from "react";

export default function ProfileFollowButton() {
  const dispatch = useDispatch();
	const navigate = useNavigate();
  const r_pp = useSelector((state) => state.pageProfile);
  const r_search = useSelector((state) => state.search);
  const r_profile = useSelector((state) => state.profile);

  useEffect(() => {
    console.log(r_profile.followeds.includes(r_pp.user.id), r_pp.user);
  }, [r_profile.followeds])
  

  return (
    <>
      {r_pp.follows.status === "login" && (
        <Button
          text="Login"
          icon={faSignInAlt}
          action={ () => navigate('/auth/login') }
          status={false}
        />
      )}
      {r_pp.follows.status === "myself" && (
        <Button
          text="Settings"
          icon={faGear}
          action={ () => navigate('/settings/users/profile')}
          status={false}
        />
      )}
      {!r_profile.followeds.includes(r_pp.user.id) && (
        <Button
          text="Follow"
          icon={faHeart}
          action={() => dispatch(followAction(r_pp.user.uuid))}
          status={r_pp.user.uuid === r_search.moreResultItemIsLoading}
        />
      )}
      {r_profile.followeds.includes(r_pp.user.id) && (
        <Button
          text="Unfollow"
          icon={faHeartCrack}
          action={() => dispatch(unFollowAction(r_pp.user.uuid))}
          status={r_pp.user.uuid === r_search.moreResultItemIsLoading}
        />
      )}
    </>
  );
}

const Button = ({ text, icon, action, status }) => {
  return (
    <>
      {!status ? (
        <button
          className="bg-yellow-400 py-2 px-5 shadow active:scale-95 w-7/12 mx-auto border border-yellow-600 mt-10 flex items-center justify-center"
          onClick={action}
        >
          <FontAwesomeIcon icon={icon} className="mr-2" /> {text}
        </button>
      ) : (
        <button
					disabled
          className="cursor-not bg-yellow-400 py-2 px-5 shadow active:scale-95 w-1/2 mx-auto border border-yellow-600 mt-10 flex items-center justify-center cursor-not-allowed opacity-50"
        >
          <FontAwesomeIcon icon={faSpinner} spin className="mr-2" /> Loading
        </button>
      )}
    </>
  );
};

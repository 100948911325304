import React from 'react'
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { truncateStr } from '../../utils/functions';
import AliceCarousel from "react-alice-carousel";
import './styles.scss';
import parse from 'html-react-parser'

export const EventCarouselCard = ({ data }) => {

  return (
    <>
      {
        data &&
        <div className='grid grid-cols-1 md:grid-cols-2 shrink-0 md:shrink w-5/6 md:w-full max-w-xl md:min-w-[500px] px-2 py-5 bg-blue-c2 border-t-4 border-yellow-400 overflow-hidden relative'>

          <div id="left-col" className=''>
            <img
              src={data.cover}
              className='rounded-md'
              alt="International Treasure Hunters League Event"
            />
            <div className='hidden md:block'>
              <div className='grid grid-cols-2 py-5 divide-x divide-sky-800'>
                <div className='flex flex-col w-full items-center'>
                  <span className='text-3xl aldo text-yellow-c1'>
                    <Moment date={data.start_date} format="DD" />
                  </span>
                  <span className='text-xl aldo text-white'>
                    <Moment date={data.start_date} format="MMMM" />
                  </span>
                  <span className='text-xs font-bold text-white'>Start Date</span>
                </div>
                <div className='flex flex-col w-full items-center'>
                  <span className='text-3xl aldo text-yellow-c1'>
                    <Moment date={data.end_date} format="DD" />
                  </span>
                  <span className='text-xl aldo text-white'>
                    <Moment date={data.end_date} format="MMMM" />
                  </span>
                  <span className='text-xs font-bold text-white'>End Date</span>
                </div>
              </div>
            </div>
          </div>

          <div id="right-col" className='py-2 px-4'>
            <div className="flex flex-row-reverse">
              <div className='px-5 py-1 -skew-x-12 bg-yellow-c1'>
                
              <Link to={"/event-detail/" + data.event_uuid}>                  
                  {
                    data.first_place_reward != 0
                      ? (
                        <span className='text-xl font-extrabold segoe transform-none text-blue-c1'>${data.first_place_reward}</span>
                      ) : (
                        <span className='text-xl font-extrabold segoe transform-none text-blue-c1'>Try Your Luck!</span>
                      )
                  }
                </Link>

                
              </div>
            </div>

            <div className="pt-5">
              <h2 className='font-bold text-white text-xl aldo line-clamp-1'>{data.title}</h2>
              {
                data.user_owner?.id != 32 || data.user_owner?.id === 11
                  ? (
                    <Link to={`/profile/${data.user_owner.username || data.user_owner.uuid}/about`}>
                      <h3 className='text-sm text-yellow-c1 pt-1 hover:underline'>{data.user_owner?.username}</h3>
                    </Link>
                  ) : (
                    <h3 className='text-sm text-yellow-c1 pt-1'>ITHL Sponsored</h3>
                  )
              }
            </div>

            <div className='hidden lg:block'>
              <p className='text-white pt-4 line-clamp-4'>
                {truncateStr(parse(data.brief_description), 134)}
              </p>
            </div>

            <div className='block md:hidden'>
              <div className='grid grid-cols-2 py-5 divide-x divide-sky-800'>
                <div className='flex flex-col w-full items-center'>
                  <span className='text-3xl aldo text-yellow-c1'>
                    <Moment date={data.start_date} format="DD" />
                  </span>
                  <span className='text-xl aldo text-white'>
                    <Moment date={data.start_date} format="MMMM" />
                  </span>
                  <span className='text-xs font-bold text-white'>Start Date</span>
                </div>
                <div className='flex flex-col w-full items-center'>
                  <span className='text-3xl aldo text-yellow-c1'>
                    <Moment date={data.end_date} format="DD" />
                  </span>
                  <span className='text-xl aldo text-white'>
                    <Moment date={data.end_date} format="MMMM" />
                  </span>
                  <span className='text-xs font-bold text-white'>End Date</span>
                </div>
              </div>
            </div>

            <div className='pt-4'>
              <Link to={`/event-detail/${data.event_uuid}`}>
                <button className='w-full bg-yellow-c1 text-blue-c1 rounded-sm py-2 uppercase segoe-bold-100'>
                  Play now
                </button>
              </Link>
            </div>
          </div>

        </div>
      }
    </>
  )
}

import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { modal } from "../../store/reducers/_withdrawalMethods.reducers";

import moment from "moment/moment";

export default function WdMethodsList() {
  const dispatch = useDispatch();
  const r_wdmethod = useSelector((state) => state.wdmethod);

  console.log(r_wdmethod)
  return (
    <>
      <div className="w-11/12 mx-auto mt-5">
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Icon</th>
              <th>Updated at</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {r_wdmethod.wdmethods.length > 0 &&
              r_wdmethod.wdmethods.map((item, key) => (
                <tr key={key}>
                  <td>{item.name}</td>
                  <td>{item.icon}</td>
                  <td>{moment(item.updated_at).fromNow()}</td>
                  <td>
                    <ul className="flex space-x-1">
                      <li className="bg-[#061322] p-[5px] rounded-[3px]" onClick={() => dispatch(modal({ update: true, key }))}>
                        <FontAwesomeIcon
                          className="text-[#FFC300] cursor-pointer"
                          icon={faEdit}
                        />
                      </li>
                      <li className="bg-[#FFC300] p-[5px] rounded-[3px]" onClick={() => dispatch(modal({ delete: true, key }))}>
                        <FontAwesomeIcon
                          className="text-[#061322] cursor-pointer"
                          icon={faTrash}
                        />
                      </li>
                    </ul>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

import React from 'react'
import Header from '../howtoplay/components/Header';
import { BodyText } from "./components/BodyText";

export const HowToPlayInfo = () => {
  return (
    
    <>
      <Header />
      <div className="w-10/12 m-auto">        
        <div className="md:flex  mt-[80px]">
          <div className="w-8/12">
            <BodyText />
          </div>
          <div className="w-4/12 px-4">
            <div className="w-full drop-shadow-md bg-[#fff] rounded-[8px] p-4">
              <ul>
                <li className="my-2 segoe text-[#77838F]">1. How to play</li>
                <li className="my-2 segoe text-[#77838F]">2. Can I create Events?</li>
                <li className="my-2 segoe text-[#77838F]">3. About the prices</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

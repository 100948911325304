import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { useCountdown } from "../../../hooks/Countdown";
import BulletButton from "../../../components/BulletButton";
import { modal } from "../../../store/_main.reducers";
import { joinEventModal } from "../../../store/reducers/_events.reducers";
import { NavLink } from "react-router-dom";
import moment from "moment/moment";
import { publishEvent } from "../../../store/actions/_events.actions";
import Timezone from "../../../hooks/timezone";

export const EventSpecification = () => {
  const r_event = useSelector((state) => state.event);
  const r_auth = useSelector((state) => state.auth);
  const [eventTime, setEventTime] = useState("");
  const [eventDate, setEventDate] = useState("");

  useEffect(() => {
    if(r_event.event){
      const dateParse = Timezone({
        date: r_event.event.full_start_time,
        formatStr: "YYYY-MM-DD HH:mm",
      })
      if(dateParse){
        const dateSections = dateParse.split(" ");
        if(dateSections.length > 0){
          if(dateSections[0]){
            setEventDate(dateSections[0])
          }
          if(dateSections[1]){
            setEventTime(dateSections[1])
          }
        }
      }
    }
  }, [r_event.event])
  

  const dispatch = useDispatch();

  const handleModal = () => {
    dispatch(modal(r_auth.logged));
    dispatch(joinEventModal(true));
  };

  const handlePlublishEvent = () => {
    if (r_event.event) {
      dispatch(publishEvent(r_event.event.event_uuid));
    }
  };

  

  return (
    <div className="md:w-2/6 sm:w-2/2">
      <div className="w-full border-[3px] border-[#E8E9EB] p-4">
        {r_event.event.full_start_time && (
          <>
            {r_event.event.blocked && r_event.event.closed_event != 2 && r_event.event.closed_event != 1 && moment(r_event.event.end_date) >= moment() && (
              <>
                <div className="mb-2 border-b border-[#E8E9EB] pb-5">
                  <span className="font-bold">Open in:</span> <br />
                  <CountDown date={r_event.event.full_start_time} />
                  <br />
                  <span className="mx-2">
                    <span className="text-yellow-c1 font-bold">
                      {
                        eventTime && <>
                          {
                            eventTime.split(":")[0]
                          }
                        </>
                      }
                      <span className="font-bold text-black"> : </span>
                      {
                        eventTime && <>
                          {
                            eventTime.split(":")[1]
                          }
                        </>
                      }
                    </span>
                    <span className="ml-2 text-black text-[0.5em] xl:text-sm">
                      Local time
                    </span>
                  </span>
                </div>
              </>
            )}
            {r_auth.logged !== undefined &&
              r_event.event_engagement.length === 0 &&
              moment(r_event.event.full_end_time) >=
                moment() && (
                <>
                  <div className="mb-2 border-b border-[#E8E9EB] pb-5">
                    <span className="font-bold">Event Active:</span> <br />
                    <div className="w-full text-center">
                      {r_event.event.closed_event == 0 ? (
                        <>
                          <button
                            onClick={handleModal}
                            className="uppercase rounded-[8px] bg-yellow-c1 py-[5px] px-[15px] font-['AldotheApache'] text-[24px] text-[#293541]"
                            icon=""
                          >
                            {r_auth.logged
                              ? "Play now"
                              : "Login and Join"}
                          </button>
                          {r_event.event.is_free_event == false && (
                            <>
                              <br />
                              <span className="text-[#818890] text-[12px] mt-2 block">
                                *To enter this event you must pay $1
                              </span>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          { r_event.event.closed_event == 2 && <>
                              <button
                                onClick={handlePlublishEvent}
                                className="uppercase rounded-[8px] bg-yellow-c1 py-[5px] px-[15px] font-['AldotheApache'] text-[24px] text-[#293541]"
                                icon=""
                              >
                                {r_auth.logged ? "Publish Event" : "Login"}
                              </button>
                              {r_event.event.is_free_event == false && (
                                <>
                                  <br />
                                  <span className="text-[#818890] text-[12px] mt-2 block">
                                    *To enter this event you must pay $1
                                  </span>
                                </>
                              )}
                            </>
                          }
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

            {r_event.event_engagement &&
              r_event.event_engagement.length > 0 && (
                <>
                  <div className="mb-2 border-b border-[#E8E9EB] pb-5">
                    {r_event.event.closed_event == 1 ? (
                      <div className="m-auto text-center w-full">
                        <div className="w-1/2 justify-end text-center">
                          <span className="bg-[#061322] px-[15px] py-[4px] rounded-[30px] text-[12px] text-white uppercase">
                            Closed event
                          </span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="flex">
                          <div className="w-1/2">
                            <span className="font-bold">Active Event:</span>
                          </div>
                          <div className="w-1/2 justify-end text-end">
                            <span className="bg-[#6E8CA8] px-[10px] py-[2px] rounded-[30px] text-[12px] text-white">
                              Already participating
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="w-full text-center mt-5">
                      {moment(r_event.event.end_date).format("YYYY-MM-DD") >
                        moment().format("YYYY-MM-DD") && (
                        <>
                          {!r_event.event.closed_event &&
                            r_event.event_engagement[0].status ===
                              "pending" && (
                              <>
                                <NavLink
                                  to="upload-proof/#event-tabs"
                                  className="uppercase rounded-[8px] bg-yellow-c1 py-[5px] px-[15px] font-['AldotheApache'] text-[24px] text-[#293541]"
                                  icon=""
                                >
                                  {r_auth.logged ? "Submit proof" : "Login"}
                                </NavLink>
                              </>
                            )}
                        </>
                      )}

                      {r_event.event.closed_event == 1 && (
                        <>
                          <NavLink
                            to="results#event-tabs"
                            className="uppercase rounded-[8px] bg-yellow-c1 py-[5px] px-[15px] font-['AldotheApache'] text-[24px] text-[#293541]"
                            icon=""
                          >
                            {r_auth.logged ? "See proofs" : "Login"}
                          </NavLink>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
          </>
        )}

        {r_event.event.full_start_time && (
          <>
            <div className="mb-2 mt-4 border-b border-[#E8E9EB] pb-5">
              <span className="font-bold">Awards</span> <br />
              <div className="mt-5">
                <div className="flex mb-5  items-center">
                  <div className="w-full text-[18px]">
                    <FontAwesomeIcon
                      icon={faTrophy}
                      className="text-[#6E8CA8] text-[24px] mr-2"
                    />
                    <span className="font-bold text-[#293541]">
                      First Place Winnings:
                    </span>
                    <span className="text-yellow-c1">
                      {" "}
                      ${r_event.event.first_place_reward}
                    </span>
                  </div>
                </div>

                {/* <div className="flex mb-5 items-center">
                            <div className="w-full text-[16px]">
                                <span className="bg-[#6E8CA8] py-[2px] px-[6px] mr-2 font-bold text-white text-[12px] rounded-[5px]">1</span>
                                <span className="font-bold text-[#293541]">1st Place:</span>
                                <span className="text-yellow-c1"> {r_event.event.first_place_extra_points} Points</span>
                            </div>
                        </div>

                        {r_event.event.second_place_reward && (
                        <div className="flex mb-5 items-center">
                            <div className="w-full text-[14px]">
                            <span className="bg-[#6E8CA8] py-[2px] px-[6px] mr-2 font-bold text-white text-[12px] rounded-[5px]">2</span>
                            <span className="font-bold text-[#293541]">2nd Place:</span>
                            <span className="text-yellow-c1"> {r_event.event.second_place_reward} Points</span>
                            </div>
                        </div>
                        )}
                        {r_event.event.third_place_reward && (
                        <div className="flex items-center">
                            <div className="w-full text-[12px]">
                            <span className="bg-[#6E8CA8] py-[2px] px-[6px] mr-2 font-bold text-white text-[12px] rounded-[5px]">3</span>
                            <span className="font-bold text-[#293541]">3th Place:</span>
                            <span className="text-yellow-c1"> {r_event.event.third_place_reward} points</span>
                            </div>
                        </div>
                        )} */}
              </div>
            </div>
          </>
        )}

        <>
          <div className="mb-2 mt-5 pb-5">
            <span className="font-bold">Specifications:</span>
            <br />
            <br />

            <div className="flex mb-5 items-center">
              <div className="w-full text-[16px]">
                <span className="font-bold text-[#293541]">From: </span>
                {
                  <span className="text-yellow-c1">
                    {Timezone({
                      date: r_event.event.full_start_time,
                      formatStr: "YYYY-MM-DD",
                    })}
                  </span>
                }
                {/* <span className="text-yellow-c1">{r_event.event.start_date}</span> */}
                <span className="font-bold text-[#293541]"> to </span>
                <span className="text-yellow-c1">
                  {Timezone({
                    date: r_event.event.full_end_time,
                    formatStr: "YYYY-MM-DD",
                  })}
                </span>
              </div>
            </div>

            {/* <div className="flex mb-5 items-center">
                    <div className="w-full text-[16px]">
                        <span className="font-bold text-[#293541]">Points for completing event: </span>
                        <span>{r_event.event.max_points_seasson}</span>
                    </div>
                </div> */}

            <div className="flex mb-5 items-center">
              <div className="w-full text-[16px]">
                <span className="font-bold text-[#293541]">
                  Type Of Event:{" "}
                </span>
                <span>{r_event.event.type_of_event}</span>
              </div>
            </div>

            <div className="flex mb-5 items-center">
              <div className="w-full text-[16px]">
                <span className="font-bold text-[#293541]">Platform: </span>
                <span>{r_event.event.platform}</span>
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-full text-[16px]">
                <span className="font-bold text-[#293541]">Requirements: </span>
                <span>{r_event.event.requirements}</span>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};
const CountDown = (props) => {
  const { date } = props;
  const localDate = Timezone({ date, formatStr: "YYYY-MM-DD HH:mm" });
  const [days, hours, minutes, seconds] = useCountdown(localDate);
  return (
    <>
      {days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0 ? (
        <>
        <div className={`flex`}>
          <span className="mx-2">
            <p className="text-yellow-c1 font-bold text-center">0</p>
            <p className="text-black text-[0.5em] xl:text-sm uppercase text-center">
              Days
            </p>
          </span>
          <span className="font-bold text-black text-center">:</span>
          <span className="mx-2">
            <p className="text-yellow-c1 font-bold text-center">0</p>
            <p className="text-black text-[0.5em] xl:text-sm uppercase text-center">
              Hours
            </p>
          </span>
          <span className="font-bold text-black text-center">:</span>
          <span className="mx-2">
            <p className="text-yellow-c1 font-bold text-center">0</p>
            <p className="text-black text-[0.5em] xl:text-sm uppercase text-center">
              Min
            </p>
          </span>
          <span className="font-bold text-black text-center">:</span>
          <span className="mx-2">
            <p className="text-yellow-c1 font-bold text-center">0</p>
            <p className="text-black text-[0.5em] xl:text-sm uppercase text-center">
              Sec
            </p>
          </span>
        </div>
        </>
      ) : (
        <div className={`flex`}>
          <span className="mx-2">
            <p className="text-yellow-c1 font-bold text-center">{days}</p>
            <p className="text-black text-[0.5em] xl:text-sm uppercase text-center">
              Days
            </p>
          </span>
          <span className="font-bold text-black text-center">:</span>
          <span className="mx-2">
            <p className="text-yellow-c1 font-bold text-center">{hours}</p>
            <p className="text-black text-[0.5em] xl:text-sm uppercase text-center">
              Hours
            </p>
          </span>
          <span className="font-bold text-black text-center">:</span>
          <span className="mx-2">
            <p className="text-yellow-c1 font-bold text-center">{minutes}</p>
            <p className="text-black text-[0.5em] xl:text-sm uppercase text-center">
              Min
            </p>
          </span>
          <span className="font-bold text-black text-center">:</span>
          <span className="mx-2">
            <p className="text-yellow-c1 font-bold text-center">{seconds}</p>
            <p className="text-black text-[0.5em] xl:text-sm uppercase text-center">
              Sec
            </p>
          </span>
        </div>
      )}
    </>
  );
};

export default function AdminSelect(props) {
  const {
    className,
    onChange,
    options,
    id,
    placeholder,
    errors,
    label,
    value,
    disabled
  } = props;
  const defaultValue = value || 1;

  return (
    <div className={`relative ${className}`}>
      <label className="font-bold text-gray-500 capitalize" htmlFor={id}>
        {label}
      </label>
      <select
        disabled={disabled}
        name={id}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        value={defaultValue}
        className={`p-[15px] w-full focus:outline-none border ${disabled ? 'bg-gray-200' : 'bg-white'} ${
          errors?.status &&
          Object.entries(errors.messages).length > 0 &&
          errors.messages[id]
            ? "border-red-600"
            : "border-gray-300"
        } text-black`}
      >
        <option value="">{placeholder}</option>
        {options.map((item, key) => (
          <option key={key} value={item.value}>
            {item.name}
          </option>
        ))}
      </select>
      {errors?.status &&
        Object.entries(errors.messages).length > 0 &&
        errors.messages[id] && (
          <span className="text-red-600">{errors.messages[id][0]}</span>
        )}
    </div>
  );
}

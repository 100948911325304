import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import Footer from '../../../components/footer/Footer'
import Loading from '../../../components/Loading'
import Menu from '../../../components/menu/Menu'
import { getSearchData } from '../../../store/actions/_search.actions'
import { EventsFound, Filters, UsersFound } from './components'
import no_data_search from '../../../assets/img/banners/no_data_search.svg';
import { setIsShowInNavBar } from '../../../store/reducers/_search.reducers'

export const SearchPage = () => {
  
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const searchState = useSelector((state) => state.search);
  const [searchFilter, setSearchFilter] = useState({
    find: "",
    type: "all"
  });

  
  useEffect(() => {
    dispatch(setIsShowInNavBar(false));
  
    return () => {
      dispatch(setIsShowInNavBar(true));
    }
  }, [])

  const handleChangeSearch = ({target}) => {
    setSearchFilter({
      ...searchFilter,
      [target.name]: target.value,
    });
  }

  useEffect(() => {
    if(searchParams){
      if(searchParams.get('find')){
        setSearchFilter({
          type: "all",
          find: searchParams.get('find')
        });
      }
    }
    

    dispatch(getSearchData({
      type: "all",
      find: searchParams.get('find')
    }));
  }, [searchParams])
  

  useEffect(() => {
    if(searchFilter.find){
      dispatch(getSearchData(searchFilter));
    }
  }, [searchFilter.type])

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(getSearchData(searchFilter));
  }

  return (
    <div className="w-full bg-cover bg-no-repeat bg-[url('../img/backgrounds/main-bg.png')] bg-center flex flex-col justify-center">

        <div className='w-fill mx-[50px]'>
          <Filters 
            searchFilter={searchFilter} 
            types={searchState.types} 
            handleChangeSearch={handleChangeSearch}
            handleSubmit={handleSubmit}
          />

          {
            searchState.isLoading && <Loading color="black" />
          }

          
          {
            !searchState.isLoading && <>
              <UsersFound data={searchState} />
              <EventsFound events={searchState.events} />
            </>
          }

          {
              searchState.isLoading == false && (
              searchState.principalResult == null &&
              searchState.events.length < 1 &&
              searchState.moreResults.length < 1
            ) && (
              <div className='m-auto text-center w-full bg-[#1D2C3C] py-[80px] mb-[100px]'>
                <div className="header-title-section uppercase text-white text-2xl lg:text-6xl font-bold aldo">
                  There are no events <span className="text-yellow-c1">in this search</span>
                </div>
                <p className="header-sub2-section segoe text-white font-bold text-md lg:text-lg my-5">
                  Soon discover more events for this category or participate in our ITHL events, don't wait any longer to win!
                </p>
                <img src={no_data_search} className="max-w-[300px] w-full m-auto" />
              </div>
            )
          }
        
        </div>

    </div>
  )
}

import React from 'react'

export const BodyText = () => {
  return (
    <>
        <div className='my-3'>
            <h2 className="uppercase font-bold font-['AldotheApache'] text-[25px]">1. How to play</h2>
            <p>
                It’s easy to simply gather your group, state your problem, and ask for ideas. But is this the most efficient way to generate fresh, productive ideas? There are specialised methods for brainstorming that take idea generation seriously, and you may want to give some a try. They include:
                <br />
                <br />
                <ol>
                    <li className='flex'>
                        <div className='mr-1'>1.</div>
                        <div>
                            Brainwriting: Everyone writes down three ideas and passes their ideas to the person on their left (or right), who builds off those ideas before passing them on again. This way, ideas can cross-pollinate, morph and build on top of each other from different perspectives. After all, two heads are better than one.
                        </div>
                    </li>
                    
                    <li className='flex'>
                        <div className='mr-1'>2.</div>
                        <div>
                            Rapid ideation: Everyone writes down as many ideas as they can in a set amount of time before anything is discussed or critiqued. This is a fun way to get all the good (and bad) ideas out fast and bring a sense of fun urgency to the session.
                        </div>
                    </li>
                    
                    <li className='flex'>
                        <div className='mr-1'>3.</div>
                        <div>
                            Figure storming: The group picks a well-known figure who is not in the room and asks how they would approach the problem. For example, “How would Barack Obama (or Harry Potter, or anyone else) approach this problem?” - prepare for some funny answers here, this method is a good ice-breaker for everyone involved.
                        </div>
                    </li>
                    
                    <li className='flex'>
                        <div className='mr-1'>4.</div>
                        <div>
                            Change of scenery: Sometimes the conference room is not the best place for brainstorming. Try moving outside, heading for lunch, or playing a game to get the creative juices flowing. One tried and tested method we enjoy is walking in nature, find a park and sit under a tree - the change of context will inspire fresh perspectives from your team.
                        </div>
                    </li>

                </ol>
            </p>



            
            <div className='my-5 ml-5'>
                <h2 className="uppercase font-bold font-['AldotheApache'] text-[25px]">a. How to JOIN AN EVENT?</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in sapien ac arcu iaculis malesuada a eget nisl. In quis euismod quam, sit amet convallis urna. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In consectetur interdum elit, ac congue est. Phasellus non sapien id ante ultrices molestie. Mauris ut sagittis lectus. Suspendisse potenti.
                    <br />
                    <br />
                    Vivamus vestibulum ullamcorper nisi, quis tincidunt purus pretium quis. Integer sodales, dui vitae varius eleifend, neque ligula ullamcorper tellus, vitae posuere nunc lacus nec sapien. Praesent hendrerit augue eu eleifend mollis. Suspendisse potenti. Pellentesque sed orci non lectus pellentesque posuere. Pellentesque iaculis vestibulum imperdiet. Vestibulum risus nulla, hendrerit sed lorem vel, porttitor iaculis lacus. Curabitur suscipit metus eget risus accumsan, mattis blandit massa rutrum. Donec orci diam, posuere eget ante vel, sodales facilisis urna.

                </p>
            </div>

            
            
            <div className='my-5 ml-5'>
                <h2 className="uppercase font-bold font-['AldotheApache'] text-[25px]">B. How to UPLOAD THE PROOFS?</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in sapien ac arcu iaculis malesuada a eget nisl. In quis euismod quam, sit amet convallis urna. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In consectetur interdum elit, ac congue est. Phasellus non sapien id ante ultrices molestie. Mauris ut sagittis lectus. Suspendisse potenti.
                    <br />
                    <br />
                    Vivamus vestibulum ullamcorper nisi, quis tincidunt purus pretium quis. Integer sodales, dui vitae varius eleifend, neque ligula ullamcorper tellus, vitae posuere nunc lacus nec sapien. Praesent hendrerit augue eu eleifend mollis. Suspendisse potenti. Pellentesque sed orci non lectus pellentesque posuere. Pellentesque iaculis vestibulum imperdiet. Vestibulum risus nulla, hendrerit sed lorem vel, porttitor iaculis lacus. Curabitur suscipit metus eget risus accumsan, mattis blandit massa rutrum. Donec orci diam, posuere eget ante vel, sodales facilisis urna.

                </p>
            </div>

            
            <div className='my-5 ml-5'>
                <h2 className="uppercase font-bold font-['AldotheApache'] text-[25px]">C. WHAT TO DO IF I WIN AN EVENT</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in sapien ac arcu iaculis malesuada a eget nisl. In quis euismod quam, sit amet convallis urna. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In consectetur interdum elit, ac congue est. Phasellus non sapien id ante ultrices molestie. Mauris ut sagittis lectus. Suspendisse potenti.
                    <br />
                    <br />
                    Vivamus vestibulum ullamcorper nisi, quis tincidunt purus pretium quis. Integer sodales, dui vitae varius eleifend, neque ligula ullamcorper tellus, vitae posuere nunc lacus nec sapien. Praesent hendrerit augue eu eleifend mollis. Suspendisse potenti. Pellentesque sed orci non lectus pellentesque posuere. Pellentesque iaculis vestibulum imperdiet. Vestibulum risus nulla, hendrerit sed lorem vel, porttitor iaculis lacus. Curabitur suscipit metus eget risus accumsan, mattis blandit massa rutrum. Donec orci diam, posuere eget ante vel, sodales facilisis urna.

                </p>
            </div>
        </div>

        
    </>
  )
}

import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy, faThumbTack } from "@fortawesome/free-solid-svg-icons";
import clockImg from "../../../../assets/img/icons/clock.png";
import parse from "html-react-parser";
import Moment from "react-moment";

export default function ProfileAbout() {
  const r_pp = useSelector((state) => state.pageProfile);
  const r_p = useSelector((state) => state.profile);

  return (
    <>
      <div className="mb-3">
        <h1 className="font-bold text-2xl">
          About {r_pp.user.username || `${r_pp.user.name} ${r_pp.user.lastname}`}
        </h1>
        {
          r_p.user && <>
            <p className="flex text-[14px] text-[#AAAAAA]">
              <img src={clockImg} className="w-[15px] h-[15px] relative top-1" /> <span className="ml-2">Member since: <Moment date={r_p.user.created_at} format="MM/DD/YYYY" /></span>
            </p>
          </>
        }
      </div>
      {/* <div className="flex flex-col text-gray-500 text-sm">
        <span>
          <FontAwesomeIcon icon={faThumbTack} className="mr-2" />{" "} 
          {r_pp.events.published} Published events
        </span>
        <span>
          <FontAwesomeIcon icon={faTrophy} className="mr-2" />{" "}
          {r_pp.events.winned} winned events
        </span>
      </div> */}
      {r_pp.user.about_me !== undefined && r_pp.user.about_me !== "" && r_pp.user.about_me !== null && (
        <div className="w-full mt-10">{parse(r_pp.user.about_me)}</div>
      )}
    </>
  );
}

/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
// import { faEdit, faTrophy, faTrash } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import { BasicSearch } from "../../../../components/basicSearch/BasicSearch";
import Loading from "../../../../components/Loading";
import { getEventsJoined } from "../../../../store/actions/_events.actions";
import { resetAll } from "../../../../store/reducers/_events.reducers";
import Live from "../../../events/list/event-joined-live";
import Past from "../../../events/list/event-joined-past";
import Upcoming from "../../../events/list/event-joined-upcoming";
import { EventCard } from "./components";

const style = {
  active:
    "text-yellow-500 border-b-4 border-yellow-400 block w-full text-center p-3 font-bold",
  inactive:
    "text-gray-500 border-b-4 border-gray-400 block w-full text-center p-3",
};

export default function EventsJoined() {
  const dispatch = useDispatch();
  const r_events = useSelector((state) => state.event);
  // const past = useSelector((state) => state.event.joined?.past);
  const live = useSelector((state) => state.event.joined?.live);
  const location = useLocation();
  const [defaultPage, setDefaultPage] = useState("live");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    dispatch(resetAll());
    // if (!r_events.loadedJoined) {
    dispatch(getEventsJoined());
    // }
  }, [dispatch]);

  useEffect(() => {
    if (searchValue) {
      setSearchValue("");
      dispatch(getEventsJoined());
    }
    if (location.pathname) {
      const urlSections = location.pathname.split("/");
      const lastUrlSection = urlSections[urlSections.length - 1];
      switch (lastUrlSection) {
        case "past":
          setDefaultPage("past");
          break;

        case "live":
        default:
          setDefaultPage("live");
          break;
      }
    }
  }, [location]);

  const handleChangeSearch = (e) => {
    const cleanInput = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
    if (cleanInput) {
      setSearchValue(cleanInput);
      dispatch(getEventsJoined(cleanInput));
    } else {
      setSearchValue("");
      dispatch(getEventsJoined());
    }
  };

  return (
    <>
      <div className="w-full mx-auto pt-[32px]">
        {defaultPage == "live" && (
          <>
            <div className="mb-2">
              <h1 className="font-bold text-xl mb-5">Recent Events Joined</h1>
              <div className="flex">
                {live && (
                  <>
                    {live.length > 0 && (
                      <>
                        {live.map((item, key) => {
                          if (key <= 1) {
                            return (
                              <EventCard
                                key={key}
                                item={item}
                                className="w-1/2 mx-1"
                              />
                            );
                          }
                        })}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}

        <div className="mt-3">
          <div className="flex mt-[32px] mb-4">
            <div className="w-3/6">
              <h1 className="font-bold text-xl mb-5">All the events</h1>
            </div>

            <div className="w-3/6 flex justify-end">
              <BasicSearch
                handleChangeSearch={handleChangeSearch}
                className=""
                searchValue={searchValue}
              />
            </div>
          </div>
          <div className="w-full flex justify-between">
            <NavLink
              className={({ isActive }) =>
                isActive ? style.active : style.inactive
              }
              to="live"
            >
              Live Now
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? style.active : style.inactive
              }
              to="upcoming"
            >
              Upcoming Events
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? style.active : style.inactive
              }
              to="past"
            >
              Past Events
            </NavLink>
          </div>
        </div>
        {r_events.loading && <Loading />}
        {r_events.loadedJoined && !r_events.loading && (
          <Routes>
            {["", "live"].map((item, key) => (
              <Route key={key} path={item} element={<Live />} />
            ))}
            <Route path="past" element={<Past />} />
            <Route path="upcoming" element={<Upcoming />} />
          </Routes>
        )}
      </div>
    </>
  );
}

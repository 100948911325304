import Header from "./components/Header";
import AvailableGames from "./components/AvailableGames";
import HowToWin from "./components/HowToWin";
import FAQ from "./components/FAQ";
import GameLogos from "./components/GameLogos";
import { HowToPlayDetail } from "./components/HowToPlayDetail";

export default function HowToPlay() {
  return (
    <>
      <Header />
      <AvailableGames />
      <HowToWin />
      <FAQ />
      <HowToPlayDetail />
      <GameLogos />
    </>
  )
}
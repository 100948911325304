import BulletLink from "../../../../components/BulletLink";
import { t } from "i18next";

export function HowToPlayDetail() {
  return (
    <section className="w-full pb-[150px] pt-[100px] bg-cover bg-center bg-no-repeat z-0 relative  bg-[url('../img/banners/banner-play.png')] ">
      <div className="justify-center text-center mx-auto pt-5">
        <h2 className="aldo text-4xl md:text-5xl uppercase mt-2 text-white">
            Do you still have <span className="text-yellow-c1">doubts</span>?
        </h2>
        <p className="mt-5 segoe text-white text-[12px]">Check our page for more details and you can enjoy hunting treasures.</p>
      </div>
      
      <div className="flex flex-col-reverse h-full top-[50px] relative">
        <div className="justify-center items-center mx-auto w-6/12">
          <BulletLink
            to="/howtoplay-info"
            text="MORE DETAILS ABOUT IT"
            className="mt-5 w-10/12 text-sm md:w-1/2 md:text-xl z-0 aldo"
          />
        </div>
      </div>
    </section>
  );
}

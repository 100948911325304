import CustomModal from "../../components/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import AdminInput from "../../components/admin/admin.input";
import AdminButton from "../../components/admin/admin.button";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useState } from 'react';
import { storeRoles } from '../../store/actions/_role.actions'

export default function RoleCreate({ status, close }) {
  const dispatch = useDispatch();
  const r_role = useSelector((state) => state.role);
	const [data, setData] = useState();

	const handleChange = (event) => {
		setData({
			...data,
			[event.target.name]: event.target.value
		})
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		// console.log(data);
		dispatch(storeRoles(data));
	}

  return (
    <CustomModal
      title="Create new Role"
      size="w-1/2"
      status={status}
      closedButton={close}
    >
      <form onSubmit={handleSubmit}>
        <AdminInput
          className=""
          onChange={handleChange}
          type="text"
          id="name"
          placeholder="Role Name"
          errors={r_role.error}
          // label="Role Name"
          // value=""
          // disabled={false}
          // pattern=""
        />
        <div className="w-1/2 mx-auto">
          <AdminButton status={r_role.btnLoading} text="Create" type="submit" icon={faCheck} />
        </div>
      </form>
    </CustomModal>
  );
}

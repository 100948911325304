import api from '../../utils/api';
import { loading, loaded, errors, request, btnLoading, modal } from '../reducers/_paymentRequest.reducers'

export const getPaymentRequest = () => async (dispatch) => {
    dispatch(loading(true))
    try {
        const response = await api.get('/payment-request');
        dispatch(loaded(true));
        dispatch(request(response.data))
    } catch (err) {
        console.error(err)
    } finally {
        dispatch(loading())
    }
}

export const sendPayment = (id) => async (dispatch) => {
    dispatch(loading(true))
    try {
        await api.post('/payment-request',{ id: id });
        dispatch(loaded(false));
    } catch (err) {
        console.error(err)
    } finally {
        dispatch(loading())
    }
}
/* eslint-disable eqeqeq */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import "react-dropdown/style.css";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
// import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { t } from "i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const FilterEvent = ({ filter, isPage }) => {
  const { eventFilter, setEventFilter, categoryEvent } = filter;

  const [eventTypes, setEventTypes] = useState([]);
  const [eventFilterText, setEventFilterText] = useState("");
  const r_events = useSelector((state) => state.event);

  useEffect(() => {
    if (r_events.types) {
      if (r_events.types.length > 0) {
        setEventTypes(r_events.types);
      }
    }
  }, [r_events]);

  //   const options = ["one", "two", "three"];
  //   const defaultOption = options[0];

  return (
    <div id="event-filter" className="flex flex-col gap-4">
      <div className="select-filters flex">
        {eventFilter.category === "" ? (
          <span className="filter-active">{t("event_explorer.all_events")}</span>
        ) : (
          <span
            onClick={(state) => {
              setEventFilter({ ...state, category: "" });
            }}
          >
            {t("event_explorer.all_events")}
          </span>
        )}

        {eventTypes.map((filter) => {
          return eventFilter.category == filter.value ? (
            <span className="filter-active" key={filter.value}>
              {filter.name}
            </span>
          ) : (
            <span
              key={filter.value}
              onClick={() => {
                setEventFilter({ ...eventFilter, category: filter.value });
              }}
            >
              {filter.name}
            </span>
          );
        })}
      </div>

      <div className="grid grid-cols-2 gap-4 filter-form">
        <div className="relative">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-white-500 dark:text-white-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            onChange={(event) => {
              setEventFilter({ ...eventFilter, search: event.target.value });
            }}
            type="search"
            className="block p-4 pl-10 w-72"
            placeholder={t("event_explorer.filter.search")}
            value={eventFilter.search}
            required
          />
        </div>

        <div className="flex justify-end">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button
                className="
                  text-white
                  bg-[#001D3D] 
                  inline-flex 
                  w-full 
                  justify-center 
                  p-3
                  md:px-4
                  md:py-2 
                  text-sm 
                  font-medium
                  shadow-sm
                  focus:outline-none 
                  focus:ring-2 
                  focus:ring-indigo-500 
                  focus:ring-offset-2 
                  focus:ring-offset-gray-100
                  rounded-[50%]
                  md:rounded-none"
              >
                <img
                  className="w-[20px] md:mr-[10px] relative md:pt-3"
                  src={require("../../../assets/img/icons/order-by.png")}
                  alt="Order by"
                />
                <span className="hidden md:block">
                  {t("event_explorer.filter.order_by")}
                  {
                    eventFilterText && <b>: {eventFilterText}</b>
                  }
                </span>
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              
              {({ close }) => (
                <Menu.Items
                className="
                                    bg-[#001D3D] 
                                    absolute 
                                    right-0 
                                    z-10 
                                    mt-2 
                                    w-[275px]
                                    origin-top-right
                                    divide-y
                                    divide-gray-100
                                    shadow-lg
                                    ring-1
                                    ring-black
                                    ring-opacity-5
                                    focus:outline-none
                                    px-[10px]
                                    py-[3px]
                                "
              >
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        href="#"
                        onClick={(e) => {
                          setEventFilter({
                            ...eventFilter,
                            order_by: "recents",
                          });
                          setEventFilterText(t("event_explorer.filter.decendent_date"));
                          
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-white segoe font-bold capitalize",
                          "block px-4 py-2 text-sm capitalize"
                        )}
                      >
                        {t("event_explorer.filter.decendent_date")}
                      </span>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        href="#"
                        onClick={(e) => {
                          
                          setEventFilter({ ...eventFilter, order_by: "older" });
                          setEventFilterText(t("event_explorer.filter.ascending_date"));
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-white segoe font-bold capitalize",
                          "block px-4 py-2 text-sm capitalize"
                        )}
                      >
                        {t("event_explorer.filter.ascending_date")}
                      </span>
                    )}
                  </Menu.Item>
                </div>

                {categoryEvent == "our_events" && (
                  <>
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            href="#"
                            onClick={(e) => {
                              setEventFilter({
                                ...eventFilter,
                                order_by: "big_prize",
                              });
                              setEventFilterText(t("event_explorer.filter.big_prize"));
                            }}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-white segoe font-bold capitalize",
                              "block px-4 py-2 text-sm capitalize"
                            )}
                          >
                            {t("event_explorer.filter.big_prize")}
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            href="#"
                            onClick={(e) => {
                              
                              setEventFilter({
                                ...eventFilter,
                                order_by: "small_prize",
                              });
                              setEventFilterText(t("event_explorer.filter.small_prize"));
                            }}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-white segoe font-bold capitalize",
                              "block px-4 py-2 text-sm capitalize"
                            )}
                          >
                            {t("event_explorer.filter.small_prize")}
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  </>
                )}

                
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <span
                          href="#"
                          onClick={(e) => {
                            
                            setEventFilter({
                              ...eventFilter,
                              order_by: "free",
                            });
                            setEventFilterText(t("event_explorer.filter.free"));
                          }}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-white segoe font-bold capitalize",
                            "block px-4 py-2 text-sm capitalize"
                          )}
                        >
                          {t("event_explorer.filter.free")}
                        </span>
                      )}
                    </Menu.Item>
                  </div>

                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        href="#"
                        onClick={(e) => {
                          
                          setEventFilter({
                            ...eventFilter,
                            order_by: "finished",
                          });
                          setEventFilterText(t("event_explorer.filter.finished"));
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-white segoe font-bold capitalize",
                          "block px-4 py-2 text-sm capitalize"
                        )}
                      >
                        {t("event_explorer.filter.finished")}
                      </span>
                    )}
                  </Menu.Item>
                </div>


                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        href="#"
                        onClick={(e) => {
                          
                          setEventFilter({
                            ...eventFilter,
                            order_by: "active",
                          });
                          setEventFilterText(t("event_explorer.filter.active"));
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-white segoe font-bold capitalize",
                          "block px-4 py-2 text-sm capitalize"
                        )}
                      >
                        {t("event_explorer.filter.active")}
                      </span>
                    )}
                  </Menu.Item>
                </div>
                
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        href="#"
                        onClick={(e) => {
                          
                          setEventFilter({
                            ...eventFilter,
                            order_by: "closed",
                          });
                          setEventFilterText(t("event_explorer.filter.closed"));
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-white segoe font-bold capitalize",
                          "block px-4 py-2 text-sm capitalize"
                        )}
                      >
                        {t("event_explorer.filter.closed")}
                      </span>
                    )}
                  </Menu.Item>
                </div>


              </Menu.Items>
              )}
              
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
};

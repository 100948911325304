import { Link } from "react-router-dom";
import { t } from "i18next";

export default function Section2() {
   return (
      <div className="hidden lg:flex text-white flex-col justify-center">
         <div className="border-b-yellow-c4 w-1/2 mb-10 text-xl uppercase font-bold">{t("footer.menu")}</div>
         <ul>
            <Menu to="/" text={t("footer.home")} />
            <Menu to="/about" text={t("footer.about")} />
            {/* <Menu to="" text={t("footer.challenge")} />
            <Menu to="" text={t("footer.recent")} /> */}
            <Menu to="/contact" text={t("footer.contact")} />
            <Menu to="/past-events" text={t("footer.past_event")} />
         </ul>
      </div>
   );
}

function Menu({ to, text }) {
   return (
      <li>
         <Link className="block mb-6" to={to}>{text}</Link>
      </li>
   );
}

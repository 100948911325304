import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import { t } from "i18next";

export default function Footer() {
   return (
      <>
         <div className="footer">
            <div className="footer-top"></div>
            <div className="flex flex-col lg:flex-row justify-between items-center w-1/2 mx-auto mb-10">
               <div className="uppercase font-bold text-2xl">
                  {/*<span className="text-white mr-3">Our</span>
                  <span className="text-yellow-c1">Newsletter</span>*/}
               </div>
               <div className="flex flex-col xl:flex-row items-center">
                  <FontAwesomeIcon
                     icon={faEnvelope}
                     className="text-yellow-c1 mr-2"
                  />
                  <span className="font-bold text-white mr-3">
						{t("footer.enter_email")}:
                  </span>
                  <input
                     type="text"
                     className="bg-blue-100 p-2 focus:outline-none text-white"
                     style={{
                        transform: "skew(-15deg)",
                        backgroundColor: "#23405e",
                     }}
                  />
               </div>
            </div>
            <div className="lg:grid grid-cols-5 gap-14">
               <Section1 />
               <Section2 />
               <Section3 />
               <Section4 />
            </div>
         </div>
         <div className="bg-blue-c2 text-white px-10 py-5">
            Copyright @2022{" "}
            <span className="text-yellow-c1">Treasure Hunters</span> All Rigths
            Reserved.
         </div>
      </>
   );
}

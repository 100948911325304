import { useEffect, useState } from "react";
import { useSelector, useDispatch} from "react-redux";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { getUserEvents } from "../../../store/actions/_events.actions";
import { proofDescription } from "../../../utils/constanst";
import { TableEventUsers } from "./components/TableEventUsers";
import { WinnerCard } from "./components/WinnerCard";

export default function ResultsShow() {
  const r_event = useSelector((state) => state.event);
  const r_eventInfo = useSelector((state) => state.event.event);
  const r_main = useSelector((state) => state.main);
  const dispatch = useDispatch();
  const [isMobileActive, setIsMobileActive] = useState(false)
  const {width} = useWindowDimensions();
  const [first, setFirst] = useState(null);
  const [second, setSecond] = useState(null);
  const [third, setThird] = useState(null);
  const [moreUserList, setMoreUserList] = useState([])

  useEffect(() => {
    setIsMobileActive(false);
    if(width <= 1250){
      setIsMobileActive(true);
    }
  }, [width])
  

  useEffect(() => {
    if(r_event.event?.event_uuid){
      if(r_event.event?.closed_event == 1){
        dispatch(getUserEvents(r_event.event.event_uuid));
      }
    }
  }, [r_event.event])

  useEffect(() => {
    setMoreUserList([]);
    setFirst(null);
    setSecond(null);
    setThird(null);
    if(r_event.usersEvent){
      if(r_event.usersEvent.length > 0){
        r_event.usersEvent.map(item=>{

          switch (item.status) {
            case 'first':
              setFirst(item);
              break;
            case 'second':
              setSecond(item);              
              break;
            case 'third':
              setThird(item);              
              break;
          
            default:
              setMoreUserList(preState => [...preState, item]);
              break;
          }
        });
      }
    }
  }, [r_event.usersEvent])
  


  return (
    <div className="p-4 lg:p-10 w-full bg-white">
      <p>
      The event have been closed and here are the results. Hope you had so much fun and keep participating to win so many events.  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book:
      </p>
      <br />
      <h3 className="segoe text-[#293541] text-[20px] font-bold"> <b>Winners:</b> </h3>

      {
        !first && !second && !third && <>
          <h4>there aren'r winners</h4>
        </>
      }

      {
        !isMobileActive && <>          
          {
            (first || second || third) && <>
              <div className="flex h-[300px]">
                {first && <WinnerCard className="min-h-[255px] bg-[#001D3DE5] relative bottom-[-10px]" user={first} mbottom="mb-3" />} 
                {second && <WinnerCard className="min-h-[275px] relative bottom-[0px] bg-[#001D3D]" user={second} mbottom="mb-5"/>} 
                {third && <WinnerCard className="min-h-[240px] relative bottom-[-20px] bg-[#001D3DCC]" user={third} mbottom="mb-1" />}            
              </div>
            </>
          }
        </>
      }
      {
        isMobileActive && <>
          {
           (first || second || third) && <>
            <div className="mt-5 overflow-auto">
              <div className="m-auto">
                {first && <WinnerCard user={first} className="min-h-[275px] bg-[#001D3D] relative bottom-[0px] w-[200px] mb-4" mbottom="mb-5"/>}                
              </div>
              <div className="flex h-[300px] flex-col md:flex-row ">
                {second && <WinnerCard user={second} className="min-h-[275px] bg-[#001D3DE5] w-[200px] sm:mb-4" mbottom="mb-3" />} 
                {third && <WinnerCard user={third} className="min-h-[275px] bg-[#001D3DCC] w-[200px] sm:mb-4" mbottom="mb-3" />}
              </div>
            </div>
           </>
          }
        </>
      }

      <div className="md:flex mt-6 pb-[75px]">
        <TableEventUsers className="sm:w-2/2 md:w-9/12" users={moreUserList} winners={{first, second, third}} />
        <div className="ml-3 sm:w-2/2 md:w-3/12">     
          If you can't see your name on here, you have to be faster and have better luck next time!
          <br />
          <Link to="/game-events" className='bg-[#FFC300] px-[10px] py-[3px] font-["AldotheApache"] rounded-[5px]'>see all the Events</Link>
        </div>
      </div>
    </div>
  );
}

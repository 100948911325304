import React, { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import defaultImage from '../../../assets/img/avatars/no_user.jpeg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { useState } from 'react';
import FlatFollowUser from '../../../components/flatFollowUser/FlatFollowUser';

export const EventHeader = () => {
    
  const r_event = useSelector((state) => state.event);
  const [profileImage, setProfileImage] = useState("../img/avatars/no_user.jpeg")

  useEffect(() => {
    if(r_event.event.user_owner?.avatar){
        setProfileImage(r_event.event.user_owner?.avatar);
    }
  }, [r_event.event.user_owner])
  

  return (
    <>   

        <div className='w-full md:flex mb-8'>
            <div className='w-12/12 md:w-6/12'>

                <h2 className="text-bold font-bold text-[30px] mb-5 mr-1">
                    {r_event.event.title}
                </h2>

                <div className='mt-3 mb-8'>
                    
                    {r_event.event.is_free_event ? (<>
                        <span className="text-[#031120] bg-[#9cddb1] rounded-[30px] py-1 px-5">
                            Free event
                        </span>
                    </>) : (<>
                        <span className="text-[#031120] bg-[#FBE667] rounded-[30px] py-1 px-5">
                            Payment Event
                        </span>
                    </>)}
                
                </div>

                
                <div className="mt-3 w-1/2 flex justify-start items-center">
                    <img
                        src={r_event.event.user_owner?.avatar || defaultImage}
                        className="rounded-full w-[60px]"
                        alt={r_event.event.user_owner?.username}
                    />

                    {r_event.event.user_owner?.id === 32 || r_event.event.user_owner?.id === 11 ? (
                        <>ITHL Sponsored <FontAwesomeIcon icon={faCircleCheck} className="text-blue-600" /></>
                    ) : (
                        <div>
                            <Link
                            className="ml-3 hover:underline segoe block"
                            to={`/profile/${
                                r_event.event.user_owner?.username ||
                                r_event.event.user_owner?.uuid
                            }/about`}
                            >
                            {r_event.event.user_owner?.username ||
                                r_event.event.user_owner?.name}
                            </Link>

                            <FlatFollowUser className={"ml-3"} user={r_event.event.user_owner} />
                        </div>
                    )}


                </div>
            </div>
            <div className='w-12/12 md:w-6/12'>            
                <div className="mt-4 overflow-hidden">
                    <img src={r_event.event.cover} alt={r_event.event.title} className="w-full max-w-[400px] max-h-[300px]" />
                </div>
            </div>
        </div>



    </>
  )
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy, faThumbTack, faCircleCheck, faHeart, faHeartCrack, faSpinner } from "@fortawesome/free-solid-svg-icons";
import imageProfile from '../../../../../../assets/img/avatars/no_user.jpeg';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  followAction,
  unFollowAction,
} from "../../../../../../store/actions/_pageProfile.actions";
import { Link } from "react-router-dom";


export const UserFollowList = ({users}) => {

    const dispatch = useDispatch();
    const r_auth = useSelector((state) => state.auth);
    const r_profile = useSelector((state) => state.profile);
    const r_search = useSelector((state) => state.search);


    return (    
        <ul>
            {
                users.map((user, index) => {
                    return (
                        <li className='grid grid-cols-12 gap-4 items-center mb-4' key={user.id}>
                            <div className='col-span-5 ml-[20px]'>

                                <div className='flex items-center'>
                                    
                                <img src={user.avatar || imageProfile} className="w-fulll max-w-[150px]" alt="" />
                                
                                <div className='col-span-5 col-start-8 text-[#AAAAAA]'>

                                    <Link to={`/profile/${user.username || user.uuid}/about`}>
                                        <h3 className='segoe ml-4 text-[#001D3D] font-bold text-[24px] hover:text-yellow-500'>
                                            {user.username}
                                            <span>
                                            {
                                                user.role_id == 3 && (
                                                    <FontAwesomeIcon
                                                        className="ml-4 text-[#0066FF] text-back text-[14px] relative top-[-10px] left-[-5px]"
                                                        icon={faCircleCheck}
                                                    />
                                                )
                                            }
                                            </span>
                                        </h3>
                                    </Link>
                                    <ul className="ml-4">
                                        <li>
                                        {user.followers_count} followers
                                        </li>
                                        <li className="flex items-center">
                                            <FontAwesomeIcon
                                                className="text-back text-[15px] active:scale-95"
                                                icon={faThumbTack}
                                            />
                                            <span className="ml-2 segoe text-[16px]">{user.events_created_by_user_count} published events</span>
                                        </li>
                                        <li className="flex items-center">
                                            <FontAwesomeIcon
                                                className="text-back text-[15px] active:scale-95"
                                                icon={faTrophy}
                                            />
                                            <span className="ml-2 segoe text-[16px]">{user.events_winned_count} winned events</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>

                            </div> 
                        
                            <div className='col-span-4 col-start-10'>
                                {
                                // r_profile
                                    
                                    r_auth.logged && (
                                        <>
                                            {
                                                r_profile.followeds.includes(user.id) ? (
                                                    <>
                                                        <Button
                                                            text="Unfollow"
                                                            icon={faHeartCrack}
                                                            action={() => dispatch(unFollowAction(user.uuid)) }
                                                            status={user.uuid === r_search.moreResultItemIsLoading}
                                                            className="
                                                            bg-[#FFC300]
                                                            text-[#001D3D]
                                                            w-[160px]
                                                            py-[10px]
                                                            rounded-[2px]"
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <Button
                                                            text="Follow"
                                                            icon={faHeart}
                                                            action={() => dispatch(followAction(user.uuid)) }
                                                            status={user.uuid === r_search.moreResultItemIsLoading}
                                                            className="
                                                            bg-[#FFC300]
                                                            text-[#001D3D]
                                                            w-[160px]
                                                            py-[10px]
                                                            rounded-[2px]"
                                                        />
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </div>
                        </li> 
                    )
                })
            }     
        </ul>
    )
}


const Button = ({ text, icon, action, status, className }) => {
    console.log(status);
    return (
      <>
        {!status ? (
          <button
            className={className}
            onClick={action}
          >
            <FontAwesomeIcon icon={icon} className="mr-2" /> {text}
          </button>
        ) : (
          <button
            disabled
            className={className}
          >
            <FontAwesomeIcon icon={faSpinner} spin className="mr-2" /> Loading
          </button>
        )}
      </>
    );
  };
  
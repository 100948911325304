import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../../../../components/CustomModal";
import AdminInput from "../../../../components/admin/admin.input";
import AdminButton from "../../../../components/admin/admin.button";
import { createRequest } from "../../../../store/actions/request.withdrawal.actions";
import { getAccounts } from "../../../../store/actions/_withdraws.actions";
import {
  modal,
  errors,
} from "../../../../store/reducers/_request.withdrawal.reducers";

export default function AddAccount() {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const requestwd = useSelector((state) => state.requestwd);
  const accounts = useSelector((state) => state.withdraw.accounts);


  useEffect(() => {
    if (!accounts.loaded) {
      dispatch(getAccounts());
    }
  }, [accounts.loaded, dispatch]);
  
  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const closeModal = () => {
    dispatch(errors());
    dispatch(modal());
    setData({});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(data)
    dispatch(createRequest(data))
  }

  return (
    <CustomModal
      title="Cash out"
      size="w-1/3"
      status={requestwd.modal}
      closedButton={closeModal}
      className="rounded-[15px]"
    >
      {accounts.accounts.length > 0 ? (
        <form action="" onSubmit={handleSubmit}>
          <div className="w-full mx-auto">
            <label
              htmlFor="method"
              className="text-gray-500 capitalize"
            >
              Select Method
            </label>
            <select
              required
              name="method"
              id="method"
              className="p-2 w-full focus:outline-none border bg-white border-gray-300 text-black rounded-md"
              onChange={handleChange}
            >
              <option></option>
              {accounts.accounts.map((item, key) => (
                <option value={item.id} key={key}>
                  {item.alias ||
                    `${item.withdrawal_method.name} - ${item.username}`}
                </option>
              ))}
            </select>
          </div>
          <AdminInput
            className="mt-5"
            classNameInput="rounded-md py-2"
            label="amount"
            onChange={handleChange}
            type="number"
            id="amount"
            errors={requestwd.errors}
          />
          <div className="w-9/12 mx-auto">
            <AdminButton
              status={requestwd.btnLoading}
              text="Send Request"
              type="submit"
              icon={faCheck}
            />
          </div>
        </form>
      ) : (
        <div>No accounts</div>
      )}
    </CustomModal>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import banner_movies from "../assets/img/thumbnails/banner_movies.png";
import cover_movies from "../assets/img/thumbnails/cover_movies.png";
import banner_shows from "../assets/img/thumbnails/banner_shows.png";
import cover_shows from "../assets/img/thumbnails/cover_shows.png";
import banner_games from "../assets/img/thumbnails/banner_games.png";
import cover_games from "../assets/img/thumbnails/cover_games.png";
import videoImage from "../assets/img/images/video-image.png";
import howtoplay from "../assets/img/thumbnails/how_to_play.png";
import IconoMovie from '../assets/img/icons/camera.png';
import IconoShows from '../assets/img/icons/pc.png';
import IconoGames from '../assets/img/icons/control.png';
import {
	faGamepad,
	faPlay,
	faTrophy,
	faVideo,
} from "@fortawesome/free-solid-svg-icons";

export const mainSlice = createSlice({
	name: "main",
	initialState: {
		modal: false,
		join_event: false,
		available_games: [
			{
				banner: banner_movies,
				screenshot: cover_movies,
				type: "howtoplay.participate_through.aside_sub",
				title: "howtoplay.participate_through.movie.title",
				category: "howtoplay.participate_through.movie.category",
				platform: "howtoplay.participate_through.movie.platform",
				channel: "howtoplay.participate_through.movie.channel",
				brief_description: "howtoplay.participate_through.movie.brief_description",
				type_of_event: "movie",
				icon: IconoMovie,
			},
			{
				banner: banner_shows,
				screenshot: cover_shows,
				type: "howtoplay.participate_through.aside_sub",
				title: "howtoplay.participate_through.show.title",
				category: "howtoplay.participate_through.show.category",
				platform: "howtoplay.participate_through.show.platform",
				channel: "howtoplay.participate_through.show.channel",
				brief_description: "howtoplay.participate_through.show.brief_description",
				type_of_event: "show",
				icon: IconoShows,
			},
			{
				banner: banner_games,
				screenshot: cover_games,
				type: "howtoplay.participate_through.aside_sub",
				title: "howtoplay.participate_through.game.title",
				category: "howtoplay.participate_through.game.category",
				platform: "howtoplay.participate_through.game.platform",
				channel: "howtoplay.participate_through.game.channel",
				brief_description: "howtoplay.participate_through.game.brief_description",
				type_of_event: "game",
				icon: IconoGames,
			},
		],
		how_to_play: [
			{
				how_to_play: "home.howto.items.sectiona.how_to_play",
				icon: faTrophy,
				cover: videoImage,
				description:
					"home.howto.items.sectiona.description",
				blockquote:
					"home.howto.items.sectiona.blockquote",
				steps: [],
			},
			{
				how_to_play: "home.howto.items.sectionb.how_to_play",
				icon: faVideo,
				cover: cover_movies,
				description:
					'home.howto.items.sectionb.description',
				blockquote: "",
				steps: [
					"home.howto.items.sectionb.steps.k1",
					"home.howto.items.sectionb.steps.k2",
					'home.howto.items.sectionb.steps.k3',
					"home.howto.items.sectionb.steps.k4",
					"home.howto.items.sectionb.steps.k5",
					"home.howto.items.sectionb.steps.k6",
					"home.howto.items.sectionb.steps.k7",
				],
			},
			{
				how_to_play: "home.howto.items.sectionc.how_to_play",
				icon: faPlay,
				cover: cover_shows,
				description:
					'home.howto.items.sectionc.description',
				blockquote: "",
				steps: [
					"home.howto.items.sectionc.steps.k1",
					"home.howto.items.sectionc.steps.k2",
					'home.howto.items.sectionc.steps.k3',
					"home.howto.items.sectionc.steps.k4",
					"home.howto.items.sectionc.steps.k5",
					"home.howto.items.sectionc.steps.k6",
					"home.howto.items.sectionc.steps.k7",
				],
			},
			{
				how_to_play: "home.howto.items.sectiond.how_to_play",
				icon: faGamepad,
				cover: cover_games,
				description:
					'home.howto.items.sectiond.description',
				blockquote: "",
				steps: [
					"home.howto.items.sectiond.steps.k1",
					"home.howto.items.sectiond.steps.k2",
					'home.howto.items.sectiond.steps.k3',
					"home.howto.items.sectiond.steps.k4",
					"home.howto.items.sectiond.steps.k5",
					"home.howto.items.sectiond.steps.k6",
					"home.howto.items.sectiond.steps.k7",
				],
			},
		],
		how_to_win: [
			{
				btn_text: "howtoplay.how_to_win.section_a.btn_text",
				title_1: "howtoplay.how_to_win.section_a.title_1",
				title_2: "howtoplay.how_to_win.section_a.title_2",
				icon: faTrophy,
				cover: howtoplay,
				description_1: "howtoplay.how_to_win.section_a.description_1",
				description_2: "howtoplay.how_to_win.section_a.description_2",
				description_3: "howtoplay.how_to_win.section_a.description_3",
				steps: [],
			},
			{
				btn_text: "howtoplay.how_to_win.section_b.btn_text",
				title_1: "howtoplay.how_to_win.section_b.title_1",
				title_2: "howtoplay.how_to_win.section_b.title_2",
				icon: faVideo,
				cover: cover_movies,
				description_1: "howtoplay.how_to_win.section_b.description_1",
				description_2: "howtoplay.how_to_win.section_b.description_2",
				description_3: "howtoplay.how_to_win.section_b.description_3",
				steps: [
					"howtoplay.how_to_win.section_b.steps.k1",
					"howtoplay.how_to_win.section_b.steps.k2",
					"howtoplay.how_to_win.section_b.steps.k3",
					"howtoplay.how_to_win.section_b.steps.k4",
					"howtoplay.how_to_win.section_b.steps.k5",
					"howtoplay.how_to_win.section_b.steps.k6",
					"howtoplay.how_to_win.section_b.steps.k7",
				],
			},
			{
				btn_text: "howtoplay.how_to_win.section_c.btn_text",
				title_1: "howtoplay.how_to_win.section_c.title_1",
				title_2: "howtoplay.how_to_win.section_c.title_2",
				icon: faPlay,
				cover: cover_shows,
				description_1: "howtoplay.how_to_win.section_c.description_1",
				description_2: "howtoplay.how_to_win.section_c.description_2",
				description_3: "howtoplay.how_to_win.section_c.description_3",
				steps: [
					"howtoplay.how_to_win.section_c.steps.k1",
					"howtoplay.how_to_win.section_c.steps.k2",
					"howtoplay.how_to_win.section_c.steps.k3",
					"howtoplay.how_to_win.section_c.steps.k4",
					"howtoplay.how_to_win.section_c.steps.k5",
					"howtoplay.how_to_win.section_c.steps.k6",
					"howtoplay.how_to_win.section_c.steps.k7",
				],
			},
			{
				btn_text: "howtoplay.how_to_win.section_d.btn_text",
				title_1: "howtoplay.how_to_win.section_d.title_1",
				title_2: "howtoplay.how_to_win.section_d.title_2",
				icon: faGamepad,
				cover: cover_games,
				description_1: "howtoplay.how_to_win.section_d.description_1",
				description_2: "howtoplay.how_to_win.section_d.description_2",
				description_3: "howtoplay.how_to_win.section_d.description_3",
				blockquote: "",
				steps: [
					"howtoplay.how_to_win.section_d.steps.k1",
					"howtoplay.how_to_win.section_d.steps.k2",
					"howtoplay.how_to_win.section_d.steps.k3",
					"howtoplay.how_to_win.section_d.steps.k4",
					"howtoplay.how_to_win.section_d.steps.k5",
					"howtoplay.how_to_win.section_d.steps.k6",
					"howtoplay.how_to_win.section_d.steps.k7",
				],
			},
		],
		options_judge: [
			{
				name: "Waiting for review",
				value: "review",
			},
			{
				name: "Winner",
				value: "first",
			},
			{
				name: "2nd Place",
				value: "second",
			},
			{
				name: "3th Place",
				value: "third",
			},
			{
				name: "Good luck next time",
				value: "next_time",
			},
			// {
			// 	name: "Completed",
			// 	value: "completed",
			// },
		],
		color_status: {
			first: "bg-green-300 border border-green-500 text-green-800",
			second: "bg-blue-300 border border-blue-500 text-blue-500",
			third: "bg-yellow-300 border border-yellow-500 text-yellow-700",
			next_time: "bg-red-300 border border-red-500 text-red-500",
			completed: "bg-purple-300 border border-purple-500 text-purple-500",
		},
		text_status: {
			first: "Congratulations, you are the winner",
			second: "Congrats, you have finished second",
			third: "Congrats, you have finished on third place",
			next_time: "Sorry, try next time.",
			completed: "Good job, you completed the challenge",
		},
		optionsSelect: [
			{
				name: "Games",
				value: "games",
			},
			{
				name: "Movies",
				value: "movies",
			},
			{
				name: "TV Shows",
				value: "shows",
			},
		]
	},
	reducers: {
		modal: (state, action) => {
			state.modal = action.payload;
		},
	},
});

export const { modal } = mainSlice.actions;
export default mainSlice.reducer;

import React from 'react'
import { t } from "i18next";
import parse from 'html-react-parser';
import { Link } from 'react-router-dom'

export const NewsCard = ({item}) => {
  return (
    <div
        className="w-full mr-3 shrink-0 border-t-8 border-yellow-400 shadow-xl p-5 pb-10 mb-10"
    >
        <img
            src={item.cover}
            className="mx-auto w-full h-72 object-cover"
            alt=""
        />
        <div className="flex justify-between items-start my-2">
        <span className="uppercase font-bold text-yellow-500">
            {item.created_at}
        </span>
        <span className="font-bold uppercase">
            {item.category}
        </span>
        </div>
        <h2 className="uppercase font-bold text-2xl font-['UACadet']">
            {item.title}
        </h2>
        <p className="text-sm mt-2 mb-10 h-24  overflow-hidden">
            {item.details ? parse(item.details) : ''}
        </p>
        <Link
            to={`news/${item.id}`}
            className="bg-yellow-500 font-bold py-2 px-4 mt-4 hover:text-yellow-800 active:scale-95"
        >
            {t("home.news.read_more")}
        </Link>
    </div>
  )
}



import UpdatesAndNews from "../news/UpdatesAndNews";
import { HowToPlay } from "./components/HowToPlay/";
import { MainBanner, BigPrize } from "./components";
import { EventExplorer } from "../../../components/EventExplorer";
import { UpComingEvents } from "../../../components/UpComingEvents";

export default function Home() {


  return (
    <>
      <MainBanner />
      <UpComingEvents />
      <EventExplorer />
      <HowToPlay  />
      <BigPrize />
      <UpdatesAndNews/>



      
      {/* <AvailableGames />
      <HowToPlay /> */}
      {/* <OurEvents /> */}
      {/* <Pricing /> */}
      {/* <UpcomingEvents /> */}
      {/* <GameLogos /> */}
      {/*<EventsLive />*/}
      {/* <RecentEvents /> */}

    </>
  );
}

import SimpleModal from "../../../components/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import AdminInput from "../../../components/admin/admin.input";
import AdminButton from "../../../components/admin/admin.button";
import { updateBanner } from '../../../store/actions/_profile.actions'
import { useState } from "react";

export default function ProfileBannerUploads(props) {
   const { status, closeModal, viewImage, setViewImage } = props;
   const r_profile = useSelector((state) => state.profile);
   const dispatch = useDispatch();


   const handleInputImageChange = (event) => {
      setViewImage(URL.createObjectURL(event.target.files[0]));
   }
   const handleSubmit = (event) => {
      event.preventDefault();
      let formData = new FormData();
      let imagefile = document.querySelector("#banner");
      formData.append("banner", imagefile.files[0] || '');
      dispatch(updateBanner(formData));
      closeModal();
   };

   return (
      <SimpleModal size="w-1/2" status={status} title="Update Banner" closer={closeModal}>
         <form className="w-full" action="" id="banner-user" onSubmit={handleSubmit}>
            { viewImage && <img src={viewImage} className="max-w-[150px] w-full" />}
            <AdminInput
               label="Banner (*)"
               className="w-full"
               type="file"
               id="banner"
               onChange={handleInputImageChange}
               placeholder="banner"
               errors={r_profile.error}
            />
            <div className="w-full lg:w-1/3 flex justify-center mx-auto">
               <AdminButton status={r_profile.btnLoading} text="Update" />
            </div>
         </form>
      </SimpleModal>
   );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AdminInput from "../../../components/admin/admin.input";
import Timezone from "../../../hooks/timezone";
import { styles } from "../../../utils/constanst";

export default function EventTime({ handleChange, data }) {
  const r_events = useSelector((state) => state.event);
  const [startDate, setStartDate] = useState("");
  const [startDateTime, setStartDateTime] = useState("");
  const [finishDate, setFinishDate] = useState("");
  const [finishDateTime, setFinishDateTime] = useState("");

  useEffect(() => {
    if (data) {
      if (data.start_date) {
        const localStartDate = Timezone({
          date: data.full_start_time,
          formatStr: "YYYY-MM-DD",
        });
        setStartDate(localStartDate);

        const localStartTime = Timezone({
          date: data.full_start_time,
          formatStr: "HH:mm",
        });
        console.log(data.full_start_time);
        setStartDateTime(localStartTime);

        const localEndDate = Timezone({
          date: data.full_end_time,
          formatStr: "YYYY-MM-DD",
        });
        setFinishDate(localEndDate);

        const localEndTime = Timezone({
          date: data.full_end_time,
          formatStr: "HH:mm",
        });
        setFinishDateTime(localEndTime);
      }
    }
  }, []);

  return (
    <fieldset className={`grid-cols-4 ${styles.fieldset}`}>
      <legend className={styles.legend}>Event Time</legend>
      <div className={`${styles.boxInfo} col-span-4`}>
        Select the time in your local time zone, each user will be able to see
        it in his or her time zone.
      </div>
      <AdminInput
        label="start date (*)"
        className="w-full"
        onChange={handleChange}
        type="date"
        id="start_date"
        // placeholder="Start Date"
        errors={r_events.errors}
        value={startDate}
      />
      <AdminInput
        label="start time"
        className="w-full"
        onChange={handleChange}
        type="time"
        id="start_time"
        // placeholder="Start Time"
        errors={r_events.errors}
        value={startDateTime}
      />
      <AdminInput
        label="end date (*)"
        className="w-full"
        onChange={handleChange}
        type="date"
        id="end_date"
        // placeholder="End Date"
        errors={r_events.errors}
        value={finishDate}
      />
      <AdminInput
        label="end time (*)"
        className="w-full"
        onChange={handleChange}
        type="time"
        id="end_time"
        // placeholder="End Time"
        errors={r_events.errors}
        value={finishDateTime}
      />
    </fieldset>
  );
}

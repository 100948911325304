import { NavLink, Routes, Route, Navigate } from "react-router-dom";
import About from "./about";
import Events from "./events";

export default function ProfileNavigation() {
  return (
    <div>
      <ul className="w-full flex justify-start items-center uppercase space-x-5 ml-[168px]">
        <li>
          <NavLink
            to={`about`}
            className={({ isActive }) =>
              isActive
                ? "font-bold border-b-2 border-yellow-500 text-yellow-500 pb-2"
                : "font-bold text-black"
            }
          >
            About me
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`events`}
            className={({ isActive }) =>
              isActive
                ? "font-bold border-b-2 border-yellow-500 text-yellow-500 pb-2"
                : "font-bold text-black"
            }
          >
            Events
          </NavLink>
        </li>
      </ul>
      <div className="w-full mt-10 p-5 shadow-md">
        <Routes>
					<Route path="about" element={<About />} />
					<Route index path="" element={<Navigate to="about" />} />
          <Route path="events" element={<Events />} />
        </Routes>
      </div>
    </div>
  );
}

import React from 'react'
import { BasicEventCard } from '../../../../../components/basicEventCard'

export const EventsFound = ({events = [],  sectionName="Post events"}) => {
  return (

    <div id="search-events" className='grid grid-cols-12 gap-4 my-5'>

      {
        events.length > 0 && (
          <div className='col-span-12 bg-white shadow-lg p-3'>
            <h2 className="uppercase font-bold font-['AldotheApache'] text-[24px] m-2 text-[#001D3D]">
              {sectionName}
            </h2>
            <div className='scrollbar custom-scroll  ml-[20px]'>
              {
                (events.length > 0) && (
                  <div className='grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 gap-4 m-auto'>
                    {
                      events.map((event, key) => {
                        return (
                          <BasicEventCard
                          className=""
                          data={event} 
                          key={key} 
                          categoryEvent='all'
                          />
                        )
                      })
                    }
                  </div>
                )
              }            
            </div>
          </div>
        )
      }

    </div>


  )
}

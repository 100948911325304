export default function AdminTextArea(props) {
  const { className, onChange, id, placeholder, errors, label, value, height, disabled } =
    props;
  const defaultValue = value || "";

  return (
    <div className={`relative ${className}`}>
      <label className="font-bold text-gray-500 capitalize" htmlFor={id}>
        {label}
      </label>
      <textarea
        defaultValue={defaultValue}
        name={id}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        className={`p-3 h-${height} w-full focus:outline-none border ${
          errors?.status &&
          Object.entries(errors.messages).length > 0 &&
          errors.messages[id]
            ? "border-red-600"
            : "border-gray-300"
        } text-black ${disabled ? 'bg-gray-200' : ''}`}
      ></textarea>
      {errors?.status &&
        Object.entries(errors.messages).length > 0 &&
        errors.messages[id] && (
          <span className="text-red-600">{errors.messages[id][0]}</span>
        )}
    </div>
  );
}

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../components/CustomModal";
import { getCardsAction } from "../../../store/actions/action.payments";
import Loading from "../../../components/Loading";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { cardElementOptions } from "../../../utils/constanst";
import { addEvent } from "../../../store/actions/_events.actions";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

export default function EventPayment({ status, closeModal, data, formData }) {
  // const dispatch = useDispatch();
  const r_payment = useSelector((state) => state.payment);

  // useEffect(() => {
  //   dispatch(getCardsAction());
  // }, [dispatch]);

  return (
    <CustomModal
      title="Event Payment"
      size="w-1/2"
      status={status}
      closedButton={closeModal}
    >
      {r_payment.loading ? (
        <Loading />
      ) : (
        <>
          <div className="bg-blue-100 text-blue-800 border border-blue-500 p-3 rounded-lg">
            Please complete the payment. ${data.total_to_pay}
          </div>
          {/* {r_payment.method && r_payment.method.length > 0 ? (
            <ul className="mt-5">
              {r_payment.method.map((item, key) => (
                <li
                  className="border-b hover:bg-gray-200 cursor-pointer p-5 flex items-center"
                  key={key}
                >
                  <input
                    className="w-4 h-4 mr-2"
                    id={`card${key}`}
                    type="radio"
                    name="card_selected"
                    value={item.id}
                  />
                  <label htmlFor={`card${key}`}>
                    {item.card.brand} {item.card.last4}
                  </label>
                </li>
              ))}
            </ul>
          ) : ( */}
            <Elements stripe={stripePromise}>
              <AddPayment formData={formData} />
            </Elements>
          {/* )} */}
        </>
      )}
    </CustomModal>
  );
}


function AddPayment({formData}) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
	const r_events = useSelector(state => state.event);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (elements == null) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (error) {
      console.log(error);
    } else {
			formData.append('paymentMethodId', paymentMethod.id)
			dispatch(addEvent(formData));
    }
  };


  return (
    <form onSubmit={handleSubmit} className="flex items-center flex-col">
			{r_events.errors.status && <div className="bg-red-100 text-red-500 border border-red-300 p-2 my-3">There are some errors in your form event creation, please fix and continue with payment</div>}
      <CardElement options={cardElementOptions} />
      <div className="w-1/2 mx-auto flex items-center justify-center">
        <button
          type="submit"
          disabled={!stripe || !elements}
          className="w-1/2 mx-auto bg-blue-500 text-white font-bold border-b-4 border-blue-700 p-2 mt-5 text-sm md:text-base active:scale-95"
        >
          Pay and Create
        </button>
      </div>
    </form>
  );
}
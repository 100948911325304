import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import BasicInput from "../../components/BasicInput";
import BasicCheckbox from "../../components/BasicCheckbox";
import BasicButton from "../../components/BasicButton";
import { registerUserAction } from "../../store/actions/_auth.actions";
import { button_loading } from '../../store/reducers/_auth.reducers';
import { ToastContainer } from "react-toastify";
import { toast } from 'react-toastify';
import { t } from "i18next";
import { useEffect } from "react";

export default function AuthRegister() {
   const [data, setData] = useState();
   const [isInvalidPartnerCode, setIsInvalidPartnerCode] = useState(false);
   const navigate = useNavigate();
   const dispatch = useDispatch({
      years_condition: false,
      receive_emails: false,
   });
	const r_auth = useSelector( state => state.auth );
	const errors = r_auth.errors
	
   const handleChange = (event) => {
      setData({
         ...data,
         [event.target.name]: event.target.value,
      });
   };

   const handleCheckbox = (event) => {
      setData({
         ...data,
         [event.target.name]: event.target.checked,
      });
   };

   useEffect(() => {

      if(data){
         if(data.ref_code){
            const testPartnerCode = /^[A-Za-z0-9]*$/.test(data.ref_code);
            if(!testPartnerCode){
               setIsInvalidPartnerCode(!testPartnerCode);
            }else{
               setIsInvalidPartnerCode(false);
            }
         }else{
            setIsInvalidPartnerCode(false);
         }
      }

   }, [data])
   

   const handleSubmit = (event) => {
      event.preventDefault();
      if(
         !data.name ||
         !data.lastname ||
         !data.email ||
         !data.username ||
         !data.password
      ){
         toast.error('Fill in the required fields', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
         });
         return;
      }

      if(isInvalidPartnerCode){
         toast.error('The partner code is invalid', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
         });
         return;
      }
      
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if(!data.email.match(mailformat))
      {
         toast.error('The email has an invalid format', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
         });
         return;
      }
		dispatch(button_loading(true))
      dispatch(registerUserAction(data));
   };


   useEffect(() => {
      if(r_auth.registered == true){
         navigate('/auth/login');
      }
    }, [r_auth.registered]);

   return (
      <>         
         <ToastContainer />
         <div className="w-full min-h-screen bg-cover bg-no-repeat bg-center bg-[url('../img/banners/bg-login-blur.png')] flex justify-center pt-10 md:p-0 md:items-center">
            
            <div className="w-11/12 lg:w-4/5 h-[800px] border-4 border-yellow-500 bg-blue-c1 flex">
               <div className="hidden border-r-2 border-yellow-500 h-full w-full p-10 md:flex flex-col justify-between items-start bg-cover bg-no-repeat bg-center bg-[url('../img/banners/cover-register.png')]">
                  <Link to="/" className="text-yellow-c1 text-sm md:text-base">
                     <FontAwesomeIcon icon={faChevronLeft} /> {t("register.go_home")}
                  </Link>
                  <img
                     className="w-14"
                     src={require("../../assets/img/logo-treasure/brand-logo.png")}
                     alt=""
                  />
               </div>

               <div className="border-l-1 border-yellow-500 h-full w-full p-5 flex flex-col pt-5 justify-between">
                  <div className="w-full">
                     <div className="flex flex-col justify-center items-center mb-3 md:mb-10">
                        <span className="font-bold text-white text-sm md:text-base">
                        {t("register.register")}
                        </span>
                        <span className="text-gray-600 text-xs md:text-sm">
                        {t("register.enter_details")}
                        </span>
                     </div>
                     <form
                        action=""
                        className="flex flex-col w-full md:w-11/12 mx-auto mt-1"
                        onSubmit={handleSubmit}
                     >
                        <div className="flex w-full mb-3">
                           <BasicInput
                              className="w-full mr-1"
                              onChange={handleChange}
                              type="text"
                              id="name"
                              name={t("register.name")}
                              errors={errors}
                           />
                           <BasicInput
                              className="w-full ml-1"
                              onChange={handleChange}
                              type="text"
                              id="lastname"
                              name={t("register.lastname")}
                              errors={errors}
                           />
                        </div>
                        <BasicInput
                           className="w-full mb-2"
                           onChange={handleChange}
                           type="text"
                           id="username"
                           name={t("register.username")}
                           errors={errors}
                        />
                        <BasicInput
                           className="w-full mb-2"
                           onChange={handleChange}
                           type="email"
                           id="email"
                           name={t("register.email")}
                           errors={errors}
                        />
                        <BasicInput
                           className="w-full mb-2"
                           onChange={handleChange}
                           type="password"
                           id="password"
                           name={t("register.password")}
                           errors={errors}
                        />
                        <BasicInput
                           className="w-full mb-2"
                           onChange={handleChange}
                           id="phone"
                           errors={errors}
                           mask="+(999) 9999-9999"
                           name={t("register.phone")}
                        />
                        <BasicInput
                           className="w-full "
                           onChange={handleChange}
                           type="text"
                           id="ref_code"
                           name={t("register.partner_code")}
                           errors={errors}
                        />
                        {
                           isInvalidPartnerCode && <>
                              <span className="text-[12px] text-red-700">Invalid parnert code.</span>
                              <br />
                           </>
                        }
                        <BasicCheckbox
                           className="flex mt-5 items-center"
                           onChange={handleCheckbox}
                           id="years_condition"
                           errors={errors}
                        >
                           {t("register.accepted")}{" "}
                           <span className="text-yellow-c1">
                           {t("register.terms")}
                           </span>
                        </BasicCheckbox>
                        <BasicCheckbox
                           className="flex mt-5 items-center"
                           onChange={handleCheckbox}
                           id="receive_emails"
                           errors={errors}
                        >
                           {t("register.receive")}{" "}
                           <span className="text-yellow-c1">
                           {t("register.terms")}
                           </span>
                        </BasicCheckbox>
                        
                        <BasicButton status={r_auth.button_loading} />
                     </form>
                  </div>
                  <div className="text-white text-center justify-self-end">
                  {t("register.account")}{" "}
                     <Link to="/auth/login" className="text-yellow-c1">
                     {t("register.login")}
                     </Link>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

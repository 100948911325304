import { t } from "i18next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";

export default function Header() {
  return (
    <section className="w-full min-h-[500px] bg-cover bg-no-repeat bg-[url('../img/banners/banner-01.png')] bg-center flex justify-center items-center">
      <div className="text-white uppercase font-bold flex flex-col">
        <h1 className="text-3xl lg:text-6xl aldo">
          <span className="text-yellow-c1">{t("howtoplay.breadcrumb.item_1")}</span>
        </h1>
      </div>
    </section>
  );
}

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdminInput from "../../components/admin/admin.input";
// import AdminTextArea from "../../components/admin/admin.textarea";
import AdminButton from "../../components/admin/admin.button";
import AdminCancel from "../../components/admin/admin.cancel";
import AdminSelect from "../../components/admin/admin.select";
import { updateNews, getNews } from "../../store/actions/_news.actions";
import { Navigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function Update() {
  const dispatch = useDispatch();
  const r_news = useSelector((state) => state.news);
  const r_main = useSelector((state) => state.main);
  const [data, setData] = useState(undefined);
  const { new_id } = useParams();

  useEffect(() => {
    if (!r_news.news_loaded) {
      dispatch(getNews());
    }
  }, [dispatch, r_news.news_loaded]);

  useEffect(() => {
    if (r_news.news_loaded && r_news.news.data.length > 0) {
      // eslint-disable-next-line eqeqeq
      let filter = r_news.news.data.filter((item) => item.id == new_id);
      if (filter.length > 0) {
        setData(filter[0]);
      }
    }
  }, [r_news.news_loaded, r_news.news.data, new_id]);

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const ckeditorValue = (value) => {
    setData({
      ...data,
      details: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData();
    let imagefile_cover = document.querySelector("#cover");
    formData.append("title", data.title);
    formData.append("cover", imagefile_cover.files[0] || data.cover);
    formData.append("category", data.category);
    formData.append("details", data.details);
    dispatch(updateNews(formData, new_id));
  };

  return (
    <>
      {r_news.redirect && <Navigate to="/settings/news" />}
      {data && (
        <form
          action=""
          id="register-new"
          onSubmit={handleSubmit}
          className="w-8/12 mx-auto mt-20 form shadow-2xl"
        >
          <fieldset>
            <legend>Update New / Information</legend>
            <div className="">
              <AdminInput
                label="title (*)"
                className="w-full mb-5"
                onChange={handleChange}
                type="text"
                id="title"
                placeholder="Title"
                errors={r_news.error}
                value={data.title}
              />
              <div className="flex justify-between items-start mb-5">
                <img
                  src={data.cover}
                  className="w-64 h-64 object-cover border mx-2"
                  alt=""
                />
                <AdminInput
                  label="Cover (*)"
                  className="w-full mx-2"
                  onChange={handleChange}
                  type="file"
                  id="cover"
                  placeholder="cover"
                  errors={r_news.error}
                />
              </div>
              <AdminSelect
                label="Category"
                className="w-full mb-5"
                onChange={handleChange}
                options={r_main.optionsSelect}
                id="category"
                placeholder="Select category"
                errors={r_news.errors}
                value={data.category}
              />

              {/* <AdminTextArea
                        label="details"
                        className="w-full col-span-4"
                        onChange={handleChange}
                        id="details"
                        placeholder="Details"
                        errors={r_news.error}
                        value={data.details}
                     /> */}
              <div className="no-tailwindcss-base">
                <CKEditor
                  id="ckeditor"
                  editor={ClassicEditor}
                  config={{
                    toolbar: {
                      items: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "|",
                        "outdent",
                        "indent",
                        "|",
                        "blockQuote",
                        "insertTable",
                        "mediaEmbed",
                        "undo",
                        "redo",
                      ],
                    },
                    table: {
                      contentToolbar: [
                        "tableColumn",
                        "tableRow",
                        "mergeTableCells",
                      ],
                    },
                  }}
                  data={data.details}
                  // onReady={(editor) => {
                  //   // You can store the "editor" and use when it is needed.
                  //   console.log("Editor is ready to use!", editor);
                  // }}
                  onChange={(event, editor) => {
                    ckeditorValue(editor.getData());
                  }}
                  // onBlur={(event, editor) => {
                  //   console.log("Blur.", editor);
                  // }}
                  // onFocus={(event, editor) => {
                  //   console.log("Focus.", editor);
                  // }}
                />
              </div>
            </div>
            <div className="mt-5 flex justify-between w-1/2 float-right">
              <AdminCancel text="Back" to="/settings/news" />
              <AdminButton status={r_news.btnLoading} text="Submit" />
            </div>
          </fieldset>
        </form>
      )}
    </>
  );
}

import { useSelector } from "react-redux";
import AdminInput from "../../../components/admin/admin.input";
import { styles } from "../../../utils/constanst";

export default function EventCapture({ handleChange, showImages, handleFile }) {
  const r_events = useSelector((state) => state.event);

  return (
    <fieldset className={`grid-cols-2 ${styles.fieldset}`}>
      <legend className={styles.legend}>Event Captures</legend>
      <div className={`${styles.boxInfo} col-span-2`}>
        We recommend images dimensions 1000x1000
        <p>
          Description Proofs are to provide a brief description about the
          captures the user should upload.
        </p>
      </div>
      
      <div>
        <img
          id="show_image_1"
          alt="Cover image"
          className={`w-full ${showImages.show_image_1 ? "block max-w-[200px]" : "hidden"}`}
        />
        <AdminInput
          label="Cover (*)"
          className="w-full"
          onChange={handleFile}
          type="file"
          id="cover"
          dataShow="show_image_1"
          // placeholder="cover"
          errors={r_events.errors}
        />
      </div>
      
      <div>
        <img
          id="show_image_2"
          alt="Screenshot image"
          className={`w-full ${showImages.show_image_2 ? "block max-w-[200px]" : "hidden"}`}
        />
        <AdminInput
          label="screenshot (*)"
          className="w-full"
          onChange={handleFile}
          dataShow="show_image_2"
          type="file"
          id="screenshot"
          // placeholder="screenshot"
          errors={r_events.errors}
        />
      </div>
      <div>
        <AdminInput
          label="description proof #1"
          className="w-full"
          onChange={handleChange}
          type="text"
          id="description_proof_1"
          // placeholder="Title"
          errors={r_events.errors}
        />
        <p className="text-[#707070] text-[14px]">
          This is the screenshot reference we are looking for: Tell the participant here what he/she is looking for and show it in an environment where he/she can get it.
        </p>
      </div>
      <div>        
        <AdminInput
          label="description proof #2"
          className="w-full"
          onChange={handleChange}
          type="text"
          id="description_proof_2"
          // placeholder="Title"
          errors={r_events.errors}
        />
        <p className="text-[#707070] text-[14px]">
          this is the capture of the environment, map or specific time where the treasure is located: Indicate to the user that you need to be specific by showing coordinates, playback times, etc.
        </p>
      </div>
    </fieldset>
  );
}

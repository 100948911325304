import { Route, Routes } from "react-router-dom";
import Users from '../users/users'
import Events from '../events/events'
import News from '../news/news'
import Roles from '../roles/roles'
import WdMethods from '../withdrawal-methods/wdmethods';
import PaymentRequest from "../payment-request/payment-request";

export default function SettingsSidebar() {
	return (
		<Routes>
		</Routes>
	)
}
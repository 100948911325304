import { createSlice } from "@reduxjs/toolkit";

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    methods: [],
    eventToPay: {
      event: null,
      isPaid: false
    },
    loading: false,
  },
  reducers: {
    methods: (state, action) => {
      state.method = action.payload;
    },
    loading: (state, action) => {
      state.loading = action.payload;
    },
    setEventToPay: (state, {payload}) => {
      state.eventToPay.event = payload;
      state.eventToPay.isPaid = false;
    },
    setEventStatusPay: (state, {payload}) => {
      state.eventToPay.isPaid = true;
    },
  },
});

export const { methods, loading, setEventToPay, setEventStatusPay } = paymentSlice.actions;
export default paymentSlice.reducer;

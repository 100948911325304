import { useEffect } from 'react';
import { useState } from 'react';
import { config } from "react-spring";
import Carousel from 'react-spring-3d-carousel';
import arrowBack from '../../assets/img/icons/arrow-back.png';
import arrowNext from '../../assets/img/icons/arrow-next.png';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import './styles.scss'

export const CustomCarousel = ({slides}) => {

  const [goToSlide, setGoToSlide] = useState(0);
  const {width} = useWindowDimensions();
  const onChangeSlide = (btnType) => {
    const slideIndex = btnType == 'next' ? goToSlide + 1 : goToSlide - 1;

    if(slideIndex >= 0 && slideIndex < slides.length){
      setGoToSlide(slideIndex);
      return
    }

    if(slideIndex < 0){
      setGoToSlide(slideIndex);
      return;
    }

    if(slideIndex >= slides.length){
      setGoToSlide(0);
      return;
    }
  }


  return (
    <div id="customCarousel">
        <Carousel
            slides={slides}
            goToSlide={goToSlide}
            offsetRadius={ width <= 1024 ? 2 : 3}
            animationConfig={config.slow}
            showNavigation={false}
        />
        <button className='btn-carousel-arrow back-btn' name="back" onClick={(e)=>{onChangeSlide('back')}}>
          <img src={arrowBack} alt="ITHL Back" />
        </button>
        <button className='btn-carousel-arrow next-btn' name="next" onClick={(e)=>{onChangeSlide('next')}}>
          <img src={arrowNext} alt="ITHL Next" />
        </button>
    </div>

  )
}
import { useSelector } from "react-redux";
import AdminInput from "../../../components/admin/admin.input";
import { styles } from "../../../utils/constanst";

export default function EventTime( { handleChange } ) {
	const r_events = useSelector((state) => state.event);

  return (
    <fieldset className={`grid-cols-4 ${styles.fieldset}`}>
      <legend className={styles.legend}>Event Time</legend>
      <div className={`${styles.boxInfo} col-span-4`}>
        Select the time in your local time zone, each user will be able to see it in his or her time zone.
      </div>
      <AdminInput
        label="start date (*)"
        className="w-full"
        onChange={handleChange}
        type="date"
        id="start_date"
        // placeholder="Start Date"
        errors={r_events.errors}
      />
      <AdminInput
        label="start time"
        className="w-full"
        onChange={handleChange}
        type="time"
        id="start_time"
        // placeholder="Start Time"
        errors={r_events.errors}
      />
      <AdminInput
        label="end date (*)"
        className="w-full"
        onChange={handleChange}
        type="date"
        id="end_date"
        // placeholder="End Date"
        errors={r_events.errors}
      />
      <AdminInput
        label="end time (*)"
        className="w-full"
        onChange={handleChange}
        type="time"
        id="end_time"
        // placeholder="End Time"
        errors={r_events.errors}
      />
    </fieldset>
  );
}

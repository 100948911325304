import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Navigate } from "react-router-dom";
import AdminInput from "../../components/admin/admin.input";
import AdminTextArea from "../../components/admin/admin.textarea";
import AdminButton from "../../components/admin/admin.button";
import AdminCancel from "../../components/admin/admin.cancel";
import AdminAlert from "../../components/admin/admin.alert";
// import AdminSelect from "../../components/admin/admin.select";
// import Modal from "../../components/Modal";
import { deleteEvent, getEvents } from "../../store/actions/_events.actions";
import { resetAlert } from "../../store/reducers/_events.reducers";
// import { modal } from "../../services/_main.reducers";
import { styles } from "../../utils/constanst";

export default function EventDelete() {
  const dispatch = useDispatch();
  const r_events = useSelector((state) => state.event);
  // const r_main = useSelector((state) => state.main);
  const [data, setData] = useState(false);
  let { event_id } = useParams();
  // const [selected, setSelected] = useState();

  useEffect(() => {
    if (!r_events.loaded) {
      dispatch(getEvents());
    }
  }, [dispatch, r_events.loaded]);

  useEffect(() => {
    if (r_events.loaded && r_events.events.draft && r_events.events.draft.length > 0) {
      let data = r_events.events.draft.filter(
        // eslint-disable-next-line eqeqeq
        (item) => item.event_uuid == event_id
      );
      setData(data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [r_events.loaded, r_events.events.length]);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(deleteEvent(event_id));
  };

  // const handlePreview = (img) => {
  //   setSelected(img);
  //   dispatch(modal(true));
  // };

  return (
    <>
      {r_events.alert.status && <Navigate to="/settings/users/profile/my-events/created/draft" />}
      {data && (
        <>
          <form
            action=""
            id="register-user"
            onSubmit={handleSubmit}
            className="w-full mt-5 mb-10 pb-32 form-event shadow-2xl"
          >
            <AdminAlert
              message={r_events.alert.message}
              status={r_events.alert.status}
              style={r_events.alert.style}
              close={() => dispatch(resetAlert())}
            />

            <fieldset className={`grid-cols-4  ${styles.fieldset}`}>
              <legend className={styles.legend}>Event Information</legend>
              <div className={`${styles.boxDelete} col-span-4`}>
                Are you sure to delete this event?
              </div>
              <AdminInput
                label="title (*)"
                className="w-full col-span-4"
                type="text"
                id="title"
                //   placeholder="Title"
                errors={r_events.errors}
								disabled={true}
                value={data.title}
              />
              <AdminInput
                label="start date (*)"
                className="w-full"
                type="date"
                id="start_date"
                //   placeholder="Start Date"
                errors={r_events.errors}
								disabled={true}
                value={data.start_date}
              />
              <AdminInput
                label="start time"
                className="w-full"
                type="time"
                id="start_time"
                // placeholder="Start Time"
                errors={r_events.errors}
								disabled={true}
                value={data.start_time}
              />
              <AdminInput
                label="end date (*)"
                className="w-full"
                type="date"
                id="end_date"
                //   placeholder="End Date"
                errors={r_events.errors}
								disabled={true}
                value={data.end_date}
              />
              <AdminInput
                label="end time (*)"
                className="w-full"
                type="time"
                id="end_time"
                // placeholder="End Time"
                errors={r_events.errors}
								disabled={true}
                value={data.end_time}
              />
              <div>
                <AdminTextArea
                  label="brief description (*)"
                  className="w-full col-span-4"
                  height={24}
                  id="brief_description"
                  //   placeholder="Brief Description"
                  errors={r_events.errors}
                  disabled={true}
                  value={data.brief_description}
                />
                <p className="text-[#707070]">
                  These instructions will be released when the event is unblocked. Be specific, add as many details on what we are looking for, what is the treasure, and any other specifics that help the player find the right treasure.   
                </p>
              </div>
              {/* <AdminInput
                label="platform"
                className="w-full"
                type="text"
                id="platform"
                //   placeholder="Platform"
                errors={r_events.errors}
                value={data.platform}
              />
              <AdminSelect
                label="type of event"
                className="w-full"
                options={optionsSelect}
                id="type_of_event"
                //   placeholder="Type of event"
                errors={r_events.errors}
                value={data.type_of_event}
              />
              <AdminInput
                label="requirements"
                className="w-full"
                type="text"
                id="requirements"
                //   placeholder="Requirements"
                errors={r_events.errors}
                value={data.requirements}
              /> */}
              {/* <div className={styles.checkboxGroup}>
                <input
                  type="checkbox"
                  id="big_prize"
                  name="big_prize"
                  className={styles.checkbox}
                  checked={data.big_prize ? true : false}
                />
                <label htmlFor="big_prize" className={styles.checkboxLabel}>
                  Is it a Big Prize?
                </label>
              </div>
              <div className={styles.checkboxGroup}>
                <input
                  type="checkbox"
                  id="is_free_event"
                  name="is_free_event"
                  className={styles.checkbox}
                  checked={data.is_free_event ? true : false}
                />
                <label htmlFor="is_free_event" className={styles.checkboxLabel}>
                  Is it a Free Event?
                </label>
              </div> */}
            </fieldset>

            {/* <fieldset className={`grid-cols-4 ${styles.fieldset}`}>
              <legend className={styles.legend}>Event Time</legend>
              <div className={`${styles.boxInfo} col-span-4`}>
                Time will be expressed in EST (Eastern Standard Time (EST), UTC
                -5)
              </div>
              <AdminInput
                label="start date (*)"
                className="w-full"
                type="date"
                id="start_date"
                //   placeholder="Start Date"
                errors={r_events.errors}
                value={data.start_date}
              />
              <AdminInput
                label="start time"
                className="w-full"
                type="time"
                id="start_time"
                // placeholder="Start Time"
                errors={r_events.errors}
                value={data.start_time}
              />
              <AdminInput
                label="end date (*)"
                className="w-full"
                type="date"
                id="end_date"
                //   placeholder="End Date"
                errors={r_events.errors}
                value={data.end_date}
              />
              <AdminInput
                label="end time (*)"
                className="w-full"
                type="time"
                id="end_time"
                // placeholder="End Time"
                errors={r_events.errors}
                value={data.end_time}
              />
            </fieldset> */}

            {/* <fieldset className={`grid-cols-2 ${styles.fieldset}`}>
              <legend className={styles.legend}>Event Captures</legend>
              <div className={`${styles.boxInfo} col-span-2`}>
                We recommend images dimensions 1000x1000
                <p>
                  Description Proofs are to provide a brief description about
                  the captures the user should upload.
                </p>
              </div>
              <div className="w-full flex items-end">
                <img
                  src={data.cover}
                  alt=""
                  className="w-14 h-14 object-cover mr-2 cursor-pointer"
                  onClick={() => handlePreview(data.cover)}
                />
                <AdminInput
                  label="Cover (*)"
                  className="w-full"

                  type="file"
                  id="cover"
                  //  placeholder="cover"
                  errors={r_events.errors}
                />
              </div>
              <div className="w-full flex items-end">
                <img
                  src={data.screenshot}
                  alt=""
                  className="w-14 h-14 object-cover mr-2 cursor-pointer"
                  onClick={() => handlePreview(data.screenshot)}
                />

                <AdminInput
                  label="screenshot (*)"
                  className="w-full"

                  type="file"
                  id="screenshot"
                  //  placeholder="screenshot"
                  errors={r_events.errors}
                />
              </div>
              <AdminInput
                label="description proof #1"
                className="w-full"
                type="text"
                id="description_proof_1"
                // placeholder="Title"
                errors={r_events.errors}
              />
              <AdminInput
                label="description proof #2"
                className="w-full"
                type="text"
                id="description_proof_2"
                // placeholder="Title"
                errors={r_events.errors}
              />
            </fieldset> */}

            {/* <fieldset className={`grid-cols-3 ${styles.fieldset}`}>
              <legend className={styles.legend}>Event Awards</legend>
              <div className={`${styles.boxInfo} col-span-3`}>
                First place rewards must be express in USD.
              </div>
              <AdminInput
                label="first place reward (*)"
                className="w-full"
                type="text"
                id="first_place_reward"
                //   placeholder="First Place Reward"
                errors={r_events.errors}
                value={data.first_place_reward}
              />
              <AdminInput
                label="first place extra points"
                className="w-full"
                type="text"
                id="first_place_extra_points"
                //   placeholder="First Place Extra Points"
                errors={r_events.errors}
              />
              <AdminInput
                label="second place reward"
                className="w-full"
                type="text"
                id="second_place_reward"
                //   placeholder="Second Place Reward"
                errors={r_events.errors}
                value={data.second_place_reward}
              />
              <AdminInput
                label="third place reward"
                className="w-full"
                type="text"
                id="third_place_reward"
                //   placeholder="Third Place Reward"
                errors={r_events.errors}
                value={data.third_place_reward}
              />
              <AdminInput
                label="max points seasson"
                className="w-full"
                type="number"
                id="max_points_seasson"
                //   placeholder="Max Points Seasson"
                errors={r_events.errors}
                value={data.max_points_seasson}
              />
            </fieldset> */}

            {/* <fieldset className={`grid-cols-1 ${styles.fieldset}`}>
              <legend className={styles.legend}>Event Details</legend>
              <div className={`${styles.boxInfo}`}>
                Brief description is just 255 characters
              </div>
              <AdminTextArea
                label="brief description (*)"
                className="w-full"
                height={24}
                id="brief_description"
                //   placeholder="Brief Description"
                errors={r_events.errors}
                value={data.brief_description}
              />
              <AdminTextArea
                label="details"
                height={64}
                className="w-full"
                id="details"
                //   placeholder="Details"
                errors={r_events.errors}
                value={data.details}
              />
            </fieldset> */}

            <div className="mt-5 flex justify-between w-1/2 float-right">
              <AdminCancel text="Back" to="/settings/users/profile/my-events/created/draft" />
              <AdminButton status={r_events.btnLoading} text="Confirm delete" />
            </div>
          </form>
          {/* {r_main.modal && (
            <Modal title="Image Preview" size="w-1/2">
              <img src={selected} alt="" />
            </Modal>
          )} */}
        </>
      )}
    </>
  );
}

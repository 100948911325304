export default function Map() {
  return (
    <div className="w-full lg:w-1/2">
      <iframe
        title="RithmXO Location"
        className="w-full h-[250px] lg:h-[400px]"
        style={{ border: "2px" }}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        src={`${process.env.REACT_APP_GOOGLE_API}place_id:Eig1MDAgUyA1MDAgVyBzdGUgMSwgTGluZG9uLCBVVCA4NDA0MiwgVVNBIiEaHwoWChQKEgkvnA7x74RNhxF3J-u0KNQIGBIFc3RlIDE`}
      ></iframe>
    </div>
  );
}

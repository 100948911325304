import api from "../../utils/api";
import { setAssets, users, errors, alert, scores, loadingScores, setIsDisableShowMoreScore, creatorsTop, loadingCustomUserData, customUserData } from "../reducers/_users.reducers";

export const getUsers = () => (dispatch) => {
   dispatch(
      setAssets({
         loaded: false,
         btnLoading: false,
         loading: true,
      })
   );
   api.get("/users")
      .then((response) => dispatch(users(response.data)))
      .catch((error) => console.log(error.response.data))
      .then(() => {
         dispatch(
            setAssets({
               loaded: true,
               btnLoading: false,
               loading: false,
            })
         );
      });
};

export const addUser = (data) => (dispatch) => {
   dispatch(
      setAssets({
         loaded: false,
         btnLoading: true,
         loading: false,
      })
   );
   api.post("/users/add-user", data)
      .then((response) => {
         dispatch(
            alert({
               status: true,
               message: "Success",
               style: "green",
            })
         );
      })
      .catch((error) =>
         dispatch(
            errors({
               status: true,
               message: error.response.data.message,
               messages: error.response.data.errors,
            })
         )
      )
      .then(() =>
         dispatch(
            setAssets({
               loaded: false,
               btnLoading: false,
               loading: false,
            })
         )
      );
};

export const getScores = (page = "", items = []) => async dispatch => {
   dispatch(loadingScores(true));
   try {
      const response = await api.get(`/scores?page=${page}`);
      if(response.data.length > 0){
         let mergeItems = [...items, ...response.data];
         // console.log(items);
         // console.log(response.data);
         dispatch(scores(mergeItems));
      }

      if(response.data.length < 1){
         dispatch(setIsDisableShowMoreScore(true));
      }
      
   } catch (error) {
      console.log(error)
   } finally {
      dispatch(loadingScores(false));
   }
}



export const getCreatorUsers = (page = "", items = [], order="desc") => async dispatch => {
   dispatch(loadingScores(true));
   try {
      const response = await api.get(`/get-creators?page=${page}&order=${order}`);
      if(response.data.length > 0){
         let mergeItems = [...items, ...response.data];
         dispatch(creatorsTop(mergeItems));
      }

      if(response.data.length < 1){
         dispatch(setIsDisableShowMoreScore(true));
      }
      
   } catch (error) {
   } finally {
      dispatch(loadingScores(false));
   }
}


export const getUserBasicData = (uuid) => async dispatch => {
   dispatch(loadingCustomUserData(true));
   try {
      const response = await api.get(`get-user-basic-data/${uuid}`);
      dispatch(customUserData(response.data));

   } catch (error) {
   } finally {
      dispatch(loadingCustomUserData(false));
   }
}
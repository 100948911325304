import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function Modal(props) {
   const { title, size, status, closer } = props;

   return ReactDOM.createPortal(
      status && (
         <>
            <div className="bg-black fixed top-0 left-0 w-full h-full z-20 opacity-50"></div>
            <div className="flex absolute top-0 left-0 w-full h-full justify-center items-start pt-[1%] overflow-hidden">
               <div className={`bg-white ${size ? size : 'w-1/2'} fixed h-min-[200px] z-30  animate__animated animate__fadeIn animate__faster lg:rounded-lg shadow-2xl`}>
                  {title && <div className="flex justify-between p-3 border-b border-b-gray-100 bg-gray-50">
                     <h1 className="font-bold">{title}</h1>
                     <FontAwesomeIcon
                        icon={faTimes}
                        className="text-2xl hover:scale-110 active:scale-90 cursor-pointer"
								onClick={closer}
                     />
                  </div>}
                  <div className="p-10 h-[500px] lg:h-auto overflow-y-auto">{props.children}</div>
               </div>
            </div>
         </>
      ),
      document.getElementById("modal")
   );
}

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminInput from "../../components/admin/admin.input";
import AdminButton from "../../components/admin/admin.button";
import AdminCancel from "../../components/admin/admin.cancel";
import AdminAlert from "../../components/admin/admin.alert";
import { addUser } from "../../store/actions/_users.actions";
import { alert } from "../../store/reducers/_users.reducers";

export default function UsersCreate() {
   const dispatch = useDispatch();
   const r_users = useSelector((state) => state.user);
   const [data, setData] = useState({
      role_id: 2,
   });

   const handleChange = (event) => {
      setData({
         ...data,
         [event.target.name]: event.target.value,
      });
   };

   const handleSubmit = (event) => {
      event.preventDefault();
      dispatch(addUser(data));
   };

   useEffect(() => {
      document.getElementById("register-user").reset();
   }, [r_users.alert.status]);

   return (
      <form
         action=""
         id="register-user"
         onSubmit={handleSubmit}
         className="w-1/3 mt-20 form"
      >
         <AdminAlert
            message={r_users.alert.message}
            status={r_users.alert.status}
            style={r_users.alert.style}
            close={() =>
               dispatch(
                  alert({
                     status: false,
                     message: "",
                     style: "green",
                  })
               )
            }
         />
         <fieldset>
            <legend>Add new kickstarter user</legend>
            <AdminInput
               label="name"
               className="w-full mb-5"
               onChange={handleChange}
               type="text"
               id="name"
               placeholder="Name"
               errors={r_users.errors}
            />
            <AdminInput
               label="lastname"
               className="w-full mb-5"
               onChange={handleChange}
               type="text"
               id="lastname"
               placeholder="Lastname"
               errors={r_users.errors}
            />
            <AdminInput
               label="email"
               className="w-full"
               onChange={handleChange}
               type="email"
               id="email"
               placeholder="Email"
               errors={r_users.errors}
            />
            <div className="mt-5 flex justify-between">
               <AdminCancel text="Cancel" to="/settings/users" />
               <AdminButton status={r_users.btnLoading} text="Submit" />
            </div>
         </fieldset>
      </form>
   );
}


import { EventTab } from "./EventTab";

import userTabImage from '../../../assets/img/icons/user-events-new.png';
import liveTabImage from '../../../assets/img/icons/l-events.png';
import ourTabImage from '../../../assets/img/icons/our-events.png';
import communityTabImage from '../../../assets/img/icons/community-events.png';

import userTabImageActive from '../../../assets/img/icons/user-events-active.png';
import liveTabImageActive from '../../../assets/img/icons/l-events-active.png';
import ourTabImageActive from '../../../assets/img/icons/our-events-active.png';
import communityTabImageActive from '../../../assets/img/icons/community-events-active.png';
import { t } from "i18next";
import { useEffect } from "react";

export const ContainerTabs = ({ handleTab }) => {
  const { categoryEvent, setCategoryEvent } = handleTab;

  useEffect(() => {
    console.log(categoryEvent);
  }, [categoryEvent])
  

  const tabEvents = [
    {
      name: t("event_explorer.live_events.title"),
      img: liveTabImage,
      imgActive: liveTabImageActive,
      slug: 'live_events'
    },
    {
      name: t("event_explorer.our_events.title"),
      img: ourTabImage,
      imgActive: ourTabImageActive,
      slug: 'our_events'
    },
    {
      name: t("event_explorer.community_events.title"),
      img: communityTabImage,
      imgActive: communityTabImageActive,
      slug: 'community_events'
    },
    // {
    //   name: 'User events',
    //   img: userTabImage,
    //   imgActive: userTabImageActive,
    //   notify: true,
    //   textNotify: "Free",
    //   slug: 'user_events'
    // }
  ];

  return (
    <div className='flex w-full'>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-5 w-full mx-2">
        {
          tabEvents.map((tab, key) => {
            return (
              categoryEvent === tab.slug
                ? (<EventTab props={{ tab, setCategoryEvent }} key={key} active={true} />)
                : (<EventTab props={{ tab, setCategoryEvent }} key={key} />)
            )
          })
        }
      </div>
    </div>
  )

}
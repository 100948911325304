import { Route, Routes } from "react-router-dom";
import Users from '../users/users'
import Events from '../events/events'
import News from '../news/news'
import Roles from '../roles/roles'
import WdMethods from '../withdrawal-methods/wdmethods';
import PaymentRequest from "../payment-request/payment-request";
import SettingsSidebar from "./SettingsSidebar";

export default function Settings() {
	return (
		<Routes>
			<Route path="users/*" element={<Users />} />
			<Route path="events/*" element={<Events />} />
			<Route path="news/*" element={<News />} />
			<Route path="roles/*" element={<Roles />} />
			<Route path="/*" element={<SettingsSidebar />} />
			<Route path="withdrawal-methods/*" element={<WdMethods />} />
			<Route path="payment-request/*" element={<PaymentRequest />} />
		</Routes>
	)
}
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading";
import BulletLink from "../../../components/BulletLink";
import { getMyEvents } from "../../../store/actions/_events.actions";

export default function MyEvents() {
   const dispatch = useDispatch();
   const r_events = useSelector((state) => state.event);

   useEffect(() => {
      dispatch(getMyEvents());
   }, [dispatch]);

   return (
      <div className="w-11/12 mx-auto mt-10">
         {r_events.loading && <Loading />}
         {!r_events.loading && r_events.my_events && (
            <>
               {r_events.my_events.length > 0 ? (
                  r_events.my_events.map((event, key) => (
                     <div key={key} className="shadow-md w-full p-5 border-2">
                        <div className="flex justify-between items-center mb-5">
                           <div>
                              <div className="text-yellow-500 font-bold">
                                 {event.start_date}
                              </div>
                              <div className="font-bold uppercase text-xl">
                                 {event.title}
                              </div>
                           </div>
                           <div className="font-bold text-yellow-400 transform-skew-20 bg-blue-c2 py-2 px-5">
                              ${event.first_place_reward}
                           </div>
                        </div>
                        <div className="flex items-start">
                           <div className="w-1/3">
                              <img src={event.cover} alt="" />
                           </div>
                           <div className="w-2/3 ml-10">
                              {/* <div className="flex border-b border-gray-200 py-3">
                                 <div className="font-bold mr-3">
                                    Max Points Seasson:
                                 </div>
                                 <div>{event.max_points_seasson}</div>
                              </div> */}
                              <div className="flex border-b border-gray-200 py-3">
                                 <div className="font-bold mr-3">Platform:</div>
                                 <div>{event.platform}</div>
                              </div>
                              <div className="flex border-b border-gray-200 py-3">
                                 <div className="font-bold mr-3">
                                    Type Of Event:
                                 </div>
                                 <div>{event.type_of_event}</div>
                              </div>
                              <div className="flex border-b border-gray-200 py-3">
                                 <div className="font-bold mr-3">
                                    Requirements:
                                 </div>
                                 <div>{event.requirements}</div>
                              </div>
                              <div className="flex w-1/2 mt-10 float-right">
                                 <BulletLink
                                    text="View Details"
                                    className="w-full lg:w-1/2 mb-3 lg:mb-0 mx-2"
                                    to={`/event-detail/${event.event_uuid}`}
                                    color="bg-gradient-to-b from-gray-200 to-gray-400"
                                    padding="py-2 px-3"
                                    iconSize="text-2xl"
                                 />
                                 <BulletLink
                                    text="Upload results"
                                    className="w-full lg:w-1/2 mx-2"
                                    to={`/event-detail/${event.event_uuid}/results`}
                                    color="bg-gradient-to-b from-yellow-300 to-yellow-500"
                                    padding="py-2 px-3"
                                    iconSize="text-2xl"
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  ))
               ) : (
                  <div>No Events</div>
               )}
            </>
         )}
      </div>
   );
}

import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
	name: 'user',
	initialState: {
		users: [],
		loaded: false,
		btnLoading: false,
		loading: false,
		errors: {
			status: false,
			message: '',
			messages: []
		},
		alert: {
			status: false,
			message: "Fake message",
			style: "green"
		},
		scores: [],
		loading_scores: false,
		creatorsTop: [],
		loading_creatorsTop: false,

		loading_customUserData: false,
		customUserData: null,
		
		isDisableShowMoreScore: false,
	},
	reducers: {
		setIsDisableShowMoreScore: (state, action) => {
			state.isDisableShowMoreScore = action.payload;
		},
		setAssets: (state, action) => {
			state.loaded = action.payload.loaded;
			state.btnLoading = action.payload.btnLoading;
			state.loading = action.payload.loading;
		},
		users: (state, action) => {
			state.users = action.payload
		},
		errors: (state, action) => {
			state.errors.status = action.payload.status
			state.errors.message = action.payload.message
			state.errors.messages = action.payload.messages
		},
		alert: (state, action) => {
			state.alert.status = action.payload.status
			state.alert.message = action.payload.message
			state.alert.style = action.payload.style
		},
		resetAll: (state) => {
			state.errors.status = false
			state.errors.message = ''
			state.errors.messages = []
			state.alert.status = false
			state.alert.message = ''
			state.alert.style = 'green'
			state.btnLoading = false
		},
		scores: (state, action) => {
			state.scores = action.payload
		},
		loadingScores: (state, action) => {
			state.loading_scores = action.payload;
		},
		creatorsTop: (state, action) => {
			state.creatorsTop = action.payload
		},
		loadingCreatorsTop: (state, action) => {
			state.loading_creatorsTop = action.payload;
		},
		customUserData: (state, action) => {
			state.customUserData = action.payload
		},
		loadingCustomUserData: (state, action) => {
			state.loading_customUserData = action.payload;
		}
	}
})

export const {customUserData, loadingCustomUserData, creatorsTop, loadingCreatorsTop, setAssets,users,errors,alert,resetAll, scores, loadingScores, setIsDisableShowMoreScore} = userSlice.actions;
export default userSlice.reducer;
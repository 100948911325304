import { createSlice } from "@reduxjs/toolkit"; 

const paymentRequestSlice = createSlice({
    name: 'payreq',
    initialState: {
        loading: false,
        loaded: false,
        errors: {
            status: false,
            message: "",
            messages: []
        },
        request: [],
        btnLoading: false,
        modal: false
    },
    reducers: {
        loading: (state, action) => {
            state.loading = (action.payload) ? action.payload : false;
        },
        loaded: (state, action) => {
            state.loaded = (action.payload) ? action.payload : false;
        },
        errors: (state, action) => {
            state.value.status = (action.payload) ? action.payload.status : false;
            state.value.message = (action.payload) ? action.payload.message : "";
            state.value.messages = (action.payload) ? action.payload.messages : [];
        },
        request: (state, action) => {
            state.request = (action.payload) ? action.payload : [];
        },
        btnLoading: (state, action) => {
            state.btnLoading = (action.payload) ? action.payload : false;
        },
        modal: (state, action) => {
            state.modal = (action.payload) ? action.payload : false;
        },
    }
})

export const { loading, loaded, errors, request, btnLoading, modal } = paymentRequestSlice.actions;
export default paymentRequestSlice.reducer;
import { useEffect } from "react";
import { useState } from "react";


export const useClickEment = () => {
    const [active, setActive] = useState(document.activeElement);

    const handleClick = (e) => {
        setActive(e.target);
    }

    useEffect(() => {        
        document.addEventListener('mousedown', handleClick);
        return() => {
        document.removeEventListener('mousedown', handleClick);
        };
    }, []);

  return active;
}

import api from '../../utils/api';
import { btnLoading, errors, loading, modal, requests, loaded } from '../reducers/_request.withdrawal.reducers'
import { balance } from '../reducers/_withdraws.reducers'

export const getRequest = () => async (dispatch) => {
    dispatch(loading(true));
    try {
        const response = await api.get('/user-withdrawal-request');
        dispatch(requests(response.data));
    } catch (err) {
        console.error(err)
    } finally {
        dispatch(loading(false))
    }
}

export const createRequest = (data) => async (dispatch) => {
    dispatch(btnLoading(true));
    dispatch(errors())
    try {
        const response = await api.post('/user-withdrawal-request', data);
        dispatch(loaded(false));
        dispatch(modal(false));
        dispatch(balance(response.data))
    } catch (err) {
        // console.log(err)
        dispatch(errors({
            status: true,
            messages: err.response.data.errors,
            message: err.response.data.message
        }))
    } finally {
        dispatch(btnLoading(false))
    }
}
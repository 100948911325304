import { useSelector } from "react-redux";
import AdminInput from "../../../components/admin/admin.input";
import { styles } from "../../../utils/constanst";

export default function EventCapture({ handleChange, data }) {
  const r_events = useSelector((state) => state.event);

  return (
    <fieldset className={`grid-cols-2 ${styles.fieldset}`}>
      <legend className={styles.legend}>Event Captures</legend>
      <div className={`${styles.boxInfo} col-span-2`}>
        We recommend images dimensions 1000x1000
        <p>
          Description Proofs are to provide a brief description about the
          captures the user should upload.
        </p>
      </div>
      <AdminInput
        label="Cover (*)"
        className="w-full"
        onChange={handleChange}
        type="file"
        id="cover"
        // placeholder="cover"
        errors={r_events.errors}
      />
      <AdminInput
        label="screenshot (*)"
        className="w-full"
        onChange={handleChange}
        type="file"
        id="screenshot"
        // placeholder="screenshot"
        errors={r_events.errors}
      />
      <div>
        <AdminInput
          label="description proof #1"
          className="w-full"
          onChange={handleChange}
          type="text"
          id="description_proof_1"
          // placeholder="Title"
          errors={r_events.errors}
          value={data.description_proof_1}
        />
        <p className="text-[#707070] text-[14px]">
          These instructions will be released when the event is unblocked. Be specific, add as many details on what we are looking for, what is the treasure, and any other specifics that help the player find the right treasure.   
        </p>
      </div>
      <div>        
        <AdminInput
          label="description proof #2"
          className="w-full"
          onChange={handleChange}
          type="text"
          id="description_proof_2"
          // placeholder="Title"
          errors={r_events.errors}
          value={data.description_proof_2}
        />
        <p className="text-[#707070] text-[14px]">
          this is the capture of the environment, map or specific time where the treasure is located: Indicate to the user that you need to be specific by showing coordinates, playback times, etc.
        </p>
      </div>
    </fieldset>
  );
}

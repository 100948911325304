import { Routes, Route } from 'react-router-dom'
import List from './news.list';
import Create from './news.create';
import Update from './news.update';
import Delete from './news.delete';

export default function News() {
	return (
		<Routes>
			<Route path="" element={<List />} />
			<Route path="create" element={<Create />} />
			<Route path="update/:new_id" element={<Update />} />
			<Route path="delete/:new_id" element={<Delete />} />
		</Routes>
	)
}
import HeaderSection from "../../../components/HeaderSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";

export default function HowToPlay() {
   return (
      <section className="w-full h-auto pt-2 lg:p-10 bg-no-repeat bg-cover bg-[url('../img/backgrounds/white-background.png')] flex flex-col justify-center items-center">
         <HeaderSection
            titleStyle="black"
            sub={t("about.howto.sub")}
            title1={t("about.howto.title1")}
            title2={t("about.howto.title2")}
         />
         <div className="w-11/12 ring-4 ring-gray-300 lg:p-10 mt-10">
            <div className="bg-white lg:bg-none p-2">
               <div className="font-bold text-xl uppercase mb-5">
                  {t("about.howto.subtitle1")} <span className="text-yellow-c1">{t("about.howto.subtitle2")}</span>
               </div>
               <div className="lg:flex items-start">
                  <div className="text-sm lg:text-lg mb-2 lg:mb-0 lg:w-1/2 lg:mr-10">
                     <p className="text-justify">
							{t("about.howto.content")}
                     </p>
                     <blockquote className="w-10/12 mx-auto mt-10">
                        <FontAwesomeIcon
                           icon={faQuoteLeft}
                           className="text-xl mr-2"
                        />
                        {t("about.howto.blockquote")}
                        <FontAwesomeIcon
                           icon={faQuoteRight}
                           className="text-xl ml-2"
                        />
                     </blockquote>

                     <span className="float-right font-bold">Ken Johnson</span>
                  </div>
                  <img
                     className="lg:w-1/2"
                     src={require("../../../assets/img/images/treasure-hunt.png")}
                     alt=""
                  />
               </div>
            </div>
            <div className="mt-5 lg:mt-0 p-2">
               {/*<div className="font-bold text-xl uppercase mb-5">
                  How to <span className="text-yellow-c1">win</span>
               </div>
               <p className="text-sm lg:text-lg">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Praesentium est doloribus magni. Perferendis temporibus est
                  cum suscipit vitae consequuntur, reiciendis, nesciunt in rem
                  illum, id earum autem. Iure, ipsa temporibus.
               </p>*/}
            </div>
         </div>
      </section>
   );
}
import HeaderSection from "../../../components/HeaderSection";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { t } from "i18next";
import userCommunityImg from "../../../assets/img/icons/upcoming-events.png";
import notFountCategoryImg from "../../../assets/img/icons/no-events-category.png";
import { useSelector } from 'react-redux'

const UserCommunityNotFound = ({ categoryEvent }) => {
  const [dataNotFound, setDataNotFound] = useState(<></>);
  useEffect(() => {
    switch (categoryEvent) {
      case "live_events":
      case "our_events":
      case "all":
        setDataNotFound(
          <img
            src={require("../../../assets/img/banners/no-events.png")}
            alt="There is no events"
            className="no-event"
          />
        );
        break;
      case "community_events":
      case "user_events":
        setDataNotFound(
          <GeneralNotFoundData
            titleStyle="white"
            title1={t("event_explorer." + categoryEvent + ".notFound.title")}
            title2={t("event_explorer." + categoryEvent + ".notFound.title2")}
            button={t("event_explorer." + categoryEvent + ".notFound.button")}
            description={t(
              "event_explorer." + categoryEvent + ".notFound.description"
            )}
            img={userCommunityImg}
          />
        );
        break;
      default:
        setDataNotFound(
          <img
            src={require("../../../assets/img/banners/no-events.png")}
            alt="There is no events"
            className="no-event"
          />
        );
        break;
    }
  }, [categoryEvent]);

  return dataNotFound;
};

export const CustomNotFoundData = ({ categoryEvent, eventFilter }) => {
  if (eventFilter.category) {
    return (
      <GeneralNotFoundData
        titleStyle="white"
        title1={t("event_explorer.categoryDataNotFound.title")}
        title2={t("event_explorer.categoryDataNotFound.title2")}
        button={t("event_explorer.categoryDataNotFound.button")}
        description={t("event_explorer.categoryDataNotFound.description")}
        img={notFountCategoryImg}
      />
    );
  }

  return (
    <UserCommunityNotFound
      categoryEvent={categoryEvent}
      eventFilter={eventFilter}
    />
  );
};

const GeneralNotFoundData = (props) => {
  const navigate = useNavigate();
  const r_auth = useSelector( state => state.auth )
  
  return (
    <div className="custom-not-found-data content-center w-full text-center">
      <HeaderSection
        titleStyle="white"
        sub2={props.description}
        title1={props.title1}
        title2={props.title2}
      />

      <div className="body-custom-data">
        {props.img && <img src={props.img} alt="" />}

        {r_auth.logged !== true && props.button && (
          <button onClick={() => navigate('/auth/register') } className="segoeBold">{props.button}</button>
        )}
      </div>
    </div>
  );
};

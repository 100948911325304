import { useSelector, useDispatch } from "react-redux";
import BulletButton from "../../../components/BulletButton";
import { joinEventModal } from "../../../store/reducers/_events.reducers";
import { modal } from "../../../store/_main.reducers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useCountdown } from "../../../hooks/Countdown";
import parse from 'html-react-parser';

export default function Instructions() {
  const r_event = useSelector((state) => state.event);
  const r_auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleModal = () => {
    dispatch(modal(r_auth.logged));
    dispatch(joinEventModal(true));
  };

  return (
    <>
      <div className="w-full bg-white pt-5">
        <div className="w-full">
          <div id="title" className="w-full mb-4">
            <h2 className="text-bold font-bold text-lg uppercase">
              {r_event.event.title}
            </h2>
          </div>
          <p>
            <h2 className="font-bold text-lg uppercase">
              Details About Challenge
            </h2>
            <div>
              {!r_event.event.blocked && r_event.event.details !== undefined && r_event.event.details !== null && r_event.event.details !== "" ? (
                <>
                  {parse(r_event.event.details)}

                  {
                    r_event.event.screenshot && (
                      <>
                        <br />
                        <h2 className="font-bold text-[20px]">Image to find:</h2>
                        <img src={r_event.event.screenshot} alt="" />
                      </>
                    )
                  }
                </>
                
              ) : (
                <div className="bg-gray-100 p-5 mt-2 mr-10">
                  <FontAwesomeIcon icon={faLock} /> Event details are blocked
                  until{" "}
                  <span className="font-bold">
                    {r_event.event.start_date_us_format}
                  </span>
                </div>
              )}
            </div>
          </p>
          
        </div>
        <div
          id="details"
          className="mt-7 grid grid-cols-1 lg:grid-cols-2 gap-x-3"
        >
          <div>
            {/* 
            <div className="border-t-4 border-yellow-400 border-l-4 border-l-gray-300 border-r-4 border-r-gray-300 border-b-4 border-b-gray-300 p-5 mt-5 mx-auto w-11/12">
              <h2 className="uppercase font-bold text-lg">Awards</h2>
              <div className="mt-5">
                <div className="flex mb-5  items-center">
                  <div className="font-bold mr-3 w-1/2 lg:w-2/5">
                    Big Award:
                  </div>
                  <div className="transform-skew-20 bg-yellow-400 p-3 font-bold w-1/2 lg:w-2/5 text-center">
                    ${r_event.event.first_place_reward}
                  </div>
                </div>

                <div className="flex mb-5  items-center">
                  <div className="font-bold mr-3 w-1/2 lg:w-2/5">
                    1st Place:
                  </div>
                  <div className="transform-skew-20 bg-yellow-400 p-3 font-bold w-1/2 lg:w-2/5 text-center">
                    {r_event.event.first_place_extra_points} Points
                  </div>
                </div>

                {r_event.event.second_place_reward && (
                  <div className="flex mb-5 items-center">
                    <div className="font-bold mr-3 w-1/2 lg:w-2/5">
                      2nd Place:
                    </div>
                    <div className="transform-skew-20 bg-yellow-400 p-3 font-bold w-1/2 lg:w-2/5 text-center">
                      {r_event.event.second_place_reward} Points
                    </div>
                  </div>
                )}
                {r_event.event.third_place_reward && (
                  <div className="flex items-center">
                    <div className="font-bold mr-3 w-1/2 lg:w-2/5">
                      3th Place:
                    </div>
                    <div className="transform-skew-20 bg-yellow-400 p-3 font-bold w-1/2 lg:w-2/5 text-center">
                      {r_event.event.third_place_reward} points
                    </div>
                  </div>
                )}
              </div>
            </div>
             */}
          </div>
        </div>
      </div>
    </>
  );
}

const CountDown = (props) => {
  const { date } = props;
  const [days, hours, minutes, seconds] = useCountdown(date);
  return (
    <div className={`flex`}>
      <span className="mx-2">
        <p className="text-yellow-c1 font-bold text-center">{days}</p>
        <p className="text-black text-[0.5em] xl:text-sm uppercase text-center">
          Days
        </p>
      </span>
      <span className="font-bold text-black text-center">:</span>
      <span className="mx-2">
        <p className="text-yellow-c1 font-bold text-center">{hours}</p>
        <p className="text-black text-[0.5em] xl:text-sm uppercase text-center">
          Hours
        </p>
      </span>
      <span className="font-bold text-black text-center">:</span>
      <span className="mx-2">
        <p className="text-yellow-c1 font-bold text-center">{minutes}</p>
        <p className="text-black text-[0.5em] xl:text-sm uppercase text-center">
          Min
        </p>
      </span>
      <span className="font-bold text-black text-center">:</span>
      <span className="mx-2">
        <p className="text-yellow-c1 font-bold text-center">{seconds}</p>
        <p className="text-black text-[0.5em] xl:text-sm uppercase text-center">
          Sec
        </p>
      </span>
    </div>
  );
};

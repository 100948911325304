import api from "../../utils/api";
import { methods, loading } from "../reducers/reducer.payments";

export const getCardsAction = () => async (dispatch) => {
  dispatch(loading(true));
  try {
    const response = await api.get("/payments/methods");
    dispatch(methods(response.data));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(loading(false));
  }
};

import React from "react";
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
  //   PaymentElement
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { cardElementOptions } from "../../utils/constanst";
// import { useDispatch } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
export const CreateCustomPaymentMethod = ({ setPaymentData }) => {
  return (
    <>
      <div>
        <h3 className="segoe text-[18px] font-bold mb-2">Add new method</h3>
      </div>
      <Elements stripe={stripePromise}>
        <PaymentForm setPaymentData={setPaymentData} />
      </Elements>
    </>
  );
};

const PaymentForm = ({ setPaymentData }) => {
  const stripe = useStripe();
  const elements = useElements();
//   const dispatch = useDispatch();
  const [termAndCondition, setTermAndCondition] = useState(false);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      console.log(error);

      setPaymentData(null);
    } else {
      setPaymentData(paymentMethod);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col">
      <CardElement options={cardElementOptions} />

      <div className="flex items-center mt-5">
        <input
          type="checkbox"
          name="accept_conditions"
          id="accept_conditions"
          className="w-5 h-5 mr-3 mt-1 accent-[#FFC300]"
          onChange={(e) => {
            setTermAndCondition(e.target.checked);
          }}
        />
        <label
          htmlFor="accept_conditions"
          className="segoe text-[14px] text-[#8A8A8A]"
        >
          I agree on{" "}
          <Link to={"/terms-conditions"} className="text-[#001D3D] font-bold">
            Terms & Conditions
          </Link>
        </label>
      </div>

      <div className="mt-5 w-full flex">
        <div className="mt-10 flex items-center w-3/6"></div>

        <div className="w-3/6 flex flex-end justify-end">
          <div className="transform-section w-max">
            <button
              type="submit"
              disabled={!stripe || !elements || !termAndCondition}
              className="font-['AldotheApache'] text-[24px]"
            >
              Create method
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

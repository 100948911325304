import SimpleModal from "../../../components/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import AdminInput from "../../../components/admin/admin.input";
import AdminButton from "../../../components/admin/admin.button";
import { updateAvatar } from '../../../store/actions/_profile.actions'
import { useState } from "react";

export default function Avatar(props) {
   const { status, viewImage, setViewImage, closeModal} = props;
   const [] = useState(null);
   const r_profile = useSelector((state) => state.profile);
   const dispatch = useDispatch();


   const handleInputImageChange = (event) => {
      setViewImage(URL.createObjectURL(event.target.files[0]));
   }

   const handleSubmit = (event) => {
      event.preventDefault();
      setViewImage(null);
      let formData = new FormData();
      let imagefile = document.querySelector("#avatar");
      formData.append("avatar", imagefile.files[0] || '');
      dispatch(updateAvatar(formData));
   };

   return (
      <SimpleModal size="w-1/2" status={status} title="Update Avatar" closer={closeModal}>
         <form className="w-full" action="" id="avatar-user" onSubmit={handleSubmit}>
            { viewImage && <img src={viewImage} className="max-w-[150px] w-full" />}
            
            <AdminInput
               label="Avatar (*)"
               className="w-full"
               type="file"
               id="avatar"
               placeholder="avatar"
               onChange={handleInputImageChange}
               errors={r_profile.error}
            />
            <div className="w-full lg:w-1/3 flex justify-center mx-auto">
               <AdminButton status={r_profile.btnLoading} text="Update" />
            </div>
         </form>
      </SimpleModal>
   );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { deletePaymentMethod, getIntentPayment, getPaymentMethods } from "../../store/actions/_subscriptions.actions";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer } from "react-toastify";
import visaImage from '../../assets/img/icons/cc-visa.png';
import mastercardImage from '../../assets/img/icons/cc-mastercard.png';
import { useEffect } from "react";


export const PaymentMethods = ({ setPaymentMethodSelected, paymentMethodSelected}) => {
    const dispatch = useDispatch();
    const r_subscription = useSelector((state) => state.subscription);
        
  
    const handleDeletePaymentMethod = async (paymentMethodId) => {
      await dispatch(deletePaymentMethod(paymentMethodId));
      dispatch(getPaymentMethods());
    }
  
    const handleConfirmDeletePaymentMethod = (paymentMethodId) => {
      confirmAlert({
        title: 'Confirm!',
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => handleDeletePaymentMethod(paymentMethodId)
          },
          {
            label: 'No'
          }
        ]
      });
    };
  
    return (
      <>
        <div  className="card-list">
          
          <ToastContainer />
          <ul>
            {r_subscription.methods &&
              r_subscription.methods.length > 0 &&
              r_subscription.methods.map((item, key) => (              
                <li className="border-b mb-2" key={key}>
                  <div className="flex items-center p-2">
                    
                    <div className="w-5/6 flex items-center">
                      <div className='mr-2'>
                          {
                            (paymentMethodSelected && paymentMethodSelected.id == item.id)
                            ? (
                              <>
                                <input
                                  checked
                                  type="radio"
                                  name="payment-card"
                                  className="w-5 h-5 mr-3 mt-1 accent-[#FFC300]"
                                  onChange={()=>{setPaymentMethodSelected(item)}}
                                />
                              </>
                            )
                            : (
                              <>                            
                                <input
                                  type="radio"
                                  name="payment-card"
                                  className="w-5 h-5 mr-3 mt-1 accent-[#FFC300]"
                                  onChange={()=>{setPaymentMethodSelected(item)}}
                                />
                              </>
                            )
                          }
                          
                      </div>
  
                      <div className="card-img"> 
                        {item.type == 'card' && <img src={item.card.brand == 'visa'? visaImage : mastercardImage} alt="card" className="w-[55px]" /> }
                      </div>
                      <div className="card-info ml-[10px]">
                        <div className="last-numbers text-[18px] segoe">
                          •••• •••• •••• {item.card.last4}
                        </div>
                        <span className="text-[14px] segoe">
                          Expiratión: {`${item.card.exp_month}/${item.card.exp_year}`}
                        </span>
                      </div>
  
                    </div>
  
                    <div className="w-1/6 flex justify-end">
                      <FontAwesomeIcon className="cursor-pointer text-[#003566]" icon={faTrash} onClick={()=>{handleConfirmDeletePaymentMethod(item.id)}} />
                    </div>
                    
                  </div>
                </li>
              ))}
            
          </ul>
        </div>
      </>
    );
  }
  
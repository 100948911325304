import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";

export const BasicSearch = (props) => {

  const {
    handleChangeSearch = (e)=>{e.preventDefault()},
    handleSubmit = (e)=>{e.preventDefault()},
    searchValue,
    className = "flex justify-center my-[50px]"
  } = props;
  

  return (
    <div id="filter-search" className={className}>
      <form className="w-full max-w-[800px]" onSubmit={handleSubmit}>
        <div className="flex items-center border-b border-black py-2">
          <FontAwesomeIcon
              className="text-back text-xl active:scale-95"
              icon={faSearch}
          />
          <input 
            name="find"
            onChange={handleChangeSearch}
            value={searchValue}
            autoComplete="off"
            className="
              font-bold 
              segoe
              appearance-none
              bg-transparent
              border-none
              w-full
              text-gray-700
              mr-3
              py-1
              px-2
              leading-tight
              focus:outline-none"
            type="text"
            placeholder="Search"
            aria-label="Full name" />
        </div>
      </form>
    </div>
  )
}

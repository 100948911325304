import { faFacebook, faTwitch, faTwitter, faInstagram, faYoutube } from "@fortawesome/fontawesome-free-brands";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from 'react-redux';
import createdEvents from "../../../../assets/img/icons/created-events.png";
import joinedEvents from "../../../../assets/img/icons/game-events.png";
import eventsWon from "../../../../assets/img/icons/trophy-events.png";

export default function ProfileSocialMedia() {
	const r_pp = useSelector( state => state.pageProfile );
	const r_p = useSelector( state => state.profile );

	return (
		<div className="shadow p-2 mt-[65px] ml-5">
			<h2 className="text-[#001D3D] font-[700] text-[25px]">Info</h2>
			<ul className="w-full ml-3 mt-5 text-gray-400 space-y-2 flex flex-col mb-5 leading-[16px]">
				<li className="segoe text-[14px] flex">
					<img className="w-[14px] mr-2 relative top-1 h-[14px]" src={createdEvents} alt="Created events" />
					{r_p.user.events_created_by_user_count} Created events
				</li>
				<li className="segoe text-[14px] flex">
					<img className="w-[14px] mr-2 relative top-1 h-[12px]" src={joinedEvents} alt="Created events" />
					{r_p.user.events_winned_count} Joined events
				</li>
				<li className="segoe text-[14px] flex">
					<img className="w-[14px] mr-2 relative top-1 h-[14px]" src={eventsWon} alt="Created events" />
					{r_p.user.events_winned_count} Events won
				</li>
			</ul>
			{
				(r_pp.user.facebook && r_pp.user.twitter && r_pp.user.instagram && r_pp.user.twitch && r_pp.user.youtube) && <>
					<h2 className="text-[#001D3D] font-[700] text-[25px]">Social Media</h2>
					<ul className="w-full ml-5 mt-10 text-gray-400 space-y-2 flex flex-col">
						<Element social={r_pp.user.facebook} icon={faFacebook} text="Facebook" />
						<Element social={r_pp.user.twitter} icon={faTwitter} text="Twitter" />
						<Element social={r_pp.user.instagram} icon={faInstagram} text="Instagram" />
						<Element social={r_pp.user.twitch} icon={faTwitch} text="Twitch" />
						<Element social={r_pp.user.youtube} icon={faYoutube} text="YouTube" />
					</ul>
				</>
			}
		</div>
	)
}

const Element = ( { social, icon, text } ) => {
	return social && <li className="hover:text-gray-900 cursor-pointer"><FontAwesomeIcon icon={icon} /> <a href={social} target="_blank" rel="noreferrer" >{text}</a></li>
}
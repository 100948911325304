import React from 'react'
import { Link } from 'react-router-dom'
import defaultImage from '../../assets/img/avatars/no_user.jpeg';
import trophyImg from '../../assets/img/icons/trophy-solid.png';
import markImg from '../../assets/img/icons/mark-solid.png';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customUserData } from '../../store/reducers/_users.reducers';
import { getUserBasicData } from '../../store/actions/_users.actions';
import Loading from '../Loading';
import { useEffect } from 'react';
import FlatFollowUser from '../flatFollowUser/FlatFollowUser';
export const UserPopUpCard = ({user, className}) => {

  const [isPopUpCard, setIsPopUpCard] = useState(false);
  const dispatch = useDispatch();
  const r_user = useSelector((state) => state.user);

  const handleHover = (uuid) => {
    setIsPopUpCard(true);
    if(uuid){
        dispatch(getUserBasicData(uuid));
        return
    }
  }

  const handleOutOver = () => {
    setIsPopUpCard(false);
    // dispatch(customUserData(null));
  }

  useEffect(() => {
    if(r_user.loading_customUserData){
        dispatch(customUserData(null));
    }
  }, [r_user.loading_customUserData])
  

  return (
    <div className='relative'>   
        <Link to={`/profile/${user.username || user.uuid}/about`}
        onMouseOut={() => {handleOutOver()}} 
        onMouseOver={() => {handleHover(user.uuid)} }
        className={className}
        >
            <span className='username hover:underline'>{"@"+user.username || user.name}</span>
        </Link>
        
        {
            isPopUpCard && <>
                <div 
                    onMouseOut={() => {handleOutOver()}} 
                    onMouseOver={() => {handleHover()} }
                    className='
                        segoe bg-[#fff] 
                        text-black 
                        m-auto 
                        text-center
                        text-[12px] 
                        absolute
                        rounded-[24px]
                        w-[260px]
                        min-h-[194px]
                        z-[10000]
                        py-5
                        px-3
                        shadow-xl
                    '>
                    
                    {
                        r_user.loading_customUserData ? <>
                            <Loading color="black" />
                        </> : <>                        
                            { r_user.customUserData && <>
                                <div className='flex'>
                                    <div style={{backgroundImage: `url(${r_user.customUserData.avatar || defaultImage})`}} className={"w-full h-screen bg-cover bg-no-repeat bg-center w-[50px] h-[50px] min-w-[50px] min-h-[50px] bg-red-100 rounded-[100%]"} ></div>
                                    <div className='ml-3 relative top-[-8px]'>
                                        <b><h2 className='font-[700] font-bold text-[25px]'>{r_user.customUserData.username || r_user.customUserData.name}</h2></b>
                                        <div className='float-left'>
                                            <FlatFollowUser user={r_user.customUserData} />
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-3 text-left'>
                                    <span className='segoe text-[14px]'>{r_user.customUserData.followers_count} followers  •  {r_user.customUserData.followeds_count} following</span>
                                </div>

                                <div className='mt-3 text-left text-[14px]'>
                                    <ul>
                                        <li><img src={markImg} alt="Follow" className='w-[12px] relative top-[-2px] inline mr-2' /> {r_user.customUserData.events_created_by_user_count} published events</li>
                                        <li className='mt-1'><img src={trophyImg} alt="Follow" className='w-[12px] relative top-[-2px] inline mr-2' /> {r_user.customUserData.events_winned_count} winned events</li>
                                    </ul>
                                </div>
                            </>
                            }
                        </>
                    }

                </div>
            </>
        }
    </div>
  )
}

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../../../components/Loading";
import NoUserImage from "../../../../assets/img/avatars/no_user.jpeg";
import { faTrophy, faThumbTack, faCircleCheck, faHeart, faHeartCrack, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { followAction, unFollowAction } from "../../../../store/actions/_pageProfile.actions";


export default function Following() {
  const dispatch = useDispatch();
  const r_follow = useSelector((state) => state.pageProfile);

  const r_pp = useSelector((state) => state.pageProfile);
  const r_auth = useSelector((state) => state.auth);
  const r_search = useSelector((state) => state.search);
  const r_profile = useSelector((state) => state.profile);

  // console.log("PageProfile: ", r_follow);

  return r_follow.loading ? (
    <Loading />
  ) : (
    <div className="w-full">
      <div className="w-full">
        <h1 className="font-bold text-lg">Following</h1>
        <h4 className="text-sm">
          Here is the list of all the users that you follow.
        </h4>
      </div>
      <table className="table-followers">
        <thead>
          <tr>
            {/* <th className="pl-2">
              <input type="checkbox" className="w-5 h-5" />
            </th> */}
            {/* <th colSpan={2}>Select all</th> */}
            <th colSpan={2}>User</th>
            <th>Following</th>
            <th>Events Created</th>
            <th>Events Registered</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {r_follow.followsList.following.length > 0 &&
            r_follow.followsList.following.map((item, key) => (
              <tr key={key}>
                {/* <td className="pl-2">
                  <input type="checkbox" className="w-5 h-5" />
                </td> */}
                <td>
                  <img
                    src={item.following_user.avatar || NoUserImage}
                    alt=""
                    className="w-10 h-10 rounded-full"
                  />
                </td>
                <td>
                  <Link
                    to={`/profile/${
                      item.following_user.username || item.uuid
                    }/about`}
                  >
                    {item.following_user.name} {item.following_user.lastname}
                  </Link>
                </td>
                <td>{moment(item.following_user.updated_at).fromNow()}</td>
                <td>{item.events_of_following_by_me_count} events</td>
                <td>
                  {item.events_registered_of_following_by_me_count} events
                </td>
                <td>
                    {
                        
                        r_auth.logged && (
                            <>
                              {
                                (r_profile.user.id != item.following_user.id) && <>
                                
                                  {
                                      r_profile.followeds.includes(item.following_user.id) ? (
                                          <>
                                              <Button
                                                  text="Unfollow"
                                                  icon={faHeartCrack}
                                                  action={() => dispatch(unFollowAction(item.following_user.uuid)) }
                                                  status={item.following_user.uuid === r_search.moreResultItemIsLoading}
                                                  className="
                                                  bg-[#FFC300]
                                                  text-[#001D3D]
                                                  w-[120px]
                                                  py-[5px]
                                                  rounded-[5px]"
                                              />
                                          </>
                                      ) : (
                                          <>
                                              <Button
                                                  text="Follow"
                                                  icon={faHeart}
                                                  action={() => dispatch(followAction(item.following_user.uuid)) }
                                                  status={item.following_user.uuid === r_search.moreResultItemIsLoading}
                                                  className="
                                                  bg-[#FFC300]
                                                  text-[#001D3D]
                                                  w-[120px]
                                                  py-[5px]
                                                  rounded-[5px]"
                                              />
                                          </>
                                      )
                                  }

                                </>
                              }
                            </>
                        )
                    }
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}


const Button = ({ text, icon, action, status, className }) => {
  console.log(status);
  return (
    <>
      {!status ? (
        <button
          className={className}
          onClick={action}
        >
          <FontAwesomeIcon icon={icon} className="mr-2" /> {text}
        </button>
      ) : (
        <button
          disabled
          className={className}
        >
          <FontAwesomeIcon icon={faSpinner} spin className="mr-2" /> Loading
        </button>
      )}
    </>
  );
}
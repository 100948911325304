import facebook from "../../assets/img/icons/icon-facebook.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFacebook,
	faTwitter,
	faYoutube,
	faInstagram,
	faTwitch
} from "@fortawesome/fontawesome-free-brands";
import { t } from "i18next";

export default function Section4() {
	return (
		<div className="text-white flex flex-col justify-center mt-5 lg:mt-0">
			<div className="border-b-yellow-c4 w-1/2 mb-2 lg:mb-10 text-xl uppercase font-bold">
				{t("footer.folowus")}
			</div>
			<ul className="flex">
				<Option
					to="https://www.instagram.com/ithl.official"
					icon={faInstagram}
				/>
				<Option
					to="https://www.twitch.tv/ithlgaming"
					icon={faTwitch}
				/>
				<Option
					to="https://twitter.com/ITHL_official"
					icon={faTwitter}
				/>
				<Option
					to="https://www.facebook.com/people/International-Treasure-Hunters-League/100082160763706/"
					icon={faFacebook}
				/>
				{/* 
				<Option
					to="https://www.youtube.com/channel/UClTbnPC6mkbpjO_1OYicrBQ"
					icon={faYoutube}
				/>
 				*/}
			</ul>
		</div>
	);
}

function Option({ to, icon }) {
	return (
		<li className="mx-2">
			<a
				href={to}
				className="hover:opacity-75 active:scale-95"
				target="_blank"
				rel="noreferrer"

			>
				<FontAwesomeIcon icon={icon} className="text-yellow-c1" style={{ fontSize: '15px' }} />
			</a>
		</li>
	);
}

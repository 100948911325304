import React from 'react'
import checkPayment from '../../../../../assets/img/icons/payment-check-satatus.png'

export const PaymentSuccess = () => {

  const handleReload = () => {
    window.location.reload();
  }

  return (
    <>

        <div className="flex flex-col text-center">
            <img src={checkPayment} alt="payment status" className='w-[171px] h-[171px] mx-auto my-5' />
            <h2 className="uppercase font-bold font-['AldotheApache'] text-[34px] text-[#404B52]">start playIng now! </h2>
            <p className='segoe text-[16px] text-[#707070]'>
              Now that you are part of the event, go to the "submit your proofs" tab in the event description and upload your results to see if you are next. 
            </p>
            <div className='transform-section w-max mx-auto my-5'>
              <button                         
                  type="submit"
                  className="font-['AldotheApache'] text-[24px] uppercase "
                  onClick={handleReload}
              >
              go to play
              </button>
            </div>
        </div>           

    </>
  )
}

import React from 'react'
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import Moment from 'react-moment';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { truncateStr } from '../../utils/functions';
import { Badge } from '../Badge';
import './styles.scss';
import { UserPopUpCard } from '../userPopUpCard';
import { useSelector } from 'react-redux';


export const BasicEventCard = ({ className = "", data, categoryEvent = '' }) => {

  const [isLive, setIsLive] = useState(false);
  const r_profile = useSelector(state => state.profile);
  const [userEvents, setUserEvents] = useState([]);
  const [isUserJoined, setIsUserJoined] = useState(false);
  useEffect(() => {
    const startMomentDate = moment(`${data.start_date} ${data.start_time}:00`).format('YYYY-MM-DD HH:mm:ss');
    const actualDate = moment().format('YYYY-MM-DD HH:mm:ss');
    setIsLive(startMomentDate < actualDate && categoryEvent === 'all');
  }, [data])

  

  useEffect(() => {
    if(r_profile.user){
      if(r_profile.user.user_events){
        r_profile.user.user_events.map(userInEvent => {
          setUserEvents(preState => [...preState, userInEvent.event_id]);
        })
      }
    }
  }, [r_profile])
  
  useEffect(() => {
    if(data){
      console.log(userEvents, data.id);
      if(userEvents.includes(data.id)){
        setIsUserJoined(true);
      }
    }
  }, [userEvents])



  // console.log("Here", data);

  return (
    data && (
      <div className='transition ease-in-out'>
      {/* <div className='transition ease-in-out delay-110 hover:-translate-y-1 hover:scale-104 duration-300'> */}
        <div className={'basic-event-card ' + className}>
        
          <div className='basic-image'>
            
            {
              isUserJoined && (
                <>
                  <Badge
                    dot={true}
                    className="absolute top-[-3px] right-[75px] flex items-center bg-[#061322]"
                    text="Joined"
                    
                  />
                </>
              )
            }

            {
              isLive && (
                <>
                  <Badge
                    dot={true}
                    className="absolute top-[-3px] right-[-5px]"
                    text="Live"
                    
                  />
                </>
              )
            }
            {
              isLive && data.is_free_event !== 0 && (
                <Badge
                  dot={false}
                  className="absolute top-[-3px] right-[35px] bg-[#7ED360]"
                  text="Free"
                />
              )
            }
            {
              !isLive && data.is_free_event !== 0 && (
                <Badge
                  className="absolute top-[3px] right-[-5px] bg-[#7ED360]"
                  text="Free"
                />
              )
            }
            <Link to={"/event-detail/" + data.event_uuid}>
              <div className="w-full h-[233px] max-w-[233px] max-h-[233px] m-auto">
                <img src={data.cover} className="z-0 w-full h-[233px] max-w-[233px] max-h-[233px]" alt="International Treasure Hunter League - Event Cover" />
              </div>
            </Link>
            
          </div>

          <div className='grid grid-cols-3 gap-2 py-3'>
            <div className='col-span-2'>
              <Link to={"/event-detail/" + data.event_uuid}>
                <h2 className='line-clamp-2 md:line-clamp-1 aldo'>
                  {truncateStr(data.title, 32)}
                </h2>
              </Link>
              {
                data.user_owner.role_id == 1
                  ? (<span className='username'>ITHL <FontAwesomeIcon icon={faCircleCheck} className="text-blue-500 ml-1" /></span>)
                  : (
                    <UserPopUpCard user={data.user_owner} />
                  )
              }
            </div>

            <div className="">
              <div className='py-1 -skew-x-12 bg-yellow-c1 justify-center text-center'>
                
                <Link to={"/event-detail/" + data.event_uuid}>
                  {
                    data.first_place_reward != 0
                      ? (
                        <span className='text-lg font-extrabold segoe transform-none text-blue-c1'>${data.first_place_reward}</span>
                      ) : (
                        <span className='text-sm font-bold segoe transform-none text-blue-c1'>Try Your Luck!</span>
                      )
                  }
                </Link>
              </div>
            </div>
          </div>


          {
            data.closed_event == 0 && data.full_end_time > moment().format('YYYY-MM-DD HH:mm:ss')  && <>
              <div className='flex flex-row event-date-container'>
                <div className='w-1/2 event-date'>

                  <span className='event-day'>
                    <Moment date={data.start_date} format="DD" />
                  </span>
                  <span className='event-month'>
                    <Moment date={data.start_date} format="MMMM" />
                  </span>

                </div>
                <div className='w-1/2 event-date'>
                  <span className='event-day'>
                    <Moment date={data.end_date} format="DD" />
                  </span>
                  <span className='event-month'>
                    <Moment date={data.end_date} format="MMMM" />
                  </span>
                </div>
              </div>
            </>
          }
          {
            data.full_end_time < moment().format('YYYY-MM-DD HH:mm:ss') && data.closed_event == 0 && <>
              <div className='flex flex-row event-date-container mt-4'>
                <div className='w-1/2 event-date'>

                  <span className='event-day'>
                    Finished
                  </span>

                </div>
                <div className='w-1/2 event-date'>
                  <Link to={"/event-detail/" + data.event_uuid} className='event-month mt-1 hover:underline hover:cursor-pointer'>
                    Results Coming Soon
                  </Link>
                </div>
              </div>
            </>
          }
          
          {
            data.closed_event == 1 && <>
              <div className='flex flex-row event-date-container mt-4'>
                <div className='w-1/2 event-date'>

                  <span className='event-day'>
                    Finished
                  </span>

                </div>
                <div className='w-1/2 event-date'>
                  <Link to={"/event-detail/" + data.event_uuid} className='event-month mt-1 hover:underline hover:cursor-pointer'>
                    See Results
                  </Link>
                </div>
              </div>
            </>
          }

        </div>
      </div>
    )
  )
}

import React from 'react'
import Moment from 'react-moment';
import moment from 'moment';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

export const EventCard = ({className, item}) => {
  return (
    <div className={className + " md:flex border border-1 border-[#DFDFDF] rounded-[8px] overflow-hidden"}>
        <div className='sm:12/12 md:w-5/12 md:mr-1'>
            <img className='md:w-[214px] md:h-[208px] w-full' src={item.event?.cover} />
        </div>
        <div className='sm:12/12 md:w-7/12 ml-1 relative'>
            <div className='my-1 flex text-[14px] segoe'>
                <div>
                    <Moment date={item.event.start_date} format="MMM" className='m-1'/>
                    <Moment date={item.event.start_date} format="DD" className='m-1' /> 
                </div>
                <div>
                    <span> - </span>
                </div>
                <div>
                    <Moment date={item.event.end_date} format="MMM" className='m-1'/>
                    <Moment date={item.event.end_date} format="DD" className='m-1' /> 
                </div>
            </div>

            <div className='my-1'>
                <h3 className='font-[700] segoe capitalize text-[18px]'>{item.event.title}</h3>
            </div>

            <div className='my-1 segoe text-[14px] max-h-[90px] overflow-hidden'>
                {parse(item.event.brief_description)}
            </div>
            <div className='my-1 segoe text-[14px]'>
                
                <Link
                  to={`/event-detail/${item.event.event_uuid}`}
                  className="text-[#FFC300] underline underline-offset-2 absolute bottom-3"
                >
                  See details
                </Link>
            </div>
        </div>

    </div>
  )
}

import { createSlice } from "@reduxjs/toolkit";

const requestSlice = createSlice({
  name: "requestwd",
  initialState: {
    btnLoading: false,
    errors: {
      status: false,
      messages: [],
      message: "",
    },
    loading: false,
    modal: false,
    requests: [],
    loaded: false,
  },
  reducers: {
    btnLoading: (state, action) => {
      state.btnLoading = action.payload ? action.payload : false;
    },
    errors: (state, action) => {
      state.errors.status = action.payload ? action.payload.status : false;
      state.errors.messages = action.payload ? action.payload.messages : [];
      state.errors.message = action.payload ? action.payload.message : "";
    },
    loading: (state, action) => {
      state.loading = action.payload ? action.payload : false;
    },
    modal: (state, action) => {
      state.modal = action.payload ? action.payload : false;
    },
    requests: (state, action) => {
      state.requests = action.payload ? action.payload : [];
      state.loaded = true
    },
    loaded: (state, action) => {
      state.loaded = action.payload ? action.payload : false;
    },
  },
});

export const { btnLoading, errors, loading, modal, requests, loaded } =
  requestSlice.actions;
export default requestSlice.reducer;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../../../components/Loading";
import NoUserImage from "../../../../assets/img/avatars/no_user.jpeg";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartRegular } from "@fortawesome/free-regular-svg-icons";
import { followAction, followFromList, getFollowersList, removeFollow, unFollowAction, unFollowFromList } from '../../../../store/actions/_pageProfile.actions'
import { faTrophy, faThumbTack, faHeart, faCircleCheck, faHeartCrack, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { setRefreshFollows } from "../../../../store/reducers/_pageProfile.reducers";


export default function Followers() {
  const dispatch = useDispatch();
  const r_follow = useSelector((state) => state.pageProfile);
  const r_pp = useSelector((state) => state.pageProfile);
  const r_auth = useSelector((state) => state.auth);
  const r_search = useSelector((state) => state.search);
  const r_profile = useSelector((state) => state.profile);

  const handleRemove = (following_id, follower_id) => {
    dispatch(removeFollow(following_id, follower_id));
  }

  useEffect(() => {
    if(r_follow.refreshFollows){
      dispatch(setRefreshFollows(false));
      dispatch(getFollowersList());
    }
  }, [r_follow.refreshFollows])
  

  return r_follow.loading ? (
    <Loading />
  ) : (
    <div className="w-full">
      <div className="w-full">
        <h1 className="font-bold text-lg">Followers</h1>
        <h4 className="text-sm">
          Here is the list of all the users that follow you.
        </h4>
      </div>
      <table className="table-followers">
        <thead>
          <tr>
            {/* <th className="pl-2">
              <input type="checkbox" className="w-5 h-5" />
            </th> */}
            {/* <th colSpan={2}>Select all</th> */}
            <th colSpan={2}>User</th>
            <th>Followed you</th>
            <th>Events Created</th>
            <th>Events Registered</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {r_follow.followsList.followers.length > 0 &&
            r_follow.followsList.followers.map((follow, key) => (
              <tr key={key}>
                {/* <td className="pl-2">
                  <input type="checkbox" className="w-5 h-5" />
                </td> */}
                <td>
                  <img
                    src={follow.follower_user.avatar || NoUserImage}
                    alt=""
                    className="w-10 h-10 rounded-full"
                  />
                </td>
                <td>
                  <Link
                    to={`/profile/${
                      follow.follower_user.username || follow.follower_user.uuid
                    }/about`}
                  >
                    {follow.follower_user.name} {follow.follower_user.lastname}
                  </Link>
                </td>
                <td>{moment(follow.follower_user.updated_at).fromNow()}</td>
                <td>{follow.events_count} events</td>
                <td>{follow.events_registered_count} events</td>
                <td>
                    {
                        
                        r_auth.logged && (
                            <>
                              {
                                (r_profile.user.id != follow.follower_user.id) && <>
                                
                                  {
                                      r_profile.followeds.includes(follow.follower_user.id) ? (
                                          <>
                                              <Button
                                                  text="Unfollow"
                                                  icon={faHeartCrack}
                                                  action={() => dispatch(unFollowAction(follow.follower_user.uuid)) }
                                                  status={follow.follower_user.uuid === r_search.moreResultItemIsLoading}
                                                  className="
                                                  m-2
                                                  bg-[#154f62]
                                                  text-[#facc16]
                                                  w-[120px]
                                                  py-[5px]
                                                  rounded-[5px]"
                                              />
                                          </>
                                      ) : (
                                          <>
                                              <Button
                                                  text="Follow"
                                                  icon={faHeart}
                                                  action={() => dispatch(followAction(follow.follower_user.uuid)) }
                                                  status={follow.follower_user.uuid === r_search.moreResultItemIsLoading}
                                                  className="
                                                  m-2
                                                  bg-[#FFC300]
                                                  text-[#001D3D]
                                                  w-[120px]
                                                  py-[5px]
                                                  rounded-[5px]"
                                              />
                                          </>
                                      )
                                  }

                                </>
                              }
                            </>
                        )
                    }<>
                    <button
                        onClick={() => {handleRemove(follow.following_user_id, follow.follower_user_id)}}
                        disabled={r_follow.removeUsersFollowLoading.includes(follow.follower_user.id)}
                        className="
                        bg-[#d1d4d8]
                        text-[#001D3D]
                        w-[120px]
                        py-[5px]
                        rounded-[5px]"
                    >
                      {
                        r_follow.removeUsersFollowLoading.includes(follow.follower_user.id) ? <FontAwesomeIcon icon={faSpinner} spin className="mr-2" /> : <FontAwesomeIcon icon={faTrash} className="mr-2" />
                        

                      }
                    </button>
                </>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}


const Button = ({ text, icon, action, status, className }) => {
  console.log(status);
  return (
    <>
      {!status ? (
        <button
          className={className}
          onClick={action}
        >
          <FontAwesomeIcon icon={icon} className="mr-2" /> {text}
        </button>
      ) : (
        <button
          disabled
          className={className}
        >
          <FontAwesomeIcon icon={faSpinner} spin className="mr-2" /> Loading
        </button>
      )}
    </>
  );
}
import { Routes, Route } from "react-router-dom";
import List from "./events.list";
import Create from "./events.create";
import Update from './events.update'
import Judge from './events.judge';
import Delete from './events.delete';

export default function Events() {
   return (
      <Routes>
         <Route path="" element={<List />} />
         <Route path="create" element={<Create />} />
         <Route path="update/:event_id" element={<Update />} />
         <Route path="delete/:event_id" element={<Delete />} />
			<Route path="judge/:event_uuid" element={<Judge />} />
      </Routes>
   );
}

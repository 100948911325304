import { createSlice } from "@reduxjs/toolkit";

const searchSlice = createSlice({
  name: "search",
  initialState: {
    types: ['all', 'events', 'users'],
    type: "all",
    principalResult: null,
    moreResults: [],
    moreResultItemIsLoading: null,
    events: [],
    isLoading: false,
    isShowInNavBar: true,
  },
  reducers: {
    setSearchType: (state, {payload}) => {
      state.type = payload
    },
    setIsShowInNavBar: (state, {payload}) => {
      state.isShowInNavBar = payload
    },
    setSearchData: (state, {payload}) => {
      state.principalResult = payload.principalResult;
      state.moreResults = payload.moreResults;
      state.events = payload.events;
    },
    moreResultItemIsLoading: (state, {payload}) => {
      state.moreResultItemIsLoading = payload;
    },
    isLoading: (state, action) => {
      state.isLoading = action.payload;
    }
  },
});

export const { setSearchType, setSearchData, isLoading, moreResultItemIsLoading, setIsShowInNavBar } = searchSlice.actions;
export default searchSlice.reducer;

import { useEffect } from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../../components/Loading";
import { getFollowersList } from "../../../../store/actions/_pageProfile.actions";
import { SearchUserEvents } from "../../../../components/SearchUserEvents/SearchUserEvents";
import { setIsShowInNavBar } from "../../../../store/reducers/_search.reducers";
import Followers from './profile.follows.followers'
import Following from './profile.follows.following'

const style = {
  active:
    "bg-yellow-400 border border-yellow-500 text-blue-900 p-2 font-bold hover:opacity-80",
  inactive: "bg-gray-200 border border-gray-300 p-2 hover:opacity-80",
  created: "rounded-l",
  joined: "rounded-r",
  create:
    "p-2 bg-yellow-400 text-blue-900 font-bold transform-skew-20 hover:opacity-80",
};

export default function Follows() {
  const dispatch = useDispatch();
  const r_follow = useSelector((state) => state.pageProfile);

  useEffect(() => {
    dispatch(getFollowersList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setIsShowInNavBar(false));

    return () => {
      dispatch(setIsShowInNavBar(true));
    };
  }, []);

  return r_follow.loading ? (
    <Loading />
  ) : (
    <div className="w-11/12 mx-auto mt-5">
      <div className="flex items-end mb-10">
        <div className="w-full flex justify-between items-center">
          <div>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${style.active} ${style.created}`
                  : `${style.inactive} ${style.created}`
              }
              to={`followers`}
            >
              <FontAwesomeIcon icon={faUserCheck} /> Followers
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${style.active} ${style.joined}`
                  : `${style.inactive} ${style.joined}`
              }
              to={`following`}
            >
              <FontAwesomeIcon icon={faCircleUser} /> Following
            </NavLink>
          </div>
        </div>

        <div className="w-1/2">
          <div className="flex justify-end items-center">
            <div className="w-[250px]">
              <SearchUserEvents searchType="users" color="black" />
            </div>
          </div>
        </div>
      </div>

      <div>
        <Routes>
          {['','followers'].map( (path, key) =>  <Route key={key} path={path} element={<Followers />} />)}
          <Route path="following" element={<Following />} />
        </Routes>
      </div>

    </div>
  );
}

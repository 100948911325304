/* eslint-disable eqeqeq */
import { Routes, Route, Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faUser,
  faCreditCard,
//   faSync,
  faCameraRotate,
  faShareNodes,
  faMoneyBill,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
import Account from "./profile.account";
import Payments from "./profile.payments";
import Security from "./profile.security";
import Followers from "./follows/profile.follows";
import Loading from "../../../components/Loading";
import ProfileAvatar from "./profile.avatar";
import Withdraws from "./withdraws/profile.withdraws";
import Events from "./events/profile.events";
import { t } from "i18next";
// import imageProfile from "../../../assets/img/avatars/no_user.jpeg";
import { useState } from "react";
import { ProfileUserAdmin } from "./ProfileUserAdmin";
import WdMethods from "../../withdrawal-methods/wdmethods";
import PaymentRequest from "../../payment-request/payment-request";
import EventListAdminJudge from "../../events/list/event-list-admin-judge";

export default function Profile() {
//   const dispatch = useDispatch();
  const r_profile = useSelector((state) => state.profile);
  const [viewImage, setViewImage] = useState(null);
  const [modal, setModal] = useState(false);
  let activeStyle = {
    color: "#EAB308",
    background: "#FFC30014",
    padding: "5px 10px",
  };
  return (
    <>
      <ProfileAvatar
        status={modal}
        closeModal={() => {
          setModal(false);
          setViewImage(null);
        }}
        viewImage={viewImage}
        setViewImage={setViewImage}
      />
      {r_profile.loading && <Loading />}
      {r_profile.loaded && (
        <div className="w-11/12 mx-auto lg:flex mt-10 space-x-5">
          <div className="w-full lg:w-2/12">
            <div className="w-full relative m-auto text-center">
              <div className="w-[150px] h-[150px] rounded-[50%] overflow-hidden m-auto text-center relative">
                <img
                  className="w-full"
                  src={
                    r_profile.user.avatar ||
                    "https://as1.ftcdn.net/v2/jpg/03/53/11/00/1000_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg"
                  }
                  alt=""
                />
              </div>
              <FontAwesomeIcon
                icon={faCameraRotate}
                className="absolute bottom-0 z-10 right-0 text-white text-3xl bg-[#061322] p-2 rounded-full cursor-pointer hover:scale-110 active:scale-95"
                onClick={() => setModal(true)}
              />
            </div>
            <ul className="menu-user mt-[50px]">
              <li>
                <NavLink
                  to="account"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  className="hover:text-[#EAB308] hover:px-[10px]"
                >
                  <FontAwesomeIcon icon={faUser} className="mr-2" />
                  {t("profile.account")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="security"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  className="hover:text-[#EAB308] hover:px-[10px]"
                >
                  <FontAwesomeIcon icon={faLock} className="mr-2" />
                  {t("profile.security")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="withdraws"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  className="hover:text-[#EAB308] hover:px-[10px]"
                >
                  <FontAwesomeIcon icon={faMoneyBill} className="mr-2" />
                  Withdraws
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="payments"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  className="hover:text-[#EAB308] hover:px-[10px]"
                >
                  <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
                  {t("profile.payments")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="my-events/created/live"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  className="hover:text-[#EAB308] hover:px-[10px]"
                >
                  <FontAwesomeIcon icon={faCalendarDays} className="mr-2" />
                  My Events
                </NavLink>
              </li>

              {r_profile.user.role_id == 1 ? (
                <>
                  <li>
                    <NavLink
                      to="admin-judge-list"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      className="hover:text-[#EAB308] hover:px-[10px]"
                    >
                      <FontAwesomeIcon icon={faCalendarDays} className="mr-2" />
                      Judge List
                    </NavLink>
                  </li>
                </>
              ) : (
                ""
              )}

              <li>
                <NavLink
                  to={`followers`}
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  className="hover:text-[#EAB308] hover:px-[10px]"
                >
                  <FontAwesomeIcon icon={faShareNodes} className="mr-2" />
                  Followers
                </NavLink>
              </li>
            </ul>
            <Link
              className="bg-yellow-500 text-blue-900 p-2 rounded uppercase text-xs block text-center mt-5 font-bold"
              to={`/profile/${
                r_profile.user.username || r_profile.user.uuid
              }/about`}
            >
              {t("profile.view_my_public_profile")}
            </Link>
            <Link
              className="bg-cyan-900 text-yellow-500 p-2 rounded uppercase text-xs block text-center mt-1 font-bold"
              to={`/profile/${
                r_profile.user.username || r_profile.user.uuid
              }/about`}
            >
              {t("profile.become_a_partner")}
            </Link>
          </div>
          <div className="w-full lg:w-10/12">
            <Routes>
              <Route path="" element={<Account />} />
              <Route path="account" element={<Account />} />
              <Route path="payments" element={<Payments />} />
              <Route path="security" element={<Security />} />
              <Route path="withdraws/*" element={<Withdraws />} />
              <Route path="user-admin/:user" element={<ProfileUserAdmin />} />
              <Route path="my-events/*" element={<Events />} />
              <Route path="followers/*" element={<Followers />} />
              <Route path="withdrawal-methods/*" element={<WdMethods />} />
              <Route path="payment-request/*" element={<PaymentRequest />} />
              <Route
                path="admin-judge-list"
                element={<EventListAdminJudge />}
              />
            </Routes>
          </div>
        </div>
      )}
    </>
  );
}

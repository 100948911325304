export default function BasicCheckbox(props) {
   const { className, onChange, type, id, placeholder, errors } = props;

   return (
      <>
         <div className={className}>
            <input
               type="checkbox"
               name={id}
               onChange={onChange}
               id={id}
               className={`w-5 h-5`}
            />
            <label htmlFor={id} className="text-white text-xs md:text-sm ml-3">
               {props.children}
            </label>
         </div>
         {errors?.status &&
            Object.entries(errors.messages).length > 0 &&
            errors.messages[id] && (
               <span className="text-red-300">{errors.messages[id][0]}</span>
            )}
      </>
   );
}

import { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNewById, getNewsPopular } from "../../../store/actions/_news.actions";
import Loading from "../../../components/Loading";
import parse from 'html-react-parser';

export default function ReadNews() {
   let { new_id } = useParams();
   const dispatch = useDispatch();
   const r_news = useSelector((state) => state.news);

   useEffect(() => {
      dispatch(getNewById(new_id));
   }, [dispatch, new_id]);

	useEffect( () => {
		dispatch(getNewsPopular(10))
	},[dispatch])

   return (
      <>
         {r_news.loading && <Loading color="black" />}
         {!r_news.loading && (
            <>
               {r_news.new && (
                  <>
                     <section
                        className={`w-full min-h-[300] lg:min-h-[600px] flex justify-center bg-cover bg-no-repeat items-center z-0`}
                        style={{ backgroundImage: `url(${r_news.new.cover})` }}
                     >
                        <div
                           className="text-white text-3xl p-10 rounded shadow lg:text-6xl uppercase font-bold flex flex-col"
                           style={{ backgroundColor: "rgba(0,0,0,.3)" }}
                        >
                           <div className="aldo">{r_news.new.title}</div>
                           <div className="capitalize text-lg text-center segoe">
                              News / {r_news.new.category} /{" "}
                              <span className="text-yellow-c1">
                                 {r_news.new.date_format_mdy}
                              </span>
                           </div>
                        </div>
                     </section>
                     <div className="w-11/12 lg:w-10/12 mx-auto border shadow-lg mt-10 p-2 lg:p-10">
                        <h1 className="aldo font-bold text-yellow-c1 text-2xl">
                           {r_news.new.title}
                           <p className="text-black text-xs">
                              {r_news.new.date_format_mdy}
                           </p>
                        </h1>
                        <div className="flex flex-col lg:flex-row text-sm justify-between lg:space-x-6">
                           <p className="segoe mt-10 text-lg w-full lg:w-7/12 mb-5">
                              {
                                 r_news.new.details && parse(r_news.new.details)
                              }
                           </p>
                           <div className="bg-blue-c2 p-2 md:p-10 w-full lg:w-5/12 rounded-2xl">
										<h2 className="aldo text-lg text-white mb-7">Popular <span className="text-yellow-c1">Post</span></h2>
										{r_news.news_popular && r_news.news_popular.map( (item, key) =>
											<div className="flex justify-start items-center mb-4" key={key}>
												<img src={item.cover} alt="" className="w-24 h-24 mr-2 object-cover border border-gray-600" />
												<Link className="cursor-pointer" to={`/news/${item.id}`}>
													<h3 className="text-white">{item.title}</h3>
													<h4 className="text-yellow-c1 text-sm">{item.date_format_mdy}</h4>
												</Link>
											</div>
										)}
									</div>
                        </div>
                     </div>
                  </>
               )}
            </>
         )}
      </>
   );
}

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { Info } from "../../components/alerts";
import { getPaymentRequest, sendPayment } from "../../store/actions/_paymentRequest.actions";
import moment from "moment/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";

export default function PaymentRequest() {
  const dispatch = useDispatch();
  const payreq = useSelector((state) => state.payreq);

  useEffect(() => {
    if (!payreq.loaded) dispatch(getPaymentRequest());
  }, [payreq.loaded]);

  const handleSendPayment = (id) => {
    dispatch(sendPayment(id))
  }

  return payreq.loading ? (
    <Loading />
  ) : (
    <>
      {payreq.request.length > 0 ? (
        <>
          
          <div className="w-full ml-5">
            <div className="mb-5">
              <h3 className="segoe text-[34px] font-[700]">Payments Request</h3>
              <p className="segoe mt-3 mb-5">
                Payments will be made within 15 days of winning the event.
              </p>
            </div>

            <div className="mt-2 w-full">
              
              <table className="table border-table">
                <thead>
                  <tr>
                    <th>Created</th>
                    <th>Email</th>
                    <th>Username</th>
                    <th>Method</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {payreq.request.map((item, key) => (
                    <>
                      {
                        item.user_method && <>
                          <tr className="border-b" key={key}>
                            <td>{moment(item.created_at).fromNow()}</td>
                            <td>{item.user_method.username}</td>
                            <td>{item.user_method.username}</td>
                            <td>{item.user_method.withdrawal_method.name}</td>
                            <td>{item.amount}</td>
                            <td><Statues status={item.status} batch={item.payout_batch_id} /></td>
                            <td>
                                {item.status === 'pending' && <FontAwesomeIcon icon={faUpload} onClick={ () => handleSendPayment(item.id)} />}
                            </td>
                          </tr>
                        </>
                      }
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        
        </>
      ) : (
        <Info status={true}>No requests</Info>
      )}
    </>
  );
}

const Statues = ( { status, batch } ) => {
    if (status === 'pending')
        return <div className="text-red-400 font-bold">Pending...</div>
    
    if (status === 'in_progress')
        return <div className="text-blue-600 font-bold">In progress ({batch})</div>
}
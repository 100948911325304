/* eslint-disable eqeqeq */
import api from "../../utils/api";
// import { modal } from "../../store/_main.reducers";
import {
  setAssets,
  events,
  errors,
  alert,
  resetErrors,
  upcomingEvents,
  availableEvents,
  recentEvents,
  showEvent,
  eventEngagement,
  myEvents,
  proof,
  onDelete,
  paymentMode,
  loading,
  // lastEvents,
  knowRole,
  types,
  setExplorerData,
  setLoadingExplorer,
  upcomingEventsLoading,
  upcommingBigPrizeEvent,
  setIsLoadingUpcommingBigPrizeEvent,
  eventsJoined,
  statusProofUploaded,
  setLoadingNotify,
  setNotifyProof,
  setNotifyCloseEvent,
  setUsersEvent,
  setPastEvents,
  setLoadingPastEvents,
  setErrorProof,
  setConfirmEvent,
  setToValidation,
  eventsToApprove,
  isChangeApproved,
} from "../reducers/_events.reducers";
import { loading as paymentLoading } from "../reducers/reducer.payments";
import { setEventStatusPay } from "../reducers/reducer.payments";

const setAssetsFn = (dispatch, loaded, btn, loading) => {
  dispatch(
    setAssets({
      loaded: loaded,
      btnLoading: btn,
      loading: loading,
    })
  );
};

export const getEvents =
  (query = "") =>
  (dispatch) => {
    setAssetsFn(dispatch, true, false, true);
    api
      .get(`/user/events/my-events?q=${query}`)
      .then((response) => dispatch(events(response.data)))
      .catch((error) => console.log(error.response.data))
      .then(() => setAssetsFn(dispatch, true, false, false));
  };

export const getEventsToApprove = () => (dispatch) => {
  setAssetsFn(dispatch, true, false, true);
  api
    .get(`/user/events/to-approve`)
    .then((response) => {
      dispatch(eventsToApprove(response.data.data));
    })
    .catch((error) => console.log(error.response.data))
    .then(() => setAssetsFn(dispatch, true, false, false));
};

export const changeStatusEvent = (uuid, type) => (dispatch) => {
  setAssetsFn(dispatch, true, false, true);
  api
    .post(`/user/events/change-status/${uuid}/${type}`)
    .then((response) => {
      dispatch(isChangeApproved(true));
    })
    .catch((error) => console.log(error.response.data))
    .then(() => setAssetsFn(dispatch, true, false, false));
};

export const getEventsJoined =
  (query = "") =>
  (dispatch) => {
    setAssetsFn(dispatch, true, false, true);
    api
      .get(`/user/events/my-events-joined?q=${query}`)
      .then((response) => dispatch(eventsJoined(response.data)))
      .catch((error) => console.log(error.response.data))
      .then(() => setAssetsFn(dispatch, true, false, false));
  };

export const publishEvent = (uuid) => (dispatch) => {
  setAssetsFn(dispatch, false, false, true);
  api
    .post("/publish-event/" + uuid, null)
    .then((response) => {
      setAssetsFn(dispatch, false, false, false);
      if (!response.data.error) {
        window.location.reload();
      }
    })
    .catch((error) => {
      setAssetsFn(dispatch, false, false, false);
    })
    .then(() => setAssetsFn(dispatch, false, false, false));
};

export const addEvent = (data) => (dispatch) => {
  dispatch(paymentLoading(true));
  setAssetsFn(dispatch, false, true, false);
  dispatch(resetErrors());
  api
    .post("/events", data)
    .then((response) => {
      dispatch(showEvent(response.data));
      dispatch(paymentLoading(false));
      dispatch(
        alert({
          status: true,
          message: "Success",
          style: "green",
        })
      );
    })
    .catch((error) => {
      dispatch(paymentLoading(false));
      dispatch(
        errors({
          status: true,
          message: error.response.data.message,
          messages: error.response.data.errors,
        })
      );
    })
    .then(() => setAssetsFn(dispatch, false, false, false));
};

export const updateEvent = (data, event_id) => (dispatch) => {
  setAssetsFn(dispatch, false, true, false);
  dispatch(resetErrors());
  api
    .post(`/update-event/${event_id}`, data)
    .then((response) => {
      dispatch(
        alert({
          status: true,
          message: "Success",
          style: "green",
        })
      );
    })
    .catch((error) =>
      dispatch(
        errors({
          status: true,
          message: error.response.data.message,
          messages: error.response.data.errors,
        })
      )
    )
    .then(() => setAssetsFn(dispatch, false, false, false));
};

export const deleteEvent = (event_uuid) => async (dispatch) => {
  try {
    await api.delete(`/events/${event_uuid}`);
    dispatch(onDelete());
    dispatch(
      alert({
        status: true,
        message: "",
        style: "",
      })
    );
  } catch (error) {
    console.error(error);
  }
};

export const eventTypesAction = () => (dispatch) => {
  api
    .get("/event-types")
    .then((response) => {
      if (response.status == 200) {
        const responseTypes = response.data.data;

        const data = responseTypes.map((type) => {
          return {
            name: type.name,
            slug: type.slug_name,
            value: type.id,
          };
        });
        dispatch(types(data));
      }
    })
    .catch((error) => console.log(error));
  // .then(() => console.log("Completed"));
};

export const getBigPrizeUpcomming =
  (limit = 1) =>
  (dispatch) => {
    dispatch(setIsLoadingUpcommingBigPrizeEvent(true));
    api
      .get("/get-big-prize-upcomming/" + limit)
      .then((response) => {
        if (response.status == 200) {
          const data = response.data.data;

          dispatch(upcommingBigPrizeEvent(data));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setIsLoadingUpcommingBigPrizeEvent(false));
      });
    // .then(() => console.log("Completed"));
  };

export const eventExplorerAction =
  (category = "live_events", filterCategory = "", search = "", orderBy = "") =>
  (dispatch) => {
    dispatch(setLoadingExplorer(true));
    api
      .get(
        "/events-explorer?category=" +
          category +
          "&filter=" +
          filterCategory +
          "&search=" +
          search +
          "&order_by=" +
          orderBy
      )
      .then((response) => {
        dispatch(setLoadingExplorer(false));
        if (response.status == 200) {
          dispatch(
            setExplorerData({
              category,
              data: response.data.data,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(setLoadingExplorer(false));
      });
    // .then(() => console.log("Completed"));
  };

export const pastEventsAction =
  (category = "live_events", filterCategory = "", search = "", orderBy = "") =>
  (dispatch) => {
    dispatch(setLoadingPastEvents(true));
    api
      .get(
        "/past-events?category=" +
          category +
          "&filter=" +
          filterCategory +
          "&search=" +
          search +
          "&order_by=" +
          orderBy
      )
      .then((response) => {
        dispatch(setLoadingPastEvents(false));
        if (response.status == 200) {
          dispatch(setPastEvents(response.data.data));
        }
      })
      .catch((error) => {
        dispatch(setLoadingPastEvents(false));
      });
    // .then(() => console.log("Completed"));
  };

export const upcomingEventsAction = () => (dispatch) => {
  dispatch(upcomingEventsLoading(true));
  api
    .get("/up-comming-events")
    .then((response) => {
      if (response.status == 200) {
        dispatch(upcomingEvents(response.data.data));
      }
    })
    .catch((error) => console.log(error));
};

export const availableEventsAction = () => (dispatch) => {
  api
    .get("/available-events")
    .then((response) => dispatch(availableEvents(response.data)))
    .catch((error) => console.log(error));
  //  .then(() => );
};

export const recentEventsAction = () => (dispatch) => {
  api
    .get("/recent-events")
    .then((response) => dispatch(recentEvents(response.data)))
    .catch((error) => console.log(error));
  //  .then(() => );
};

export const getEvent = (event_id) => (dispatch) => {
  setAssetsFn(dispatch, false, false, true);
  api
    .get(`/events/${event_id}`)
    .then((response) => dispatch(showEvent(response.data)))
    .catch((error) => console.log(error));
  //  .then(() => );
};

export const joinEvent = (event_uuid) => async (dispatch) => {
  dispatch(loading(true));
  try {
    let response = await api.post(`/user-events`, { event_uuid: event_uuid });
    // console.log("EventActions:135",response);
    dispatch(loading(false));
    if (response.status === 200) {
      dispatch(setEventStatusPay(true));
      // window.location.reload();
    }
  } catch (error) {
    dispatch(loading(false));
    dispatch(
      paymentMode({
        active: true,
        response: error.response.data,
      })
    );
  }
};

export const actionNotifyProof = (event_id) => async (dispatch) => {
  dispatch(setLoadingNotify(true));
  dispatch(setNotifyProof(false));
  try {
    let response = await api.post(`/notify-user-proof/${event_id}`);
    dispatch(setLoadingNotify(false));
    if (response.status === 200) {
      dispatch(setNotifyProof(true));
    }
  } catch (error) {
    dispatch(setLoadingNotify(false));
  }
};

export const actionNotifyCloseEvent = (event_id) => async (dispatch) => {
  dispatch(setLoadingNotify(true));
  dispatch(setNotifyCloseEvent(false));
  try {
    let response = await api.post(`/notify-close-event/${event_id}`);
    dispatch(setLoadingNotify(false));
    if (response.status === 200) {
      dispatch(setNotifyCloseEvent(true));
    }
  } catch (error) {
    dispatch(setLoadingNotify(false));
  }
};

export const verifyParticipation = (event_uuid) => async (dispatch) => {
  try {
    let response = await api.get(`/user-events/${event_uuid}`);
    dispatch(eventEngagement(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const uploadProof = (data) => async (dispatch) => {
  setAssetsFn(dispatch, false, true, false);
  let errorStatus = false;
  try {
    dispatch(resetErrors());
    const response = await api.post("/update-user-events", data);
    if (response.status === 200) {
      dispatch(statusProofUploaded(true));
    }
  } catch (error) {
    errorStatus = true;
    dispatch(
      errors({
        status: true,
        message: error.response.data.message,
        messages: error.response.data.errors,
      })
    );
  } finally {
    // dispatch(modal(false));
    if (!errorStatus) {
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
    setAssetsFn(dispatch, false, false, false);
  }
};

export const getMyEvents = () => async (dispatch) => {
  setAssetsFn(dispatch, false, false, true);
  try {
    const response = await api.get("/user-events");
    dispatch(myEvents(response.data));
  } catch (error) {
    console.error(error);
  }
};

export const getProof = (event_uuid) => async (dispatch) => {
  setAssetsFn(dispatch, false, false, true);
  try {
    const response = await api.get(`/proof/${event_uuid}`);
    dispatch(proof(response.data));
  } catch (error) {
    console.error(error);
  }
};

export const assignPlace = (data) => async (dispatch) => {
  try {
    const response = await api.post("/assign-place", data);
    dispatch(proof(response.data));
  } catch (error) {
    console.log(error.response.data);
    if (error.response) {
      if (error.response.data?.message) {
        if (error.response.data.message) {
          dispatch(setErrorProof(error.response.data.message));
        }
      }
    }
  }
};
export const closeEvent = (uuid) => async (dispatch) => {
  await api.post("/close-event/" + uuid);
  window.location.reload();
};

export const confirmEvent = (data) => async (dispatch) => {
  try {
    await api.post("/confirm-event", data);
    dispatch(setConfirmEvent(true));
    window.location.reload();
  } catch (error) {
    dispatch(setErrorProof(error.response.data.message));

    setTimeout(() => {
      window.location.reload();
    }, 1500);
  }
};

export const eventToValidate = (data) => async (dispatch) => {
  try {
    await api.post("/event-to-validate", data);
    dispatch(setToValidation(true));
  } catch (error) {
    dispatch(setErrorProof(error.response.data.message));

    setTimeout(() => {
      window.location.reload();
    }, 1500);
  }
};

export const myRole = () => async (dispatch) => {
  dispatch(loading(true));
  try {
    const response = await api.get("/users/my/role");
    dispatch(knowRole(response.data));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(loading(false));
  }
};

export const getUserEvents = (event_uuid) => async (dispatch) => {
  dispatch(setUsersEvent([]));
  try {
    const response = await api.get("/user-in-events/" + event_uuid);
    if (response.status == 200) {
      // console.log(response.data);
      dispatch(setUsersEvent(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminButton from "../../../components/admin/admin.button";
import AdminInput from "../../../components/admin/admin.input";
import AdminAlert from "../../../components/admin/admin.alert";
import { updatePassword, closeAlert } from "../../../store/actions/_profile.actions";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function ProfileSecurity() {
   const [data, setData] = useState();
   const dispatch = useDispatch();
   const r_profile = useSelector((state) => state.profile);

   const handleChange = (event) => {
      setData({
         ...data,
         [event.target.name]: event.target.value,
      });
   };

   const handleSubmit = (event) => {
      event.preventDefault();
      dispatch(updatePassword(data));
   };

	useEffect( () => {
		if (r_profile.alert.status) {
			document.getElementById("update-password").reset();
		}
	},[r_profile.alert.status])

   return (
      <div className="w-10/12 mx-auto">
         <h1 className="text-right font-bold border-b border-gray-300 p-2  mb-10 italic block">
            Security
         </h1>
         <form action="" className="w-10/12 mx-auto" onSubmit={handleSubmit} id="update-password">
            <AdminAlert
               message={r_profile.alert.message}
               status={r_profile.alert.status}
               style={r_profile.alert.style}
               close={() => dispatch(closeAlert())}
            />
            <AdminInput
               className="mb-5"
               onChange={handleChange}
               type="password"
               id="password"
               placeholder="Old password"
               errors={r_profile.error}
               label="Old Password"
            />
            <AdminInput
               className="mb-5"
               onChange={handleChange}
               type="password"
               id="new_password"
               placeholder="New Password"
               errors={r_profile.error}
               label="New Password"
            />
            <AdminInput
               className="mb-5"
               onChange={handleChange}
               type="password"
               id="repeat_password"
               placeholder="Repeat new password"
               errors={r_profile.error}
               label="Repeat New password"
            />
            <div className="w-10/12 lg:w-1/3 flex justify-center items-center mx-auto">
               <AdminButton icon={faCheck} status={r_profile.btnLoading} text="Update Password	" />
            </div>
         </form>
      </div>
   );
}

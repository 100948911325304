import api from "../../utils/api";
import {
  login,
  logout,
  items_menu,
  errors,
  button_loading,
  verify,
  errorLogin,
  success,
  registered,
  setIsEmailVerified,
  setErrorVerifyEmail,
} from "../reducers/_auth.reducers";

const loginFn = async (data, dispatch) => {
  let data_login = {
    grant_type: "password",
    client_id: process.env.REACT_APP_CLIENT,
    client_secret: process.env.REACT_APP_SECRET,
    username: data.email,
    password: data.password,
    scope: "",
  };
  api
    .post(`${process.env.REACT_APP_AUTH}/token`, data_login)
    .then((response) => {
      dispatch(login(response.data));
      localStorage.setItem("access_token", response.data.access_token);
      localStorage.setItem("expires_in", response.data.expires_in);
      localStorage.setItem("refresh_token", response.data.refresh_token);
      localStorage.setItem("token_type", response.data.token_type);
      window.location.href = "/dashboard";
    })
    .catch((error) =>
      //  console.log(error.response)
      dispatch(
        errorLogin({
          status: true,
          message: error.response.data.hint || error.response.data.message,
        })
      )
    );
};

export const loginAction = (data) => (dispatch) => {
  dispatch(
    errorLogin({
      status: false,
      message: "",
    })
  );
  loginFn(data, dispatch);
};

export const logoutAction = () => (dispatch) => {
  dispatch(logout());
  localStorage.removeItem("access_token");
  localStorage.removeItem("expires_in");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("token_type");
  window.location.href = "/auth/login";
};

export const getItemsMenu = () => (dispatch) => {
  api
    .get("/menu")
    .then((response) => dispatch(items_menu(response.data)))
    .catch((error) => console.log(error));
};

export const registerUserAction = (data) => (dispatch) => {
  api
    .post("/users", data)
    .then((response) => {
      if (response.status === 200) {
        // loginFn(data, dispatch);
        dispatch(registered(true));
      }
    })
    .catch((error) => dispatch(errors(error.response.data)))
    //.catch((error) => console.log(error))
    .then(() => dispatch(button_loading(false)));
};


export const verifyEmailAction = (data) => (dispatch) => {
  api
    .post("/verify-email", data)
    .then((response) => {
      setErrorVerifyEmail(response.data)
      if (response.status === 200) {
        // loginFn(data, dispatch);
        dispatch(setIsEmailVerified(true));
      }
    })
    .catch((error) => dispatch(setErrorVerifyEmail(error.response.data)));
};



export const verifyAuth = () => (dispatch) => {
  api
    .get("/verify")
    .then((response) => {
      if (response.data.status === "ok") {
        dispatch(verify(true));
      } else {
        dispatch(verify(false));
      }
    })
    .catch((error) => dispatch(verify(false)));
};

export const RecoverPasswordAction = (data) => async (dispatch) => {
  dispatch(button_loading(true));
  try {
    await api.post("/forgot-password", data);
    dispatch(success({ status: true, message: "Check your email!" }));
  } catch (error) {
    // console.log(error)
    dispatch(
      errorLogin({
        status: true,
        message: error.response.data.message,
      })
    );
  } finally {
    dispatch(button_loading(false));
  }
};

export const ResetPasswordAction = (data) => async (dispatch) => {
  dispatch(button_loading(true));
  try {
    await api.post("/reset-password", data);
    window.location.href="/auth/login";
  } catch (error) {
    // console.log(error)
    dispatch(
      errorLogin({
        status: true,
        message: error.response.data.message,
      })
    );
  } finally {
    dispatch(button_loading(false));
  }
};

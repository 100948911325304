import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { styles, optionsSelect } from "../../../utils/constanst";
import AdminInput from "../../../components/admin/admin.input";
import AdminSelect from "../../../components/admin/admin.select";
import { t } from "i18next";

export default function EventInfo( { handleChange, handleCheckbox, data } ) {
  const [eventTypes, setEventTypes] = useState([]);
	const r_events = useSelector((state) => state.event);

  useEffect(() => {
    if(r_events.types){
      if(r_events.types.length > 0){
        setEventTypes(r_events.types);
      }
    }
  }, [r_events]);
  

  return (
    <fieldset className={`grid-cols-3  ${styles.fieldset}`}>
      <legend className={styles.legend}>Event Information</legend>
      <AdminInput
        label="title (*)"
        className="w-full col-span-3"
        onChange={handleChange}
        type="text"
        id="title"
        // placeholder="Title"
        errors={r_events.errors}
      />
      <AdminInput
        label="platform"
        className="w-full"
        onChange={handleChange}
        type="text"
        id="platform"
        // placeholder="Platform"
        errors={r_events.errors}
      />
      <AdminSelect
        label="type of event"
        className="w-full"
        placeholder={t('general.select')}
        onChange={handleChange}
        options={eventTypes}
        id="type_of_event"
        // placeholder="Type of event"
        errors={r_events.errors}
        value={data.type_of_event}
      />
      <AdminInput
        label="requirements"
        className="w-full"
        onChange={handleChange}
        type="text"
        id="requirements"
        // placeholder="Requirements"
        errors={r_events.errors}
      />
      {r_events.know_role !== 2 && (
        <>
          <div className={styles.checkboxGroup}>
            <input
              onChange={handleCheckbox}
              type="checkbox"
              id="big_prize"
              name="big_prize"
              checked={data.big_prize}
              className={styles.checkbox}
            />
            <label htmlFor="big_prize" className={styles.checkboxLabel}>
              Is it a Big Prize?
            </label>
          </div>
          <div className={styles.checkboxGroup}>
            <input
              onChange={handleCheckbox}
              type="checkbox"
              id="is_free_event"
              name="is_free_event"
              checked={data.is_free_event}
              className={styles.checkbox}
            />
            <label htmlFor="is_free_event" className={styles.checkboxLabel}>
              Is it a Free Event?
            </label>
          </div>
        </>
      )}
    </fieldset>
  );
}

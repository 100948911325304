export const truncateStr = (str, length = 20, ending = "...") => {
    return str.length > length ? str.substring(0, length) + ending : str;
}

export const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
}
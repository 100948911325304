import { useState, useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "react-alice-carousel/lib/alice-carousel.css";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

export const GeneralCarousel = ({ items, slise = 0 }) => {
  const [sliseCard, setSliseCard] = useState(slise);
  const [responsive, setResponsive] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [carouselItems, setCarouselItems] = useState([]);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (slise < 1) {
      if (width < 564) {
        setSliseCard(1);
      }

      if (width > 564 && width < 1024) {
        setSliseCard(2);
      }

      if (width > 1024) {
        setSliseCard(3);
      }

      setResponsive({
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
      });
    } else {
      setResponsive(null);
    }
  }, [width]);

  const slidePrev = () => {
    const newIndex = activeIndex + sliseCard;

    if (newIndex < 0) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndex - sliseCard);
    }
  };
  const slideNext = () => {
    const newIndex = activeIndex + sliseCard;

    if (newIndex > items.length) {
      setActiveIndex(items.length);
    } else {
      setActiveIndex(activeIndex + sliseCard);
    }
  };
  const syncActiveIndex = ({ item }) => setActiveIndex(item);

  useEffect(() => {
    setCarouselItems(items);
  }, [items]);

//   console.log(carouselItems)

  return (
    <>
      {carouselItems.length > 0 && (
        <div className="_general_carousel_cointainer">
          <AliceCarousel
            mouseTracking
            disableDotsControls
            disableButtonsControls
            items={carouselItems}
            activeIndex={activeIndex}
            responsive={responsive}
            onSlideChanged={syncActiveIndex}
          />

          {sliseCard < carouselItems.length && (
            <>
              <button
                onClick={slidePrev}
                className="carousel-btn carousel-btn-prev bg-yellow-500 font-bold py-2 px-4 mt-4 hover:text-yellow-800 active:scale-95"
              >
                <FontAwesomeIcon icon={faAngleLeft} className="text-xl" />
              </button>
              <button
                onClick={slideNext}
                className="carousel-btn carousel-btn-next bg-yellow-500 font-bold py-2 px-4 mt-4 hover:text-yellow-800 active:scale-95"
              >
                <FontAwesomeIcon icon={faAngleRight} className="text-xl" />
              </button>
            </>
          )}
        </div>
      )}
    </>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from "react-router-dom";
import { getEventsToApprove } from "../../../store/actions/_events.actions";
import { isChangeApproved } from "../../../store/reducers/_events.reducers";
import EventList from "./event-list";

export default function EventListAdminJudge() {
  const dispatch = useDispatch();
  const r_event = useSelector((state) => state.event);
//   const location = useLocation();

  useEffect(() => {
    dispatch(getEventsToApprove());
  }, [dispatch]);

  useEffect(() => {
    if (r_event.isChangeApproved) {
      dispatch(isChangeApproved(false));
      dispatch(getEventsToApprove());
    }
  }, [r_event.isChangeApproved]);

  return (
    <>
      <div className="w-full mx-auto pt-10">
        <div className="mb-5">
          <h1 className="font-bold text-xl mb-5">EVENTS TO APPROVE</h1>
          <hr />
        </div>
        <EventList data={r_event.eventsToApprove} type="approve" />
      </div>
    </>
  );
}

/* eslint-disable eqeqeq */
import { useEffect } from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { verifyParticipation, getEvent } from "../../../store/actions/_events.actions";
//import { verifyAuth } from "../auth/_auth.actions";
import Loading from "../../../components/Loading";
import { JoinEvent } from "./JoinEvent";
import { EventHeader } from "./EventHeader";
import { EventSpecification } from "./EventSpecification";
import { EventBody } from "./EventBody";


export default function Events() {
  const r_event = useSelector((state) => state.event);
  const r_auth = useSelector((state) => state.auth);
  const { event_id } = useParams();
  const dispatch = useDispatch();

  //useEffect(() => {
  //   dispatch(verifyAuth());
  //}, [dispatch]);

  useEffect(() => {
    dispatch(verifyParticipation(event_id));
  }, [dispatch, event_id]);

  useEffect(() => {
    window.scrollTo(0, 100);
    dispatch(getEvent(event_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      {!r_auth.logged && r_event.join_event && <Navigate to="/auth/login" />}
      {r_auth.logged && r_event.join_event && (
        <JoinEvent eventData={r_event.event} />
      )}
      {/* <section className="w-full min-h-[200px] md:min-h-[600px] bg-cover bg-no-repeat bg-[url('../img/banners/banner-event-desktop.jpg')] bg-center flex justify-center items-center animate__animated animate__fadeIn">
        <div className="text-white text-3xl lg:text-6xl uppercase font-bold flex flex-col">
          <div className="aldo">
            Play <span className="text-yellow-c1">Now</span>
          </div>
        </div>
      </section> */}
      {r_event.loading && <Loading color="black" />}
      {!r_event.loading && r_event.event && (
        // <section className="w-full h-auto p-5 xl:p-10 bg-no-repeat bg-cover bg-[url('../img/backgrounds/white-background.png')] flex flex-col justify-center items-center animate__animated animate__fadeIn">
        <section className="
          w-full 
          h-auto 
          p-5 
          xl:p-10 
          flex 
          flex-col 
          justify-center 
          items-center 
          animate__animated animate__fadeIn
        ">

          <div className="mt-5 md:px-[0px] lg:px-[100px] w-full sm:px-[0px]">
            <div className="md:flex">

              <div className="md:w-4/6 sm:w-2/2 md:pr-5 md:mr-10">
                <EventHeader />
                <EventBody />
              </div>
              <EventSpecification />

            </div>
          </div>


            

        </section>
      )}
    </>
  );
}

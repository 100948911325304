import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminButton from "../../../components/admin/admin.button";
import AdminInput from "../../../components/admin/admin.input";
import AdminAlert from "../../../components/admin/admin.alert";
import {
  updateProfile,
  closeAlert,
} from "../../../store/actions/_profile.actions";
import { styleAccount } from "../../../utils/constanst";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ProfileBanner from "./profile.banner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";

export default function ProfileAccount() {
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const r_profile = useSelector((state) => state.profile);

  useEffect(() => {
    setData({
      name: r_profile.user.name,
      lastname: r_profile.user.lastname,
      email: r_profile.user.email,
      birthdate: r_profile.user.birthdate,
      username: r_profile.user.username,
      facebook: r_profile.user.facebook,
      twitter: r_profile.user.twitter,
      instagram: r_profile.user.instagram,
      twitch: r_profile.user.twitch,
      youtube: r_profile.user.youtube,
      zelle: r_profile.user.zelle,
      paypal: r_profile.user.paypal,
      venmo: r_profile.user.venmo,
      cashapp: r_profile.user.cashapp,
      about_me: r_profile.user.about_me || "",
    });
  }, [r_profile.user]);

  const handleChange = (event) => {



    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!data.username) {
      toast.error("Username is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    if(!data.name || !data.lastname){
      toast.error("The name and lastname are required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    if(!data.email){
      toast.error("Email are required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    if(!data.birthdate){
      toast.error("You must be of legal age", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    
    if(data.birthdate){
      let userBirthDate = moment(data.birthdate);
      let actualDate = moment();
      let diff = actualDate.diff(userBirthDate, 'years');
      
      if(!diff || diff < 18){
        toast.error("You must be of legal age", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }

    }

    
    // console.log(data)
    dispatch(updateProfile(data));
  };

  const ckeditorValue = (value) => {
    setData({
      ...data,
      about_me: value,
    });
  };

  return (
    <div className="w-full lg:w-10/12 mx-auto">
      <ToastContainer />

      <h1 className="text-right font-bold border-b border-gray-300 p-2  mb-10 italic block">
        Your Account
      </h1>
      {data && (
        <>
          <ProfileBanner />
          <form action="" className="w-full mx-auto" onSubmit={handleSubmit}>
            <AdminAlert
              message={r_profile.alert.message}
              status={r_profile.alert.status}
              style={r_profile.alert.style}
              close={() => dispatch(closeAlert())}
            />
            <fieldset className={styleAccount.fieldset}>
              <legend className={styleAccount.legend}>Personal Info</legend>
              <div className="flex flex-col items-center lg:flex-row w-full lg:space-x-4 mb-3">
                <AdminInput
                  className="basis-full mb-2 lg:mb-0 lg:basis-1/3"
                  onChange={handleChange}
                  type="text"
                  id="username"
                  placeholder="Your username"
                  errors={r_profile.error}
                  label="username"
                  value={data.username}
                />
                <AdminInput
                  className="basis-full mb-2 lg:mb-0 lg:basis-1/2"
                  onChange={handleChange}
                  type="text"
                  id="name"
                  placeholder="Your name"
                  errors={r_profile.error}
                  label="Name"
                  value={data.name}
                />
                <AdminInput
                  className="basis-full mb-2 lg:mb-0 lg:basis-1/2"
                  onChange={handleChange}
                  type="text"
                  id="lastname"
                  placeholder="Your lastname"
                  errors={r_profile.error}
                  label="Lastname"
                  value={data.lastname}
                />
              </div>
              <div className="flex flex-col items-center lg:flex-row w-full lg:space-x-4">
                <AdminInput
                  className="basis-full mb-2 lg:mb-0 lg:basis-1/3"
                  onChange={handleChange}
                  type="email"
                  id="email"
                  placeholder="Your email"
                  errors={r_profile.error}
                  label="email"
                  value={data.email}
                />
                <AdminInput
                  className="basis-full mb-2 lg:mb-0 lg:basis-1/3"
                  onChange={handleChange}
                  type="date"
                  id="birthdate"
                  placeholder="Your birthdate"
                  errors={r_profile.error}
                  label="birthdate"
                  value={data.birthdate}
                />
              </div>
              <div className="w-full mt-4 profile-account no-tailwindcss-base">
                <CKEditor
                  id="ckeditor_profile"
                  editor={ClassicEditor}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    // console.log( 'Editor is ready to use!', editor );
                  }}
                  config={{
                    toolbar: {
                      items: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "|",
                        "outdent",
                        "indent",
                        "|",
                        "blockQuote",
                        "insertTable",
                        "mediaEmbed",
                        "undo",
                        "redo",
                      ],
                    },
                    table: {
                      contentToolbar: [
                        "tableColumn",
                        "tableRow",
                        "mergeTableCells",
                      ],
                    },
                  }}
                  data={data.about_me}
                  onChange={(event, editor) => {
                    ckeditorValue(editor.getData());
                  }}
                />
              </div>
            </fieldset>
            <fieldset className={styleAccount.fieldset}>
              <legend className={styleAccount.legend}>Social Media</legend>
              <div className="flex flex-col items-center lg:flex-row w-full lg:space-x-4 mb-3">
                <AdminInput
                  className="basis-full mb-2 lg:mb-0 lg:basis-1/2"
                  onChange={handleChange}
                  type="text"
                  id="facebook"
                  placeholder="Facebook"
                  errors={r_profile.error}
                  label="Facebook"
                  value={data.facebook}
                />
                <AdminInput
                  className="basis-full mb-2 lg:mb-0 lg:basis-1/2"
                  onChange={handleChange}
                  type="text"
                  id="twitter"
                  placeholder="Twitter"
                  errors={r_profile.error}
                  label="Twitter"
                  value={data.twitter}
                />
              </div>
              <div className="flex flex-col items-center lg:flex-row w-full lg:space-x-4 mb-3">
                <AdminInput
                  className="basis-full mb-2 lg:mb-0 lg:basis-1/2"
                  onChange={handleChange}
                  type="text"
                  id="instagram"
                  placeholder="Instagram"
                  errors={r_profile.error}
                  label="Instagram"
                  value={data.instagram}
                />
                <AdminInput
                  className="basis-full mb-2 lg:mb-0 lg:basis-1/2"
                  onChange={handleChange}
                  type="text"
                  id="twitch"
                  placeholder="Twitch"
                  errors={r_profile.error}
                  label="Twitch"
                  value={data.twitch}
                />
              </div>
              <div className="flex w-full space-x-4">
                <AdminInput
                  className="basis-full mb-2 lg:mb-0"
                  onChange={handleChange}
                  type="text"
                  id="youtube"
                  placeholder="Youtube"
                  errors={r_profile.error}
                  label="Youtube"
                  value={data.youtube}
                />
              </div>
            </fieldset>
            <fieldset className={styleAccount.fieldset}>
              <legend className={styleAccount.legend}>Collect Pay Info</legend>
              <div className="flex flex-col items-center lg:flex-row w-full lg:space-x-4 mb-3">
                <AdminInput
                  className="basis-full mb-2 lg:mb-0 lg:basis-1/2"
                  onChange={handleChange}
                  type="text"
                  id="zelle"
                  placeholder="Zelle"
                  errors={r_profile.error}
                  label="Zelle account"
                  value={data.zelle}
                />
                <AdminInput
                  className="basis-full mb-2 lg:mb-0 lg:basis-1/2"
                  onChange={handleChange}
                  type="text"
                  id="paypal"
                  placeholder="Paypal"
                  errors={r_profile.error}
                  label="Paypal Account"
                  value={data.paypal}
                />
              </div>
              <div className="flex flex-col items-center lg:flex-row w-full lg:space-x-4 mb-3">
                <AdminInput
                  className="basis-full mb-2 lg:mb-0 lg:basis-1/2"
                  onChange={handleChange}
                  type="text"
                  id="venmo"
                  placeholder="Venmo"
                  errors={r_profile.error}
                  label="Venmo account"
                  value={data.venmo}
                />
                <AdminInput
                  className="basis-full mb-2 lg:mb-0 lg:basis-1/2"
                  onChange={handleChange}
                  type="text"
                  id="cashapp"
                  placeholder="CashApp"
                  errors={r_profile.error}
                  label="CashApp Account"
                  value={data.cashapp}
                />
              </div>
            </fieldset>
            <div className="w-1/2 lg:w-1/3 flex justify-center items-center mx-auto">
              <AdminButton
                icon={faCheck}
                status={r_profile.btnLoading}
                text="Update"
              />
            </div>
          </form>
        </>
      )}
    </div>
  );
}

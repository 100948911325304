import { useEffect } from 'react'
import Header from './components/Header';
const style = {
   ul_1: "",
   ul_2: "ml-10 my-5",
   ul_3: "ml-14 my-5",
   li_main: "my-10 text-lg",
   li: "my-2 text-base",
   num: "font-bold text-gray-600",
};
export default function Terms() {

	useEffect( () => {
		window.scrollTo(0, 100);
	},[])
	
   return (
      <>
         <Header/>
         <div className='mt-5 m-auto text-center'>
            <button className='bg-yellow-c1 text-white mx-5 px-[20px] py-[10px] rounded-[10px]'>Terms and Conditions</button>
            <button className='bg-[#FFC3004D] text-white mx-5 px-[20px] py-[10px] rounded-[10px]'>Privacy Policy</button>
            <button className='bg-[#FFC3004D] text-white mx-5 px-[20px] py-[10px] rounded-[10px]'>User Policy</button>
         </div>
         <div className="w-10/12 mx-auto pb-10">
            <h2 className="text-center uppercase font-bold my-10">
               General Statement of Function and Terms
            </h2>
            <p className="indent-10 my-5">
               Due to ITHL being worldwide, each country has specific requirements
               that ITHL has not listed out but at any time may enforce based on
               local laws and requirements. It is the individual's sole
               responsibility to sign up and register with ITHL to know their local
               laws and requirements for joining Competitive Leagues and receiving
               prizes associated with winnings at competitive leagues. ITHL
               function is to compete in Treasure Hunting on digital platforms, and
               there are no stakes or wagering, but it is for individuals to
               compete against each other in a Race based on their skills, effort,
               and personal abilities.{" "}
            </p>
            <p className="indent-10 my-5">
               ITHL's transformative use and experience in the digital, virtual,
               and real world is to build a new platform for an individual to
               compete in Treasure Hunting. In no way is the ITHL league dependent
               on any specific digital platform, digital environment, real-world
               location, show, movie, or video game, but it is a stand-alone
               experience and platform using both real-world and digital
               environments.
            </p>
            <ul className={style.ul_1}>
               <li className={style.li_main}>
                  <span className={style.num}>1.1</span> Code of Behavior and
                  Communications
                  <ul className={style.ul_2}>
                     <li className={style.li}>
                        <span className={style.num}>1.1.1</span> All insults that
                        happen on ITHL platform may be punished. It includes but is
                        not limited to comment sections, News Feeds Message boards,
                        Discord CHannels, and chats that occur within the Platform
                        networked environments.
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>1.1.2</span> It is the
                        discretion of ITHL administration to determine the
                        penalties, and consequences of the offenses. ITHL in full
                        does not condone anything in regard to the below items.
                        <ul className={`${style.ul_3} list-disc`}>
                           <li>The threat of Violence and Physical Harm</li>
                           <li>Sexual harassment</li>
                           <li>Anything Pornographic in nature</li>
                           <li>Stalking</li>
                        </ul>
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>1.1.3</span> Spamming and
                        continuous contact with specific individuals, groups,
                        channels, forums, or comment sections with the intent to
                        damage or promote none ITHL messages and agendas is not
                        allowed.{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>1.1.4</span> Cheating of any
                        kind is not allowed by ITHL. ITHL will police to the best
                        of its ability and determine consequences for cheating
                        based on the circumstances of the Event.{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>1.1.5</span> Cheating by
                        working with employees or individuals familiar with the
                        Treasure Hunt and Events themselves will be permanently
                        banned and even prosecuted for damages to the Event and
                        other players.
                     </li>
                  </ul>
               </li>
               <li className={style.li_main}>
                  <span className={style.num}>1.2</span> Account and General
                  <ul className={style.ul_2}>
                     <li className={style.li}>
                        <span className={style.num}>1.2.1</span> International
                        Treasure Hunters League Administration has full rights to
                        determine if its platform is being used correctly. The ITHL
                        administration's responsibility is to make sure fair
                        playing conditions are observed, and the League Environment
                        can be supported to meet the League Players' needs.{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>1.2.2</span> An ITHL account is
                        only allowed to be held by those 13 years or older.{" "}
                     </li>
                     <li>
                        <span className={style.num}>1.2.3</span> All information on
                        the account must be kept up to date by the account holder.{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>1.2.4</span> In the case of
                        prizes, winnings, and points assignment, all information
                        and contact information is up to date for ITHL to be in
                        proper communication as is needed to League Members.{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>1.2.5</span> Individuals who
                        create accounts agree that it is their sole responsibility
                        to have all the needed equipment, hardware, or any
                        additional supplies to participate in the Events of ITHL
                        personally.{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>1.2.6</span> Registered League
                        Accounts wave any right to claim "unfairness" or lack of
                        Events on any specific platform. All Events and Treasure
                        Hunts will be at the discretion of ITHL Administration.{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>1.2.7</span> Players are to
                        have nonoffensive images associated with their Profile
                        images uploaded to their accounts.{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>1.2.8</span> Users ASSUME ALL
                        RISKS related to creating digital presence and accounts.
                        Users RELEASES, WAIVES and COVENANTS NOT TO SUE
                        INTERNATIONAL TREASURE HUNTERS LEAGUE. ITHL user ASSUMES
                        ALL RISKS, hazards, and dangers arising from or relating to
                        account creation on internationaltreasurehuntersleague.com
                        and related activities, from all known and unknown causes
                        and obvious and hidden conditions, including, but not
                        limited to, personal injury (including identity theft),
                        which includes, without limitation, damages resulting from
                        fraud or communications.{" "}
                     </li>
                  </ul>
               </li>
               <li className={style.li_main}>
                  <span className={style.num}>1.3.0</span> Refund Policy
                  <ul className={style.ul_2}>
                     <li className={style.li}>
                        <span className={style.num}>1.3.1</span> Refunds are not
                        available to individuals and accounts on any level.{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>1.3.2</span> Individuals take
                        on the full understanding that they have all information
                        needed to complete the Event in full upon registration.{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>1.3.3</span> It is up to each
                        individual to determine each competitor's platforms.{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>1.3.4</span> it is up to the
                        ITHL administration to refund for the extenuating
                        circumstance if needed.{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>1.3.5</span> Users agree that
                        once a player is registered within the platform, they can
                        choose to participate in whatever Event they would like
                        based on their options. Therefore, account Types vary based
                        on the individual's desire to compete.{" "}
                     </li>
                  </ul>
               </li>
               <li className={style.li_main}>
                  <span className={style.num}>1.4.0</span> Account Types and Event
                  registration:
                  <ul className={style.ul_2}>
                     <li className={style.li}>
                        <span className={style.num}>1.4.1</span> STARTER account
                        requires individuals to pay entry at the Event's
                        registration time. This applies to enter all events.{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>1.4.2</span> COMPETITOR account
                        allows individual registration into 12 Events within each
                        calendar month.{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>1.4.3</span> PROFESSIONAL
                        account allows individual registration into 25 events
                        within each calendar month.
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>1.4.4</span> COMPETITOR and
                        PROFESSIONAL accounts do not allow individuals to "roll"
                        forward any registrations but are only associated with the
                        Calendar month of the payment and registration.{" "}
                     </li>
                  </ul>
               </li>
               <li className={style.li_main}>
                  <span className={style.num}>1.5.0</span> Participation
                  <ul className={style.ul_2}>
                     <li className={style.li}>
                        <span className={style.num}>1.5.1</span> Users must own or
                        have legal access to the Show, Movie, Game, or real-world
                        location to participate in the Event. If a USER
                        participates while not using a legally accessed
                        environment, the ITHL administration will review it for
                        corrective action.{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>1.5.2</span> ITHL reserves the
                        sole right to any ITHL event, and no other individuals or
                        businesses are authorized to host ITHL events, only through
                        the ITHL platform.{" "}
                     </li>
                  </ul>
               </li>
               <li className={style.li_main}>
                  <span className={style.num}>2.0.0</span> Seasons and Events
                  <ul className={style.ul_2}>
                     <li className={style.li}>
                        <span className={style.num}>2.1.1</span> Season is roughly
                        four months where registered individuals can join events,
                        and upon completion of the Event, they earn points pushing
                        them up on the Leaderboard.{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>1.6.2</span> Those at the top
                        of the Leaderboard will be invited to participate in the
                        Season Finals for a week. During that week, points earned
                        will not go towards any Season. Instead, it will be
                        considered "pre-season".{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>2.1.2</span> All Events during
                        the season are considered competitive in nature. All
                        registered users are expected to compete in fairness and
                        equity with each other. If Events or Season parameters are
                        confusing at any time, it is up to the registered
                        individual to contact ITHL through the proper channels.{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>2.1.3</span> Event details and
                        information will always provide the terms for completion,
                        start time and date, end time and date, winnings associated
                        with first place, and the point breakdown from completing
                        the Event.{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>2.2.1</span> All registered
                        individuals who are also registered with an event must
                        submit their proof to cross the virtual finish line
                        associated with each Event. The virtual finish line will be
                        based on submitting all the required images and information
                        associated with the Event.{" "}
                     </li>
                     <li className={style.li}>
                        <span className={style.num}>2.3.1</span> Any broadcasting
                        of an Event and an individual participating in the
                        registered Event is authorized.
                     </li>
                     <li>
                        <span className={style.num}>2.4.1</span> ITHL will
                        determine winners and placement as soon as they are able
                        from the completion of an event. Order will be determined
                        as individuals submit according to the Event's
                        requirements.{" "}
                     </li>
                  </ul>
               </li>
            </ul>
         </div>
      </>
   );
}

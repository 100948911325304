import React, { useEffect, useState } from 'react'
import { CustomCarousel } from '../customCarousel';
import { EventCarouselCard } from '../EventCarouselCard';
import { useSelector, useDispatch } from "react-redux";
import { upcomingEventsAction } from '../../store/actions/_events.actions';
import Loading from '../Loading';
import { t } from "i18next";

import './styles.scss'
import DataNotFound from '../DataNotFound/DataNotFound';
import { UpcomingBanners } from './components';
export const UpComingEvents = () => {

  const dispatch = useDispatch();
  const eventState = useSelector((state) => state.event);
  const [upCommingEvents, setUpCommingEvents] = useState([]);
  const [isLoading, setIsloading] = useState([]);
  const [slidesUpCommingEvents, setSlidesUpCommingEvents] = useState([]);

  useEffect(() => {
    setIsloading(true);
    dispatch(upcomingEventsAction());
  }, []);

  useEffect(() => {
    if (eventState.upcoming_events) {
      setIsloading(eventState.upcoming_events_loaded);
      setUpCommingEvents(eventState.upcoming_events);
    }
  }, [eventState]);

  useEffect(() => {
    setSlidesUpCommingEvents([]);
    if (upCommingEvents.length > 0) {

      upCommingEvents.map(event => {
        setSlidesUpCommingEvents(state => [
          ...state,
          {
            key: event.event_uuid,
            content: <EventCarouselCard data={event} />
          }
        ]);
      })
    }

    // console.log(slidesupCommingEvents);
  }, [upCommingEvents])


  return (
    <>
      <div className="flex flex-col w-full bg-cover bg-no-repeat bg-main-bg bg-center justify-center mt-5 mb-20">

        <h2 className="text-5xl m-5 aldo">{t("home.up_comming.title")}</h2>

        {isLoading && <Loading />}

        {(!isLoading && slidesUpCommingEvents.length > 3) &&
          <div className='my-5 md:my-60 mx-5'>
            <div className='hidden md:block'>
              <CustomCarousel slides={slidesUpCommingEvents} />
            </div>
            <div className='block md:hidden'>
              <div className='flex overflow-x-auto space-x-5'>
                {upCommingEvents.map((event, index) => {
                  return <EventCarouselCard data={event} key={index} />
                })}
              </div>
            </div>
          </div>
        }

        {(!isLoading && slidesUpCommingEvents.length <= 3) && (
          <div className='mx-5'>
            
            <div className='flex overflow-x-auto space-x-5'>
                {upCommingEvents.map((event, index) => {
                  return <EventCarouselCard data={event} key={index} />
                })}
              </div>
          </div>
        )}

        {
          (!isLoading && slidesUpCommingEvents.length < 1) && (
            <UpcomingBanners />
          )
        }

      </div>
    </>
  )
}

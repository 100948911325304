/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import AdminSelect from "../../components/admin/admin.select";
import {
  getProof,
  assignPlace,
  confirmEvent,
  actionNotifyProof,
  actionNotifyCloseEvent,
  eventToValidate,
  changeStatusEvent,
  closeEvent,
} from "../../store/actions/_events.actions";
import { modal } from "../../store/_main.reducers";
import moment from "moment/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove, faXmark } from "@fortawesome/free-solid-svg-icons";
import checkPayment from "../../assets/img/icons/payment-check-satatus.png";
import {
  isChangeApproved,
  setConfirmEvent,
  setErrorProof,
  setNotifyCloseEvent,
  setNotifyProof,
} from "../../store/reducers/_events.reducers";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import Timezone from "../../hooks/timezone";
import { t } from "i18next";
const style = {
  yellow:
    "rounded-[8px] px-5 py-3 bg-[#FFC300] text-[#061322] font-bold hover:opacity-80 mx-[5px]",
  bluedark:
    "rounded-[8px] px-5 py-3 bg-[#003566] text-[#FFFEFA] font-bold hover:opacity-80 mx-[5px]",
};
export default function Judge() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { event_uuid } = useParams();
  const r_event = useSelector((state) => state.event);
  const r_profile = useSelector((state) => state.profile);
  const [image, setImage] = useState(undefined);
  const [comment, setComment] = useState(undefined);
  const r_main = useSelector((state) => state.main);
  const [winnerModal, setWinnerModal] = useState(false);
  const [loadingWinner, setLoadingWinner] = useState(false);
  const [identifyProof, setIdentifyProof] = useState(null);
  const [isCloseAlert, setIsCloseAlert] = useState(false);
  const [dirtyWinners, setDirtyWinners] = useState(false);

  const [localStarTime, setLocalStarTime] = useState(null);
  const [localEndDate, setLocalEndDate] = useState(null);

  useEffect(() => {
    if (r_event) {
      if (r_event.proof) {
        if (r_event.proof.length > 0) {
          let data = r_event.proof[0];

          const _localStarTime = Timezone({
            date: data.start_date + " " + data.start_time + ":00",
            formatStr: "YYYY-MM-DD HH:mm:ss",
          });
          setLocalStarTime(_localStarTime);

          const _localEndDate = Timezone({
            date: data.end_date + " " + data.end_time + ":00",
            formatStr: "YYYY-MM-DD HH:mm:ss",
          });
          setLocalEndDate(_localEndDate);

          console.log(_localStarTime);
        }
      }
    }
  }, [r_event]);

  useEffect(() => {
    dispatch(getProof(event_uuid));
  }, [dispatch, event_uuid]);

  const openModal = (img, comment, nProof) => {
    setIdentifyProof(nProof);
    setImage(img);
    setComment(comment);
    dispatch(modal(true));
  };

  useEffect(() => {
    if (r_event.isChangeApproved) {
      dispatch(isChangeApproved(false));
      navigate("/settings/users/profile/admin-judge-list");
    }
  }, [r_event.isChangeApproved]);

  const handleSelect = (event, user_event_id, event_uuid, user_id) => {
    setDirtyWinners(true);
    let data = {
      type: event.target.value,
      user_event_id: user_event_id,
      event_uuid: event_uuid,
      user_id: user_id,
    };
    dispatch(assignPlace(data));
    dispatch(getProof(event_uuid));
  };

  useEffect(() => {
    if (r_event.errorProf) {
      dispatch(setErrorProof(""));
      toast.error(r_event.errorProf, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }, [r_event.errorProf]);

  // useEffect(()=>{
  //   if(r_event.eventConfirm){

  //     toast.success('Participants will receive notification of their position by mail', {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //     });
  //     dispatch(setConfirmEvent(false));
  //     setTimeout(() => {
  //        window.location.reload();
  //      }, 2000);
  //   }
  // },[r_event.eventConfirm]);

  useEffect(() => {
    if (r_event.eventToConfirmation) {
      toast.success("Event score in review", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch(setConfirmEvent(false));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [r_event.eventToConfirmation]);

  const openModalConfirm = () => {
    setWinnerModal(true);
    dispatch(modal(true));
  };

  const confirmSend = (event_uuid) => {
    toast.info("Updating data", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    // setLoadingWinner(true);
    // dispatch(modal(true));
    // setWinnerModal(false);

    dispatch(confirmEvent({ event_uuid: event_uuid }));
    // dispatch(eventToValidate({ event_uuid: event_uuid }));
  };

  const toValidate = (event_uuid) => {
    setLoadingWinner(true);
    // dispatch(modal(false));
    // setWinnerModal(false);

    // dispatch(confirmEvent({ event_uuid: event_uuid }));
    dispatch(eventToValidate({ event_uuid: event_uuid }));
  };

  const changeStatusToApprove = (uuid, type) => {
    dispatch(changeStatusEvent(uuid, type));
  };

  // console.log(r_event.proof[0].user_events);

  const handleNotifyProof = (event_uuid) => {
    dispatch(modal(true));
    dispatch(actionNotifyProof(event_uuid));
  };
  const handleNotifyCloseEvent = (event_uuid) => {
    dispatch(modal(true));
    dispatch(actionNotifyCloseEvent(event_uuid));
  };

  const handleCloseEvent = (uuid) => {
    toast.info("Closing event", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

    dispatch(closeEvent(uuid));
  };

  return (
    <>
      <ToastContainer />
      <div className="w-full text-xs mx-auto mt-10">
        {r_event.loading && <Loading />}
        {!r_event.loading && r_event.proof && r_event.proof.length > 0 && (
          <>
            <div className="mb-8">
              <Link
                to="/settings/users/profile/my-events/created/live"
                className="text-[#00000080]"
              >
                Events /
              </Link>
              <span className="ml-1">{r_event.proof[0].title}</span>
            </div>
            {}
            <div className="float-right">
              <button
                className={
                  r_event.proof[0].closed_event == 0
                    ? style.yellow
                    : style.yellow + " opacity-80"
                }
                disabled={r_event.proof[0].closed_event != 0}
                onClick={() => {
                  handleNotifyProof(event_uuid);
                }}
              >
                Send proofs reminder
              </button>

              <button
                className={
                  r_event.proof[0].closed_event == 0
                    ? style.bluedark
                    : style.bluedark + " opacity-80"
                }
                disabled={r_event.proof[0].closed_event != 0}
                onClick={() => {
                  handleNotifyCloseEvent(event_uuid);
                }}
              >
                Send notification of closure
              </button>
            </div>

            <h1 className="uppercase font-bold mb-10 border-b">
              {r_event.proof[0].title}
            </h1>
            {r_event.proof[0]?.user_events &&
            r_event.proof[0].user_events.length > 0 ? (
              <>
                {!isCloseAlert &&
                  r_event.proof[0].validate_confirmation_status != null && (
                    <>
                      {r_event.proof[0].validate_confirmation_status == 2 && (
                        <>
                          <div
                            className="text-[13px] my-5 alert bg-green-100 border-t-4 border-t-green-300 py-5 px-6 mb-3 text-base text-black-700 inline-flex items-center w-full alert-dismissible fade show"
                            role="alert"
                          >
                            <strong className="">
                              {t("events.accepted")}
                              {/* {The list of winners has been approved and the event has been closed, you can see the results in the "
                            <Link
                              className="text-[#00068E] underline cursor-pointer"
                              to={`/event-detail/${r_event.proof[0].event_uuid}`}
                            >
                              results
                            </Link>
                          " tab and share them with your community.} */}
                            </strong>
                            <button
                              onClick={() => {
                                setIsCloseAlert(true);
                              }}
                              type="button"
                              className="
                              btn-close 
                              box-content 
                              w-4 
                              h-4 
                              p-1 
                              ml-auto 
                              text-yellow-900 
                              border-none 
                              rounded-none 
                              opacity-50 
                              focus:shadow-none 
                              focus:outline-none 
                              focus:opacity-100 
                              hover:text-yellow-900 
                              hover:opacity-75 
                              hover:no-underline"
                              data-bs-dismiss="alert"
                              aria-label="Close"
                            >
                              <FontAwesomeIcon
                                icon={faXmark}
                                className="
                                  cursor-pointer
                                "
                              />
                            </button>
                          </div>
                        </>
                      )}

                      {r_event.proof[0].validate_confirmation_status == 3 && (
                        <>
                          <div
                            className="text-[13px] my-5 alert bg-yellow-100 border-t-4 border-t-yellow-300 py-5 px-6 mb-3 text-base text-black-700 inline-flex items-center w-full alert-dismissible fade show"
                            role="alert"
                          >
                            <strong className="">
                              {t("events.rejected")}
                              {/* The winner list was rejected because the selected finalists do not comply with the ITHL "
                            <Link
                              className="text-[#00068E] underline cursor-pointer"
                              to={`/terms-conditions`}
                            >
                              terms and conditions
                            </Link>
                            ". Redefine the winners and submit the list for review again. */}
                            </strong>
                            <button
                              onClick={() => {
                                setIsCloseAlert(true);
                              }}
                              type="button"
                              className="
                              btn-close 
                              box-content 
                              w-4 
                              h-4 
                              p-1 
                              ml-auto 
                              text-yellow-900 
                              border-none 
                              rounded-none 
                              opacity-50 
                              focus:shadow-none 
                              focus:outline-none 
                              focus:opacity-100 
                              hover:text-yellow-900 
                              hover:opacity-75 
                              hover:no-underline"
                              data-bs-dismiss="alert"
                              aria-label="Close"
                            >
                              <FontAwesomeIcon
                                icon={faXmark}
                                className="
                                  cursor-pointer
                                "
                              />
                            </button>
                          </div>
                        </>
                      )}

                      {r_event.proof[0].validate_confirmation_status == 4 && (
                        <>
                          <div
                            className="text-[13px] my-5 alert bg-red-100 border-t-4 border-t-red-300 py-5 px-6 mb-3 text-base text-black-700 inline-flex items-center w-full alert-dismissible fade show"
                            role="alert"
                          >
                            <strong className="">
                              {t("events.rejected_redefine")}
                              {/* The winners list has been redefined and the event has closed, you can see the results in the "
                            <Link
                              className="text-[#00068E] underline cursor-pointer"
                              to={`/event-detail/${r_event.proof[0].event_uuid}`}
                            >
                              results
                            </Link>
                            " tab and share them with your community. If you think it was a mistake, you can contact us at: contact@internationaltreasurehuntersleague.com */}
                            </strong>
                            <button
                              onClick={() => {
                                setIsCloseAlert(true);
                              }}
                              type="button"
                              className="
                              btn-close 
                              box-content 
                              w-4 
                              h-4 
                              p-1 
                              ml-auto 
                              text-yellow-900 
                              border-none 
                              rounded-none 
                              opacity-50 
                              focus:shadow-none 
                              focus:outline-none 
                              focus:opacity-100 
                              hover:text-yellow-900 
                              hover:opacity-75 
                              hover:no-underline"
                              data-bs-dismiss="alert"
                              aria-label="Close"
                            >
                              <FontAwesomeIcon
                                icon={faXmark}
                                className="
                                  cursor-pointer
                                "
                              />
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}

                <table className="table">
                  <thead>
                    <tr>
                      <th>Order</th>
                      <th>Uploaded</th>
                      <th>Name / Email</th>
                      <th>Status</th>
                      <th>Proof 1</th>
                      <th>Proof 2</th>
                      <th>Position</th>
                    </tr>
                  </thead>
                  <tbody>
                    {r_event.proof[0].user_events.map((proof, key) => (
                      <tr key={key}>
                        <td>
                          <span>{key + 1}</span>
                        </td>
                        <td>
                          <Timezone
                            date={proof.upload_proof_date}
                            formatStr="MM/DD/YY h:ma"
                          />
                        </td>
                        <td>
                          <span className="block">
                            {proof.user.name} {proof.user.lastname}
                          </span>
                          <span className="block">{proof.user.email}</span>
                          {r_profile.user.role_id == 1 ? (
                            <>
                              <Link
                                className="text-blue-400 hover:underline"
                                to={`/settings/users/profile/user-admin/${
                                  proof.user.username || proof.user.uuid
                                }`}
                              >
                                {proof.user.username || "See profile"}
                              </Link>
                            </>
                          ) : (
                            <>
                              <Link
                                className="text-blue-400 hover:underline"
                                to={`/profile/${
                                  proof.user.username || proof.user.uuid
                                }/about`}
                              >
                                {proof.user.username || "See profile"}
                              </Link>
                            </>
                          )}
                        </td>
                        <td>
                          <Status status={proof.status} />
                        </td>
                        <td>
                          <img
                            onClick={() =>
                              openModal(proof.firts_proof, proof.comment, 1)
                            }
                            src={proof.firts_proof}
                            className="w-16 h-16 object-cover cursor-pointer hover:opacity-60"
                            alt=""
                          />
                        </td>
                        <td>
                          <img
                            onClick={() =>
                              openModal(proof.second_proof, proof.comment, 2)
                            }
                            src={proof.second_proof}
                            className="w-16 h-16 object-cover cursor-pointer hover:opacity-60"
                            alt=""
                          />
                        </td>
                        <td>
                          {!r_event.proof[0].closed_event &&
                          proof.status != "pending" ? (
                            <AdminSelect
                              className="w-full"
                              onChange={(event) =>
                                handleSelect(
                                  event,
                                  proof.id,
                                  r_event.proof[0].event_uuid,
                                  proof.user.id
                                )
                              }
                              options={r_main.options_judge}
                              placeholder="Select an option"
                              errors={false}
                              value={proof.status}
                            />
                          ) : (
                            <>
                              {!r_event.proof[0].closed_event &&
                              proof.status == "pending" ? (
                                <>No evidence to qualify</>
                              ) : (
                                ""
                              )}

                              {r_event.proof[0].closed_event ? (
                                <>Options not available</>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="w-4/6 flex justify-center mx-auto my-5">
                  {r_profile.user.role_id == 1 ? (
                    <>
                      {dirtyWinners && (
                        <>
                          <button
                            type="button"
                            className={`w-full bg-red-500 text-white font-bold border-b-4 border-red-700 p-2 mt-5 text-sm md:text-base  mx-2 ${
                              r_event.proof[0].closed_event
                                ? "opacity-60"
                                : "active:scale-95"
                            }`}
                            onClick={() => {
                              confirmSend(r_event.proof[0].event_uuid);
                            }}
                            disabled={r_event.proof[0].closed_event}
                          >
                            Change the results and close the event
                          </button>
                        </>
                      )}

                      <button
                        type="button"
                        className={`w-full bg-blue-500 text-white font-bold border-b-4 border-blue-700 p-2 mt-5 text-sm md:text-base  mx-2 ${
                          r_event.proof[0].closed_event || dirtyWinners
                            ? "opacity-60"
                            : "active:scale-95"
                        }`}
                        onClick={() => {
                          changeStatusToApprove(
                            r_event.proof[0].event_uuid,
                            "reject"
                          );
                        }}
                        disabled={r_event.proof[0].closed_event || dirtyWinners}
                      >
                        Return to define
                      </button>

                      <button
                        type="button"
                        className={`w-full bg-green-500 text-white font-bold border-b-4 border-green-700 p-2 mt-5 text-sm md:text-base  mx-2 ${
                          r_event.proof[0].closed_event || dirtyWinners
                            ? "opacity-60"
                            : "active:scale-95"
                        }`}
                        onClick={() => {
                          changeStatusToApprove(
                            r_event.proof[0].event_uuid,
                            "approve"
                          );
                        }}
                        disabled={r_event.proof[0].closed_event || dirtyWinners}
                      >
                        Approve results
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        className={`w-full bg-blue-500 text-white font-bold border-b-4 border-blue-700 p-2 mt-5 text-sm md:text-base  mx-2 ${
                          r_event.proof[0].closed_event ||
                          (r_event.proof[0].validate_confirmation_status != 3 &&
                            r_event.proof[0].validate_confirmation_status !=
                              null)
                            ? "opacity-60"
                            : "active:scale-95"
                        }`}
                        onClick={() => {
                          openModalConfirm();
                        }}
                        disabled={
                          r_event.proof[0].closed_event ||
                          (r_event.proof[0].validate_confirmation_status != 3 &&
                            r_event.proof[0].validate_confirmation_status !=
                              null)
                        }
                      >
                        {r_event.proof[0].closed_event == 1
                          ? "Closed Event"
                          : "Submit for approval"}
                      </button>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                {!r_event.proof[0].closed_event && (
                  <>
                    <div
                      className="text-[13px] my-5 alert bg-blue-100 border-t-4 border-t-blue-300 py-5 px-6 mb-3 text-base text-black-700 inline-flex items-center w-full alert-dismissible fade show"
                      role="alert"
                    >
                      <strong className="">
                        {localStarTime <=
                          moment().format("YYYY-MM-DD HH:mm:ss") && (
                          <>{t("events.close.no_inicialize")} {localStarTime}</>
                        )}

                        {r_event.proof[0].user_events.length < 1 &&
                          moment().format("YYYY-MM-DD HH:mm:ss") <
                            localEndDate && <>{t("events.close.no_users")}</>}

                        {moment().format("YYYY-MM-DD HH:mm:ss") >
                          localEndDate && <>{t("events.close.finalized")}</>}
                      </strong>
                      <button
                        onClick={() => {
                          setIsCloseAlert(true);
                        }}
                        type="button"
                        className="
                          btn-close 
                          box-content 
                          w-4 
                          h-4 
                          p-1 
                          ml-auto 
                          text-yellow-900 
                          border-none 
                          rounded-none 
                          opacity-50 
                          focus:shadow-none 
                          focus:outline-none 
                          focus:opacity-100 
                          hover:text-yellow-900 
                          hover:opacity-75 
                          hover:no-underline"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      >
                        <FontAwesomeIcon
                          icon={faXmark}
                          className="
                              cursor-pointer
                            "
                        />
                      </button>
                    </div>
                  </>
                )}

                <div className="flex justify-center">
                  <button
                    type="button"
                    className={`w-2/6 m-auto justify-self-center bg-blue-500 text-white font-bold border-b-4 border-blue-700 p-2 mt-5 text-sm md:text-base  mx-2 ${
                      r_event.proof[0].closed_event
                        ? "opacity-60"
                        : "active:scale-95"
                    }`}
                    onClick={() => {
                      handleCloseEvent(r_event.proof[0].event_uuid);
                    }}
                    disabled={r_event.proof[0].closed_event}
                  >
                    {r_event.proof[0].closed_event == 1
                      ? "Closed Event"
                      : "Close event"}
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>

      {winnerModal ? (
        <>
          <Modal size="w-full lg:max-w-[600px] relative">
            <FontAwesomeIcon
              icon={faRemove}
              onClick={() => {
                dispatch(modal(false));
                setWinnerModal(false);
              }}
              className="
            absolute
            right-[40px]
            top-[55px]
            text-[25px]
            cursor-pointer
          "
            />

            {loadingWinner ? (
              <>
                <Loading color="black" />
              </>
            ) : (
              <>
                <div className="flex flex-col text-center">
                  <img
                    src={checkPayment}
                    alt="payment status"
                    className="w-[171px] h-[171px] mx-auto my-5"
                  />
                  <h2 className="uppercase font-bold font-['AldotheApache'] text-[34px] text-[#404B52]">
                    Results sent for revIew!
                  </h2>
                  <p className="segoe text-[16px] text-[#707070]">
                    Your results will be sent to a review process before being
                    published. When approved, you will receive an email
                    notification or the event status will change on this screen.{" "}
                  </p>
                  <div className="transform-section w-max mx-auto my-5">
                    <button
                      type="submit"
                      className="font-['AldotheApache'] text-[24px] uppercase "
                      onClick={() => {
                        toValidate(r_event.proof[0].event_uuid);
                      }}
                    >
                      Submit Scores
                    </button>
                  </div>
                </div>
              </>
            )}
          </Modal>
        </>
      ) : (
        <>
          <Modal>
            {!r_event.loading &&
              r_event.proof &&
              r_event.proof.length > 0 &&
              !r_event.loadingNotify &&
              !r_event.notifyProof &&
              !r_event.notifyCloseEvent && (
                <>
                  <FontAwesomeIcon
                    icon={faRemove}
                    onClick={() => {
                      dispatch(modal(false));
                    }}
                    className="
                    absolute
                    right-[40px]
                    top-[30px]
                    text-[25px]
                    cursor-pointer
                  "
                  />
                  <p className="py-5 px-2 bg-[#DBEAFE] text-[#0066FF] segoe my-5 border border-[#0066FF] segoe">
                    Description Proof #{identifyProof}: &nbsp;
                    {identifyProof == 1
                      ? parse(r_event.proof[0].description_proof_1 || "")
                      : parse(r_event.proof[0].description_proof_2 || "")}
                  </p>
                  <div className="flex space-x-5 mt-6">
                    <div>
                      <img
                        src={r_event.proof[0].screenshot}
                        className="w-full"
                        alt=""
                      />
                      <p className="my-3 bg-[#EEEEEE] border border-[#707070] text-[#707070] py-5 px-3 min-h-[100px]">
                        Your original picture!!
                      </p>
                    </div>
                    <div>
                      <img src={image} className="w-full" alt="" />
                      <p className="my-3 bg-[#EEEEEE] border border-[#707070] text-[#707070] py-5 px-3 min-h-[100px]">
                        Participant's note: {comment || "There is no note"}
                      </p>
                    </div>
                  </div>
                </>
              )}

            {r_event.loadingNotify && (
              <>
                <Loading color="black" />
              </>
            )}
            {!r_event.loadingNotify && r_event.notifyProof && (
              <>
                <FontAwesomeIcon
                  icon={faRemove}
                  onClick={() => {
                    dispatch(modal(false));
                    dispatch(setNotifyProof(false));
                  }}
                  className="
                    absolute
                    right-[40px]
                    top-[55px]
                    text-[25px]
                    cursor-pointer
                  "
                />
                <div className="flex flex-col text-center">
                  <img
                    src={checkPayment}
                    alt="payment status"
                    className="w-[171px] h-[171px] mx-auto my-5"
                  />
                  <h2 className="uppercase font-bold font-['AldotheApache'] text-[34px] text-[#404B52]">
                    Message sent successfully
                  </h2>
                  <p className="segoe text-[16px] text-[#707070]">
                    A notification has been sent informing that the Proof Review
                    Time has started.
                  </p>
                </div>
              </>
            )}
            {!r_event.loadingNotify && r_event.notifyCloseEvent && (
              <>
                <FontAwesomeIcon
                  icon={faRemove}
                  onClick={() => {
                    dispatch(modal(false));
                    dispatch(setNotifyCloseEvent(false));
                  }}
                  className="
                    absolute
                    right-[40px]
                    top-[55px]
                    text-[25px]
                    cursor-pointer
                  "
                />
                <div className="flex flex-col text-center">
                  <img
                    src={checkPayment}
                    alt="payment status"
                    className="w-[171px] h-[171px] mx-auto my-5"
                  />
                  <h2 className="uppercase font-bold font-['AldotheApache'] text-[34px] text-[#404B52]">
                    Message sent successfully
                  </h2>
                  <p className="segoe text-[16px] text-[#707070]">
                    A notification has been sent informing that The Proofs
                    Review Time has started. It is time for you to define and
                    confirm the winners to close the event
                  </p>
                </div>
              </>
            )}
          </Modal>
        </>
      )}
    </>
  );
}

function Status({ status }) {
  const r_main = useSelector((state) => state.main);
  return (
    <span
      className={`uppercase inline font-bold  px-5 py-1 ${r_main.color_status[status]}`}
    >
      {status}
    </span>
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";

export default function Section1() {
  return (
    <div className="text-white col-span-2">
      <img
        src={require("../../assets/img/logo-treasure/brand-logo.png")}
        alt="Logo Bottom"
      />
      <p className="my-5">
		{t("footer.honestly")}
      </p>
      <ul>
        <Contact
          icon={faLocationDot}
          label={t("footer.address")}
          text="500 W 500 S Lindon, Utah 84042"
        />
        {/*<Contact icon={faPhone} label="Phone" text="+1 (786) 623 8895" />*/}
        <Contact
          icon={faAt}
          label="Email"
          text=" Ken@internationaltreasurehuntersleague.com"
        />
      </ul>
    </div>
  );
}

function Contact({ icon, label, text }) {
  return (
    <li className="flex items-center lg:block mb-2  w-full">
      <FontAwesomeIcon icon={icon} className="text-yellow-c1 mr-2" />
      <span className="font-bold hidden lg:inline">{label}:</span>{" "}
      <span className="truncate">{text}</span>
    </li>
  );
}

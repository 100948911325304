import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCountdown } from "../../hooks/Countdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import {
  faBars,
  // faMagnifyingGlass,
  faTimes,
  faUser,
  faGlobe,
  faSearch,
  faCircleCheck
} from "@fortawesome/free-solid-svg-icons";
import Element from "./Element";
import { getItemsMenu } from "../../store/actions/_auth.actions";
import { getUser } from "../../store/actions/_profile.actions";
import { verifyAuth } from "../../store/actions/_auth.actions";
import { useTranslation } from "react-i18next";
import spanish from "../../assets/img/icons/spain.png";
import english from "../../assets/img/icons/united-states.png";
import i18next from "i18next";
import { t } from "i18next";
import { SearchUserEvents } from "../SearchUserEvents/SearchUserEvents";
import Timezone from '../../hooks/timezone'

import moment from "moment";

export default function Menu() {
  const [menu, setMenu] = useState("hidden lg:flex");
  const [date, setDate] = useState();
  const dispatch = useDispatch();
  const r_auth = useSelector((state) => state.auth);
  const r_search = useSelector((state) => state.search);
  const r_events = useSelector((state) => state.event.upcoming_events);
  const now = new Date();
  const { t } = useTranslation();
  const [isCloseMenu, setIsCloseMenu] = useState(true);
  // const today = `${now.getUTCFullYear()}-${now.getUTCMonth()+ 1}-${now.getUTCDate()} ${now.getUTCHours()}:${now.getUTCMinutes()}`
  const today = moment(date).utc().format("YYYY-MM-DD HH:mm")
  
  useEffect(() => {
    // console.log(clickElement);
    // console.log(clickElement.classList);
    // console.log("today", today, "moment", moment(date).utc().format("YYYY-MM-DD HH:mm"));
  }, [])


  useEffect(() => {
    if (r_auth.logged === undefined) dispatch(verifyAuth());
  }, [dispatch, r_auth.logged]);

  useEffect(() => {
    if (r_auth.logged) dispatch(getItemsMenu());
  }, [dispatch, r_auth.logged]);

  useEffect(() => {
    if (r_auth.logged) dispatch(getUser());
  }, [dispatch, r_auth.logged]);

  useEffect(() => {
    if (r_events.length > 0) {
      setDate(r_events[0].full_start_time);
    }
  }, [r_events]);

  const closeMenu = () => {
    setIsCloseMenu(true);
    setMenu("hidden lg:flex");
  };

  const changeLanguage = (e) => {
    var lng = localStorage.getItem("lng") === "en" ? "es" : "en";
    localStorage.setItem("lng", lng);
    i18next.changeLanguage(lng);
    window.location.reload();
  };
  

  return (
    r_auth.logged !== undefined && (
      <div className="w-full min-h-[160px]">
        {/* Yellow Section */}
        <div className="flex justify-between items-center menu-top-bar segoeBold">
          <div className="block">
            {t("menu.upcoming")} {date && <DaysIndicator date={date} />}
          </div>
          <div className="hidden md:block">
            <div className="flex items-center pr-10">
              <div className="menu-top-bar hidden lg:block">
                {t("menu.inttime")}{" "}
                <div className="menu-top-bar-days-indicator">{moment().format('llll')}</div>
              </div>
              <div>{t("menu.end_season")}</div>
            </div>
          </div>
        </div>

        {/* Blue Section - Navigation */}
        <nav className="grid grid-cols-3 md:grid-cols-2 items-center bg-[#061322] py-2">
          {/* Left Col */}
          <div className="hidden md:block">
            <div className="flex items-center">
              {/* Logo */}
              <Link to={"/"}>
                <img
                  src={require("../../assets/img/logo-treasure/brand-logo.png")}
                  className="w-24 p-2 lg:p-0 lg:ml-10"
                  alt="International Treasure Hunters League"
                />
              </Link>

            
              {/* SEARCH */}
              {
                r_search.isShowInNavBar && <SearchUserEvents searchType="all" className="ml-[50px]" />
              }
              

            </div>
          </div>

          {/* Right Col - Menu */}
          <div className="">
            {/* Mobile Menu Button */}
            <div className="flex justify-end mr-5">
              <FontAwesomeIcon
                icon={faBars}
                className="text-white text-2xl ml-3 lg:hidden"
                onClick={() => {
                  setMenu("block");
                  setIsCloseMenu(false);
                }}
              />
            </div>
            <ul className={`
                  z-10 
                  w-5/6 
                  lg:w-full 
                  fixed 
                  lg:relative 
                  top-0 
                  left-0 
                  bg-black 
                  lg:bg-[#061322] 
                  h-screen 
                  lg:h-auto 
                  lg:flex 
                  mr-3 
                  ${menu} 
                  segoeBold 
                  justify-end 
                  pr-1 
                  lg:pr-8
                  rounded-r-3xl
                  rounded-b-3xl
                  lg:rounded-r-none
                  lg:rounded-b-none
            `}>
              {/* Mobile Menu - X Button */}
              <li className="lg:hidden text-right p-3">
                <FontAwesomeIcon
                  icon={faTimes}
                  className="text-white text-2xl lg:hidden"
                  onClick={closeMenu}
                />
              </li>
              <div className="block md:hidden">
              {
                r_search.isShowInNavBar && <SearchUserEvents searchType="all" handleEvent={closeMenu} />
              }
              </div>
              <Element url="/" text="Explorer" onClick={closeMenu} />
              <Element url="/game-events" text="Events" onClick={closeMenu} />
              {r_auth.logged && !isCloseMenu && (
                <div>
                  <Element url="/settings/users/profile/my-events/created/live" text="My profile" onClick={closeMenu} />
                </div>
              )}
              {/* <Element url="/about" text="About us" onClick={closeMenu} /> */}
              {/* {!r_auth.logged && (
                <Element
                  url="/game-events"
                  text="Game Events"
                  onClick={closeMenu}
                />
              )} */}
              {/*<Element
                     url="/contact"
                     text="Contact Us"
                     onClick={closeMenu}
                  />*/}
              {/* <Element
                        url="/kickstarter"
                        text="Kickstarter"
                        onClick={closeMenu}
                     /> */}
              {/*<Element url="" text="Settings" expand={true} />*/}

              <div className="hidden lg:flex">
                {r_auth.items_menu.length > 0 &&
                  r_auth.items_menu.map((item, key) => (
                    <Element
                      key={key}
                      url=""
                      id={item.id}
                      text={item.name}
                      expand={item.expand}
                      submenu={item.submodules}
                      onClick={closeMenu}
                    />
                  ))}
              </div>

              {/* Login Button */}
              <div className="hidden lg:block">
                {!r_auth.logged && (
                  <li onClick={closeMenu} className="flex">
                    <div className="my-auto -skew-x-12 bg-yellow-c1 mr-3">
                      <Link
                        to="/auth/login"
                        className='flex flex-row h-full'>
                        {/* <FontAwesomeIcon icon={faUser} className="mr-2" /> */}
                        <span className="py-1 px-2 font-bold">Login</span>
                        <div className="bg-blue-c2 w-1"></div>
                        <div className="bg-yellow-c1 w-1"></div>
                      </Link>
                    </div>
                  </li>
                )}
              </div>

              {/* Register Button */}
              <div className="hidden lg:block">
                {!r_auth.logged && (
                  <li onClick={closeMenu} className="flex">
                    <div className="my-auto -skew-x-12 bg-yellow-c1 mr-3">
                      <Link
                        to="/auth/register"
                        className='flex flex-row h-full'>
                        {/* <FontAwesomeIcon icon={faUser} className="mr-2" /> */}
                        <span className="py-1 px-2 font-bold">Register</span>
                        <div className="bg-blue-c2 w-1"></div>
                        <div className="bg-yellow-c1 w-1"></div>
                      </Link>
                    </div>
                  </li>
                )}
              </div>

              {/* Search button */}
              {/* 
              <li className="hidden lg:block cursor-pointer">
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className="menu-bar-item-search"
                />
              </li>
              */}

              {/* Language */}
              <li className="flex mt-5 lg:mt-0 ml-8 lg:ml-0" onClick={changeLanguage}>
                {/* <FontAwesomeIcon icon={faGlobe} className="language" />{" "} */}
                {localStorage.getItem("lng") === "en"
                  ?
                  <div className="flex items-center bg-yellow-c1 my-auto px-2 py-1 rounded-md cursor-pointer">
                    <img src={english} className="w-5 h-5 mr-2" alt="" />
                    <span className="font-bold">EN</span>
                  </div>
                  :
                  <div className="flex items-center bg-yellow-c1 my-auto px-2 py-1 rounded-md cursor-pointer">
                    <img src={spanish} className="w-5 h-5 mr-2" alt="" />
                    <span className="font-bold">ES</span>
                  </div>
                }
              </li>
            </ul>
          </div>

          {/* Mobile Title */}
          <div className="block md:hidden">
            <h1 className="aldo font-bold text-[#ffc300] text-2xl text-center">
              <Link to="/">ITHL</Link>
            </h1>
          </div>

          {/* Mobile Register Button */}
          <div className="block md:hidden">
            {!r_auth.logged && (
              <li onClick={closeMenu} className="flex justify-end">
                <div className="my-auto -skew-x-12 bg-yellow-c1 mr-3">
                  <Link
                    to="/auth/register"
                    className='flex flex-row h-full px-2'>
                    <FontAwesomeIcon icon={faUser} className="my-auto text-xs" />
                    <span className="py-1 px-2 font-bold text-xs">Sign Up</span>
                    <div className="bg-blue-c2 w-1"></div>
                  </Link>
                </div>
              </li>
            )}
            {r_auth.items_menu.length > 0 &&
              r_auth.items_menu.map((item, key) => (
                <Element
                  key={key}
                  url=""
                  id={item.id}
                  text={item.name}
                  expand={item.expand}
                  submenu={item.submodules}
                  onClick={closeMenu}
                />
              ))}
          </div>
        </nav >

      </div >
    )
  );
}

function DaysIndicator({ date }) {
  const Content = ({ time, unit }) => {
    return (
      <>
        <div className="menu-top-bar-days-indicator">{time}</div> {unit}
      </>
    );
  };

  const [days, hours, minutes] = useCountdown(date);
  return date ? (
    <>
      {days === 0 ? (
        <>
          {hours === 0 ? (
            <Content time={minutes} unit="minutes" />
          ) : hours === 1 ? (
            <Content time={hours} unit="hour" />
          ) : (
            <Content time={hours} unit="hours" />
          )}
        </>
      ) : days === 1 ? (
        <Content time={days} unit="day" />
      ) : (
        <Content time={days} unit="days" />
      )}
    </>
  ) : (
    <Content time="0" unit="days" />
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHeart,
    faHeartCrack,
    faSpinner,
} from "@fortawesome/free-solid-svg-icons";
// import { faGears } from "@fortawesome/free-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  followAction,
  unFollowAction,
} from "../../store/actions/_pageProfile.actions";
import followImg from '../../assets/img/icons/heart-to-follow-02.png';
import unFollowImg from '../../assets/img/icons/heart-to-unfollow-02.png';

export default function FlatFollowUser({user, className}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const r_pp = useSelector((state) => state.pageProfile);
    const r_auth = useSelector((state) => state.auth);
    const r_profile = useSelector((state) => state.profile);

    return (
        <>            
            {r_auth.logged && user && (
                <>
                {
                    r_profile.followeds.includes(user.id) ? (
                        <>
                            <FollowLink
                                text="Unfollow"
                                icon={unFollowImg}
                                action={() => dispatch(unFollowAction(user.uuid)) }
                                status={r_pp.btnLoading}
                                className={className}
                            />
                        </>
                    ) : (
                        <>
                            <FollowLink
                                text="Follow"
                                icon={followImg}
                                action={() => dispatch(followAction(user.uuid)) }
                                status={r_pp.btnLoading}
                                className={className}
                            />
                        </>
                    )
                }
            </>
            )}
        </>
    );
}
const FollowLink = ({ text, icon, action, status, className }) => {
    console.log(status);
    return (
      <>
        {!status ? (
            <div 
                className={className}
                onClick={action}
            >
                <img src={icon} alt="Follow" className='w-[16px] inline mr-2' />
                <span className='underline relative top-[2px] cursor-pointer'>{text}</span>
            </div>
        ) : (
          <div
            disabled
            className={className}
          >
            <FontAwesomeIcon icon={faSpinner} spin className="mr-2" /> Loading
          </div>
        )}
      </>
    );
  };
  
/* eslint-disable eqeqeq */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

export default function EventList({ data, type }) {
  return data && data.length > 0 ? (
    <div className="w-full">
      {data.map((item, key) => (
        <div
          key={key}
          className="w-full border-b flex px-3 py-5 hover:bg-gray-100"
        >
          <div className="flex flex-col w-3/12">
            <span className="text-sm text-gray-600">
              {moment(item.start_date).format("MMM Do")} -{" "}
              {moment(item.end_date).format("MMM Do")}
            </span>
            <span>{item.title}</span>
          </div>
          <div className="flex flex-col w-3/12">
            <span className="text-sm text-gray-600">Category</span>
            <span>{item.name}</span>
          </div>
          <div className="flex flex-col w-2/12">
            <span className="text-sm text-gray-600">Type</span>
            <span>Apex</span>
          </div>
          <div className="flex flex-col w-2/12">
            <span className="text-sm text-gray-600">Reward</span>
            <span>{item.first_place_reward || "FREE"}</span>
          </div>
          <div className="mr-3 relative top-2">
            <Status status={item.status} />
          </div>
          <div className="w-2/12 flex items-center">
            <Options type={type} uuid={item.event_uuid} item={item} />
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className="w-3/4 mx-auto bg-blue-100 my-5 p-5 border border-blue-300">
      No records
    </div>
  );
}

const Options = ({ type, uuid, item }) => {
  if (type === "live") {
    return (
      
      <div className="inline-grid grid-cols-3 gap-1">
        {/* <Link to={`/settings/users/profile/my-events/judge/${uuid}`}>
          <FontAwesomeIcon icon={faTrophy} />
        </Link> */}        
        <div className="group flex relative">
          <Link to={`/event-detail/${uuid}`}>
            <FontAwesomeIcon icon={faAnglesRight} className="text-xl" />
          </Link>
          <span className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
          -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto">Preview</span>
        </div>
        
        <FreeEventBadge item={item}/>
      </div>
    );
  }
  return (
    <div className="inline-grid grid-cols-3 gap-1">
      <div className="group flex relative">
        <Link to={`/event-detail/${uuid}`} className="flex items-center justify-center bg-[#FFC300]  text-white rounded-lg p-1 w-8 h-8">
          <FontAwesomeIcon icon={faAnglesRight} className="text-xl" />
        </Link>
        <span className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute left-1/2 
        -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto">Preview</span>
      </div>
      
      <FreeEventBadge item={item}/>
    </div>
  );
};

function Status({ status }) {
  const r_main = useSelector((state) => state.main);
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);
  const navigate = useNavigate();

  return (
    <div>
      {
        isOpenTooltip && (
          <div 
            className='
              opacity-75 
              segoe 
              bg-[#4F5863] 
              text-white 
              m-auto 
              text-center 
              w-[200px] 
              text-[12px] 
              absolute 
              right-[-50px] 
              top-[-46px]
              rounded-[3px] 
              py-1 
              px-2
            '
            onClick={()=>{ navigate('/settings/users/profile/withdraws'); }}
            onMouseOut={() => {
              setIsOpenTooltip(false);
            }} 
            onMouseOver={() => {
              if(status == "first"){

                setIsOpenTooltip(true)}
              }
            }
            >
            In 14 working days you will receive your payment
          </div>
        )
      }
      
      <span
        className={`uppercase inline font-bold  px-5 py-1 ${r_main.color_status[status]}`}
        onMouseOut={() => {
          setIsOpenTooltip(false);
        }} 
        onMouseOver={() => {
          if(status == "first"){

            setIsOpenTooltip(true)}
          }
        }
        >
        {status}
      </span>
      
    </div>
  );
}

const FreeEventBadge = ({item}) => {
  return item?.event?.is_free_event ? <>
    
    <div className="">
          <span
            className={"flex items-center bg-[#7ED360] rounded-lg p-1 w-8 h-8 custom-badge"}
          >
            <span className='text-xs -rotate-45 font-bold text-black'>Free</span>
          </span>
        </div>
  </> : <></>
}
import { Link } from "react-router-dom";

export default function AdminButton(props) {
   const { text, to } = props;
   let textBtn = text || "Cancel";
	let toBtn = to || "/";
   return (
      <Link
         to={toBtn}
         className="w-full block bg-red-500 text-white font-bold border-b-4 border-red-700 p-2 mt-5 text-sm md:text-base active:scale-95 text-center mx-2"
      >
         {textBtn}
      </Link>
   );
}

import { useEffect } from 'react' 
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';
import { verifyAuth } from '../store/actions/_auth.actions';

export default function RequireAuth( { children } ) {
	const dispatch = useDispatch();
	const r_auth = useSelector( state => state.auth );

	useEffect( () => {
		if (r_auth.logged === undefined) dispatch(verifyAuth());
	},[dispatch, r_auth.logged]);

	if (r_auth.logged !== undefined) {
		if (r_auth.logged === true) {
			return <Navigate to="/dashboard" />;
		} else {
			return children;
		}
	}
}
import { useSelector } from "react-redux";
import AdminInput from "../../../components/admin/admin.input";
import AdminSelect from "../../../components/admin/admin.select";
import { styles } from "../../../utils/constanst";

export default function EventAward({ handleChange, data }) {
  const r_events = useSelector((state) => state.event);

  return (
    <>
      {r_events.know_role === 1 && <Admin handleChange={handleChange} data={data} />}
      {r_events.know_role === 2 && <User />}
      {r_events.know_role === 3 && <Partner handleChange={handleChange} data={data}  />}
    </>
  );
}

const Prize = ({handleChange, data}) => {
  const r_events = useSelector((state) => state.event);
  return (
    <>
      <AdminSelect
        label="type of Award"
        className="w-full"
        onChange={handleChange}
        options={[{ name: "Money", value: "money" }]}
        id="type_prize"
        // placeholder="Type of event"
        errors={r_events.errors}
        value={data.type_prize}
      />
      <AdminInput
        label="first place winnings (* $$$)"
        className="w-full"
        min="0"
        onChange={handleChange}
        type="text"
        // type={data.type_prize === "money" ? "number" : "text"}
        // pattern={data.type_prize === "money" ? "[0-9]" : "[A-Za-z]"}
        id="first_place_reward"
        // placeholder="First Place Reward"
        errors={r_events.errors}
        value={data.first_place_reward}
      />
    </>
  );
};

const Points = ({ handleChange, data }) => {
  const r_events = useSelector((state) => state.event);
  return (
    <>
      {/* <AdminInput
        label="first place points"
        className="w-full"
        onChange={handleChange}
        type="number"
        id="first_place_extra_points"
        // placeholder="First Place Extra Points"
        errors={r_events.errors}
        value={data.first_place_extra_points}
      />

      <AdminInput
        label="second place points"
        className="w-full"
        onChange={handleChange}
        type="number"
        id="second_place_reward"
        // placeholder="Second Place Points"
        errors={r_events.errors}
        value={data.second_place_reward}
      />
      <AdminInput
        label="third place points"
        className="w-full"
        onChange={handleChange}
        type="number"
        id="third_place_reward"
        // placeholder="Third Place Points"
        errors={r_events.errors}
        value={data.third_place_reward}
      />
      <AdminInput
        label="Points for Event Completion"
        className="w-full"
        onChange={handleChange}
        type="number"
        id="max_points_seasson"
        // placeholder="Points for Event Completion"
        errors={r_events.errors}
        value={data.max_points_seasson}
      /> */}
    </>
  );
};

const Header = (props) => {
  return (
    <fieldset className={`grid-cols-3 ${styles.fieldset}`}>
      <legend className={styles.legend}>Event Awards</legend>
      <div className={`${styles.boxInfo} col-span-3`}>
        First place winnings must be express in USD.
      </div>
      {props.children}
    </fieldset>
  );
};

const Admin = ({handleChange, data}) => {
  return (
    <Header>
      <Prize handleChange={handleChange} data={data} />
      <Points handleChange={handleChange} data={data} />
    </Header>
  );
};

const User = () => {
  return null;
};

const Partner = ( {handleChange, data} ) => {
  return (
    !data.is_free_event && (
      <Header>
        <Prize handleChange={handleChange} data={data} />
      </Header>
    )
  );
};
import { useState } from 'react'; 
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraRotate } from "@fortawesome/free-solid-svg-icons";
import ProfileBannerUpload from './profile.banner.upload';

export default function ProfileBanner() {
  const r_profile = useSelector((state) => state.profile);
  const [viewImage, setViewImage] = useState(null);
	const [modal, setModal] = useState(false);

  return (
    <>
			<ProfileBannerUpload status={modal} closeModal={ () => {setModal(false); setViewImage(null)}} viewImage={viewImage} setViewImage={setViewImage} />
      {r_profile.user.banner ? (
        <div
          className="mb-10 relative w-full h-32 bg-center bg-cover"
          style={{ backgroundImage: `url('${r_profile.user.banner}')` }}
        >
          <FontAwesomeIcon
            icon={faCameraRotate}
            className="absolute bottom-0 right-0  text-white text-lg bg-[#061322] p-2 rounded-full cursor-pointer hover:scale-110 active:scale-95"
						onClick={ () => setModal(true)}
          />
        </div>
      ) : (
        <div className="mb-10 relative w-full h-32 bg-center bg-blue-50 border border-blue-300 text-blue-500 flex items-center justify-center">
          Your banner here
          <FontAwesomeIcon
            icon={faCameraRotate}
            className="absolute bottom-[-10px] right-[-10px] text-white text-lg bg-[#061322] text-2xl p-2 rounded-full cursor-pointer hover:scale-110 active:scale-95"
						onClick={ () => setModal(true)}
          />
        </div>
      )}
    </>
  );
}

import HeaderSection from "../../../components/HeaderSection"
import Yubelka from '../../../assets/img/avatars/Yubelka.png'
import Maria from '../../../assets/img/avatars/Maria.png'
import Publio from '../../../assets/img/avatars/Publio.png'
import Sara from '../../../assets/img/avatars/Sara.png'
import Ken from '../../../assets/img/avatars/Ken.png'
import { t } from "i18next"

export default function OurMembers() {
	return (
		<section className="w-full h-auto mt-5 lg:mt-2 p-5 lg:p-10 bg-no-repeat bg-cover bg-[url('../img/backgrounds/dark-background.png')] flex flex-col justify-center items-center">
      <HeaderSection
        titleStyle="white"
        sub={t("about.members.sub")}
        title1={t("about.members.title1")}
        title2={t("about.members.title2")}
      />
      <div className="flex mt-10 w-full flex-nowrap overflow-x-auto pb-5">
				<Members picture={Ken} name="Ken Johnson" position={t("about.members.ken")} />
				<Members picture={Publio} name="Publio Quintero" position={t("about.members.publio")} />
				<Members picture={Sara} name="Sara Gifford" position={t("about.members.sara")} />
				<Members picture={Yubelka} name="Yubelka Munoz" position={t("about.members.maria")} />
				<Members picture={Maria} name="María Becerra" position={t("about.members.yubelka")} />
			</div>
    </section>
	)
}

function Members( { picture, name, position } ) {
	return (
		<div className="mx-2 w-[150px] lg:w-full shrink-0 lg:shrink">
			<img src={picture} className="w-full" alt="" />
			<span className="text-xs lg:text-2xl text-white font-bold text-center w-full block mt-2">{name}</span>
			<span className="text-[.5em] lg:text-sm text-yellow-c1 font-bold text-center w-full block">{position}</span>
		</div>
	)
}
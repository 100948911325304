import { useEffect, useState, useRef } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EventInfo from "./create/event.create.info";
import EventTime from "./create/event.create.time";
import EventCapture from "./create/event.create.captures";
import EventAward from "./create/event.create.awards";
import EventDetail from "./create/event.create.details";
import AdminButton from "../../components/admin/admin.button";
import AdminCancel from "../../components/admin/admin.cancel";
import AdminAlert from "../../components/admin/admin.alert";
import Loading from "../../components/Loading";
import { addEvent, eventTypesAction, myRole } from "../../store/actions/_events.actions";
import { resetAlert } from "../../store/reducers/_events.reducers";
import EventPayment from "./create/event.create.payment";
import { getIntentPayment, getPaymentMethods } from "../../store/actions/_subscriptions.actions";
import { faDiscord } from "@fortawesome/fontawesome-free-brands";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from 'react-toastify';
import Timezone from "../../hooks/timezone";

export default function EventCreate() {
  const dispatch = useDispatch();
  const r_events = useSelector((state) => state.event);
  const r_profile = useSelector((state) => state.profile);
  const eventRegister = useRef(null);
  const [data, setData] = useState({
    big_prize: 0,
    first_place_extra_points: 0,
    second_place_reward: 0,
    third_place_reward: 0,
    max_points_seasson: 0,
    type_of_event: 1,
    is_free_event: 0,
    type_prize: "money",
    first_place_reward: 0,
  });

  const [showImages, setShowImages] = useState({    
    show_image_1: false,
    show_image_2: false,
  })

  const [dataForm, setDataForm] = useState(null);
  const [openModal, setOpenModal] = useState(false)


  useEffect(() => {
    dispatch(myRole());
    dispatch(eventTypesAction());

    
    dispatch(getIntentPayment());
    dispatch(getPaymentMethods());
  }, [dispatch]);

  useEffect( () => {
    var payment = parseFloat(data.first_place_reward) + parseFloat(data.first_place_reward) * (10 / 100);
    setData({
      ...data,
      total_to_pay: payment
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data.first_place_reward]);

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  
  
  const handleFile = (event) => {
    let id = event.target.id;
    let element = document.getElementById(id);
    const nameData = element.getAttribute("data-show");
    var output = document.getElementById(nameData);
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });

    setShowImages({
      ...showImages,
      [nameData]: true,
    });
    console.log(showImages);
    
    output.src = URL.createObjectURL(element.files[0]);
    output.onload = function () {
      URL.revokeObjectURL(output.src); // free memory
    };
  };

  const handleCheckbox = (event) => {
    let value = event.target.checked ? 1 : 0;
    let error = false;

    if(data.is_free_event && event.target.name == "big_prize"){
      if(value){
        error = true;
        toast.error('You cannot activate both options', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
        return;
      }
    }

    
    if(data.big_prize && event.target.name == "is_free_event"){
      if(value){
        error = true;
        toast.error('You cannot activate both options', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
        return;
      }
    }
    if(error){
      return;
    }

    setData({
      ...data,
      [event.target.name]: value,
    });
    console.log(data);
  };

  const ckeditorValue = (value) => {
    setData({
      ...data,
      details: value,
    });
  };

  const ckeditorValueBriefDescription = (value) => {
    setData({
      ...data,
      brief_description: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if(!r_profile.user.username){
      
      
      toast.error('You need to complete your profile with your username to create a public even. Go to account and create a username', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      return;
    }

    let formData = new FormData();
    let imagefile = document.querySelector("#screenshot");
    let imagefile_cover = document.querySelector("#cover");
    formData.append("title", data.title || "");
    formData.append("big_prize", data.big_prize || 0);
    formData.append("cover", imagefile_cover.files[0] || "");
    formData.append("screenshot", imagefile.files[0] || "");
    formData.append("description_proof_1", data.description_proof_1 || "");
    formData.append("description_proof_2", data.description_proof_2 || "");
    formData.append("platform", data.platform || "");
    formData.append("start_date", data.start_date || "");
    formData.append("start_time", data.start_time || "");
    formData.append("end_date", data.end_date || "");
    formData.append("end_time", data.end_time || "");
    formData.append("first_place_reward", data.first_place_reward);
    formData.append("first_place_extra_points", data.first_place_extra_points);
    formData.append("second_place_reward", data.second_place_reward);
    formData.append("third_place_reward", data.third_place_reward);
    formData.append("max_points_seasson", data.max_points_seasson);
    formData.append("type_of_event", data.type_of_event);
    formData.append("requirements", data.requirements || "");
    formData.append("brief_description", data.brief_description || "");
    formData.append("details", data.details || "");
    formData.append("is_free_event", data.is_free_event);
    formData.append("type_prize", data.type_prize);
    formData.append("total_to_pay", data.total_to_pay);

    
    const utcStartDate = Timezone({
      date: data.start_date+" "+data.start_time+":00",
      formatStr: "YYYY-MM-DD",
      convertType: "toUTC"
    });

    const utcStartTime = Timezone({
      date: data.start_date+" "+data.start_time+":00",
      formatStr: "HH:mm",
      convertType: "toUTC"
    });


    
    const utcEndDate = Timezone({
      date: data.end_date+" "+data.end_time+":00",
      formatStr: "YYYY-MM-DD",
      convertType: "toUTC"
    });

    const utcEndTime = Timezone({
      date: data.end_date+" "+data.end_time+":00",
      formatStr: "HH:mm",
      convertType: "toUTC"
    });

    
    formData.append("utc_start_date", utcStartDate || "");
    formData.append("utc_start_time", utcStartTime|| "");
    formData.append("utc_end_date", utcEndDate || "");
    formData.append("utc_end_time", utcEndTime || "");

    // console.log(utcEndDate, utcEndTime);
    if (r_events.know_role === 3 && data.total_to_pay > 0) {
      setDataForm(formData);
      setOpenModal(true);
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      dispatch(addEvent(formData));
    }
  };

  // useEffect( () => {
  //   if (dataForm !== null) {
  //     dispatch(addEvent(dataForm));
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[dataForm])

  return (
    <>
      {r_events.alert.status && r_events.event?.event_uuid && <Navigate to={`/event-detail/${r_events.event.event_uuid}`} />}
      {/* {r_events.alert.status && <Navigate to="/settings/users/profile/my-events/created/live" />} */}
      {r_events.loading && <Loading />}
      {!r_events.loading && (
        <>
        
          <ToastContainer />
          <form
            action=""
            id="register-user"
            ref={eventRegister}
            onSubmit={handleSubmit}
            className="w-full mt-5 mb-10 pb-32 form-event shadow-2xl"
          >
            <AdminAlert
              message={r_events.alert.message}
              status={r_events.alert.status}
              style={r_events.alert.style}
              close={() => dispatch(resetAlert())}
            />

            <EventInfo
              data={data}
              handleChange={handleChange}
              handleCheckbox={handleCheckbox}
            />
            <EventTime handleChange={handleChange} />
            <EventCapture handleChange={handleChange} showImages={showImages} handleFile={handleFile} />
            <EventAward handleChange={handleChange} data={data} />
            <EventDetail
              handleChange={handleChange}
              ckeditorValue={ckeditorValue}
              ckeditorValueBriefDescription={ckeditorValueBriefDescription}
            />

            <div className="flex justify-between w-1/2 float-right">
              <AdminCancel text="Back" to="/settings/users/profile/my-events/created/live" />
              {r_events.know_role === 3 ? (
                data.is_free_event ? (
                  <AdminButton status={r_events.btnLoading} text="See Preview" />
                ) : (
                  data.type_prize === "money" &&
                  data.first_place_reward > 0 && (
                    <AdminButton
                      type="See Preview"
                      status={r_events.btnLoading}
                      text={`Your total is 
                        $${data.total_to_pay}`}
                    />
                  )
                )
              ) : (
                <AdminButton status={r_events.btnLoading} text="See Preview" />
              )}
            </div>
          </form>
          <EventPayment closeModal={ () => setOpenModal(false)} status={openModal} data={data} formData={dataForm} />
        </>
      )}
    </>
  );
}

/**
 * lang comment
 * @Author: Publio Quintero
 * @Date: 2022-10-16 11:43:53
 * @Desc: pageProfile es la vista donde mostramos la información referente al perfil del usuario, y donde la hacemos pública para que otros usuarios la puedan visitar.
 * Su estructura de archivos inicia en src/app/pages/profile
 * La ruta de inicio está en App.js
 * Los componentes que conforman este archivo están en src/app/pages/profile/components
 * El action  al que hace referencia es src/store/actions/_pageProfile.actions.js
 * El reducer al que hace referencia es src/store/reducers/_pageProfile.reducers.js
 * El reducer es inicializado en src/store/store.js
 * El modelo al que está relacionado en el backend es PageProfile
 * El controlador al que está relacionado en el backend es PageProfileController
 */

import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileNavigation from "./components/navigation";
import ProfileSearch from "./components/search";
import ProfileBanner from "./components/banner";
import ProfileHeader from "./components/header";
import ProfileFollowButton from "./components/followButton";
import ProfileSocialMedia from "./components/socialMedia";
import {
  getDataAction,
  getFollowsAction,
  getEventsAction,
  getFollowStatusAction,
  unFollowAction,
  followAction
} from "../../../store/actions/_pageProfile.actions";
import Loading from "../../../components/Loading";
import Moment from "react-moment";
import defaultImage from '../../../assets/img/avatars/no_user.jpeg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy, faThumbTack, faCircleCheck, faHeart, faHeartCrack, faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function ProfileFollowers() {
  const dispatch = useDispatch();
  let { user } = useParams();
  const r_pp = useSelector((state) => state.pageProfile);
  const r_auth = useSelector((state) => state.auth);
  const r_search = useSelector((state) => state.search);
  const r_profile = useSelector((state) => state.profile);

  useEffect(() => {
    // if (!r_pp.loaded) {
      dispatch(getDataAction(user));
    // }
  }, []);

  useEffect(() => {
    if (r_pp.user.uuid) {
      dispatch(getFollowsAction(r_pp.user.uuid));
      dispatch(getEventsAction(r_pp.user.uuid));
      dispatch(getFollowStatusAction(r_pp.user.uuid))
    }
  }, [r_pp.user.uuid, dispatch]);

  
  return (
    <>
      {r_pp.loading && <Loading />}
      {!r_pp.loading && r_pp.user && (
        <div>
          <ProfileBanner />
          <div className="w-12/12 mx-auto flex">
            <div className="basis-11/12 m-auto">
              <div className="md:flex">
                <div className="w-8/12">
                    <ProfileHeader />
                </div>
                <div className="w-4/12">
                    <ProfileFollowButton />
                </div>
              </div>
              
              <div className="w-10/12 m-auto mt-5">
                <Link to={"/profile/" + r_pp.user.username + "/about"} className="uppercase text-[#001D3D] font-[700]">About me</Link>
                <Link to={"/profile/" + r_pp.user.username + "/events"} className="uppercase mx-6 text-[#001D3D] font-[700]">Events</Link>
                
                <div className="mt-3">
                    <table className="w-full">
                        <thead>
                            <tr className="border-b border-[#ddd]">
                                <td>User</td>
                                <td>Followed you</td>
                                <td>Events created</td>
                                <td>Events registered</td>
                                <td></td>
                            </tr>
                        </thead>

                        <tbody>
                          {
                            r_pp.follows.followers.map((follow, index) => {
                              return (

                                <tr index={index} className="border-b border-[#ddd]">
                                  <td className="segoe flex py-3">
                                    <div style={{backgroundImage: `url(${follow.follower_user.avatar || defaultImage})`}} className={"w-full h-screen bg-cover bg-no-repeat bg-center w-[50px] h-[50px] min-w-[50px] min-h-[50px] bg-red-100 rounded-[100%]"} ></div>
                                    
                                    <div className="relative left-2">
                                      <p className="p-0 m-0 leading-1">
                                        {follow.follower_user.username}
                                      </p>
                                      <span className="text-[12px] relative top-[-5px]">
                                        {follow.follower_user.followers_count} followers</span>
                                    </div>
                                  </td>
                                  <td className="py-3">
                                    <Moment date={follow.follower_user.created_at} format="MM/DD/YYYY" />
                                  </td>
                                  <td className="py-3">{follow.follower_user.events_created_by_user_count} events</td>
                                  <td className="py-3">{follow.follower_user.user_events_count} events</td>
                                  <td>
                                    
                                      {
                                          
                                          r_auth.logged && (
                                              <>
                                                {
                                                  (r_profile.user.id != follow.follower_user.id) && <>
                                                  
                                                    {
                                                        r_profile.followeds.includes(follow.follower_user.id) ? (
                                                            <>
                                                                <Button
                                                                    text="Unfollow"
                                                                    icon={faHeartCrack}
                                                                    action={() => dispatch(unFollowAction(follow.follower_user.uuid)) }
                                                                    status={follow.follower_user.uuid === r_search.moreResultItemIsLoading}
                                                                    className="
                                                                    bg-[#FFC300]
                                                                    text-[#001D3D]
                                                                    w-[120px]
                                                                    py-[5px]
                                                                    rounded-[5px]"
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Button
                                                                    text="Follow"
                                                                    icon={faHeart}
                                                                    action={() => dispatch(followAction(follow.follower_user.uuid)) }
                                                                    status={follow.follower_user.uuid === r_search.moreResultItemIsLoading}
                                                                    className="
                                                                    bg-[#FFC300]
                                                                    text-[#001D3D]
                                                                    w-[120px]
                                                                    py-[5px]
                                                                    rounded-[5px]"
                                                                />
                                                            </>
                                                        )
                                                    }

                                                  </>
                                                }
                                              </>
                                          )
                                      }
                                  </td>
                              </tr>
                              )
                            })
                          }
                        </tbody>
                    </table>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      )}
    </>
  );
}



const Button = ({ text, icon, action, status, className }) => {
  console.log(status);
  return (
    <>
      {!status ? (
        <button
          className={className}
          onClick={action}
        >
          <FontAwesomeIcon icon={icon} className="mr-2" /> {text}
        </button>
      ) : (
        <button
          disabled
          className={className}
        >
          <FontAwesomeIcon icon={faSpinner} spin className="mr-2" /> Loading
        </button>
      )}
    </>
  );
};
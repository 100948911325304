import React, { useEffect } from 'react'
import defaultImage from '../../../assets/img/avatars/no_user.jpeg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faCircleCheck, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getDataAdminAction } from '../../../store/actions/_pageProfile.actions';
import { faFacebook, faTwitch, faTwitter, faInstagram, faYoutube } from "@fortawesome/fontawesome-free-brands";


import createdEvents from "../../../assets/img/icons/created-events.png";
import joinedEvents from "../../../assets/img/icons/game-events.png";
import eventsWon from "../../../assets/img/icons/trophy-events.png";
import at from "../../../assets/img/icons/at.png";
import users from "../../../assets/img/icons/users.png";
import userCheck from "../../../assets/img/icons/user-check.png";
import phone from "../../../assets/img/icons/phone.png";

export const ProfileUserAdmin = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { user } = useParams();
  const r_pp = useSelector((state) => state.pageProfile);
  const r_auth = useSelector((state) => state.auth);
  const r_profile = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(getDataAdminAction(user));
  }, []);
  useEffect(() => {
    console.log(r_pp);
  }, [r_pp]);
  return (
    <div className='ml-8'>
      <h1 className="font-bold text-xl my-2 mb-5">Events</h1>
      <span className='segoe cursor-pointer' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faChevronLeft} className="text-[11px] relative top-[-1px]" /> Go back</span>
      <div className='flex items-center mt-5 mb-5'>
          <div className="mt-3 w-1/2 flex">
            {
              r_pp.user && <>
                <div className={`
                  min-h-[92px] 
                  min-w-[92px] 
                  h-[92px] 
                  w-[92px]
                  overflow-hidden
                  rounded-[50%]
                  text-center
                  bg-cover
                  bg-no-repeat
                  bg-center`}
                  style={{backgroundImage: `url(${r_pp.user?.avatar || defaultImage})`}}
                >
                </div>
                {r_pp.user?.id === 32 || r_pp.user?.id === 11 ? (
                    <span className="ml-3 hover:underline block text-[#001D3D]">ITHL Sponsored <FontAwesomeIcon icon={faCircleCheck} className="text-blue-600" /></span>
                ) : (
                  <b className="text-[25px] font-bold">
                    <Link
                      className="ml-3 hover:underline block text-[#001D3D]"
                      to={`/profile/${
                          r_pp.user?.username ||
                          r_pp.user?.uuid
                      }/about`}
                      >
                      {r_pp.user?.username ||
                          r_pp.user?.name}
                      </Link>
                  </b>
                )}
              </>
            }

          </div>

          <div className='w-1/2 flex justify-end'>
            <Link to={`/profile/${user}/about`} className="bg-[#003566] px-4 py-2 rounded-[5px] text-[#fff]">
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="text-[#fff] mr-2" />
              Public Profile
            </Link>
          </div>
      </div>
      <div className="grid grid-cols-4 gap-4 mt-5 mb-5">
        <div className="bg-white drop-shadow-md min-h-[250px]">
            <div className='p-4'>
              <h3 className=' block text-[#001D3D] font-bold text-[20px]'>
                Info
              </h3>
              <ul className="w-full mt-5 text-gray-400 space-y-2 flex flex-col mb-5 leading-[16px]">
                <li className="segoe text-[14px] flex">
                  <img className="w-[14px] mr-2 relative top-1 h-[14px]" src={createdEvents} alt="Created events" />
                  {r_pp.user.events_created_by_user_count} Created events
                </li>
                <li className="segoe text-[14px] flex">
                  <img className="w-[14px] mr-2 relative top-1 h-[12px]" src={joinedEvents} alt="Created events" />
                  {r_pp.user.events_winned_count} Joined events
                </li>
                <li className="segoe text-[14px] flex">
                  <img className="w-[14px] mr-2 relative top-1 h-[14px]" src={eventsWon} alt="Created events" />
                  {r_pp.user.events_winned_count} Events won
                </li>
              </ul>
            </div>
        </div>
        <div className="bg-white drop-shadow-md min-h-[250px]">
            <div className='p-4'>
              <h3 className='block text-[#001D3D] font-bold text-[20px]'>
                Social Media
              </h3>

              <ul className="w-full mt-5 text-gray-400 space-y-2 flex flex-col">
                {
                  r_pp.user.facebook && <>
                    <Element social={r_pp.user.facebook} icon={faFacebook} text="Facebook" />
                  </>
                }
                { 
                  r_pp.user.twitter && <>
                  <Element social={r_pp.user.twitter} icon={faTwitter} text="Twitter" /> 
                  </> 
                }
                { 
                  r_pp.user.instagram && <>
                  <Element social={r_pp.user.instagram} icon={faInstagram} text="Instagram" /> 
                  </> 
                }
                { 
                  r_pp.user.twitch && <>
                  <Element social={r_pp.user.twitch} icon={faTwitch} text="Twitch" /> 
                  </> 
                }
                { 
                  r_pp.user.youtube && <>
                  <Element social={r_pp.user.youtube} icon={faYoutube} text="YouTube" /> 
                  </> 
                }
              </ul>
              
              {
                (!r_pp.user.facebook && !r_pp.user.twitter && !r_pp.user.instagram && !r_pp.user.twitch && !r_pp.user.youtube) && <>
                  <ul className="w-full mt-5 text-gray-400 space-y-2 flex flex-col">
                    <li>Not found</li>
                  </ul>
                </>
              }
            </div>
        </div>
        <div className="col-span-2 bg-white drop-shadow-md min-h-[250px]">          
          <div className='p-4'>
            <h3 className=' block text-[#001D3D] font-bold text-[20px]'>
              Contact Info
            </h3>
            <ul className="w-full mt-5 text-gray-400 space-y-2 flex flex-col mb-5 leading-[16px]">
              {
                r_pp.user.email && <>
                  <li className="segoe text-[14px] flex">
                    <img className="w-[14px] mr-2 relative top-1 h-[14px]" src={at} alt="email" />
                    {r_pp.user.email} 
                  </li>
                </>
              }
              
              {
                r_pp.user.phone && <>
                  <li className="segoe text-[14px] flex">
                    <img className="w-[14px] mr-2 relative top-1 h-[12px]" src={phone} alt="Phone" />
                    {r_pp.user.phone}
                  </li>
                </>
              }

              {
                !r_pp.user.email && !r_pp.user.phone && <>
                  <li>Not found</li>
                </>
              }
            </ul>
          </div>
        </div>
        <div className="bg-white drop-shadow-md min-h-[250px]">
        
          <div className='p-4'>
            <h3 className=' block text-[#001D3D] font-bold text-[20px]'>
              Payment
            </h3>

            {
              r_pp.user.withdrawal_methods?.length > 0 && <>
                
                <ul className="w-full mt-5 text-gray-400 space-y-2 flex flex-col mb-5 leading-[16px]">
                  {
                    r_pp.user.withdrawal_methods.map(method => {
                      return <>
                        <li className="segoe text-[14px] flex items-center">
                          <img 
                            className="w-[14px] mr-2 relative top-1 h-[14px]" 
                            src={require(`../../../assets/img/icons/${method.withdrawal_method.icon}`)}
                            alt="Method" />
                            <span className='text-[#707070]'>Complete</span>
                        </li>
                      </>
                    })
                  }
                </ul>
              </>
            }
            {
              r_pp.user.withdrawal_methods?.length < 1 && <>
                
                <ul className="w-full mt-5 text-gray-400 space-y-2 flex flex-col mb-5 leading-[16px]">
                  <li>Not found</li>
                </ul>
              </>
            }
            
          </div>
        </div>
        <div className="bg-white drop-shadow-md min-h-[250px]">
          
          <div className='p-4'>
            <h3 className=' block text-[#001D3D] font-bold text-[20px]'>
              Follow
            </h3>
            <ul className="w-full mt-5 text-gray-400 space-y-2 flex flex-col mb-5 leading-[16px]">
              <li className="segoe text-[14px] flex">
                <img className="w-[14px] mr-2 relative top-1 h-[14px]" src={users} alt="Created events" />
                {r_pp.user.followers_count} <span className='ml-2 text-[#707070]'>Folowers</span> 
              </li>
              <li className="segoe text-[14px] flex">
                <img className="w-[14px] mr-2 relative top-1 h-[12px]" src={userCheck} alt="Created events" />
                {r_pp.user.followeds_count} <span className='ml-2 text-[#707070]'>Following</span> 
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}


const Element = ( { social, icon, text } ) => {
	return social && <li className="hover:text-gray-900 cursor-pointer"><FontAwesomeIcon icon={icon} /> <a href={social} target="_blank" rel="noreferrer" >{text}</a></li>
}
import { useState, useEffect } from 'react';
import './index.scss';

export const TransformLetter = ({letterToTransform, finalAppendLetter = "$", className=""}) => {

    const [letters, setLetters] = useState([])

    useEffect(() => {
        setLetters([]);
        if(letterToTransform){
            
            setLetters(Array.from(letterToTransform));
        }


    }, [letterToTransform])
    

    return (
        <div id="transform-letters">

            <div className='transform-container'>    
                {
                    letters && (
                            
                        letters.map((letter, index) => {
                            return (
                                <div key={index} className={className + " bg-[#061422] text-yellow-c1 letter"}>
                                    {letter}
                                </div>
                            )
                        })
                            
                    )
                }
                
                {
                    (letters.length > 0 && finalAppendLetter)
                    &&
                    (
                        <div className={className + ' bg-[#061422] text-yellow-c1 letter'}>
                            {finalAppendLetter}
                        </div>
                    )
                }
            </div>
           

        </div>
    )
}

import { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { EventExplorer } from '../../components/EventExplorer';
import { UpComingEvents } from '../../components/UpComingEvents';
//import EventsLive from "./home/EventsLive";
import RecentEvents from "./home/components/RecentEvents";
import UpcomingEvents from "./home/components/UpcomingEvents";

export default function GameEvents() {
	let { type_of_event, filter_category, order_event, event_type } = useParams();
   useEffect(() => {
      console.log("filter_category, order_event, event_type ", filter_category, order_event, event_type);
   })
   
   return (
      <>
         {/* <section className="w-full min-h-[500px] bg-cover bg-no-repeat bg-[url('../img/banners/banner-events.png')] bg-center flex justify-center items-center">
            <div className="text-white text-3xl lg:text-6xl uppercase font-bold flex flex-col">
               <div className="aldo">
                  Game <span className="text-yellow-c1">Events</span>
               </div>
            </div>
         </section> */}
         {/* <UpcomingEvents type={type_of_event} /> */}
         {/*<EventsLive />*/}
         {/* <RecentEvents type={type_of_event} /> */}

         
         <UpComingEvents />
         <EventExplorer 
            category={filter_category}
            oder={order_event}
            event_type={event_type}
            isPage={true}
         />
      </>
   );
}

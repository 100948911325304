import { Link } from 'react-router-dom';
import { t } from "i18next";
import './styles.scss';

export const HowToPlay = () => {
  return (
    <div id="how-to-play" className='h-[25rem] w-full px-[3rem]'>
        <div className='flex h-full w-full items-center'>
            <div className='static-icons'>
                <img src={require('../../../../../assets/img/icons/pc-02.png')} alt="How to Play?" />
                <img className='custom-position' src={require('../../../../../assets/img/icons/camera02.png')} alt="How to Play?" />
            </div>
            <div className="how-to-body w-full grid sm:grid-cols-1 md:grid-cols-2">
                <div className='header-title text-center'>
                    <div className="subtitle">
                        <span className='segoe'>
                            {t("home.howto.play.subtitle")}
                        </span>                    
                    </div>
                    <div className="title">
                        {t("home.howto.play.title1")}
                        <span className="text-yellow-c1">{t("home.howto.play.title2")}</span>
                    </div>
                </div>
                <div className='pt-5'>                    
                    <div className='know-more transform-section'>
                        <Link to="/HowToPlay">
                            <button>
                                {t("home.howto.play.to_win")}
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='static-final'>
                <img src={require('../../../../../assets/img/icons/trophy.png')} alt="How to Play?" />
            </div>
        </div>
    </div>
  )
}

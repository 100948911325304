import { createSlice } from "@reduxjs/toolkit";

export const eventSlice = createSlice({
   name: "event",
   initialState: {
      eventConfirm:false,
      eventToConfirmation: false,
      events: [],
      pastEvents: {
         list: [],
         loading: false
      },
      joined: [],
      event: {},
      eventsToApprove: [],
      isChangeApproved: false,
      usersEvent: [],
      types: [],
      explorer: {
         data: {
            all: [],
            live_events: [],
            our_events: [],
            user_events: [],
            community_events: [],
         },
         isLoading: true
      },
      loadingNotify: false,
      notifyProof: false,
      notifyCloseEvent: false,
      loaded: false,
      loadedJoined: false,
      btnLoading: false,
      loading: false,
      errors: {
         status: false,
         message: "",
         messages: [],
      },
      errorProf: "",
      alert: {
         status: false,
         message: "Fake message",
         style: "green",
      },
      upcoming_big_prize_event: {
         isLoading: false,
         data: []
      },
      upcoming_events: [],
      upcoming_events_loaded: false,
      available_events: [],
      available_events_loaded: false,
      recent_events: [],
      recent_events_loaded: false,
      join_event: false,
		event_engagement: [],
		my_events: [],
		proof: [],
      payment_mode: {
         active: false,
         response: {}
      },
      know_role: 0
   },
   reducers: {
      setConfirmEvent: (state, {payload}) => {
         state.eventConfirm = payload;
      },
      setToValidation: (state, {payload}) => {
         state.eventToConfirmation = payload;
      },
      
      setErrorProof: (state, {payload}) => {
         state.errorProf = payload
      },
      setPastEvents: (state, {payload}) => {
         state.pastEvents.list = payload;
      },
      setLoadingPastEvents: (state, {payload}) => {
         state.pastEvents.loading = payload;
      },
      setUsersEvent: (state, {payload}) => {
         state.usersEvent = payload;
      },
      setAssets: (state, action) => {
         state.loaded = action.payload.loaded;
         state.btnLoading = action.payload.btnLoading;
         state.loading = action.payload.loading;
      },
      events: (state, action) => {
         state.events = action.payload;
         state.loaded = true;
      },
      isChangeApproved: (state, action) => {
         state.isChangeApproved = action.payload;
         state.loaded = true;
      },
      eventsToApprove: (state, action) => {
         state.eventsToApprove = action.payload;
         state.loaded = true;
      },
      errors: (state, action) => {
         state.errors.status = action.payload.status;
         state.errors.message = action.payload.message;
         state.errors.messages = action.payload.messages;
      },
      alert: (state, action) => {
         state.alert.status = action.payload.status;
         state.alert.message = action.payload.message;
         state.alert.style = action.payload.style;
      },
      resetAll: (state) => {
         state.errors.status = false;
         state.errors.message = "";
         state.errors.messages = [];
         state.alert.status = false;
         state.alert.message = "";
         state.alert.style = "green";
         state.btnLoading = false;
      },
      resetErrors: (state) => {
         state.errors.status = false;
         state.errors.message = "";
         state.errors.messages = [];
      },
      resetAlert: (state) => {
         state.alert.status = false;
         state.alert.message = "";
         state.alert.style = "green";
      },
      upcomingEvents: (state, action) => {
         state.upcoming_events = action.payload;
         state.upcoming_events_loaded = false;
      },
      
      setLoadingNotify: (state, {payload}) => {
         state.loadingNotify = payload;
      },
      setNotifyProof: (state, {payload}) => {
         state.notifyProof = payload;
      },
      setNotifyCloseEvent: (state, {payload}) => {
         state.notifyCloseEvent = payload;
      },
      upcomingEventsLoading: (state, {payload}) => {
         state.upcoming_events_loaded = payload;
      },
      types: (state, action) => {
         state.types = action.payload;
      },

      upcommingBigPrizeEvent: (state, {payload}) => {
         state.upcoming_big_prize_event.data = payload;
         state.upcoming_big_prize_event.isLoading = false
      },
      
      setIsLoadingUpcommingBigPrizeEvent: (state, {payload}) => {
         state.upcoming_big_prize_event.isLoading = payload
      },

      setExplorerData: (state, {payload}) => {
         state.explorer.isLoading = false;
         if(state.explorer.data[payload.category]){
            state.explorer.data[payload.category] = payload.data;
         }
      },
      setLoadingExplorer: (state, {payload}) => {
         state.explorer.isLoading = payload;
      },

      upCommingEvents: (state, action) => {
         state.upCommingEvents = action.payload;
         state.upCommingEventsLoaded = true;
      },
      availableEvents: (state, action) => {
         state.available_events = action.payload;
         state.available_events_loaded = true;
      },
      recentEvents: (state, action) => {
         state.recent_events = action.payload;
         state.recent_events_loaded = true;
      },
      showEvent: (state, action) => {
         state.event = {...action.payload, ...{proof_uploaded: false}};
         state.loading = false;
      },
      statusProofUploaded: (state, {payload}) => {
         state.event.proof_uploaded = payload;
      },
      joinEventModal: (state, action) => {
         state.join_event = action.payload;
      },
		eventEngagement: (state, action) => {
			state.event_engagement = action.payload
		},
		myEvents: (state, action) => {
			state.my_events = action.payload;
			state.loading = false
		},
		proof: (state, action) => {
			state.proof = action.payload
			state.loading = false
		},
      onDelete: (state) => {
         state.loaded = false
      },
      paymentMode: (state, action) => {
         state.payment_mode.active = action.payload.active;
         state.payment_mode.response = action.payload.response;
      },
      loading: (state, action) => {
         state.loading = action.payload
      },
      knowRole: (state, action) => {
         state.know_role = action.payload;
      },
      eventsJoined: (state, action) => {
         state.joined = action.payload;
         state.loadedJoined = true
      }
   },
});

export const {
   isChangeApproved,
   setToValidation,
   setConfirmEvent,
   setErrorProof,
   setLoadingPastEvents,
   setPastEvents,
   setUsersEvent,
   setLoadingNotify,
   setNotifyProof,
   setNotifyCloseEvent,
   setExplorerData,
   setLoadingExplorer,
   types,
   upCommingEvents,
   setAssets,
   eventsToApprove,
   events,
   errors,
   alert,
   resetAll,
   resetErrors,
   resetAlert,
   upcomingEvents,
   upcomingEventsLoading,
   availableEvents,
   recentEvents,
   showEvent,
	joinEventModal,
	eventEngagement,
	myEvents,
	proof,
   onDelete,
   paymentMode,
   loading,
   knowRole,
   upcommingBigPrizeEvent,
   setIsLoadingUpcommingBigPrizeEvent,
   eventsJoined,
   statusProofUploaded
} = eventSlice.actions;
export default eventSlice.reducer;

import React from 'react';
import './styles.scss';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export const Badge = (props) => {
  let { className, text, dot } = props;
  const [isOpenLive, setIsOpenLive] = useState(false);
  const [isOpenFree, setIsOpenFree] = useState(false);
  const [isOpenJoined, setIsOpenJoined] = useState(false);

  return (
    <>
      {/* 
      <div className={className + " rounded-[3px] px-[8px] py-[2px] w-max custom-badge"}>
        {dot && (<div className="w-[10px] h-[10px] rounded-[50%] bg-black mr-[10px]"></div>)}
        <div className='uppercase text-red font-bold text-[13px] text-black'>
          {text}
        </div>
      </div>
    */}
    
      { text === "Live" && (
        <div>
          <Link to={"/explore-events/live_events"}
            className={className + " rounded-3xl p-1  w-8 h-8 custom-badge"} 
            onMouseOut={() => {
              setIsOpenLive(false);
            }} 
            onMouseOver={() => {setIsOpenLive(true)} }>

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-black">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.348 14.651a3.75 3.75 0 010-5.303m5.304 0a3.75 3.75 0 010 5.303m-7.425 2.122a6.75 6.75 0 010-9.546m9.546 0a6.75 6.75 0 010 9.546M5.106 18.894c-3.808-3.808-3.808-9.98 0-13.789m13.788 0c3.808 3.808 3.808 9.981 0 13.79M12 12h.008v.007H12V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
              </svg>
              
          
          </Link>
          {
            isOpenLive && (
              <div className='opacity-75 segoe bg-[#fff] text-black m-auto text-center w-[70px] text-[12px] absolute right-[-25px] top-[30px] absolute rounded-[3px] py-1 px-2'>
                Live now
              </div>
            )
          }
        </div>
        
      )}
      
      { text === "Joined" && (
        <div>
          <span
            className={className + " rounded-3xl p-1 w-8 h-8"}
            onMouseOut={() => {
              setIsOpenJoined(false);
            }} 
            onMouseOver={() => {setIsOpenJoined(true)} }>
        
            <span className='text-[8px] text-[#fff] -rotate-45 font-bold text-black'>Joined</span>
          </span>
          
          {
            isOpenJoined && (
              <div className='opacity-75 segoe bg-[#fff] text-black m-auto text-center w-[70px] text-[12px] absolute right-[55px] top-[30px] absolute rounded-[3px] py-1 px-2'>
                Joined
              </div>
            )
          }
        </div>
      )}
      { text === "Free" && (
        <div>
          <Link to={"/explore-events/all/free"}
            className={className + " rounded-3xl p-1 w-8 h-8 custom-badge"}            
            onMouseOut={() => {
              setIsOpenFree(false);
            }} 
            onMouseOver={() => {setIsOpenFree(true)} }>
            <span className='text-xs -rotate-45 font-bold text-black'>Free</span>
          </Link>
          
          {
            isOpenFree && (
              <div className='opacity-75 segoe bg-[#fff] text-black m-auto text-center w-[70px] text-[12px] absolute right-[15px] top-[30px] absolute rounded-[3px] py-1 px-2'>
                Free
              </div>
            )
          }
        </div>
      )}
    </>
  )
}

import CustomModal from "../../components/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import AdminInput from "../../components/admin/admin.input";
import AdminButton from "../../components/admin/admin.button";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { updateRoles } from "../../store/actions/_role.actions";

export default function RoleUpdate({ status, close }) {
  const dispatch = useDispatch();
  const r_role = useSelector((state) => state.role);
  const [data, setData] = useState(undefined);

  useEffect(() => {
    setData(r_role.roles[r_role.modal.key]);
    return () => {
      setData(undefined);
    };
  }, [r_role.modal.key, r_role.roles]);

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(updateRoles(data, data.id, r_role.modal.key));
  };

  return (
    data && (
      <CustomModal
        title="Update Role"
        size="w-1/2"
        status={status}
        closedButton={close}
      >
        <form onSubmit={handleSubmit}>
          <AdminInput
            className=""
            onChange={handleChange}
            type="text"
            id="name"
            placeholder="Role Name"
            errors={r_role.error}
            // label="Role Name"
            value={data.name}
            // disabled={false}
            // pattern=""
          />
          <div className="w-1/2 mx-auto">
            <AdminButton
              status={r_role.btnLoading}
              text="Update"
              type="submit"
              icon={faCheck}
            />
          </div>
        </form>
      </CustomModal>
    )
  );
}

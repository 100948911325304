export const Error = ({ status, children }) => {
  return (
    status && (
      <div className="bg-red-200 text-red-400 border border-red-300">
        {children}
      </div>
    )
  );
};

export const Info = ({ status, children }) => {
  return (
    status && (
      <div className="bg-blue-200 text-blue-700 border border-blue-400 shadow w-1/2 mx-auto mt-10 p-2">
        {children}
      </div>
    )
  );
};

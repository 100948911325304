import api from "../../utils/api";
import { user, setAssets, errors, alert, avatar, banner, setFolloweds } from "../reducers/_profile.reducers";
import { modal } from "../_main.reducers";
import { invoices, loading } from '../reducers/_subscriptions.reducers'

const setAssetsFn = (dispatch, btnLoading, loading, loaded) => {
   dispatch(
      setAssets({
         btnLoading: btnLoading,
         loaded: loaded,
         loading: loading,
      })
   );
};

export const getUser = () => (dispatch) => {
   setAssetsFn(dispatch, false, true, false);
   api.get("/profile")
      .then((response) => {
         if(response.data){
            if(response.data.length > 0){
               let userData = response.data[0];
               dispatch(user(userData));
               let usersFolloweds = [];
               if(userData.followeds.length > 0){
                  userData.followeds.map(item => {
                     usersFolloweds.push(item.following_user_id);
                  });
               }
            
               dispatch(setFolloweds(usersFolloweds));
            }
         }
      })
      .catch((error) => console.log(error))
      .then(() => setAssetsFn(dispatch, false, false, true));
};

export const closeAlert = () => (dispatch) => {
   dispatch(
      alert({
         status: false,
         message: "",
         style: "green",
      })
   );
};

export const updateProfile = (data) => (dispatch) => {
   setAssetsFn(dispatch, true, false, true);
   api.put("/profile", data)
      .then((response) => {
         dispatch(user(response.data));
         dispatch(
            alert({
               status: true,
               message: "Success",
               style: "green",
            })
         );
         dispatch(
            errors({
               status: false,
               message: "",
               messages: [],
            })
         );
      })
      .catch((error) => {
         dispatch(
            errors({
               status: true,
               message: error.response.data.message,
               messages: error.response.data.errors,
            })
         );
      })
      .then(() => {
         setAssetsFn(dispatch, false, false, true);
      });
};


export const updateAvatar = (data) => (dispatch) => {
   setAssetsFn(dispatch, true, false, true);
   api.post("/users/update-avatar", data)
      .then((response) => {
         dispatch(avatar(response.data));
         dispatch(modal(false));
      })
      .catch((error) =>
         dispatch(
            errors({
               status: true,
               message: error.response.data.message,
               messages: error.response.data.errors,
            })
         )
      )
      .then(() => setAssetsFn(dispatch, false, false, true));
};

export const updateBanner = (data) => (dispatch) => {
   setAssetsFn(dispatch, true, false, true);
   api.post("/users/update-banner", data)
      .then((response) => {
         dispatch(banner(response.data));
      })
      .catch((error) =>
         dispatch(
            errors({
               status: true,
               message: error.response.data.message,
               messages: error.response.data.errors,
            })
         )
      )
      .then(() => setAssetsFn(dispatch, false, false, true));
};

export const updatePassword = (data) => (dispatch) => {
   setAssetsFn(dispatch, true, false, true);
   dispatch(
      errors({
         status: false,
         message: "",
         messages: [],
      })
   );
   api.post("/users/update-password", data)
      .then((response) => {
         dispatch(
            alert({
               status: true,
               message: "Your password had been changed",
               style: "green",
            })
         );
      })
      .catch((error) =>
         dispatch(
            errors({
               status: true,
               message: error.response.data.message,
               messages: error.response.data.errors,
            })
         )
      )
      .then(() => setAssetsFn(dispatch, false, false, true));
};

export const getInvoices = () => async dispatch => {
	dispatch(loading(true))
	try {
		const response = await api.get('/subscription/invoices');
		dispatch(invoices(response.data))
	} catch (error) {
		console.error(error)
	} finally {
		dispatch(loading(false))
	}
}
import React, { useState, useEffect } from 'react';
import { BasicEventCard } from '../../../components/basicEventCard/';
import Loading from '../../../components/Loading';
import { CustomNotFoundData } from './CustomNotFoundData';

export const ContainerCardExploreEvents = ({eventData}) => {
  const {eventsExplorer, isLoading, categoryEvent, eventFilter} = eventData;
  const [listEvents, setListEvents] = useState([]);


  useEffect(() => {
    if(categoryEvent){
      if(eventsExplorer[categoryEvent]?.data){
        setListEvents(eventsExplorer[categoryEvent]?.data);
      }
    }
  }, [categoryEvent, eventsExplorer])
  

  

  return (
    <div id="event-explorer" className='scrollbar custom-scroll'>
      {
        (listEvents && !isLoading) && (
          <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4 m-auto'>
            {
              listEvents.map((event, key) => {
                return (
                  <BasicEventCard
                  className=""
                  data={event} 
                  key={key} 
                  categoryEvent= {categoryEvent}
                  />
                )
              })
            }
          </div>
        )
      }
        
      {isLoading && <Loading color="white" />}


      {
        (listEvents.length < 1 && !isLoading) &&
        <CustomNotFoundData categoryEvent={categoryEvent} eventFilter = {eventFilter} />
      }

      
      
    </div>
  )
}



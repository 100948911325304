import { faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AdminButton(props) {
  const { status, text, type, icon } = props;
  let textBtn = text || "Register";
  let defaultIcon = icon || faCheck
  return (
    <button
      disabled={status}
      className={`w-full bg-blue-500 text-white font-bold border-b-4 border-blue-700 p-2 mt-5 text-sm md:text-base  mx-2 ${
        status ? "opacity-60 cursor-not-allowed" : "active:scale-95"
      }`}
      type={type || "submit"}
    >
      {status ? (
        <>
          <FontAwesomeIcon icon={faSpinner} spin /> Loading
        </>
      ) : (
        <>
          <FontAwesomeIcon icon={defaultIcon} /> {textBtn}
        </>
      )}
    </button>
  );
}

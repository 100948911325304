import api from "../../utils/api";
import { isLoading, setSearchData } from "../reducers/_search.reducers";

export const getSearchData = ({find, type}) => async (dispatch) => {
  dispatch(isLoading(true));
  try {
    const response = await api.get(`/search?find=${find}&type=${type}`);
    
    dispatch(setSearchData(response.data));

  } catch (error) {
    console.error(error);
  } finally {
    dispatch(isLoading(false));
  }

};
